import React, { PureComponent } from 'react'
import { withRouter, NavLink } from 'react-router-dom'

import Axios from 'axios'
import ZIP from 'node-zip'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@material-ui/icons/Search'
import DeleteIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import Tooltip from '@material-ui/core/Tooltip'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CopyIcon from '@material-ui/icons/Assignment'
import ViewIcon from '@material-ui/icons/OpenInNew'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import XMLViewer from 'react-xml-viewer'
import JSONPretty from 'react-json-prettify'

import Config from '../config'
import Utils from '../utils'
import awsLogo from '../images/aws.svg'

import AWS from 'aws-sdk'
import {decodeSDCIRecord} from './sdci'

import {getStyleDefs} from './styles'

import {atelierForestLight} from 'react-json-prettify/dist/themes'
atelierForestLight.background = "rgb(255,255,255)"

// TODO: Need to show in SALES part if part has been refunded.

var s3Client
var CWL

const styles = theme => (getStyleDefs(theme))

class Bookings extends PureComponent {

  constructor(props, context) {
    super(props, context)
    this.state = this.getDefaultState("")
  }

  componentDidMount() {
    let {stage} = this.props.match.params
    Config.setConfig('mode',stage)
    this.setState(this.getDefaultState(stage))
  }

  componentDidUpdate(prevProps) {
    let {stage} = this.props.match.params

    if(prevProps.match.params.stage !== stage)
    {
      this.setState({uniqueErrors:{data:[]}},() => {
        this.setState({stage})
        this.setState(this.getDefaultState(stage))
      })
    }
  }

  getDefaultState(stage) {
    return {
      expanded : 'sales',
      errorMessage : null,
      expandedJourney : false,
      searchTerm : "",
      stage:stage,
      bookingReference : null,
      salesData:null,
      journeyData:[],
      auditData:[],
      callLogData:{
        call : null,
        data : null,
        pdf : []
      },
      sdciRecords : [],
      sdciReport : null,
      shiftRecords : null,
      errorData : [],
      dialog : {
        open : false,
        title : "",
        content : "",
      }
    }
  }

  async findBookingRef (searchTerm) {

    try
    {
      if (!this.state.stage) { return }

      let awsconfig = Config.getConfig('awsConfig')

      CWL = new AWS.CloudWatchLogs(awsconfig)
      s3Client = new AWS.S3(awsconfig)

      let options = {
        method : 'POST',
        url : `${Config.getConfig('adminApi')}request`, // can be booking ref, ticket ref, reservation ref or aws request ID
        data : {
          pkg : "Bookings",
          fn : "findBookingRef",
          params : {
            mode : this.state.stage,
            searchTerm : searchTerm
          }
        },
        headers : {
          'Content-type': 'application/json',
          'x-api-key': Config.getAPIKey(),
        },
      }

      this.setState({errorMessage:null})
      
      let result = await Axios(options)

      this.setState({bookingReference:result.data.data})
      this.getAllData(result.data.data)        
    }
    catch(err)
    {
      this.setState({errorMessage:"Booking not found"})
      console.error("findBookingRef error",err)
    }
  }

  async getAllData(bookingReference) {
    this.getSalesData(bookingReference)
    this.getJourneyData(bookingReference)
  //    this.getBookingAuditData(bookingReference)
  }

  async getBookingAuditData(bookingReference) {

    try
    {
      let options = {
        method : 'POST',
        url : `${Config.getConfig('adminApi')}request`,
        data : {
          pkg : "Bookings",
          fn : "getBookingAudit",
          params : {
            mode : this.state.stage,
            bookingReference : bookingReference
          }
        },
        headers : {
          'Content-type': 'application/json',
          'x-api-key': Config.getAPIKey(),
        },
      }

      let result = await Axios(options)
      this.setState({auditData:result.data.data})
    }
    catch(err)
    {
      console.error(err)
    }    
  }

  async getSalesData(bookingReference) {

    try
    {
      let options = {
        method : 'POST',
        url : `${Config.getConfig('adminApi')}request`,
        data : {
          pkg : "Bookings",
          fn : "getSalesData",
          params : {
            mode : this.state.stage,
            bookingReference : bookingReference
          }
        },
        headers : {
          'Content-type': 'application/json',
          'x-api-key': Config.getAPIKey(),
        },
      }

      let result = await Axios(options)
      
      this.setState({salesData:result.data.data})
      this.getCallLog(result.data.data.awsRequestId)
      this.getS3logs(result.data.data.awsRequestId)
//      this.getTicketPDF(result.data.data.awsRequestId)
      this.getErrors(result.data.data.awsRequestId)
      this.getSDCIData(result.data.data.licensee,result.data.data.machineId,bookingReference)
    }
    catch(err)
    {
      console.error(err)
    }
  }

  async getSDCIData(licensee,machineId,bookingReference) {

    try
    {
      let options = {
        method : 'POST',
        url : `${Config.getConfig('adminApi')}request`,
        data : {
          pkg : "Bookings",
          fn : "getSDCIRecords",
          params : {
            mode : this.state.stage,
            licensee : licensee,
            machineId : machineId,
            bookingReference : bookingReference
          }
        },
        headers : {
          'Content-type': 'application/json',
          'x-api-key': Config.getAPIKey(),
        },
      }

      let result = await Axios(options)

      this.setState({sdciRecords : result.data.data})

      this.getSDCIReport(licensee,machineId,result.data.data[0].shiftId)
    }
    catch(err)
    {
      console.error(err)
    }
  }

  async getSDCIReport(licensee,machineId,shiftId) {

    try
    {
      let options = {
        method : 'POST',
        url : `${Config.getConfig('adminApi')}request`,
        data : {
          pkg : "Bookings",
          fn : "getSDCIReport",
          params : {
            mode : this.state.stage,
            licensee : licensee,
            machineId : machineId,
            shiftId : shiftId
          }
        },
        headers : {
          'Content-type': 'application/json',
          'x-api-key': Config.getAPIKey(),
        },
      }

      let result = await Axios(options)

      let shift = result.data.data

      shift.report = JSON.parse(shift.report)

      this.setState({sdciReport : shift})
      this.setState({shiftRecords : shift.shiftRecords})
    }
    catch(err)
    {
      console.error(err)
    }
  }

  async getErrors(awsRequestId) {

    try
    {
      let options = {
        method : 'POST',
        url : `${Config.getConfig('adminApi')}request`,
        data : {
          pkg : "Bookings",
          fn : "getRequestErrors",
          params : {
            mode : this.state.stage,
            awsRequestId : awsRequestId,
          }
        },
        headers : {
          'Content-type': 'application/json',
          'x-api-key': Config.getAPIKey(),
        },
      }

      let result = await Axios(options)

      this.setState({errorData : result.data.data})
    }
    catch(err)
    {
      console.error(err)
    }
  }

  async getCallLog(awsRequestId) {

    try
    {
      let options = {
        method : 'POST',
        url : `${Config.getConfig('adminApi')}request`,
        data : {
          pkg : "Bookings",
          fn : "getCallLog",
          params : {
            mode : this.state.stage,
            awsRequestId : awsRequestId,
          }
        },
        headers : {
          'Content-type': 'application/json',
          'x-api-key': Config.getAPIKey(),
        },
      }

      let result = await Axios(options)

      let callLogData = {...this.state.callLogData}

      console.log("callLogData",callLogData)
      
      callLogData.call = result.data.data

      if (callLogData && callLogData.call)
      {
        this.setState({callLogData},() => {
          let logGroupName = `/aws/lambda/${callLogData.call.api}-${callLogData.call.stage}-${callLogData.call.functionName}`
          this.getCloudwatchLogs(logGroupName,callLogData.call.logStreamName,callLogData.call.awsRequestId)  
        })  
      }
    }
    catch(err)
    {
      console.error("getCallLog",err)
    }
  }

  async getCloudwatchLogs(logGroupName,logStreamName,awsRequestId) {

    try
    {
      let params = {
        logGroupName : logGroupName,
        logStreamNames : [logStreamName],
        interleaved : true,
        filterPattern : `"${awsRequestId}"`
      }

      let logs = (await CWL.filterLogEvents(params).promise()).events

      let callLogData = {...this.state.callLogData}
      callLogData.cloudwatch = logs

      this.setState({callLogData})
    }
    catch(err)
    {
      console.error(err)
    }
  }

  async getS3logs(awsRequestId) {

    try
    {
      // Need to get logs from S3. Need mode (this.state.stage) and awsRequestId
      // Data is in a ZIP file. We want to stream ZIP (not download) and extract data

      const params = {
        Bucket: `com.hazardousfrog.logs.${this.state.stage}`,
        Key: `${awsRequestId}.zip`,
      }

      let data = await s3Client.getObject(params).promise()
      let zip = new ZIP(data.Body,{base64: false, checkCRC32: true})
      let logs = (JSON.parse(zip.files['data.json']._data))

      let callLogData = {...this.state.callLogData}
      
      callLogData.data = {
        timings : JSON.parse(logs.timings),
        lsm : logs.lsm ? JSON.parse(logs.lsm) : null,
        nrs : logs.nrs ? JSON.parse(logs.nrs) :  null,
        request : logs.request ? JSON.parse(logs.request) :  null,
        response : logs.response ? JSON.parse(logs.response) :  null,
        jpData : logs.jp ? JSON.parse(logs.jp) : null
      }

      this.setState({callLogData})
    }
    catch(err)
    {
      console.error("ERROR!",err)
    }
  }

  async getTicketPDF(awsRequestId) {

    try
    {
      let options = {
        method : 'POST',
        url : `${Config.getConfig('adminApi')}request`,
        data : {
          pkg : "Bookings",
          fn : "getTicketPDF",
          params : {
            mode : this.state.stage,
            awsRequestId : awsRequestId,
          }
        },
        headers : {
          'Content-type': 'application/json',
          'x-api-key': Config.getAPIKey(),
        },
      }

      let result = await Axios(options)

      let callLogData = {...this.state.callLogData}

      let sortedByCartItem = {}
      
      for(let p=0;p<result.data.data.length;p++)
      {
        let item = result.data.data[p]

        if (!sortedByCartItem[item.cartItem]) { sortedByCartItem[item.cartItem] = [] }

        sortedByCartItem[item.cartItem].push(item)
      }

      callLogData.pdf = sortedByCartItem

      console.log("eTicket PDF",callLogData.pdf)

      this.setState({callLogData})
    }
    catch(err)
    {
      console.error(err)
    }
  }

  async getJourneyData(bookingReference) {

    try
    {
      let options = {
        method : 'POST',
        url : `${Config.getConfig('adminApi')}request`,
        data : {
          pkg : "Bookings",
          fn : "getJourneyData",
          params : {
            mode : this.state.stage,
            bookingReference : bookingReference,
          }
        },
        headers : {
          'Content-type': 'application/json',
          'x-api-key': Config.getAPIKey(),
        },
      }

      let result = await Axios(options)
      this.setState({journeyData:result.data.data})

      for (let j=0;j<result.data.data.length;j++)
      {
        this.getTicketsForJourney(bookingReference,j)
        this.getReservationsForJourney(result.data.data[j].reservationRef,j)
        this.getSeatsForJourney(result.data.data[j].reservationRef,j)
      }
    }
    catch(err)
    {
      console.error(err)
    }
  }

  async getTicketsForJourney(bookingReference,cartItem) {

    try
    {
      let options = {
        method : 'POST',
        url : `${Config.getConfig('adminApi')}request`,
        data : {
          pkg : "Bookings",
          fn : "getTicketsForJourney",
          params : {
            mode : this.state.stage,
            bookingReference : bookingReference,
            cartItem : cartItem
          }
        },
        headers : {
          'Content-type': 'application/json',
          'x-api-key': Config.getAPIKey(),
        },
      }

      let result = await Axios(options)

      let journeyData = [...this.state.journeyData]

      let tickets = result.data.data

      for (let t=0;t<tickets.length;t++)
      {
        // Not got this function for some reason...
       // tickets[t].auditData = await this.getTicketAuditData(bookingReference,cartItem,t,tickets[t].ticketRef)
        
        if (tickets[t].fulfilmentMethod === "tod")
        {
          try
          {

            let ctrData = await this.getCTR(bookingReference,tickets[t].ticketRef)
            tickets[t].ctr = ctrData.ctr
            tickets[t].decodedCtr = ctrData.decodedCtr
            console.log("ctr",tickets[t].ctr)
          }
          catch(err)
          {
            console.log("Could not get CTR",err)
          }
        }
      }

      journeyData[cartItem].tickets = tickets

      this.setState({journeyData})
    }
    catch(err)
    {
      console.error(err)      
    } 
  }

  async getCTR(bookingReference,ticketReference) {

    try
    {
      let options = {
        method : 'POST',
        url : `${Config.getConfig('adminApi')}request`,
        data : {
          pkg : "Bookings",
          fn : "recallCTR",
          params : {
            mode : this.state.stage,
            bookingReference : bookingReference,
            ticketReference : ticketReference,
            machineId : this.state.salesData.machineId,
            licensee : this.state.salesData.licensee
          }
        },
        headers : {
          'Content-type': 'application/json',
          'x-api-key': Config.getAPIKey(),
        },
      }

      console.log("getCTR",options)

      let result = await Axios(options)

      console.log("CTR",result.data)

      return result.data.data
    }
    catch(err)
    {
      console.error("ERROR",err)
    } 
  }

  async getTicketAuditData(bookingReference,cartItem,ticketIndex,ticketReference) {

    try
    {
      let options = {
        method : 'POST',
        url : `${Config.getConfig('adminApi')}request`,
        data : {
          pkg : "Bookings",
          fn : "getTicketAudit",
          params : {
            mode : this.state.stage,
            bookingReference : bookingReference,
            ticketReference : ticketReference,
          }
        },
        headers : {
          'Content-type': 'application/json',
          'x-api-key': Config.getAPIKey(),
        },
      }

      let result = await Axios(options)

      console.log("Ticket audit data",result.data)
      return result.data.data
    }
    catch(err)
    {
      console.error(err)
    }
  }

  async getReservationsForJourney(reservationReference,cartItem) {

    try
    {
      let options = {
        method : 'POST',
        url : `${Config.getConfig('adminApi')}request`,
        data : {
          pkg : "Bookings",
          fn : "getReservationsForJourney",
          params : {
            mode : this.state.stage,
            reservationReference : reservationReference,
          }
        },
        headers : {
          'Content-type': 'application/json',
          'x-api-key': Config.getAPIKey(),
        },
      }

      let result = await Axios(options)
      let journeyData = [...this.state.journeyData]

      journeyData[cartItem].reservation = result.data.data

      console.log("getReservationsForJourney",result.data)

      this.setState({journeyData})
    }
    catch(err)
    {
      console.error(err)
    }    
  }

  async getSeatsForJourney(reservationReference,cartItem) {

    try
    {
      let options = {
        method : 'POST',
        url : `${Config.getConfig('adminApi')}request`,
        data : {
          pkg : "Bookings",
          fn : "getSeatsForJourney",
          params : {
            reservationReference : reservationReference,
          }
        },
        headers : {
          'Content-type': 'application/json',
          'x-api-key': Config.getAPIKey(),
        },
      }

      let result = await Axios(options)
      let journeyData = [...this.state.journeyData]

      journeyData[cartItem].seats = result.data.data

      this.setState({journeyData})
    }
    catch(err)
    {
      console.error(err)
    }    
  }

  viewCTR(ticket) {
    let dialog = {...this.state.dialog}
    dialog.open = true
    dialog.title = "Ticket " + ticket.ticketRef
    dialog.content = (
      <div>
        <XMLViewer xml={ticket.ctr} />
        <pre>{JSON.stringify(ticket.decodedCtr,null,2)}</pre>
      </div>
    )
    this.setState({dialog})
  }

  // TODO: Tickets : Add function to decode barcode payload
  async viewETicketPayload(ticket)
  {
    const { classes } = this.props

    let dialog = {...this.state.dialog}
    dialog.open = true
    dialog.title = "Ticket " + ticket.ticketRef
    dialog.content = (
      <React.Fragment>
        <Typography variant="caption" gutterBottom>Outbound payload</Typography>
        <div className={classes.eTicketPayloadDiv}>{ticket.payloadOut}</div>

        {ticket.payloadRtn &&
           <React.Fragment>
             <Typography variant="caption" gutterBottom>Inbound payload</Typography>
             <div className={classes.eTicketPayloadDiv}>{ticket.payloadRtn}</div>
          </React.Fragment>
        }
      </React.Fragment>
    )
    this.setState({dialog})
  }

  viewPDF(ticket)
  {
    let data = new Blob([new Uint8Array(ticket.pdfData.data).buffer], {type: 'application/pdf'})
    let pdfURL = window.URL.createObjectURL(data)
    let tempLink = document.createElement('a')
    tempLink.href = pdfURL
    tempLink.setAttribute('target', '_blank')
    tempLink.click()
  }


  closeDialogue = () =>
  {
    let dialog = {...this.state.dialog}
    dialog.open = false
    this.setState({dialog})
  }

  async viewSDCIRecord(sdciRecord) {
    const { classes } = this.props

    console.log("viewSDCIRecord",sdciRecord)
    let recordData = await decodeSDCIRecord(sdciRecord)
    console.log("recordData",recordData)

    let dialog = {...this.state.dialog}
    dialog.open = true
    dialog.title = "SDCI Record"
    dialog.content = (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCellHeader}colSpan={4}>{sdciRecord.recordData}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableCellHeader}>Field</TableCell>
            <TableCell className={classes.tableCellHeader}>Field Name</TableCell>
            <TableCell className={classes.tableCellHeader}>Raw Data</TableCell>
            <TableCell className={classes.tableCellHeader}>Value</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {recordData.map((item,index) => (  
            <TableRow className={classes.stripeRow} key={index}>
              <TableCell className={classes.tableCellHeader}>{index +1}</TableCell>
              <TableCell className={classes.tableCellHeader}>{item.name}</TableCell>
              <TableCell className={classes.tableCellData}>{item.rawdata}</TableCell>
              <TableCell className={classes.tableCellData}>{item.data}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
    this.setState({dialog})
  }

  displayJourney(classes,journey) {
    return (
      <div>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCellHeader}>Fulfilment Method</TableCell>
              <TableCell className={classes.tableCellData}>{journey.fulfilmentMethod}</TableCell>
              <TableCell className={classes.tableCellHeader}>Type</TableCell>
              <TableCell className={classes.tableCellData}>{journey.singleOrReturn === 'R' ? 'Return' : 'Single'}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.tableCellHeader}>Description</TableCell>
              <TableCell colSpan={3} className={classes.tableCellDataWide}>{journey.description}</TableCell>
            </TableRow>

            {journey.newBookingRef &&
              <TableRow>
                <TableCell colSpan={3} className={classes.tableCellHeader}>Advance fare Change of travel time new Booking Reference</TableCell>
                <TableCell className={classes.tableCellData}>{journey.newBookingRef}</TableCell>
              </TableRow>
            }

            <TableRow>
              <TableCell className={classes.tableCellHeader}>Departure Date</TableCell>
              <TableCell className={classes.tableCellData}>{Utils.formattedDateTime(Utils.parseDateTime(journey.depart))}</TableCell>
              <TableCell className={classes.tableCellHeader}>Return Date</TableCell>
              <TableCell className={classes.tableCellData}>{journey.singleOrReturn === "R" ? Utils.formattedDateTime(Utils.parseDateTime(journey.return)) : '-'}</TableCell>
            </TableRow>

            {journey.reservation && 
              <TableRow>
                <TableCell className={classes.tableCellHeader}>Reservation Reference</TableCell>
                <TableCell className={classes.tableCellData}>{journey.reservationRef}</TableCell>
                <TableCell className={classes.tableCellHeader}>Cancelled / Refunded</TableCell>
                <TableCell className={classes.tableCellData}>{journey.cancelled ? Utils.formattedDateTime(Utils.parseDBDateTime(journey.cancelledDateTime)) : 'No'}</TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>

        {journey.tickets && journey.tickets.length > 0 && 

          <React.Fragment>

            <br/><br/>
            <Typography className={classes.heading}>Tickets</Typography>

            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Ticket Reference</TableCell>
                  <TableCell className={classes.tableCell}>Origin</TableCell>
                  <TableCell className={classes.tableCell}>Destination</TableCell>
                  <TableCell className={classes.tableCell}>Direction</TableCell>
                  <TableCell className={classes.tableCell}>Depart</TableCell>
                  <TableCell className={classes.tableCell}>Return</TableCell>
                  <TableCell className={classes.tableCell}>Fare Code</TableCell>
                  <TableCell className={classes.tableCell}>SoR</TableCell>
                  <TableCell className={classes.tableCell}>Route</TableCell>
                  <TableCell className={classes.tableCell}>Price</TableCell>
                  <TableCell className={classes.tableCell}>Discount</TableCell>
                  <TableCell className={classes.tableCell}>Adults</TableCell>
                  <TableCell className={classes.tableCell}>Children</TableCell>
                  <TableCell className={classes.tableCell}>Data</TableCell>
                  {/* TODO: Tickets : If eTicket, show if used */}
                </TableRow>
              </TableHead>

              <TableBody>
                {journey.tickets.map((ticket,index) => {
                  return (
                    <React.Fragment key={index}>
                      <TableRow className={classes.stripeRow}>
                        <TableCell className={classes.tableCell}>{ticket.ticketRef}</TableCell>
                        <TableCell className={classes.tableCell}>{ticket.origin} {/* TODO: Ticket Origin : Show CRS with tooltip for NLC / Name - use fareOrigin */}</TableCell>
                        <TableCell className={classes.tableCell}>{ticket.destination}{/* TODO: Ticket Destination : Show CRS with tooltip for NLC / Name* - use fareDestination */}</TableCell> 
                        <TableCell className={classes.tableCell}>{ticket.journeyDirection}</TableCell>
                        <TableCell className={classes.tableCell}>{Utils.formattedDateTime(ticket.departureDate)}</TableCell>
                        <TableCell className={classes.tableCell}>{Utils.formattedDateTime(ticket.returnDate)}</TableCell>
                        <TableCell className={classes.tableCell}>{ticket.fareCode}</TableCell>
                        <TableCell className={classes.tableCell}>{ticket.fareType}</TableCell>
                        <Tooltip title={`Via London: ${ticket.viaLondon ? 'Y' : 'N'}`}><TableCell className={classes.tableCell}>{ticket.routeCode}{/* TODO: Ticket Route : Add route code tooltip*/}</TableCell></Tooltip> 
                        <TableCell className={classes.tableCell}>£{Utils.twoDp(ticket.price/100)}</TableCell>
                        <TableCell className={classes.tableCell}>{ticket.railcard ? ticket.railcard : ticket.discountCode ? `${ticket.discountCode} (${ticket.discountPercent} %)` : '-'}</TableCell>
                        <TableCell className={classes.tableCell}>{ticket.numAdults}</TableCell>
                        <TableCell className={classes.tableCell}>{ticket.numChildren}</TableCell>
                        {journey.fulfilmentMethod === 'tod' && 
                          <TableCell className={classes.tableCell}><IconButton color="primary" onClick={event => this.viewCTR(ticket)}><ViewIcon /></IconButton></TableCell>
                        }
                        {journey.fulfilmentMethod === 'eticket' && 
                          <TableCell className={classes.tableCell}><IconButton color="primary" onClick={event => this.viewETicketPayload(ticket)}><ViewIcon /></IconButton></TableCell>
                        }
                      </TableRow>
                    </React.Fragment>
                  )
                })}

              </TableBody>
            </Table>

          </React.Fragment>
        }

        {this.state.callLogData.pdf[journey.cartItem] &&
          <React.Fragment>
            <br/><br/>
            <Typography className={classes.heading}>PDFs (Disable AdBlock!)</Typography>
            <Table className={classes.table}>
              {this.state.callLogData.pdf[journey.cartItem].map(ticket => {
                return (
                  <TableRow>
                    <TableCell className={classes.tableCell}>{ticket.filename}</TableCell>
                    <TableCell className={classes.tableCell}><IconButton color="primary" onClick={event => this.viewPDF(ticket)}><ViewIcon /></IconButton></TableCell>
                  </TableRow>
                )
              })}
            </Table>
          </React.Fragment>
        } 

        {journey.seats && journey.seats.length > 0 && 
          <React.Fragment>

            <br/><br/>
            <Typography className={classes.heading}>Reserved Seats</Typography>
          
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Ticket Code</TableCell>
                  <TableCell className={classes.tableCell}>NRS Code</TableCell>
                  <TableCell className={classes.tableCell}>NRS Ref</TableCell>
                  <TableCell className={classes.tableCell}>Type</TableCell>
                  <TableCell className={classes.tableCell}>Origin</TableCell>
                  <TableCell className={classes.tableCell}>Destination</TableCell>
                  <TableCell className={classes.tableCell}>Service ID</TableCell>
                  <Tooltip title="Fare Set : Leg : Fare"><TableCell className={classes.tableCell}>IDX</TableCell></Tooltip>
                  <TableCell className={classes.tableCell}>Dir</TableCell>
                  <TableCell className={classes.tableCell}>Seats</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {journey.seats.map((seat,index) => {
                  return (
                  <TableRow key={index} className={classes.stripeRow}>
                    <TableCell className={classes.tableCell}>{seat.fareCode}{/* TODO: Seat : Show supplement code if applicable*/}</TableCell> 
                    <TableCell className={classes.tableCell}>{seat.nrsCode}</TableCell>
                    <TableCell className={classes.tableCell}>{seat.nrsRef}</TableCell>
                    <TableCell className={classes.tableCell}>{seat.accomType}</TableCell>
                    <TableCell className={classes.tableCell}>{seat.legOrigin}{/* TODO: Seat Origin : Show CRS with tooltip for NLC / Name*/}</TableCell> 
                    <TableCell className={classes.tableCell}>{seat.legDestination}{/* TODO: Seat Origin : Show CRS with tooltip for NLC / Name*/}</TableCell> 
                    <TableCell className={classes.tableCell}>{seat.serviceId}</TableCell>
                    <TableCell className={classes.tableCell}>{seat.fareSet}:{seat.fare}:{seat.leg}</TableCell>
                    <TableCell className={classes.tableCell}>{seat.journeyDirection}</TableCell>
                    <Tooltip title={seat.seatData}><TableCell className={classes.tableCell}>{seat.seatData === "NOPL" ? "NOPL" : seat.seatNumbers}</TableCell></Tooltip>
                  </TableRow>
                )})}
              </TableBody>
            </Table>
          </React.Fragment>
        }

      </div>      
    )
  }

  handleTextInputChange = name => event => {
    this.setState({ [name]: event.target.value });
  }

  onKeyDown = name => event => {
    if (event.key === 'Enter')
    {
      event.preventDefault()
      event.stopPropagation()
      this.findBookingRef(this.state.searchTerm)
    }
  }

  clearSearchTerm = event => {
    this.setState({searchTerm:""})
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    })
  }

  handleJourneyChange = panel => (event, expanded) => {
    this.setState({
      expandedJourney: expanded ? panel : false,
    })
  }

  render() {

  const { classes } = this.props
  const { expanded,expandedJourney } = this.state

    return (

      <React.Fragment>

        <div className="stageMenu">
          <Button component={NavLink} to="/bookings/live" activeClassName="active">live</Button>
          <Button component={NavLink} to="/bookings/test" activeClassName="active">test</Button>
        </div>

        <Paper className={classes.paper}>
          <IconButton className={classes.iconButton} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            className={classes.searchInput}
            placeholder="Search..."
            inputProps={{ 'aria-label': 'search bookings' }}
            value={this.state.searchTerm}
            onChange={this.handleTextInputChange('searchTerm')}
            onKeyDown={this.onKeyDown('searchTerm')}
          />
          <IconButton className={classes.iconButton} aria-label="search" onClick={this.clearSearchTerm}>
            <DeleteIcon />
          </IconButton>
          <Typography variant="h4" gutterBottom className={classes.error}>{this.state.errorMessage}</Typography>
          <Typography variant="caption" gutterBottom className={classes.indentedText}>Enter a Booking Reference, Ticket Number, Reservation Reference or AWS Request Reference</Typography>
        </Paper>

        <Paper className={classes.paper}>
          {this.state.salesData &&
            <ExpansionPanel expanded={expanded === 'sales'} onChange={this.handleChange('sales')}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Sales Data</Typography> {/* TODO: Sales : If any ToD tickets, show if collected. If eTicket show if used */}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tableCellHeader}>Transaction Reference</TableCell>
                      <TableCell className={classes.tableCellData}>{this.state.salesData.transactionRef}</TableCell>
                      <TableCell className={classes.tableCellHeader}>Previous Transaction Reference</TableCell>
                      <TableCell className={classes.tableCellData}>{this.state.salesData.prevBookingRef ? this.state.salesData.prevBookingRef : '-'}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={classes.tableCellHeader}>Booked Date / Time</TableCell>
                      <TableCell className={classes.tableCellData}>{Utils.formattedDateTime(this.state.salesData.ts)}</TableCell>
                      <TableCell className={classes.tableCellHeader}>Cancelled (Non-issued) Date / Time</TableCell>
                      <TableCell className={classes.tableCellData}>{this.state.salesData.cancelledDateTime ? Utils.formattedDateTime(this.state.salesData.cancelledDateTime) : '-'}</TableCell>
                    </TableRow>                    

                    <TableRow>                    
                      <TableCell className={classes.tableCellHeader}>Machine Number</TableCell>
                      <TableCell className={classes.tableCellData}>{this.state.salesData.machineId}</TableCell>
                      <TableCell className={classes.tableCellHeader}>Mode</TableCell>
                      <TableCell className={classes.tableCellData}>{this.state.salesData.mode}</TableCell>
                    </TableRow>                    

                    <TableRow>
                      <TableCell className={classes.tableCellHeader}>Licensee</TableCell>
                      <TableCell className={classes.tableCellData}>{this.state.salesData.licensee}</TableCell>
                      <TableCell className={classes.tableCellHeader}>Organisation</TableCell>
                      <TableCell className={classes.tableCellData}>{this.state.salesData.organisationName} ({this.state.salesData.organisationId})</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={classes.tableCellHeader}>User</TableCell>
                      <TableCell className={classes.tableCellData}>{this.state.salesData.userName} ({this.state.salesData.userId})</TableCell>
                      <TableCell className={classes.tableCellHeader}>API Key</TableCell>
                      <TableCell className={classes.tableCellData}>{this.state.salesData.apiKeyId}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={classes.tableCellHeader}>IP Address</TableCell>
                      <TableCell className={classes.tableCellData}>{this.state.salesData.ip}</TableCell>
                    </TableRow>  

                  </TableBody>
                </Table>
              </ExpansionPanelDetails>
            </ExpansionPanel>            
          }

          {this.state.journeyData.map((journey,index) => {
              return (
                <ExpansionPanel key={index} expanded={expanded === `journey${index}`} onChange={this.handleChange(`journey${index}`)}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>Journey {index + 1}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    {this.displayJourney(classes,this.state.journeyData[index])}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )
            })
          }

          {this.state.auditData.length > 0 &&
            <ExpansionPanel expanded={expanded === 'auditData'} onChange={this.handleChange('auditData')}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Audit Data</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <JSONPretty json={this.state.auditData} theme={atelierForestLight}/>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          }

          {this.state.callLogData.call &&
            <ExpansionPanel expanded={expanded === 'callLogDataCall'} onChange={this.handleChange('callLogDataCall')}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Call Log & Timings</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>

              <div>
                <div>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.tableCellHeader}>AWS Request ID</TableCell>
                        <TableCell className={classes.tableCellDataWide}>{this.state.callLogData.call.awsRequestId}</TableCell>
                        <TableCell className={classes.tableCellHeader}>AWS Gateway ID</TableCell>
                        <TableCell className={classes.tableCellDataWide}>{this.state.callLogData.call.gatewayRequestId}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell className={classes.tableCellHeader}>Request URL</TableCell>
                        <TableCell className={classes.tableCellData}>{this.state.callLogData.call.api}-{this.state.callLogData.call.stage}/{this.state.callLogData.call.functionName}</TableCell>
                        <TableCell className={classes.tableCellHeader}>Version</TableCell>
                        <TableCell className={classes.tableCellData}>{this.state.callLogData.call.version}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell className={classes.tableCellHeader}>Memory Allocated</TableCell>
                        <TableCell className={classes.tableCellData}>{this.state.callLogData.call.memoryAllocated} MB</TableCell>
                        <TableCell className={classes.tableCellHeader}>Response Time</TableCell>
                        <TableCell className={classes.tableCellData}>{this.state.callLogData.call.responseTime} ms</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell className={classes.tableCellHeader}>Stage</TableCell>
                        <TableCell className={classes.tableCellDataWide}>{this.state.callLogData.call.stage}</TableCell>
                        <TableCell className={classes.tableCellHeader}>Cold Start</TableCell>
                        <TableCell className={classes.tableCellData}>{this.state.callLogData.call.coldstart ? 'YES' : 'NO'}</TableCell>
                      </TableRow>                    
                    </TableBody>
                  </Table>
                </div>

                <br/>
                <br/>

                {this.state.callLogData.data && this.state.callLogData.data.timings &&
                  <div>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableCellHeader}>Module</TableCell>
                          <TableCell className={classes.tableCellHeader}>Instances</TableCell>
                          <TableCell className={classes.tableCellHeader}>Total Time</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {Object.keys(this.state.callLogData.data.timings).map((key,index) => {

                          let item = this.state.callLogData.data.timings[key]

                          return (
                            <TableRow key={index} className={classes.stripeRow}>
                              <TableCell className={classes.tableCellData}>{key}</TableCell>
                              <TableCell className={classes.tableCellData}>{item.this ? 1 : item.count}</TableCell>
                              <TableCell className={classes.tableCellData}>{item.this ? item.this : item.total} ms</TableCell>
                            </TableRow>
                            )                    
                        })}

                        <TableRow>
                          <TableCell className={classes.tableCellData}>Lambda Re-use</TableCell>
                          <TableCell className={classes.tableCellData}>{this.state.callLogData.data.timings.call.count}</TableCell>
                          <TableCell className={classes.tableCellData}>{this.state.callLogData.data.timings.call.average} ms</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                }
                  </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          }   



          {this.state.callLogData.data &&
            <React.Fragment>
              <ExpansionPanel expanded={expanded === 'requestJSON'} onChange={this.handleChange('requestJSON')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>Request JSON</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <JSONPretty json={this.state.callLogData.data ? this.state.callLogData.data.request : null} theme={atelierForestLight}/>
                </ExpansionPanelDetails>
              </ExpansionPanel>            
              <ExpansionPanel expanded={expanded === 'jpData'} onChange={this.handleChange('jpData')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>Journey Planner Data JSON</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>

                  <div>
                    {this.state.callLogData.data.jpData && this.state.callLogData.data.jpData.length === 1 &&
                       <JSONPretty json={this.state.callLogData.data.jpData[0]} theme={atelierForestLight}/>
                    }

                    {this.state.callLogData.data.jpData && this.state.callLogData.data.jpData.length > 1 && this.state.callLogData.data.jpData.map((data,index) => {
                      return (
                        <ExpansionPanel key={index} expanded={expandedJourney === `journey${index}`} onChange={this.handleJourneyChange(`journey${index}`)}>
                          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className={classes.heading}>Journey {index + 1}</Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <JSONPretty json={data} theme={atelierForestLight}/>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      )
                    })}
                  </div>
                
                </ExpansionPanelDetails>
              </ExpansionPanel>  
              <ExpansionPanel expanded={expanded === 'responseJSON'} onChange={this.handleChange('responseJSON')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>Response JSON</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <JSONPretty json={this.state.callLogData.data.response ? this.state.callLogData.data.response : null} theme={atelierForestLight}/>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </React.Fragment>             
          }

          {this.state.callLogData.data && this.state.callLogData.data.lsm &&
            <ExpansionPanel expanded={expanded === 'callLogDataLSM'} onChange={this.handleChange('callLogDataLSM')}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>LSM logs</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>

                <Table className={classes.table}>
                  <TableBody>
                    {this.state.callLogData.data.lsm.map((item,index) => {

                      return (
                        <React.Fragment key={index}>
                          <TableRow key={'request' + index} className={classes.stripeRow}>
                            <TableCell className={classes.tableCellData}>Request {index}</TableCell>
                            <TableCell className={classes.tableCellData}><XMLViewer xml={item.request} /></TableCell>
                          </TableRow>

                          <TableRow key={'response' + index} className={classes.stripeRow}>
                            <TableCell className={classes.tableCellData}>Response {index}</TableCell>
                            <TableCell className={classes.tableCellData}><XMLViewer xml={item.response} /></TableCell>
                          </TableRow>
                        </React.Fragment>
                    )})}
                  </TableBody>
                </Table>

              </ExpansionPanelDetails>
            </ExpansionPanel>
          }

          {this.state.callLogData.call && this.state.callLogData.call.logStreamName.substr(0,7) !== "offline" && 
            <ExpansionPanel expanded={expanded === 'cloudwatch1'} onChange={this.handleChange('cloudwatch1')}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Cloudwatch</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{display:"block"}}>
                <div>
                  <div style={{display:"inline-block"}}>
                    <Typography variant="caption">AWS Request ID</Typography>
                    <Button href={`https://eu-west-1.console.aws.amazon.com/cloudwatch/home?region=eu-west-1#logEventViewer:group=/aws/lambda/${this.state.callLogData.call.api}-${this.state.callLogData.call.stage}-${this.state.callLogData.call.functionName};stream=${this.state.callLogData.call.logStreamName};filter="${this.state.callLogData.call.awsRequestId}"`} target="_aws"><img src={awsLogo} alt="link to AWS" className={classes.leftIcon}/> {this.state.callLogData.call.awsRequestId}</Button> <Button onClick={event => Utils.copyToClipboard(this.state.callLogData.call.awsRequestId)}><CopyIcon /></Button>
                  </div>
                  <div style={{display:"inline-block"}}>
                    <Typography variant="caption">Log Stream</Typography>
                    <Button href={`https://eu-west-1.console.aws.amazon.com/cloudwatch/home?region=eu-west-1#logEventViewer:group=/aws/lambda/fulfilment-v1-fulfil;stream=${this.state.callLogData.call.logStreamName}`} target="_aws"><img src={awsLogo} alt="link to AWS" className={classes.leftIcon}/> {this.state.callLogData.call.logStreamName}</Button> <Button onClick={event => Utils.copyToClipboard(this.state.callLogData.call.logStreamName)}><CopyIcon /></Button>
                  </div>
                </div>

                {this.state.callLogData.cloudwatch && 

                  <React.Fragment>
                    <br/><br/>

                    <Table className={classes.table}>
                      <TableBody>

                        {this.state.callLogData.cloudwatch.map((log,index) => {

                          return (
                            <TableRow key={log.eventId} className={classes.stripeRow}>
                              <TableCell className={classes.tableNoWrapCell} style={{width:"100px"}}>{Utils.formattedDateTimeWithDecimalSeconds(log.timestamp)}</TableCell>
                              <TableCell className={classes.tableCellScroll}><pre>{Utils.formatLogMessage(log.message)}</pre></TableCell>
                            </TableRow>
                          )
                        })}

                      </TableBody>
                    </Table>
                  </React.Fragment>
                }
              </ExpansionPanelDetails>
            </ExpansionPanel>
          }

          {this.state.sdciRecords.length > 0 &&
            <ExpansionPanel expanded={expanded === 'sdciRecords'} onChange={this.handleChange('sdciRecords')}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>SDCI+ Records</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>

                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                    <TableCell className={classes.tableCellHeader}>ID</TableCell>
                    <TableCell className={classes.tableCellHeader}>Seq Num</TableCell>
                      <TableCell className={classes.tableCellHeader}>Ticket</TableCell>
                      <TableCell className={classes.tableCellHeader}>Record</TableCell>
                      <TableCell className={classes.tableCellHeader}>Type</TableCell>
                      <TableCell className={classes.tableCellHeader}>Credit</TableCell>
                      <TableCell className={classes.tableCellHeader}>Debit</TableCell>
                      <TableCell className={classes.tableCellHeader} colSpan={2}>Data</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                  {this.state.sdciRecords.map((sdci,index) => {

                    return (
                      <React.Fragment key={'sdci' + index}>
                        <TableRow className={classes.clickableTableRow + ' ' + classes.stripeRow} onDoubleClick={event => this.viewSDCIRecord(sdci)}>
                          <TableCell className={classes.tableCellDataCompact}>{sdci.id}</TableCell>
                          <TableCell className={classes.tableCellDataCompact}>{sdci.sequenceNumber}</TableCell>
                          <TableCell className={classes.tableCellDataCompact}>{sdci.ticketReference}</TableCell>
                          <TableCell className={classes.tableCellDataCompact}>{sdci.recordType}</TableCell>
                          <TableCell className={classes.tableCellDataCompact}>{sdci.transactionType}</TableCell>
                          <TableCell className={classes.tableCellDataCompact}>{sdci.creditAmount}</TableCell>
                          <TableCell className={classes.tableCellDataCompact}>{sdci.debitAmount}</TableCell>
                          <TableCell className={classes.tableCellDataWide}><pre>{sdci.recordData}</pre></TableCell>
                          <TableCell className={classes.tableCell}><IconButton color="primary" onClick={event => this.viewSDCIRecord(sdci)}><ViewIcon /></IconButton></TableCell>
                        </TableRow>
                    </React.Fragment>
                    )})}
                  </TableBody>
                </Table>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          }
          {this.state.sdciReport &&
            <ExpansionPanel expanded={expanded === 'sdciReport'} onChange={this.handleChange('sdciReport')}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>SDCI+ Report</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Table className={classes.table}>
                    <TableBody>
                      <TableRow>
                      <TableCell className={classes.tableCellHeader}>Machine Number</TableCell>
                        <TableCell className={classes.tableCellData}>{this.state.sdciReport.machineNumber}</TableCell>
                        <TableCell className={classes.tableCellHeader}>Shift Number</TableCell>
                        <TableCell className={classes.tableCellData}>{this.state.sdciReport.ShiftNumber}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell className={classes.tableCellHeader}>Start ID</TableCell>
                        <TableCell className={classes.tableCellData}>{this.state.shiftRecords && this.state.shiftRecords.DB && this.state.shiftRecords.DB.sequenceNumber ? this.state.shiftRecords.DB.sequenceNumber : '-'}</TableCell>
                        <TableCell className={classes.tableCellHeader}>End ID</TableCell>
                        <TableCell className={classes.tableCellData}>{this.state.shiftRecords && this.state.shiftRecords.DD && this.state.shiftRecords.DD.sequenceNumber ? this.state.shiftRecords.DD.sequenceNumber : '-'}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell className={classes.tableCellHeader}>Shift Started</TableCell>
                        <TableCell className={classes.tableCellData}>{Utils.formattedDateTime(this.state.sdciReport.DateTimeUsed)}</TableCell>
                        <TableCell className={classes.tableCellHeader}>Shift Ended</TableCell>
                        <TableCell className={classes.tableCellData}>{Utils.formattedDateTime(this.state.sdciReport.DateTimeShiftEnded)}</TableCell>
                      </TableRow>

                      <TableRow>                        
                        <TableCell className={classes.tableCellHeader}>Delivered</TableCell>
                        <TableCell className={classes.tableCellData}>{this.state.sdciReport.delivered ? 'Y' : 'N'}</TableCell>
                        <TableCell className={classes.tableCellHeader}>Delivered Date</TableCell>
                        <TableCell className={classes.tableCellData}>{Utils.formattedDateTime(this.state.sdciReport.DateTimeFileDelivered)}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell className={classes.tableCellHeader}>Delivery attempts</TableCell>
                        <TableCell className={classes.tableCellData}>{this.state.sdciReport.deliveryAttempts}</TableCell>
                        <TableCell className={classes.tableCellHeader}>Error in report</TableCell>
                        <TableCell className={classes.tableCellData}>{this.state.sdciReport.submissionError ? 'Y' : 'N'}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell className={classes.tableCellHeader}>Report Email</TableCell>
                        <TableCell className={classes.tableCellDataWide}>{this.state.sdciReport.reportS3Key && <React.Fragment><Button href={`${Config.getConfig('deliveryReportsS3Url')}${this.state.sdciReport.reportS3Key}`} target="_aws"><img src={awsLogo} alt="link to AWS" className={classes.leftIcon}/> {this.state.sdciReport.reportS3Key}</Button> <Button onClick={event => Utils.copyToClipboard(this.state.sdciReport.reportS3Key)}><CopyIcon /></Button></React.Fragment>}</TableCell>
                        <TableCell className={classes.tableCellHeader}>TIS Version</TableCell>
                        <TableCell className={classes.tableCellData}>{this.state.sdciReport.TISVersion}</TableCell>
                      </TableRow>

                      {this.state.shiftRecords && 
                      <React.Fragment>
                        {this.state.shiftRecords.DB &&
                          <TableRow className={classes.clickableTableRow} onDoubleClick={event => this.viewSDCIRecord(this.state.shiftRecords.DB)}>
                            <TableCell className={classes.tableCellHeader}>DB</TableCell>
                            <TableCell colSpan={4} className={classes.tableCellData}>{this.state.shiftRecords.DB.recordData}</TableCell>
                            <TableCell className={classes.tableCell}><IconButton color="primary" onClick={event => this.viewSDCIRecord(this.state.shiftRecords.DB)}><ViewIcon /></IconButton></TableCell>
                          </TableRow>
                        }
                        {this.state.shiftRecords["2C"] &&
                          <TableRow className={classes.clickableTableRow} onDoubleClick={event => this.viewSDCIRecord(this.state.shiftRecords["2C"])}>
                            <TableCell className={classes.tableCellHeader}>2C</TableCell>
                            <TableCell colSpan={4} className={classes.tableCellData}>{this.state.shiftRecords["2C"].recordData}</TableCell>
                            <TableCell className={classes.tableCell}><IconButton color="primary" onClick={event => this.viewSDCIRecord(this.state.shiftRecords["2C"])}><ViewIcon /></IconButton></TableCell>
                          </TableRow>
                        }
                        {this.state.shiftRecords.DD && 
                          <TableRow className={classes.clickableTableRow} onDoubleClick={event => this.viewSDCIRecord(this.state.shiftRecords.DD)}>
                            <TableCell className={classes.tableCellHeader}>DD</TableCell>
                            <TableCell colSpan={4} className={classes.tableCellData}>{this.state.shiftRecords.DD.recordData}</TableCell>
                            <TableCell className={classes.tableCell}><IconButton color="primary" onClick={event => this.viewSDCIRecord(this.state.shiftRecords.DD)}><ViewIcon /></IconButton></TableCell>
                          </TableRow>
                        }
                      </React.Fragment>
                      }
                      {this.state.sdciReport.report &&
                        <TableRow>
                          <TableCell colSpan={6} className={classes.tableCellData}><JSONPretty json={this.state.sdciReport.report} theme={atelierForestLight}/></TableCell>
                        </TableRow>
                      } 
                    </TableBody>
                  </Table>

              </ExpansionPanelDetails>
            </ExpansionPanel>
          }
          {this.state.errorData.length > 0 &&
            <ExpansionPanel expanded={expanded === 'errorData'} onChange={this.handleChange('errorData')}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Error Logs</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div><pre>{JSON.stringify(this.state.errorData,null,2)}</pre></div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          }
          {/* TODO: Add call data / logs for reservations */}
        </Paper>

        <Dialog
          open={this.state.dialog.open}
          onClose={this.closeDialogue}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogTitle id="form-dialog-title">{this.state.dialog.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.state.dialog.content}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    )
  }
}

Bookings.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(Bookings))
