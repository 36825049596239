import Moment from 'moment-timezone'

import React, { PureComponent } from 'react'
import { withRouter, NavLink } from 'react-router-dom'

import Axios from 'axios'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise'

import logsInDbCountComponent from './logsInDbCountComponent'
import datePickerComponent from './datePickerComponent'

import LogPopup from './log-popup'
import AlertPopup from './alert-popup'

import Config from '../config'
import GridConfig from './grid-config'

const FirstDataLimit = 1000 // How many rows of data to load from DB on our first request
const DataLimit = 5000 // How many rows of data to load from DB per request

const styles = theme => ({
  paper: {
    width: 'calc(100% - 60)',
    marginTop: theme.spacing() * 3,
    overflowX: 'auto',
    position : 'fixed',
    top : 120,
    bottom : 20,
    left : 20,
    right : 20,
  }  
})

var visible = false
var refreshTimer

class LogsList extends PureComponent {

  constructor(props, context) {
    super(props, context)
    this.state = {stage:""}
  }
  
  async componentDidMount() {
    let {stage} = this.props.match.params
    visible = true
    this.setState({stage,numLogsLoaded:0,numLogsInDb:0,moreLogs:false,selectedNode:null,minId:null,maxId:null,logsDate:new Date(),modalOpen:false,searchFailure:null},() => {
      this.getLogs()
    })
  }

  componentDidUpdate(prevProps) {
    let {stage} = this.props.match.params
    
    if(prevProps.searchTerm !== this.props.searchTerm)
    {
      this.searchLogs(this.props.searchTerm)
    }

    if(prevProps.match.params.stage !== stage)
    {
      if (refreshTimer)
      {
        clearInterval(refreshTimer)
        refreshTimer = null
      }

      if (this.gridApi)
      {
        this.gridApi.setRowData([])
      }

      this.setState({stage,numLogsLoaded:0,numLogsInDb:0,moreLogs:false,selectedNode:null,minId:null,maxId:null,modalOpen:false},() => {
        this.getLogs()
      })
    }
  }

  componentWillUnmount() {
    visible = false
    clearInterval(refreshTimer)
    refreshTimer = null
  }

  async getLogs() {
    if (!visible || !this.state.stage)
    {
      return
    }

    let minId = this.state.minId
    let maxId = this.state.maxId

    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg: "Logs",
        fn : "getLogs",
        params : {
          date : Moment(this.state.logsDate).tz('Europe/London').format('YYYY-MM-DD HH:mm:ss'),
          mode : this.state.stage,
          limit: this.state.numLogsLoaded ? DataLimit : FirstDataLimit,
          minId,
          maxId,
          queryMode : refreshTimer ? 'newer' : (this.state.numLogsLoaded > 0 ? 'older' : 'initialise')
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey(),
      },
    }

    Axios(options).then(async result => {

      // Abort loading if we have changed view
      if (!visible)
      {
        return
      }

      if (result.data.mode === this.state.stage && result.data.data.length)
      {
        let numLogsLoaded = this.state.numLogsLoaded + result.data.data.length
        let moreLogs = result.data.moreLogs
        let numLogsInDb = result.data.numLogsInDb
  
        let minIdInResults = result.data.data.reduce((min, p) => p.id < min ? p.id : min, result.data.data[0].id)
        let maxIdInResults = result.data.data.reduce((max, p) => p.id > max ? p.id : max, result.data.data[0].id)

        minId = minId ? Math.min(minId,minIdInResults) : minIdInResults
        maxId = maxId ? Math.max(maxId,maxIdInResults) : maxIdInResults

        this.gridApi.applyTransactionAsync({add:result.data.data},() => {
          if (this.state.selectedNode)
          {
            this.gridApi.ensureNodeVisible(this.state.selectedNode, 'middle')
          }
        })

        this.setState({maxId,minId,moreLogs,numLogsInDb,numLogsLoaded},async () => {

          if (moreLogs)
          {
            this.getLogs()
          }
          else
          {
            if (!refreshTimer)
            {
              // Only do this is date selected is today (so newer logs being added to logs now)
              let _this = this
              refreshTimer = setInterval(_this.getLogs.bind(_this),5000)
            }
          }
        })
      }
    })
    .catch(err => {
      console.error(err)
    })    
  }

  onGridReady = params => {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi
    this.gridApi.setSortModel([{colId:"id",sort:"desc"}])
  }

  setLogsDate = logsDate => {
    this.setState({logsDate})

    if (refreshTimer)
    {
      clearInterval(refreshTimer)
      refreshTimer = null  
    }

    if (this.gridApi)
    {
      this.gridApi.setRowData([])
    }

    this.setState({numLogsLoaded:0,numLogsInDb:0,moreLogs:false,selectedNode:null,minId:null,maxId:null},() => {
      this.getLogs()
    })
  }

  getLogsDate = () => {
    return this.state.logsDate
  }

  getLogsLeftToLoad = () => {
    return this.state.numLogsInDb - this.state.numLogsLoaded
  }

  selectionChanged = () => {
    var selectedNode = this.gridApi.getSelectedNodes()[0]
    this.setState({selectedNode})
  }

  // Used to de-select a previously selected row
  rowClicked = event => {
    if (event.node === this.state.selectedNode) // Don't do anything unless selected row has been clicked
    {
      setTimeout(() => { // Need to clear selection, unless we have double clicked to open the modal
        if (!this.state.modalOpen)
        {
          this.setState({selectedNode:null},() => {
            event.node.setSelected(false)
          })
        }
      },100)
    }
  }

  // Double click a row
  openLogDetail = event => {
    this.setState({selectedNode:event.node},() => {
      this.setState({modalOpen:true})
    })
  }
  
  viewportChanged = data => {
    // If we have scrolled our selected row off the screen then de-select it.
    if (this.state.selectedNode)
    {
      for (let idx=data.firstRow+GridConfig.visibleRowBufferSize;idx<=data.lastRow-GridConfig.visibleRowBufferSize;idx++)
      {
        let node = this.gridApi.getDisplayedRowAtIndex(idx)
  
        if (node === this.state.selectedNode)
        {
          return
        }
      }
  
      // Didn't find out selected node in displayed nodes, so unselect it
      this.state.selectedNode.setSelected(false)
      this.setState({selectedNode:null})
    }
  }

  handleCloseModal = () => {
    this.setState({modalOpen:false})
  }

  handleCloseAlert = () => {
    this.setState({searchFailure:null})
  }

  searchLogs = async searchTerm => {
    if (searchTerm)
    {
      let options = {
        method : 'POST',
        url : `${Config.getConfig('adminApi')}request`,
        data : {
          pkg: "Logs",
          fn : "searchLogs",
          params : {
            mode : this.state.stage,
            searchTerm,
          }
        },
        headers : {
          'Content-type': 'application/json',
          'x-api-key': Config.getAPIKey(),
        },
      }

      let result = (await Axios(options)).data

      if (result.tsStart)
      {
        let logsDate = Moment(result.tsStart).tz('Europe/London').toDate()

        console.log("logsDate",logsDate)
        console.log("tsStart",result.tsStart)


        // Same date, different time...
        if (Moment(logsDate).startOf('day').format("DD-MM-YY") !== Moment(this.state.logsDate).startOf('day').format("DD-MM-YY"))
        {
          this.setLogsDate(logsDate)
        }

        // Now set filters...
        var instance = this.gridApi.getFilterInstance(result.type)

        instance.setModel({
          type: 'contains',
          filter: searchTerm
        })

        this.gridApi.onFilterChanged()
      }
      else
      {
        // Open alert dialog
        this.setState({searchFailure:"Can't find " + result.type + " : " + searchTerm})
      }
    }
  }

  render() {

  const { classes } = this.props
  
    return (

      <React.Fragment>

        <div className="stageMenu">
          <Button component={NavLink} to="/logs/live" activeClassName="active">Live</Button>
          <Button component={NavLink} to="/logs/test" activeClassName="active">Test</Button>
        </div>

        <Paper className={classes.paper}>

          <div className="ag-theme-alpine" style={ {height: '100%', width: '100%'} }>
            <AgGridReact
              onGridReady={this.onGridReady}
              rowBuffer={GridConfig.visibleRowBufferSize*2}
              columnDefs={GridConfig.columnDefs}
              animateRows={true}
              rowSelection="single"
              rowDeselection={true}
              suppressCellSelection={true}
              enableCellTextSelection={true}
              defaultColDef={{filterParams:{ newRowsAction:'keep'}}}
              frameworkComponents={{
                logsInDbCountComponent : logsInDbCountComponent,
                datePickerComponent : datePickerComponent,
              }}
              statusBar = {{
                statusPanels : [
                  { statusPanel: 'datePickerComponent', align: 'left', statusPanelParams : {setDateFn:this.setLogsDate,getDateFn:this.getLogsDate,logsDate:this.state.logsDate}},
                  { statusPanel: 'logsInDbCountComponent', align: 'right', statusPanelParams : {logsToLoadFn:this.getLogsLeftToLoad}},
                  { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'right' },
                ]
              }}
              sideBar={{
                toolPanels : ['filters'],
                closedByDefault : true,
              }}
              onRowClicked={this.rowClicked.bind(this)}
              onRowDoubleClicked={this.openLogDetail.bind(this)}
              onViewportChanged={this.viewportChanged.bind(this)}
              onSelectionChanged={this.selectionChanged.bind(this)}
              >
            </AgGridReact>
          </div>

          {this.state.selectedNode && 
            <LogPopup modalOpen={this.state.modalOpen} selectedLog={this.state.selectedNode.data} handleCloseModal={this.handleCloseModal} classes={classes} stage={this.state.stage}/>
          }

          {this.state.searchFailure && 
            <AlertPopup modalOpen={this.state.searchFailure !== null} text={this.state.searchFailure} handleCloseAlert={this.handleCloseAlert}/>
          }

        </Paper>

      </React.Fragment>
    )
  }
}

LogsList.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(LogsList))
