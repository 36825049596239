import React, { PureComponent } from 'react'
import { NavLink, withRouter } from 'react-router-dom'

import Axios from 'axios'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import ViewIcon from '@material-ui/icons/OpenInNew'
import RefreshIcon from '@material-ui/icons/Autorenew'
import NewShiftIcon from '@material-ui/icons/CreateNewFolder'
import SendShiftIcon from '@material-ui/icons/MailOutline'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import Config from '../config'
import Utils from '../utils'
import {decodeSDCIRecord} from '../bookings/sdci'

// TODO: Add link to download ALTS report ZIP file
// TODO: Add option to restart ALL shifts

const reportFiles = {
  drv002 : {error:2,note:"Credit Card Reconciliation (we never expect this)"},
  drv009 : {error:2,note:"Reportable Items from Validation (we never expect this)"},
  drv010 : {error:2,note:"TIS Duplicate Transactions (we never expect this)"},
  drv019 : {error:0,note:"Details of Refund CC Transactions over £50 (Not used any more)"},
  drv022 : {error:0,note:"Profit Centre Daily CC Transaction Total (This one is supposed to have content)"},
  drv023 : {error:2,note:"Total Credit Card Claims & APTIS Credit (we don't expect any data in here)"},
  drv029 : {error:1,note:"TIS Shift evaluation - Might get a row in here if we do a rollback and have a gap in sequence numbers"},
  drv049 : {error:2,note:" Data Retrieval Validation and Error Report (Rejected records)"},
  drv073a : {error:1,note:"Transaction Continuity Control - Might get a row in here if we do a rollback and have a gap in sequence numbers"},
  drv073b : {error:1,note:"Credit Card Transaction Continuity Control - Might get a row in here if we do a rollback and have a gap in sequence numbers"},
  drv084 : {error:1,note:"Overlapping Shifts Report (we never expect this). However RDG have told us to ignore these as they can be generated bu ANOTHER shift!."},
  drv089 : {error:2,note:"Duplicate Credit Card Report (we never expect this)"},
  notreceived : {error:0,note:"Lennon / ALTS processing"}
}

const modalClasses = {
  0 : 'reportOK',
  1 : 'reportWarning',
  2 : 'reportError',
}

var visible = false

const styles = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },  
  paper: {
    width: 'calc(100%)',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  paperBorder : {
    width: 'calc(100%)',
    marginTop: theme.spacing(3),    
    padding: 40,
    textAlign: 'center'
  },
  table: {
    minWidth: 700,
    width : '100%',
    tableLayout : 'auto'
  },
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '220px',
    padding : '4px 15px 4px 15px',
    fontSize : '10px',
  },
  tableCellWide: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding : '4px 15px 4px 15px',
    width: 'calc(100% - 250px)',
    fontSize : '10px',
  },
  tableCellHeader: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    padding : '4px 15px 4px 15px',
  },
  tableCellHeaderSmall: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '75px',
    padding : '4px 15px 4px 15px',
  },
  tableCellData: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    padding : '4px 15px 4px 15px',
    fontWeight : 'bold'
  },   
  center: {
    textAlign : 'center'
  },
  error: {
    color : 'red'
  },
  ok: {
    color : 'green'
  },  
  clickableTableRow: {
    cursor : 'pointer'
  },
  button: {
    margin: theme.spacing(),
  },  
  stripeRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  }, 
  reportOK: {
    color : 'blue'
  },
  reportWarning: {
    color : 'orange'
  },
  reportError: {
    color : 'red'
  },
})


class SettlementDetail extends PureComponent {

  constructor(props, context) {
    super(props, context)
    this.state = {stage:"",licensee:"",machineId:"",machineData:{},settlementFiles:[],reportOpen:false,endShiftModalOpen:false,report:{},shiftData:{},sdcidialog:{open:false}}
  }

  async componentDidMount() {
    let {stage,machineId,licensee} = this.props.match.params
    let mode = (stage === "alts") ? "test" : "live"
    Config.setConfig("mode",mode)
    this.setState({stage:stage,licensee:licensee,machineId:machineId,machineData:{},settlementFiles:[],reportOpen:false,shiftData:{},sdcidialog:{open:false}},this.init)
  }

  componentWillUnmount() {
    visible = false
  }

  async init() {
    visible = true

    this.setState({settlementFiles:[]},function() {
      this.getMachineData()
      this.getSettlementData()
    })
  }

  getMachineData = async => {

    if (!visible || !this.state.machineId.length)
    {
      return
    }

    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "SDCI",
        fn: "getMachineData",
        params : {
          licensee : this.state.licensee,
          machineId : this.state.machineId
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey(),
      },
    }

    Axios(options).then(result => {

      // Abort loading if we have changed view
      if (!visible)
      {
        return
      }

      let machineData = result.data.data

      this.setState({machineData})
    })
    .catch(err => {
     console.error(err)
    })    
  }

  getShiftData = async shiftId => {

    if (!visible || !this.state.machineId.length)
    {
      return
    }

    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "SDCI",
        fn: "getShiftData",
        params : {
          stage : this.state.stage,
          licensee : this.state.licensee,
          machineId : this.state.machineId,
          shiftId : shiftId,
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey(),
      },
    }

    Axios(options).then(result => {

      // Abort loading if we have changed view
      if (!visible)
      {
        return
      }

      let shiftData = result.data.data

      this.setState({shiftData})
    })
    .catch(err => {
     console.error(err)
    })    
  }

  getSettlementData = async => {

    if (!visible || !this.state.machineId.length)
    {
      return
    }

    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "SDCI",
        fn: "getSettlementFiles",
        params : {
          stage : this.state.stage,
          licensee : this.state.licensee,
          machineId : this.state.machineId,
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey(),
      },
    }

    Axios(options).then(result => {

      // Abort loading if we have changed view
      if (!visible)
      {
        return
      }

      let settlementFiles = result.data.data

      this.setState({settlementFiles})
      this.setState({endShiftModalOpen:false})
    })
    .catch(err => {
     console.error(err)
    })    
  }

  showReport = item => {
    this.setState({reportOpen:true,shiftData:{},report:item.report ? JSON.parse(item.report) : {"notreceived":"We have not received the processing report yet"}})
    this.getShiftData(item.ShiftNumber)
  }

  hideReport = () => {
    this.setState({reportOpen:false})
  }

  async viewSDCIRecord(sdciRecord) {
    const { classes } = this.props

    let recordData = await decodeSDCIRecord(sdciRecord)

    let sdcidialog = {...this.state.sdcidialog}
    sdcidialog.open = true
    sdcidialog.title = "SDCI Record"
    sdcidialog.content = (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCellHeader}colSpan={4}>{sdciRecord.recordData}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCellHeader}>Field</TableCell>
            <TableCell className={classes.tableCellHeader}>Field Name</TableCell>
            <TableCell className={classes.tableCellHeader}>Raw Data</TableCell>
            <TableCell className={classes.tableCellHeader}>Value</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {recordData.map((item,index) => (  
            <TableRow className={classes.stripeRow} key={index}>
              <TableCell className={classes.tableCellHeader}>{index +1}</TableCell>
              <TableCell className={classes.tableCellHeader}>{item.name}</TableCell>
              <TableCell className={classes.tableCellData}>{item.rawdata}</TableCell>
              <TableCell className={classes.tableCellData}>{item.data}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
    this.setState({sdcidialog})
  }

  closeSdciDialogue  = () =>
  {
    let sdcidialog = {...this.state.sdcidialog}
    sdcidialog.open = false
    this.setState({sdcidialog})
  }

  errorFormat = status => {

    switch (status)
    {
      case 0: { return 'OK'}
      case 1: { return 'Warning'}
      case 2: { return 'Error'}
      default: { return 'Error'}
    }
  }

  endShift = async () => {
    // refreshes settlemesnt files after ending the shift

    this.setState({endShiftModalOpen:true,settlementFiles:[]})

    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "SDCI",
        fn: "endShift",
        params : {
          stage : this.state.stage,
          licensee : this.state.licensee,
          machineId : this.state.machineId,
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey(),
      },
    }

    Axios(options).then(result => {

      // Abort loading if we have changed view
      if (visible)
      {
        setTimeout(this.getSettlementData,5000)
      }
    })
    .catch(err => {
     console.error(err)
    }) 
  }

  sendShift = async () => {
    // refreshes settlemesnt files after ending the shift

    this.setState({endShiftModalOpen:true,settlementFiles:[]})

    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "SDCI",
        fn: "sendShift",
        params : {
          stage : this.state.stage,
          licensee : this.state.licensee,
          machineId : this.state.machineId,
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey(),
      },
    }

    Axios(options).then(result => {

      // Abort loading if we have changed view
      if (visible)
      {
        setTimeout(this.getSettlementData,20000)
      }
    })
    .catch(err => {
     console.error(err)
    }) 
  }

  render() {

    const { classes } = this.props
  
    return (

      <React.Fragment>

        <div className="stageMenu">
          <Button component={NavLink} to={'/settlement/' + this.state.stage} activeClassName="active">Back to Machine List</Button>
          <Button component={NavLink} to={`/settlement/lennon/${this.state.licensee}/${this.state.machineId}`} activeClassName="active">Lennon</Button>
          <Button component={NavLink} to={`/settlement/alts/${this.state.licensee}/${this.state.machineId}`} activeClassName="active">ALTS</Button>
        </div>
   
        <Paper className={classes.paper}>

          <Table className={classes.table}>

            <TableBody>
              <TableRow>
                <TableCell className={classes.tableCellHeader}>Licensee</TableCell>
                <TableCell className={classes.tableCellData} colSpan={3}>{this.state.machineData.name} ({this.state.machineData.licensee})</TableCell>
                <TableCell className={classes.tableCellHeader}>Notes</TableCell>
                <TableCell className={classes.tableCellData} colSpan={3}>{this.state.machineData.notes}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell className={classes.tableCellHeader}>Lennon Machine Name</TableCell>
                <TableCell className={classes.tableCellData}>{this.state.machineData.LennonMachineName}</TableCell>
                <TableCell className={classes.tableCellHeader}>Machine ID</TableCell>
                <TableCell className={classes.tableCellData}>{this.state.machineData.machineId}</TableCell>
                <TableCell className={classes.tableCellHeader}>Window</TableCell>
                <TableCell className={classes.tableCellData}>{this.state.machineData.window}</TableCell>
                <TableCell className={classes.tableCellHeader}>NLC</TableCell>
                <TableCell className={classes.tableCellData}>{this.state.machineData.sellingNLC}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell className={classes.tableCellHeader}>Business Group</TableCell>
                <TableCell className={classes.tableCellData}>{this.state.machineData.businessGroup}</TableCell>
                <TableCell className={classes.tableCellHeader}>Sales Channel</TableCell>
                <TableCell className={classes.tableCellData}>{this.state.machineData.salesChannel}</TableCell>
                <TableCell className={classes.tableCellHeader}>Shift Length</TableCell>
                <TableCell className={classes.tableCellData}>{this.state.machineData.shiftDuration} hrs</TableCell>
                <TableCell className={classes.tableCellHeader}>Revenue Mode</TableCell>
                <TableCell className={classes.tableCellData}>{this.state.machineData.revenueMode ? 'Non-revenue' : 'Revenue'}</TableCell>
              </TableRow>  

              <TableRow>
                <TableCell className={classes.tableCellHeader}>Enabled</TableCell>
                <TableCell className={classes.tableCellData}>{Utils.yesNoFormat(this.state.machineData.enabled)}</TableCell>
                <TableCell className={classes.tableCellHeader}>Send Lennon</TableCell>
                <TableCell className={classes.tableCellData}>{Utils.yesNoFormat(this.state.machineData.send_lennon)}</TableCell>
                <TableCell className={classes.tableCellHeader}>Send ALTS</TableCell>
                <TableCell className={classes.tableCellData}>{Utils.yesNoFormat(this.state.machineData.send_alts)}</TableCell>
                <TableCell className={classes.tableCellData}></TableCell>
              </TableRow>  

              <TableRow>  
                <TableCell className={classes.tableCellData} colSpan={5}>
                  <Button color="primary" onClick={event => this.init()} variant="outlined"><RefreshIcon />Refresh data</Button>
                </TableCell>

                <TableCell className={classes.tableCellData} colSpan={3}>
                  <Button color="secondary" onClick={event => this.endShift()} variant="outlined"><NewShiftIcon />new {this.state.stage} shift</Button>
                  &nbsp;
                  <Button color="primary" onClick={event => this.sendShift()} variant="outlined"><SendShiftIcon />send to {this.state.stage}</Button>
                </TableCell>
              </TableRow>  

            </TableBody>

          </Table>
    
        </Paper>

            
        <Paper className={classes.paper}>

          <Table className={classes.table}>

            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>Shift</TableCell>
                <TableCell className={classes.tableCell}>Version</TableCell>
                <TableCell className={classes.tableCell}>Start ID</TableCell>
                <TableCell className={classes.tableCell}>End ID</TableCell>
                <TableCell className={classes.tableCell}>Shift Start</TableCell>
                <TableCell className={classes.tableCell}>Shift End</TableCell>
                <TableCell className={classes.tableCell}>Filename</TableCell>
                <TableCell className={classes.tableCell}>Delivered</TableCell>
                <TableCell className={classes.tableCell}>Attempts</TableCell>
                <TableCell className={classes.tableCell}>Status</TableCell>
                <TableCell className={classes.tableCell}>Report</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.state.settlementFiles.map((item,index) => {

                let rowclass = classes.tableCell

                if (item.delivered && item.submissionError)
                {
                  rowclass += ' ' + (item.submissionError === 1 ? classes.reportWarning : classes.reportError)
                }

                return (
                  <TableRow key={item.ShiftNumber} className={classes.clickableTableRow + ' ' + classes.stripeRow} onDoubleClick={event => this.showReport(item)}>                      
                    <TableCell className={rowclass}>{item.ShiftNumber}</TableCell>
                    <TableCell className={rowclass}>{item.TISVersion}</TableCell>
                    <TableCell className={rowclass}>{item.startID}</TableCell>
                    <TableCell className={rowclass}>{item.endID}</TableCell>
                    <TableCell className={rowclass}>{Utils.formattedDateTime(item.DateTimeUsed)}</TableCell>
                    <TableCell className={rowclass}>{Utils.formattedDateTime(item.DateTimeShiftEnded)}</TableCell>
                    <TableCell className={rowclass}>{item.filename}</TableCell>
                    <TableCell className={rowclass}>{Utils.formattedDateTime(item.DateTimeFileDelivered)}</TableCell>
                    <TableCell className={rowclass}>{item.deliveryAttempts}</TableCell>
                    <TableCell className={rowclass}>{item.report ? this.errorFormat(item.submissionError) : ''}</TableCell>
                    <TableCell className={rowclass}>{item.report && <IconButton color="primary" onClick={event => this.showReport(item)}><ViewIcon /></IconButton>}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
    
        </Paper>

        <Dialog
          open={this.state.reportOpen}
          onClose={this.hideReport}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogTitle id="form-dialog-title">SDCI+ Report</DialogTitle>
          <DialogContent>
            <DialogContentText>

            {Object.keys(this.state.report).map((key,index) => {

              let reportFile = key.split(".")[0]
              let note = reportFiles[reportFile].note
              let warningType =  reportFiles[reportFile].error
              let content = this.state.report[key]

              let className = classes[modalClasses[warningType]]

              if (content.length)
              {
                return (
                  <React.Fragment key={key}>
                    <b className={className}>{note} ({key})</b>
                    <pre>{content}</pre>
                  </React.Fragment>
                )
              }
              else
              {
                return (<React.Fragment/>)
              }
            })}
            {this.state.report && !this.state.report.length && <React.Fragment>Report is empty</React.Fragment>}
            
            <Table>
            {this.state.shiftData.DB && 
              <TableRow className={classes.clickableTableRow} onDoubleClick={event => this.viewSDCIRecord(this.state.shiftData.DB)}>
                <TableCell className={classes.tableCellHeaderSmall}>DB</TableCell>
                <TableCell className={classes.tableCellWide}>{this.state.shiftData.DB.recordData}</TableCell>
                <TableCell className={classes.tableCellHeaderSmall}><IconButton color="primary" onClick={event => this.viewSDCIRecord(this.state.shiftData.DB)}><ViewIcon /></IconButton></TableCell>
              </TableRow>
            }
            {this.state.shiftData["2C"] && 
              <TableRow className={classes.clickableTableRow} onDoubleClick={event => this.viewSDCIRecord(this.state.shiftData["2C"])}>
                <TableCell className={classes.tableCellHeaderSmall}>2C</TableCell>
                <TableCell className={classes.tableCellWide}>{this.state.shiftData["2C"].recordData}</TableCell>
                <TableCell className={classes.tableCellHeaderSmall}><IconButton color="primary" onClick={event => this.viewSDCIRecord(this.state.shiftData["2C"])}><ViewIcon /></IconButton></TableCell>
              </TableRow>
            }
            {this.state.shiftData.DD && 
              <TableRow className={classes.clickableTableRow} onDoubleClick={event => this.viewSDCIRecord(this.state.shiftData.DD)}>
                <TableCell className={classes.tableCellHeaderSmall}>DD</TableCell>
                <TableCell className={classes.tableCellWide}>{this.state.shiftData.DD.recordData}</TableCell>
                <TableCell className={classes.tableCellHeaderSmall}><IconButton color="primary" onClick={event => this.viewSDCIRecord(this.state.shiftData.DD)}><ViewIcon /></IconButton></TableCell>
              </TableRow>
            }
            </Table>
            </DialogContentText>
          </DialogContent>

        </Dialog>

        <Dialog
          open={this.state.endShiftModalOpen}
        >
          <DialogTitle id="form-dialog-title">SDCI+ Report</DialogTitle>
          <DialogContent>
            <DialogContentText>
            Ending /Sending shift data. Please wait.
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.sdcidialog.open}
          onClose={this.closeSdciDialogue}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogTitle id="form-dialog-title">{this.state.sdcidialog.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.state.sdcidialog.content}
            </DialogContentText>
          </DialogContent>
        </Dialog>

      </React.Fragment>
    )
  }
}

SettlementDetail.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(SettlementDetail))