import Moment from 'moment'
import Axios from 'axios'

import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter, NavLink } from 'react-router-dom'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ViewIcon from '@material-ui/icons/OpenInNew'

import Config from '../config'
import Utils from '../utils'

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin : 'auto',
    width : 'calc(100% + 48px)'
  },
  h1 : {
    textAlign : 'center',
    color : '#A7CADE',
  },
  chart : {
    backgroundColor : 'white',
    borderRadius : '5px',
  },
  paper: {
    width: 'calc(100%)',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    backgroundColor : '#fff',
  },
  table: {
    minWidth: 700,
    width : '100%',
    tableLayout : 'auto'
  },
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    padding : '4px 5px 4px 5px'
  },
  errored : {
    color : 'red'
  },
  clickableTableRow: {
    cursor : 'pointer'
  },
  button: {
    margin: theme.spacing(),
  },  
  stripeRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  }, 
  bold : {
    fontWeight : "bold"
  },
  better : {
    color : "green"
  },
  worse : {
    color : "red"
  }
})

class DataManager extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {stats:[],overdue:false}
  }

  async componentDidMount() {
    this.getStats()
  }

  async getStats() {

    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "DM",
        fn : "getProcessedDataStats"
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey(),
      },
    }

    Axios(options).then(result => {

      let stats = result.data.data

      let overdue = false

      stats.map(stat => {
        let expectedUpdatedDays = /Nrs/.test(stat.tbl) ? 8 : 2
        stat.overdue = Moment(stat.updated).add(expectedUpdatedDays,'days').isBefore()

        overdue = overdue || stat.overdue

        return stat
      })

      this.setState({stats})

    })
    .catch(err => {
     console.error(err)
    })    
  }

  drillDown(event,stat)
  {
    this.props.history.push(`${this.props.history.location.pathname}/${stat.tbl}`)
  }

  render() {
    const { classes } = this.props

    return (
      <React.Fragment>
        <div className="stageMenu">
          <Button component={NavLink} to="/data-manager/published" activeClassName="active">published</Button>
          <Button component={NavLink} to="/data-manager/processed" activeClassName="active">processed</Button>
          <Button component={NavLink} to="/data-manager/incoming" activeClassName="active">incoming</Button>
        </div>

        <Typography variant="subtitle1" gutterBottom style={{clear:'both'}}>
          Processed Data

          {this.state.overdue &&
            <span className={classes.error}>*** OVERDUE *** </span>
          }
        </Typography>

        <Paper className={classes.paper}>
          <Table className={classes.table}>

            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>Table</TableCell>
                <TableCell className={classes.tableCell}>Last Update Type</TableCell>
                <TableCell className={classes.tableCell}>Updated</TableCell>
                <TableCell className={classes.tableCell}>Sequence</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.state.stats.map((stat,index) => {
                
                let dateClass = [classes.tableCell]
                if (stat.overdue) {
                  dateClass.push(classes.error)
                }

                let hasDrilldown = /cache/.test(stat.tbl)

                return (

                  <TableRow key={stat.tbl} className={classes.clickableTableRow + ' ' + classes.stripeRow} onDoubleClick={event => this.drillDown(event,stat)}>
                    <TableCell className={classes.tableCell}>{stat.tbl}</TableCell>
                    <TableCell className={classes.tableCell}>{stat.type}</TableCell>
                    <TableCell className={dateClass.join(" ")}>{Utils.formattedDateTime(stat.updated)}</TableCell>
                    <TableCell className={classes.tableCell}>{stat.sequence}</TableCell>
                    <TableCell className={classes.tableCell}>{hasDrilldown && <IconButton color="primary" onClick={event => this.drillDown(event,stat)}><ViewIcon /></IconButton>}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
    
        </Paper>
      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(DataManager))