import _ from 'lodash'
import Axios from 'axios'
import React, { Component , createRef } from 'react'
import { SelectableGroup } from 'react-selectable-fast'

import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise/dist/styles/ag-grid.css'
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import ActionCellRenderer from './attributes-action-cell-renderer'

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import { FiType } from "react-icons/fi"
import { ImLocation } from "react-icons/im"
import { BiSelectMultiple } from "react-icons/bi"
import { AiOutlineExpand } from "react-icons/ai"
import { VscSymbolMisc } from "react-icons/vsc"
import { SiTarget } from "react-icons/si"
import { MdAirlineSeatReclineExtra } from "react-icons/md"
import { BsBoxArrowDown } from "react-icons/bs"
import { BsArrowLeftRight } from "react-icons/bs"
import { MdOutlineEventSeat } from "react-icons/md"
import { MdHotelClass } from  "react-icons/md"
import { MdOutlineHotelClass } from "react-icons/md"

import Config from '../config'
import Seat from './seat'
import SelectionTools from './selection-tools'
import AttributeSelector from './attribute-selector-popup'
import getGridConfig from './attribute-editor-grid-config'

import DynamicComponent from '../components/dynamic-component'

import { SiAirtable } from "react-icons/si"
import { FaLuggageCart } from "react-icons/fa" 
import { FaToiletPaper } from "react-icons/fa" 
import { MdExitToApp } from "react-icons/md" 
import { MdOutlineEmojiFoodBeverage } from "react-icons/md" 
import { MdOutlineDirectionsBike } from "react-icons/md" 

const areaIcons = {
  table : SiAirtable,
  luggage : FaLuggageCart,
  toilet : FaToiletPaper,
  exit  : MdExitToApp,
  food  : MdOutlineEmojiFoodBeverage,
  bike  : MdOutlineDirectionsBike,
}

const defaultState = {
  selectedSeatIds:[],
  history:[],
  attributeGroups:[],
  clickedSeat:null,
  attributeSelectorOpen:false,
  seats : []
}

const attributesGroupsToColumns = {
  FACING : 'facing',
  ACCOM_TYPE : 'accomType',
  SEAT_TYPE : 'seatType',
  SEAT_POSITION : 'seatPos',
  AISLE_SIDE : 'aislePos'
}

const attributesToColumns = {
  WIFI : 'wifi',
  POWER : 'power',
  LUGGAGE : 'nearLuggage',
  TOILETS : 'nearToilets',
  BABY : 'nearBabyChange',
  FOOD : 'nearFood',
}

const attributeToDbColumnValue = {
  WHEELCHAIR : 'W',
  SEAT : 'S',
  BIKE : 'C',
  BED : 'B',
  LEFT : 'L',
  RIGHT : 'R',
  UP : 'U',
  DOWN : 'D',
  AIRLINE : 'A',
  TABLE : 'T',
  WORKSTATION : 'W',
  MEET_GREET : 'M',
  MIDDLE : 'M',
  WINDOW : 'W',
  AISLE : 'A',
  INDIVIDUAL : 'I',
  AISLE_LEFT : 'L',
  AISLE_RIGHT : 'R',
}

const getSeatAttributes = async () => {
  let options = {
    method : 'POST',
    url : `${Config.getConfig('adminApi')}request`,
    data : {
      pkg: "RarsCoaches",
      fn : "getSeatAttributesAndGroups",
    },
    headers : {
      'Content-type': 'application/json',
      'x-api-key': Config.getAPIKey(),
    },
  }

  let attributeGroups = (await Axios(options)).data.attributeGroups

  return attributeGroups 
}

let SelectionToolsBound
class SeatAttributeEditor extends Component {

  constructor(props) {
    super(props)
    this.state = defaultState
    this.state.seats = _.cloneDeep(this.props.seats)

    SelectionToolsBound = {
      selectAllSeats : SelectionTools.selectAllSeats.bind(this),
      clearSeatSelection : SelectionTools.clearSeatSelection.bind(this),
      invertSeatSelection : SelectionTools.invertSeatSelection.bind(this),
      handleSelectionFinish : SelectionTools.handleSelectionFinish.bind(this),
      gridSelectionChanged : SelectionTools.gridSelectionChanged.bind(this),
    }

    if (this.props.isEditor)
    {
      this.props.setActionButtons(this.getActionButtons())
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.seats !== this.props.seats)
    {
      // Make a copy of seats so that we do not mutate props when we adjust seat properties
      let seats = _.cloneDeep(this.props.seats)
   
      this.setState({seats})

      // Grid does not pick up nested changes, so we need to update rows ourselves
      if (this.gridApi)
      {
        this.gridApi.setRowData(this.state.seats)
      }
    }
  }
  
  async componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress, false)

    // Get attributes groups data from server
    let attributeGroups = await getSeatAttributes()
    this.setState({attributeGroups})
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.handleKeyPress, false);
  }

  closePopups = () => {
    this.setState({attributeSelectorOpen:false})
  }
  
  onGridReady = params => {
    this.gridApi = params.api
    this.gridApi.setRowData(this.state.seats)
  }
  
  // Set all "state.selectedSeatIds" to selected in the grid.
  // Needed since save action will unselect them
  gridDataUpdated = () => {
    this.gridApi && this.gridApi.forEachNode(node => {
      let selected = this.state.selectedSeatIds.includes(node.data.accomId)
      node.setSelected(selected)
    })
  }

  // Find the first seat object in our array of selected seat IDs
  getFirstSelectedSeat = () => {
    let selectedSeatId = this.state.selectedSeatIds[0]
  
    for(let seat of this.state.seats) {
      if (seat.accomId === selectedSeatId) return seat
    }
  }

  // Fired by double clicking grid and from keypress handler]
  actionClickHandler = (attributeGroup,seat) => {
    let attributeSelectorOpen = true
    let clickedSeat = seat
    let clickedAttributeGroup = attributeGroup
  
    this.setState({attributeSelectorOpen,clickedSeat,clickedAttributeGroup})
  }

  setSeatClass = seatClass => {
    let seats = this.state.seats

    for(let seat of seats) {
      if (this.state.selectedSeatIds.includes(seat.accomId))
      {
        seat.class = seatClass
      }
    }

    this.props.updateSeats(seats)
  }

  // Assigns selected attributes (currently edited seat) to all selected seats
  // Saves data back to parent
  saveSeatAttributes = async (groupName,value,multiple,checked) => {
    let seats = this.state.seats
  
    // Save current state to history before we make changes
    let history = this.state.history
    history.push(_.cloneDeep(seats))
  
    for (let s in seats)
    {
      if (this.state.selectedSeatIds.includes(seats[s].accomId))
      {
        if (multiple)
        {
          if (checked)
          {
            if (!seats[s].attributes[groupName].includes(value))
            {
              seats[s].attributes[groupName].push(value)
 
              if (attributesToColumns[value])
              {
                seats[s][attributesToColumns[value]] = 1
              }
            }
          }
          else // not "checked" - so remove attribute
          {
            _.pull(seats[s].attributes[groupName],value)

            if (attributesToColumns[value])
            {
              seats[s][attributesToColumns[value]] = 0
            }
          }
        }
        else
        {
          seats[s].attributes[groupName] = checked ? [value] : []

          if (attributesGroupsToColumns[groupName])
          {
            seats[s][attributesGroupsToColumns[groupName]] = attributeToDbColumnValue[value]
          }
        }
      }
    }
  
    this.props.updateSeats(seats)
  
    // Grid seats are unselected after save. onModelUpdater will fire gridDataUpdated which wil re-selecte them,
  
    this.closePopups()
  }

  undoLastChange = () => {

    let history = this.state.history
    let lastItem = history.pop()
  
    if (lastItem)
    {
      let seats = lastItem

      this.setState({history,seats})
  
      this.props.updateSeats(seats)
    }
  }

  // Handle short-cut key presses
  handleKeyPress = event => {

    let key = event.key
  
    if (event.metaKey) key += '-meta'
    if (event.ctrlKey) key += '-ctrl'
    if (event.shiftKey) key += '-shift'
  
    switch(key)
    {
      case "o-ctrl" : case "O-ctrl" : return this.actionClickHandler(this.state.attributeGroups.ACCOM_TYPE,this.getFirstSelectedSeat())
      case "t-ctrl" : case "T-ctrl" : return this.actionClickHandler(this.state.attributeGroups.SEAT_TYPE,this.getFirstSelectedSeat())
      case "p-ctrl" : case "P-ctrl" : return this.actionClickHandler(this.state.attributeGroups.SEAT_POSITION,this.getFirstSelectedSeat())
      case "f-ctrl" : case "F-ctrl" : return this.actionClickHandler(this.state.attributeGroups.FACING,this.getFirstSelectedSeat())
      case "m-ctrl" : case "M-ctrl" : return this.actionClickHandler(this.state.attributeGroups.MISC,this.getFirstSelectedSeat())
      case "n-ctrl" : case "N-ctrl" : return this.actionClickHandler(this.state.attributeGroups.NEAR_TO,this.getFirstSelectedSeat())
      case "l-ctrl" : case "L-ctrl" : return this.actionClickHandler(this.state.attributeGroups.AISLE_SIDE,this.getFirstSelectedSeat())
      
      case "a-ctrl" : case "A-ctrl" : return SelectionToolsBound.selectAllSeats()
      case "s-ctrl" : case "S-ctrl" : return SelectionToolsBound.clearSeatSelection()
      case "i-ctrl" : case "I-ctrl" : return SelectionToolsBound.invertSeatSelection()
      
      case "z-ctrl" : case "Z-ctrl" :  return this.undoLastChange()

      case "1-ctrl" : return this.setSeatClass(1)
      case "2-ctrl" : return this.setSeatClass(2)

      default : {} //ignore other keys
    }
  }
  
  // Provides a list of buttons that will be displayed at the bottom of the page
  getActionButtons = () => (
    <React.Fragment>
      <Tooltip title="Accommodation Type (CTRL-O)">
        <IconButton onClick={e => this.actionClickHandler(this.state.attributeGroups.ACCOM_TYPE,this.getFirstSelectedSeat())} color="primary" size="large">
          <MdAirlineSeatReclineExtra/>
        </IconButton>
      </Tooltip>

      <Tooltip title="Seat Type (CTRL-T)">
        <IconButton onClick={e => this.actionClickHandler(this.state.attributeGroups.SEAT_TYPE,this.getFirstSelectedSeat())} color="primary" size="large">
          <FiType/>
        </IconButton>
      </Tooltip>

      <Tooltip title="Position (Window / Aisle etc.) (CTRL-P)">
        <IconButton onClick={e => this.actionClickHandler(this.state.attributeGroups.SEAT_POSITION,this.getFirstSelectedSeat())} color="primary" size="large">
          <ImLocation/>
        </IconButton>
      </Tooltip>

      <Tooltip title="1st class Class (CTRL-1)">
        <IconButton onClick={e => this.setSeatClass(1)} color="primary" size="large">
          <MdHotelClass/>
        </IconButton>
      </Tooltip>

      <Tooltip title="Standard Class (CTRL-2)">
        <IconButton onClick={e => this.setSeatClass(2)} color="primary" size="large">
          <MdOutlineHotelClass/>
        </IconButton>
      </Tooltip>

      <Tooltip title="Facing (Left/Right) (CTRL-F)">
        <IconButton onClick={e => this.actionClickHandler(this.state.attributeGroups.FACING,this.getFirstSelectedSeat())} color="primary" size="large">
          <BsArrowLeftRight/>
        </IconButton>
      </Tooltip>
      
      <Tooltip title="Aisle Side (Left/Right) (CTRL-L)">
        <IconButton onClick={e => this.actionClickHandler(this.state.attributeGroups.AISLE_SIDE,this.getFirstSelectedSeat())} color="primary" size="large">
          <MdOutlineEventSeat/>
        </IconButton>
      </Tooltip>

      <Tooltip title="Miscelaneous attributes (CTRL-M)">
        <IconButton onClick={e => this.actionClickHandler(this.state.attributeGroups.MISC,this.getFirstSelectedSeat())} color="primary" size="large">
          <VscSymbolMisc/>
        </IconButton>
      </Tooltip>
      
      <Tooltip title="Near to (CTRL-M)">
        <IconButton onClick={e => this.actionClickHandler(this.state.attributeGroups.NEAR_TO,this.getFirstSelectedSeat())} color="primary" size="large">
          <SiTarget/>
        </IconButton>
      </Tooltip>

      <Tooltip title="Select all seats (CTRL-A)">
        <IconButton onClick={SelectionToolsBound.selectAllSeats} color="primary" size="large">
          <BiSelectMultiple/>
        </IconButton>
      </Tooltip>

      <Tooltip title="Invert seat selection (CTRL-I)">
        <IconButton onClick={SelectionToolsBound.invertSeatSelection} color="primary" size="large">
          <BsBoxArrowDown/>
        </IconButton>
      </Tooltip>

      <Tooltip title="Clear seat selection (CTRL-S)">
        <IconButton onClick={SelectionToolsBound.clearSeatSelection} color="primary" size="large">
          <AiOutlineExpand/>
        </IconButton>
      </Tooltip>

    </React.Fragment>
  )

  render() {
    const attributeGroups = this.state.attributeGroups
    const GridConfig = getGridConfig(this.actionClickHandler.bind(this),attributeGroups)

    this.selectableGroupRef = createRef()

    return (
      <React.Fragment>
        <br/>
        <SelectableGroup
          ref={this.selectableGroupRef}
          className="selectable-group"
          clickClassName="tick"
          enableDeselect={true}
          mixedDeselect={false}
          tolerance={0}
          onSelectionFinish={SelectionToolsBound.handleSelectionFinish}
          ignoreList={this.props.isEditor ? [] : ['.seat']}>
          
          <div>
            <img src={this.props.coach.ourImageUrl} style={{width:this.props.coach.ourImageSize.width,height:this.props.coach.ourImageSize.height}} alt=""/>

            {this.props.coach.areaData.map(area => (
              <div className="area" style={{position:'absolute',width:area.width,height:area.height,top:area.y,left:area.x}}>
                <div style={{position:'absolute',left:(area.width/2) - 15, top:(area.height/2) - 20}}>
                  <DynamicComponent component={areaIcons[area.type]} size="2em"/>
                </div>
              </div>
            ))}

            <div style={{position:"absolute",top:0}}>
              <div style={{position:"relative"}}>
                {this.state.seats.map(seat => (
                  <Seat seat={seat} coach={this.props.coach} key={seat.accomId} tooltip={false}/>
                ))}
              </div>
            </div>
          </div>

        </SelectableGroup>

        <div className="ag-theme-alpine" style={{width:'100%',height:`calc(100vh - ${this.props.coach.ourImageSize.height + 210}px)`}}>
          <AgGridReact
            onGridReady={this.onGridReady}
            rowBuffer={GridConfig.visibleRowBufferSize*2}
            columnDefs={GridConfig.columnDefs}
            animateRows={true}
            rowSelection="multiple"
            rowDeselection={true}
            suppressCellSelection={true}
            suppressRowClickSelection={!this.props.isEditor}
            onSelectionChanged={SelectionToolsBound.gridSelectionChanged}
            onModelUpdated={this.gridDataUpdated.bind(this)}
            statusBar = {{
              statusPanels : [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'right' },
              ]
            }}
            sideBar={{
              toolPanels : ['filters'],
              closedByDefault : true,
            }}
            frameworkComponents = {{
              actionCellRenderer : ActionCellRenderer,
            }}
            >
          </AgGridReact>
        </div>

        <AttributeSelector open={this.state.attributeSelectorOpen} seat={this.state.clickedSeat} attributeGroup = {this.state.clickedAttributeGroup} saveSeatAttributes={this.saveSeatAttributes} close={this.closePopups}/>

      </React.Fragment>
    )
  }
}

export default SeatAttributeEditor