import Axios from 'axios'
import Config from '../config'

export async function awsSdk(fn,params={}) {
  try
  {
    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "AWS",
        fn : fn,
        params : params
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey('adminApi'),
      },
    }

    let result = await Axios(options)

    return result.data.data
  }
  catch(err)
  {
    console.log(JSON.stringify(err,null,2))
    throw err
  }
}

export async function getMyIp() {
  try
  {
    let options = {
      method : 'GET',
      url : `https://api.ipify.org?format=json'`,
    }

    let result = await Axios(options)

    return result.data
  }
  catch(err)
  {
    throw err
  }
}

