import React from 'react'
import { createSelectable } from 'react-selectable-fast'

import Tooltip from '@material-ui/core/Tooltip'

const Seat = createSelectable(props => {
  const { selectableRef, isSelected, isSelecting, seat, coach, tooltip } = props

  let classNames = [
    'seat',
    isSelecting && 'selecting',
    isSelected && 'selected',
  ].filter(Boolean).join(' ')

  if (seat.inventoryClass.length !== 2)
  {
    classNames += ' seat-error'
  }

  let seatType = "seat"

  switch (seat.accomType)
  {
    case "S" : {
      switch (seat.class)
      {
        case 1: {classNames += ' seat-class-first'; break}
        case 2: {classNames += ' seat-class-standard'; break}
        case 9: {classNames += ' seat-class-other'; break}

        default : {classNames += ' seat-error'}
      }

      seatType = "seat"
      break
    }

    case "B" : {
      classNames += ' bed'
      seatType = "bed"
      break
    }

    case "W" : {
      classNames += ' wheelchair'
      seatType = "wheelchair"
      break
    }

    case "C" : {
      classNames += ' bike'
      seatType = "bike"
      break
    }

    default : {
      classNames += ' seat-error'
      seatType = "unknown"
    }
  }

  switch(seat.facing)
  {
    case "L": {
      classNames += ' seat-facing-left'
      break
    }
    case "R": {
      classNames += ' seat-facing-right'
      break
    }
    case "U": {
      classNames += ' seat-facing-up'
      break
    }
    case "D": {
      classNames += ' seat-facing-down'
      break
    }
    default:{}
  }
  
  return (
    <Tooltip key={seat.accomId} title={Object.keys(seat.attributes).map(k => seat.attributes[k]).join(".")} >
      <div ref={selectableRef} className={classNames} style={{width:coach.ourSeatSize.width,height:coach.ourSeatSize.height,top:seat.position.y,left:seat.position.x, pointerEvents:tooltip ? "" : "none"}} disabled={!tooltip}>
        <div className={"seat-text"}>{seat.accomId}</div>
        <div className={"seat-class"}>{seat.inventoryClass}</div>
        <div className={"seat-type"}>{seatType}</div>
      </div>
    </Tooltip>
  )
})

export default (Seat)