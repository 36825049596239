import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Badge from '@material-ui/core/Badge'

const styles = theme => ({
  card: {
    textAlign : 'center',
    cursor : 'pointer',
  },
  cardContent : {
    padding : '0 !important',
  },
  title: {
    padding : '3px',
    fontSize: 14,
    color : '#80AA66',
  },
  calls: {
    fontSize: 28,
  },
  coldstarts: {
    fontSize: 14,
  },
  duration: {
    fontSize: 12,
  },

})

const tooltipStyle = {
  tooltip : {
    fontSize: "20px",
    whiteSpace: "pre-line"
  }
}

const CustomTooltip = withStyles(tooltipStyle)(Tooltip)

class StatsCard extends Component {

  constructor(props, context) {
    super(props, context)

    this.state = {data:{}}
  }

  componentDidMount() {
    if (this.props.dataSource)
    {
      this.updateData()

      this.intervalRef = setInterval(() => {
        if (!this.props.sleeping)
        {
          this.updateData()
        }
      }, this.props.updateInterval)
    }
  }
  
  componentWillUnmount() {
    clearInterval(this.intervalRef)
  }

  async updateData() {
    if (this.props.namespace)
    {
      let data = await this.props.dataSource(this.props.namespace,this.props.mode)

      data.errorPercent = data.errorssum ? (data.errorssum / data.invocationssum * 100).toFixed(1) : 0

      this.setState({data})  
    }
  }

  render() {

    const { classes } = this.props
  
    let tooltipText = `Number of calls : ${this.state.data.invocationssum}
    
    Errors : ${this.state.data.errorssum} (${this.state.data.errorPercent}%)
    
    Average Duration ${parseInt(this.state.data.durationaverage,10)}ms
    Maximum Duration ${parseInt(this.state.data.durationmaximum,10)}ms
    Minimum Duration ${parseInt(this.state.data.durationminimum,10)}ms`

    return (
      <Card className={classes.card} onClick={event => this.props.clickHandler(this.props)}>
        <CustomTooltip title={tooltipText}>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.title} gutterBottom component="h2">
              {this.props.title}
            </Typography>

            <Typography className={classes.calls} variant="h5" color="textPrimary">
              <Badge badgeContent={this.state.data.errorssum} color="secondary">
                {this.state.data.invocationssum}
              </Badge>
            </Typography>

            <Typography className={classes.duration} variant="h5" color="textPrimary">
              {this.state.data.durationaverage ? parseInt(this.state.data.durationaverage,10) : 0} ms
            </Typography>
          </CardContent>
        </CustomTooltip>
      </Card>
    )
  }
}

export default withStyles(styles)(StatsCard)
