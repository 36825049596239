import React, { PureComponent } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'

class AlertPopup extends PureComponent {

  render()
  {
    return (
      <Dialog
        open={this.props.modalOpen}
        onClose={this.props.handleCloseAlert}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        maxWidth="lg"
      >

        <DialogTitle id="scroll-dialog-title">Search Error</DialogTitle>

        <DialogContent>{this.props.text}</DialogContent>

        <DialogActions>
          <Button onClick={this.props.handleCloseAlert} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default AlertPopup
