import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import passwordGenerator from 'generate-password'

import { Dialog, DialogActions, DialogContent, Button, FormGroup, FormControlLabel, TextField, Switch } from '@material-ui/core'

import { updateUser } from '../data-providers/users'

const styles = theme => ({
  formControl: {
    margin: 0,
    minWidth: 120,
    maxWidth: 300,
  },
  formGroup: {
    paddingTop: '20px',
  },
  button: {
    margin: theme.spacing(),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select : {
    minWidth : 300
  },
})

class EditUserForm extends Component {

  constructor(props, context) {
    super(props, context)  
    this.state = {user:{},passwordRequired:false,autoPassword:true,removePassword:false}
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.node !== prevProps.node)
    {
      let user = this.props.node
      this.setState({user,passwordRequired:false,autoPassword:true,removePassword:false})
    }
  }

  
  handleCancel = () => {
    this.props.close()
  }

  handleSubmit = async () => {
    try
    {
      let user = this.state.user
      let newPassword

      let userData = {
        organisationId : user.organisationId,
        username : user.username,
        systemOwner : user.systemOwner,
        systemAdmin : user.systemAdmin,
        licenseeAdmin : user.licenseeAdmin,
        organisationAdmin : user.organisationAdmin
      }
      
      if (this.state.removePassword)
      {
        userData.password = null
      }
      else
      {
        if (this.state.passwordRequired && this.state.autoPassword)
        {
          newPassword = passwordGenerator.generate({length:12,numbers:true,excludeSimilarCharacters:true,lowercase:true,uppercase:true})
        }
      }

      await updateUser(this.state.user.userId,userData,newPassword)

      this.props.refreshGrid(user.organisationId,user.userId)

      this.props.close()
    }
    catch(err)
    {
      console.log(err)
      throw err
    }
  }

  intToTrueFalse = value => value === 1

  handleUserChange = event => {
    let user = this.state.user
    user[event.target.name] = event.target.value ? event.target.value : event.target.checked ? 1 : 0

    this.setState({user})
  }

  handleChange = event => {
    let value = event.target.value ? event.target.value : event.target.checked ? true : false 
    this.setState({[event.target.name]:value})
  }

  render() {

    const { classes, open  } = this.props

    return (
        
        <Dialog open={open} fullWidth={true} maxWidth="md">
          <DialogContent>

            {this.state.user && 
              <div>{this.state.user.username}</div>  
            }
            
            <FormGroup row className={classes.formGroup}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.intToTrueFalse(this.state.user.systemAdmin)}
                    onChange={this.handleUserChange}
                    name="systemAdmin"
                    color="primary"
                  />
                }
                label="System Admin"
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={this.intToTrueFalse(this.state.user.licenseeAdmin)}
                    onChange={this.handleUserChange}
                    name="licenseeAdmin"
                    color="primary"
                  />
                }
                label="Licensee Admin"
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={this.intToTrueFalse(this.state.user.organisationAdmin)}
                    onChange={this.handleUserChange}
                    name="organisationAdmin"
                    color="primary"
                  />
                }
                label="Organisation Admin"
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={this.intToTrueFalse(this.state.user.refunds)}
                    onChange={this.handleUserChange}
                    name="refunds"
                    color="primary"
                  />
                }
                label="Can do refunds"
              />

            </FormGroup>

            <FormGroup row className={classes.formGroup}>
            <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.removePassword}
                      onChange={this.handleChange}
                      name="removePassword"
                      color="primary"
                    />
                  }
                  label="Remove Password"
                />

              <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.passwordRequired}
                      onChange={this.handleChange}
                      name="passwordRequired"
                      color="primary"
                      disabled={this.state.removePassword}
                    />
                  }
                  label="Reset Password"
                />

                {this.state.passwordRequired && 
                  <React.Fragment>
                    <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.autoPassword}
                            onChange={this.handleChange}
                            name="autoPassword"
                            color="primary"
                            disabled={this.state.removePassword}
                          />
                        }
                        label="Auto Generate Password"
                      />

                    {!this.state.autoPassword && 
                      <TextField autoFocus margin="dense" name="password" label="Password" value={this.state.user.password} onChange={this.handleUserChange} inputProps={{"data-lpignore":"true"}} disabled={this.state.removePassword}/>
                    }
                  </React.Fragment>
                }
            </FormGroup>
  

          </DialogContent>

          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">Cancel</Button>
            <Button onClick={this.handleSubmit} color="primary">Save User</Button>
          </DialogActions>
        </Dialog>

    )
  }
}

export default withStyles(styles)(EditUserForm)