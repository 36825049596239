import React, { PureComponent } from 'react'
import { NavLink,withRouter } from 'react-router-dom'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { ResponsiveContainer, ComposedChart, Bar, Line, XAxis,YAxis,CartesianGrid,Tooltip,Legend } from 'recharts'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import chartProvider from '../data-providers/charts'

import './overview.css'

const chartOptions = {
  metrics : ['Timer','Timer','Timer','Timer','Timer'],
  stats : ['Requests','Errors','Avg','Min','Max'],
  colours : ['#ddd','#000','#ff9900','#65AD63','#ff0000'],
}

const chartPeriodOptions = [
  {label:"1 hour",minutes:60,period:60,resolution:1},
  {label:"2 hours",minutes:120,period:120,resolution:1},
  {label:"6 hours",minutes:360,period:360,resolution:5},
  {label:"12 hours",minutes:720,period:720,resolution:5},
  {label:"1 day",minutes:1440,period:1440,resolution:5},
  {label:"1 week",minutes:10080,period:10080,resolution:180},
  {label:"1 month",minutes:43200,period:43200,resolution:360},
]

const styles = theme => ({
  paper: {
    width: 'calc(100%)',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    padding: '15px'
  },  
  chart : {
    backgroundColor : 'white',
    borderRadius : '5px',
  },
  button: {
    margin: theme.spacing(),
  },
  active : {
    color : '#fff',
    backgroundColor : '#000',
  },
})

class CustomizedAxisTick extends React.PureComponent {

  render () {
    const {x, y, payload} = this.props;
    
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">{payload.value}</text>
      </g>
    )
  }
}



const RailServiceChart = props => (

  <ResponsiveContainer height={400} width='100%'>
    <ComposedChart data={props.data} margin={{top: 5, right: 30, left: 20, bottom: 25}} className="overviewChart">
      <XAxis dataKey="date" height={55} tick={<CustomizedAxisTick/>} interval={5} key={Math.random()}/>
      <CartesianGrid strokeDasharray="1 1"/>
      <YAxis yAxisId="right" orientation="right" unit="ms" stroke="#8884d8" key={Math.random()}/>
      <YAxis yAxisId="left" orientation="left" key={Math.random()}/>   
      <Tooltip/>
      <Legend verticalAlign="top" align="right" className={props.classes.Legend}/>
          
      {chartOptions.stats.map((stat,index) => {

        let metric = props.source.toLowerCase() + chartOptions.metrics[index].toLowerCase()
        let statname = chartOptions.stats[index]
        let datakey = metric + stat
        let title = chartOptions.stats[index]

        switch (statname)
        {
          case "Requests": return (<Bar yAxisId="left" name={`${props.source} ${title}`} key={datakey} type="monotone" dataKey={stat} fill={chartOptions.colours[index]} />)
          case "Errors": return (<Bar yAxisId="left" name={title} key={datakey} type="monotone" dataKey={stat} stroke={chartOptions.colours[index]} />)
          default : return (<Line yAxisId="right" name={title} key={datakey} type="monotone" dataKey={stat} stroke={chartOptions.colours[index]} />)
        }
      })}

    </ComposedChart>
  </ResponsiveContainer>
)

class Overview extends PureComponent {

  constructor(props, context) {
    super(props, context)
    this.state = {mode:"",nrs:[],lsm:[],tvd:[],chartPeriod:chartPeriodOptions[0]}
  }

  componentDidMount() {
    let {mode} = this.props.match.params

    this.setState({mode},this.startUpdateTimer)
  }

  componentDidUpdate(prevProps) {
    let {mode} = this.props.match.params

    if(prevProps.match.params.mode !== mode)
    {
      clearInterval(this.timerID)

      let {mode} = this.props.match.params
  
      this.setState({mode},this.startUpdateTimer)
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  startUpdateTimer() {
    this.refreshCharts()
    this.timerID = setInterval(() => this.refreshCharts(),60000)
  }

  refreshCharts = async () => {

    let promises = []

    promises.push(chartProvider(this.state.mode,"Nrs",this.state.chartPeriod.period,this.state.chartPeriod.resolution))
    promises.push(chartProvider(this.state.mode,"Lsm",this.state.chartPeriod.period,this.state.chartPeriod.resolution))
    promises.push(chartProvider(this.state.mode,"Tvd",this.state.chartPeriod.period,this.state.chartPeriod.resolution))

    let [nrs,lsm,tvd] = await Promise.all(promises)

    this.setState({nrs,lsm,tvd})
  }

  setChartPeriod = index => {
    this.setState({chartPeriod:chartPeriodOptions[index]},this.refreshCharts)
  }

  render() {

    const { classes } = this.props

    return (

      <React.Fragment>

        <div className="stageMenu">
          <Button component={NavLink} to="/nrs-lsm-tvd/live" activeClassName="active">live</Button>
          <Button component={NavLink} to="/nrs-lsm-tvd/test" activeClassName="active">test</Button>
        </div>

        <div className="viewOptions">
          {chartPeriodOptions.map((period,index) => {

            let className = period === this.state.chartPeriod ? classes.active : null
            return (<Button variant="contained" className={className} key={period.minutes} onClick={event => this.setChartPeriod(index)}>{period.label}</Button>)
          })}
        </div>

        <Paper className={classes.paper}>
          <Typography variant="h4" gutterBottom style={{clear:'both'}}>NRS ({this.state.mode})</Typography>
          <RailServiceChart data={this.state.nrs} classes={classes} source="NRS"/> 
        </Paper>

        <Paper className={classes.paper}>
        <Typography variant="h4" gutterBottom style={{clear:'both'}}>LSM ({this.state.mode})</Typography>
          <RailServiceChart data={this.state.lsm} classes={classes} source="LSM"/> 
        </Paper>

        <Paper className={classes.paper}>
        <Typography variant="h4" gutterBottom style={{clear:'both'}}>eTVD ({this.state.mode})</Typography>
          <RailServiceChart data={this.state.tvd} classes={classes} source="eTVD"/> 
        </Paper>

      </React.Fragment>
    )
  }
}

Overview.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(Overview))

