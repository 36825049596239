import AWS from 'aws-sdk'
import Moment from 'moment-timezone'
import _ from 'lodash'

const Config = require('../config')

const ChartConfig = {
  statTypes : ['SampleCount','Average','Minimum','Maximum','Sum'],
  statTitles : ['Requests','Avg','Min','Max','Errors'],
}

export default async function getStats(mode,source,dataPeriod,dataResolution) {
  
  try
  {
    // Need nearest 5 minutes
    let today = Moment() //We want GMT here, not BST!

    if (dataPeriod > 10080)
    {
      today.subtract(today.minute() % 5,"minutes")
    }

    let combinedData = {}
    let labelFormat = dataPeriod > 1440 ? 'DD-MMM HH:mm' : 'HH:mm'

    combinedData[today.format(labelFormat)] = {date : today.format(labelFormat)}

    for (let d=0;d<dataPeriod;d += dataResolution)
    {
      today.subtract(dataResolution,"m") // mutates 'today' on each iteration!
  
      combinedData[today.format(labelFormat)] = {date : today.format(labelFormat)}
  
      for (let t=0;t<ChartConfig.statTitles.length;t++)
      {
        combinedData[today.format(labelFormat)][ChartConfig.statTitles[t]] = 0
      }
    }

    let data = await getCloudwatchMetric(mode,source,dataPeriod,dataResolution)

    for (let d=0;d<data.length;d++)
    {
      for (let i=0;i<data[d].Values.length;i++)
      {
        let date = data[d].Timestamps[i]
        let value = data[d].Values[i]

        combinedData[Moment(date).format(labelFormat)][ChartConfig.statTitles[d]] = parseInt(value,10)
      }
    }
  
    return _.reverse(_.values(combinedData))
  }
  catch(err)
  {
    console.error("ERROR:getCloudwatchData",err)
    throw err
  }
}

async function getCloudwatchMetric(mode,service,dataPeriod,dataResolution) {

  try
  {
    let awsConfig = Config.getConfig("awsConfig")
    if (!awsConfig) { return }

    const CW = new AWS.CloudWatch(awsConfig)


    let startTime = Moment().tz('Europe/London').subtract(dataPeriod,'minutes').toISOString()
    let endTime = Moment().tz('Europe/London').toISOString()

    const namespace = `SMARTIX/${service.toUpperCase()}`

    let params = {
      StartTime : startTime,
      EndTime : endTime,
      MetricDataQueries : []
    }

    params.MetricDataQueries.push(getMetricDataQuery(namespace,'Invocations','SampleCount',mode,dataResolution))
    params.MetricDataQueries.push(getMetricDataQuery(namespace,'Duration','Average',mode,dataResolution))
    params.MetricDataQueries.push(getMetricDataQuery(namespace,'Duration','Minimum',mode,dataResolution))
    params.MetricDataQueries.push(getMetricDataQuery(namespace,'Duration','Maximum',mode,dataResolution))
    params.MetricDataQueries.push(getMetricDataQuery(namespace,'Errors','Sum',mode,dataResolution))

    return (await CW.getMetricData(params).promise()).MetricDataResults
  }
  catch(err)
  {
    console.log("ERROR:getCloudwatchMetric",JSON.stringify(err,null,2))
  }
}

function getMetricDataQuery (namespace,MetricName,Stat,mode,dataResolution) {

  return {
    Id : MetricName.toLowerCase() + Stat,
    MetricStat : {
      Metric : {
        MetricName : MetricName,
        Namespace : namespace,
        Dimensions: [
          {
            Name: "mode",
            Value: mode
          }
        ]
      },
      Period : dataResolution * 60, //Convert mins to seconds
      Stat : Stat,
    },
    ReturnData : true
  }
}

