import Axios from 'axios'
import React, { Component } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'

import CloseIcon from '@material-ui/icons/Close'
import { MdMergeType } from "react-icons/md" 
import { AiOutlineDelete } from "react-icons/ai" 

import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise/dist/styles/ag-grid.css'
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'

import { confirm } from 'mui-confirm-modal'

import Config from '../config'

const TocImageFormatter = props => {

  return <div style={{height:300}}>
    <img src={props.data.tocImageUrl} alt="" style={{position:'absolute',height:props.data.tocImageSize.height,left:0,top:0}} height={props.data.tocImageSize.height}/>

    {props.data.tocSeats.map(seat => (
      <div key={seat.id} className="seat greySeat" style={{position:'absolute',width:props.data.tocSeatSize.width,height:props.data.tocSeatSize.height,top:seat.yPos,left:seat.xPos}}>
        {seat.id}
      </div>
    ))}
  </div>
}

const OurImageFormatter = props => {

  return <div style={{height:300}}>
    <img src={props.data.ourImageUrl} alt="" style={{position:'absolute',height:props.data.ourImageSize.height,left:0,top:0}} height={props.data.ourImageSize.height}/>

    {props.data.seats.map(seat => (
      <div key={seat.accomId} className="seat greySeat" style={{position:'absolute',width:props.data.ourSeatSize.width,height:props.data.ourSeatSize.height,top:seat.y,left:seat.x}}>
        {seat.accomId}
      </div>
    ))}
  </div>
}

const primaryRadio = props => <FormControlLabel value={props.value} control={<Radio />} label={props.value} />

class MergeHandler extends Component {

  constructor(props) {
    super(props)
    this.state = {
      mergableCoaches : [],
      selectedCoaches : [],
      sameSeatNum : true,
      useTOCimages : true,
      primaryCoachType : {},
      mergedCoachName : "",
      columnDefs : []
    }

    this.filterMergableCoaches()
  }

  async componentDidUpdate(prevProps) {

    if (this.props.allCoaches !== prevProps.allCoaches || this.props.coach !== prevProps.coach)
    {
      if (this.props.coach)
      {
        this.filterMergableCoaches()
      }

      let columnDefs = this.getColumnDefs(this.state.useTOCimages)

      this.setState({selectedCoaches:[],columnDefs})
    }
  }
   
  getColumnDefs = useTOCimages => {
    return [
      {
        field: "ourTypeId",
        headerName: "Our Type ID",
        sortable: true, 
        filter: 'agNumberColumnFilter',
        width :100,
        menuTabs : ['filterMenuTab'],
        filterParams : {
          filterOptions : ['inRange','greaterThanOrEqual','lessThanOrEqual'],
          suppressAndOrCondition : true,
          inRangeInclusive : true,
        },
        cellRendererFramework: primaryRadio,
      },
      {
        field: "ourName",
        headerName: "ourName",
        sortable: true, 
        filter: 'agSetColumnFilter',
        menuTabs : ['filterMenuTab'],
        width : 300
      },
      {
        field: "numSeats",
        headerName: "Num seats",
        sortable: false,
      },
      {
        field: "tocImageUrl",
        headerName: (useTOCimages ? "TOC " : "Our ") + "Image",
        sortable: false,
        autoHeight : true,
        height : 300,
        width : 3000,
        cellRendererFramework: useTOCimages ? TocImageFormatter : OurImageFormatter
      },
    ]
  }

  filterMergableCoaches = () => {
    let mergableCoaches = this.props.allCoaches.sort((a,b) => a.numSeats - b.numSeats).filter(c => {
      return c.carrierCode === this.props.coach.carrierCode && (!this.state.sameSeatNum || c.numSeats === this.props.coach.numSeats)
    })

    let primaryCoachType = mergableCoaches.filter(c => c.ourTypeId === this.state.primaryCoachType.ourTypeId)[0] || mergableCoaches[0]
    let mergedCoachName = primaryCoachType ? primaryCoachType.ourName : ""

    this.setState({primaryCoachType,mergableCoaches,mergedCoachName})
  }

  selectionChanged = grid => {
    let selectedCoaches = grid.api.getSelectedRows().map(r => r.ourTypeId)
    this.setState({selectedCoaches})
  }

  setSeatNum = event => {
    let sameSeatNum = event.target.checked
    this.setState({sameSeatNum},() => {
      this.filterMergableCoaches()
    })
  }

  setImageType = event => {
    let useTOCimages = event.target.checked
    let columnDefs = this.getColumnDefs(useTOCimages)
    this.setState({useTOCimages,columnDefs})
  }

  handlePrimaryIdChange = event => {
    let primaryTypeId = parseInt(event.target.value,10)
    let mergableCoaches = this.state.mergableCoaches

    this.setState({mergableCoaches})

    let primaryCoachType = mergableCoaches.filter(c => c.ourTypeId === primaryTypeId)[0] || mergableCoaches[0]
    let mergedCoachName = primaryCoachType.ourName

    this.setState({primaryCoachType,mergedCoachName})
  }

  handleMergedCoachNameChange = event => {
    let mergedCoachName = event.target.value
    this.setState({mergedCoachName})
  }

  removeCoach = () => {
    let mergableCoaches = []
    let selectedCoaches = []

    this.state.mergableCoaches.forEach(c => {

      if (!this.state.selectedCoaches.includes(c.ourTypeId))
      {
        mergableCoaches.push(c)
      }
    })

    this.setState({mergableCoaches,selectedCoaches})
  }

  merge = async () => {

    let baseCoachTypeId = this.state.primaryCoachType.ourTypeId
    let coachesToMerge = this.state.selectedCoaches.filter(id => id !== baseCoachTypeId)
    let name = this.state.mergedCoachName

    console.log("baseCoachTypeId",baseCoachTypeId)
    console.log("selectedCoaches",this.state.selectedCoaches)
    console.log("coachesToMerge",coachesToMerge)

    if (!coachesToMerge.length)
    {
      alert('Nothing to merge. No coaches selected')
      return
    }
    
    if (await confirm({message:'Are you certain you wish to merge these coaches? This is NOT reversible!'}))
    {
      let options = {
        method : 'POST',
        url : `${Config.getConfig('adminApi')}request`,
        data : {
          pkg: "RarsCoaches",
          fn : "mergeCoaches",
          params : {
            baseCoachTypeId,
            coachesToMerge,
            name
          }
        },
        headers : {
          'Content-type': 'application/json',
          'x-api-key': Config.getAPIKey(),
        },
      }

      await Axios(options)

      // Need to tell LIST to update
      await this.props.refreshList()
      this.props.close()
    }
  }

  render() {
    const { open, close, coach } = this.props

    if (!open) return ''

    return (
      <Dialog fullScreen open={open} onClose={close}>
        <DialogTitle id="form-dialog-title">Merge coach types {coach.tocName} </DialogTitle>
        <DialogContent>
          <div style={{display: 'flex',flexDirection: 'column'}}>
            <div style={{display: 'flex',flexDirection: 'row'}}>
              <Typography>All coaches</Typography>
              <Switch checked={this.state.sameSeatNum} onChange={this.setSeatNum} />
              <Typography>Coaches with same number of seats only</Typography>
            </div>

            <div style={{display: 'flex',flexDirection: 'row'}}>
              <Typography>Our images</Typography>
              <Switch checked={this.state.useTOCimages} onChange={this.setImageType} />
              <Typography>TOC Images</Typography>
            </div>
          </div>

          <RadioGroup onChange={this.handlePrimaryIdChange} value={this.state.primaryCoachType.ourTypeId}>
            <div className="ag-theme-alpine" style={ {height: '640px', width: '100%'} }>

              <AgGridReact
                columnDefs={this.state.columnDefs}
                rowData={this.state.mergableCoaches}
                animateRows={true}
                rowSelection="multiple"
                rowDeselection={true}
                suppressCellSelection={true}
                enableCellTextSelection={false}
                sideBar={{
                  toolPanels : ['filters'],
                  closedByDefault : true,
                }}
                onSelectionChanged={this.selectionChanged.bind(this)}
                >
              </AgGridReact>
            </div>
          </RadioGroup>

          <div style={{marginTop:'10px'}}>
            <TextField label="Merged coach name" fullWidth variant="outlined" value={this.state.mergedCoachName} onChange={this.handleMergedCoachNameChange}/>
          </div>

        </DialogContent>

        <DialogActions>
        
          <IconButton onClick={this.removeCoach} color="primary" size="large">
            <AiOutlineDelete/>
          </IconButton>

          <IconButton onClick={this.merge} color="secondary" size="large">
            <MdMergeType/>
          </IconButton>
          
          <div style={{flex: '1 0 0'}} />

          <IconButton onClick={close} color="primary" size="large">
            <CloseIcon/>
          </IconButton>
        </DialogActions>
      </Dialog>
    )
  }
}

export default MergeHandler