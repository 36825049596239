import _ from 'lodash'
import React, { Component } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'

import CloseIcon from '@material-ui/icons/Close'
import { CgUserAdd } from "react-icons/cg"

class AddSeatHandler extends Component {

  constructor(props) {
    super(props)

    let seats = _.cloneDeep(this.props.seats)

    this.state = {
      seats,
      nearToSeat : seats[0],
      newSeatId : '',
    }
  }
   
  handleChange = event => {
    let state = this.state
    state[event.target.name] = event.target.value
    this.setState(state)
  }

  addSeat = () => {
    if (this.state.newSeatId)
    {
      let newSeat = _.cloneDeep(this.state.nearToSeat)

      newSeat.accomId = this.state.newSeatId

      newSeat.position.x += 10
      newSeat.position.y += 10
      newSeat.isNew = true

      delete newSeat.rarsPropertyCodes // Not in RARS so don't want RARS properties applied.

      this.props.addNewSeat(newSeat)

      this.props.close()
    }
  }


  render() {
    const { open, close, seats } = this.props

    if (!seats) return ''

    return (
      <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={close}>
        <DialogTitle id="form-dialog-title">Add a seat to this coach</DialogTitle>
        <DialogContent>
          <form style={{display: 'flex',flexDirection: 'column',marginBottom:'20px'}}>
            <div style={{display: 'flex',flexDirection: 'row'}}>
              <InputLabel id="newSeatId" style={{marginRight:'10px'}}>ID of new seat</InputLabel>
              <TextField value={this.state.newSeatId} name="newSeatId" id="newSeatId" onChange={this.handleChange}/>
            </div>

            <div style={{display: 'flex',flexDirection: 'row'}}>
              <InputLabel id="nearToSeat" style={{marginRight:'10px'}}>Position new seat next to</InputLabel>&nbsp;
              <Select
                id="nearToSeat"
                name="nearToSeat"
                value={this.state.nearToSeat}
                onChange={this.handleChange}
              >
                {this.state.seats.map(s => <MenuItem value={s}>{s.accomId}</MenuItem>)}
              </Select>
              </div>
          </form>
        </DialogContent>

        <DialogActions>
          <IconButton onClick={this.addSeat} color="secondary" size="large">
            <CgUserAdd/>
          </IconButton>
          
          <div style={{flex: '1 0 0'}} />

          <IconButton onClick={close} color="primary" size="large">
            <CloseIcon/>
          </IconButton>
        </DialogActions>
      </Dialog>
    )
  }
}

export default AddSeatHandler