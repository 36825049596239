import React, { PureComponent } from 'react'
import { NavLink,withRouter } from 'react-router-dom'

import Axios from 'axios'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import ViewIcon from '@material-ui/icons/OpenInNew'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Config from '../config'

const styles = theme => ({
  paper: {
    width: 'calc(100%)',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
    width : '100%',
    tableLayout : 'auto'
  },
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    padding : '4px 15px 4px 15px'
  },
  center: {
    textAlign : 'center'
  },
  error: {
    color : 'red'
  },
  ok: {
    color : 'green'
  },  
  clickableTableRow: {
    cursor : 'pointer'
  },
  button: {
    margin: theme.spacing(),
  },  
  stripeRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  }, 
})

const TableHeader = props => (
  <TableHead>
    <TableRow>
      <TableCell className={props.classes.tableCell}>API</TableCell>
      <TableCell className={props.classes.tableCell}>Version</TableCell>
      <TableCell className={props.classes.tableCell}>Function</TableCell>
      <TableCell className={[props.classes.tableCell, props.classes.center].join(' ')} colSpan={3}>Today</TableCell>
      <TableCell className={[props.classes.tableCell, props.classes.center].join(' ')} colSpan={3}>Week</TableCell>
      <TableCell className={[props.classes.tableCell, props.classes.center].join(' ')} colSpan={3}>Month</TableCell>
      <TableCell className={props.classes.tableCell}></TableCell>
    </TableRow>

    <TableRow>
      <TableCell className={props.classes.tableCell} colSpan={3}></TableCell>
      <TableCell numeric="true" className={props.classes.tableCell}>Calls</TableCell>
      <TableCell numeric="true" className={[props.classes.tableCell, props.classes.ok].join(' ')}>Ok</TableCell>
      <TableCell numeric="true" className={[props.classes.tableCell, props.classes.error].join(' ')}>Errors</TableCell>
      <TableCell numeric="true" className={props.classes.tableCell}>Calls</TableCell>
      <TableCell numeric="true" className={[props.classes.tableCell, props.classes.ok].join(' ')}>Ok</TableCell>
      <TableCell numeric="true" className={[props.classes.tableCell, props.classes.error].join(' ')}>Errors</TableCell>
      <TableCell numeric="true" className={props.classes.tableCell}>Calls</TableCell>
      <TableCell numeric="true" className={[props.classes.tableCell, props.classes.ok].join(' ')}>Ok</TableCell>
      <TableCell numeric="true" className={[props.classes.tableCell, props.classes.error].join(' ')}>Errors</TableCell>
      <TableCell className={props.classes.tableCell}></TableCell>
    </TableRow>
  </TableHead>
)

const TableDataRow = props => (
    <TableRow key={props.key2} className={props.classes.clickableTableRow + ' ' + props.classes.stripeRow} onDoubleClick={event => drillDown(event,props.history,props.item.userId,props.item.user)}>
      <TableCell className={props.classes.tableCell}>{props.item.api}</TableCell>
      <TableCell className={props.classes.tableCell}>{props.item.version}</TableCell>
      <TableCell className={props.classes.tableCell}>{props.item.functionName}</TableCell>
      <TableCell numeric="true" className={props.classes.tableCell}>{props.item.today_calls}</TableCell>
      <TableCell numeric="true" className={[props.classes.tableCell, props.classes.ok].join(' ')}>{props.item.today_ok}</TableCell>
      <TableCell numeric="true" className={[props.classes.tableCell, props.classes.error].join(' ')}>{props.item.today_errors}</TableCell>
      <TableCell numeric="true" className={props.classes.tableCell}>{props.item.week_calls}</TableCell>
      <TableCell numeric="true" className={[props.classes.tableCell, props.classes.ok].join(' ')}>{props.item.week_ok}</TableCell>
      <TableCell numeric="true" className={[props.classes.tableCell, props.classes.error].join(' ')}>{props.item.week_errors}</TableCell>
      <TableCell numeric="true" className={props.classes.tableCell}>{props.item.month_calls}</TableCell>
      <TableCell numeric="true" className={[props.classes.tableCell, props.classes.ok].join(' ')}>{props.item.month_ok}</TableCell>
      <TableCell numeric="true" className={[props.classes.tableCell, props.classes.error].join(' ')}>{props.item.month_errors}</TableCell>
    </TableRow>
)

const drillDown = (event,history,id,name) =>
{
  history.push(`${history.location.pathname}/${id}/${name}`)
}

class Organisations extends PureComponent {

  constructor(props, context) {
    super(props, context)
    this.state = {stage:"",users:{}}
  }

  async componentDidMount() {
    let {stage,licenseeId,licensee,organisationId,organisation} = this.props.match.params
    this.setState({stage:stage,licensee:licensee,licenseeId:licenseeId,organisation:organisation,organisationId:organisationId,users:{}},this.init)
  }

  componentDidUpdate(prevProps) {
    let {stage,licenseeId,licensee,organisationId,organisation} = this.props.match.params

    if(prevProps.match.params.stage !== stage)
    {
      this.setState({stage:stage,licensee:licensee,licenseeId:licenseeId,organisation:organisation,organisationId:organisationId,users:{}},this.init)
    }
  }

  async init() {
    this.getUsage()
  }

  async getUsage() {

    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "API",
        fn : "getOrganisationUsage",
        params : {
          mode : this.state.stage,
          organisation : this.state.organisationId
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey(),
      },
    }

    Axios(options).then(result => {
      this.setState({users:result.data.users})
    })
    .catch(err => {
     console.error(err)
    })    
  }



  render() {

  const { classes } = this.props

    return (

      <React.Fragment>

        <div className="stageMenu">
          <Button component={NavLink} to={`/api-usage/${this.state.stage}`} activeClassName="active">back to overview</Button>
          <Button component={NavLink} to={`/api-usage/${this.state.stage}/${this.state.licenseeId}/${this.state.licensee}`} activeClassName="active">back to {this.state.licensee}</Button>
          <Button component={NavLink} to={`/api-usage/live/${this.state.licenseeId}/${this.state.licensee}/${this.state.organisationId}/${this.state.organisation}`} activeClassName="active">live</Button>
          <Button component={NavLink} to={`/api-usage/test/${this.state.licenseeId}/${this.state.licensee}/${this.state.organisationId}/${this.state.organisation}`} activeClassName="active">test</Button>
        </div>

        <Typography variant="subtitle1" gutterBottom style={{clear:'both'}}>{this.state.licensee}:{this.state.organisation} API Usage ({this.state.stage.toUpperCase()})</Typography>

        {Object.keys(this.state.users).map(userId => {

          let usage = this.state.users[userId].data

          return (

            <React.Fragment key={userId}>
              <Typography variant="subtitle1" gutterBottom style={{clear:'both'}}>{this.state.users[userId].user}
                <IconButton color="primary" onClick={event => drillDown(event,this.props.history,userId,this.state.users[userId].user)}><ViewIcon /></IconButton>
              </Typography>
    
              <Paper className={classes.paper}>
                <Table className={classes.table}>

                  <TableHeader classes={classes}/>

                  <TableBody>
                    {usage.map((item,index) => {

                      return (
                        <TableDataRow key={index} key2={index} item={item} classes={classes} history={this.props.history} />
                      )
                    })}
                  </TableBody>
                </Table>
          
              </Paper>

              <br/><br/>
            </React.Fragment>
          )
        })}
      </React.Fragment>
    )
  }
}

Organisations.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(Organisations))
