import React from 'react'

export default function DynamicComponent(props) {
  const TagName = props.component

  let filteredProps = {}

  for (let p in props)
  {
    if (p !== "component")
    {
      filteredProps[p] = props[p]
    }
  }

  return <TagName {...filteredProps}/>
}