import Axios from 'axios'
import React, { Component } from 'react'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise/dist/styles/ag-grid.css'
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'

import { MdMergeType } from "react-icons/md" 

import Config from '../config'
import getGridConfig from './list-grid-config'

import ActionCellRenderer from './action-cell-renderer'
import CoachDetails from './details-popup'

import MergeHandler from './merge-handler'

const styles = theme => ({
  paper: {
    width: 'calc(100% - 60)',
    marginTop: '3px',
    overflowX: 'auto',
    position : 'fixed',
    top : 120,
    bottom : 20,
    left : 20,
    right : 20,
  }  
})

var visible = false

const defaultState = {coaches:[],selectedNode:null,selectedCoach:null,selectedImage:null,modalOpen:false,showMergePopup:false}
class CoachesList extends Component {

  constructor(props) {
    super(props)
    this.state = defaultState
  }
  
  async componentDidMount() {
    visible = true
    this.setState(defaultState,() => {
      this.getCoaches()
    })
  }

  componentWillUnmount() {
    visible = false
  }

  async getCoaches() {

    if (!visible)
    {
      return
    }

    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg: "RarsCoaches",
        fn : "getCoaches",
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey(),
      },
    }
    
    let coaches = (await Axios(options)).data.coaches

    // Abort loading if we have changed view
    if (!visible)
    {
      return
    }

    this.setState({coaches})
  }

  onGridReady = params => {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi
    this.gridApi.setSortModel([{colId:"id",sort:"desc"}])
  }

  selectionChanged = () => {
    let selectedNode = this.gridApi.getSelectedNodes()[0]
    let selectedCoach = selectedNode.data
    this.setState({selectedNode,selectedCoach})
  }

  actionClickHandler = (action,node) => {
    switch(action)
    {
      case "link" : return this.openCoachPopup(node)
      default: {}
    }
  }

  openCoachPopup = selectedNode => {
    let selectedCoach = selectedNode.data

    this.setState({selectedNode,selectedCoach},() => {
      this.setState({modalOpen:true})
    })
  }

  // Double click a row
  openCoachDetail = event => {
    this.setState({modalOpen:true})
  }

  saveCoach = coach => {
    let selectedNode = this.state.selectedNode

    if (selectedNode)
    {
      selectedNode.setData(coach)
        
      this.setState({selectedNode})
    }
  }

  closePopups = () => {
    this.setState({modalOpen:false,imageOpen:false,showMergePopup:false})
  }

  merge = () => {
    if (this.state.selectedCoach)
    {
      this.setState({showMergePopup:true})
    }
  }

  render() {

    let GridConfig = getGridConfig(this.actionClickHandler.bind(this))

    return (

      <React.Fragment>

        <Paper className="paper">

          <div className="ag-theme-alpine" style={ {height: 'calc(100% - 80px)', width: '100%'} }>
            <AgGridReact
              onGridReady={this.onGridReady}
              rowBuffer={GridConfig.visibleRowBufferSize*2}
              columnDefs={GridConfig.columnDefs}
              rowData={this.state.coaches}
              animateRows={true}
              rowSelection="single"
              rowDeselection={true}
              suppressCellSelection={true}
              enableCellTextSelection={true}
              defaultColDef={{filterParams:{ newRowsAction:'keep'}}}
              statusBar = {{
                statusPanels : [
                  { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'right' },
                ]
              }}
              sideBar={{
                toolPanels : ['filters'],
                closedByDefault : true,
              }}
              frameworkComponents = {{
                actionCellRenderer : ActionCellRenderer,
              }}
              onRowDoubleClicked={this.openCoachDetail.bind(this)}
              onSelectionChanged={this.selectionChanged.bind(this)}
              >
            </AgGridReact>
          </div>

          <Tooltip title="Merge another coach type into this one (CTRL-M)">
            <IconButton onClick={this.merge} color="primary" size="large" style={{marginTop:'-70px'}}>
              <MdMergeType/>
            </IconButton>
          </Tooltip>

        </Paper>

        <CoachDetails open={this.state.modalOpen} coach={this.state.selectedCoach} close={this.closePopups} saveCoach={this.saveCoach} allCoaches={this.state.coaches} refreshList={this.getCoaches.bind(this)}/>
        <MergeHandler open={this.state.showMergePopup} close={this.closePopups} coach={this.state.selectedCoach} allCoaches={this.state.coaches} refreshList={this.getCoaches.bind(this)}/>

      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(CoachesList))