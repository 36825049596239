import React, { PureComponent } from 'react'
import { withRouter, NavLink, Link } from 'react-router-dom'
 
import Axios from 'axios'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import ViewIcon from '@material-ui/icons/OpenInNew'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import CopyIcon from '@material-ui/icons/Assignment'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Config from '../config'
import Utils from '../utils'
import awsLogo from '../images/aws.svg'

const DataLimit = 15 // How many rows of data to load from DB per request

const styles = theme => ({
  paper: {
  width: 'calc(100%)',
  marginTop: theme.spacing(3),
  overflowX: 'auto',
  },
  table: {
  minWidth: 700,
  width : '100%',
  tableLayout : 'auto'
  },
  tableCell: {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '200px',
  padding : '4px 15px 4px 15px'
  },
  clickableTableRow: {
  cursor : 'pointer'
  },
  button: {
  margin: theme.spacing(),
  },  
  stripeRow: {
  '&:nth-of-type(odd)': {
  backgroundColor: theme.palette.background.default,
  },
  }, 
})

class ErrorDetail extends PureComponent {

    constructor(props, context) {
      super(props, context)
      this.state = {uniqueError:{logGroupName:"",stage:""},uniqueErrorId:"",errors:{data:[]},comments:[],commentDialogueOpen:false,fixDialogueOpen:false}
      this.commentFieldRef = React.createRef()
      this.fixCommentFieldRef = React.createRef()
    }
  
    componentDidMount() {
      let {stage,uniqueErrorId} = this.props.match.params
      this.setState({stage,uniqueErrorId},this.init)
    }

    init() {
      this.getUniqueError()
      this.getComments()
      this.getErrors()
    }
  
    async getUniqueError() {
  
      try
      {
        let options = {
          method : 'POST',
          url : `${Config.getConfig('adminApi')}request`,
          data : {
            pkg : "Errors",
            fn : "getUniqueErrorDetail",
            params : {
              uniqueErrorId : this.state.uniqueErrorId
            }
          },
          headers : {
            'Content-type': 'application/json',
            'x-api-key': Config.getAPIKey(),
          },
        }
  
        let result = await Axios(options)
  
        this.setState({uniqueError:result.data.data[0]})
      }
      catch(err)
      {
        console.error(err)
        throw(err)
      }  
    }
  
    async getComments() {
  
      try
      {
        let options = {
          method : 'POST',
          url : `${Config.getConfig('adminApi')}request`,
          data : {
            pkg : "Errors",
            fn : "getComments",
            params : {
              uniqueErrorId : this.state.uniqueErrorId
            }
          },
          headers : {
            'Content-type': 'application/json',
            'x-api-key': Config.getAPIKey(),
          },
        }
  
        let result = await Axios(options)
  
        this.setState({comments:result.data.data})
      }
      catch(err)
      {
        console.error(err)
        throw(err)
      }  
    }
  
    async getErrors() {
  
      try
      {
        let options = {
          method : 'POST',
          url : `${Config.getConfig('adminApi')}request`,
          data : {
            pkg : "Errors",
            fn : "getErrors",
            params : {
              uniqueErrorId : this.state.uniqueErrorId,
              limit : DataLimit,
              offset : this.state.errors.data.length
            }
          },
          headers : {
            'Content-type': 'application/json',
            'x-api-key': Config.getAPIKey(),
          },
        }
  
        let result = await Axios(options)
        let errors = Object.assign({},this.state.errors)
  
        errors.data = errors.data.concat(result.data.data)
        errors.more = result.data.more
  
        this.setState({errors})   
      }
      catch(err)
      {
        console.error(err)
        throw(err)      
      }
    }
  
    backToList(event) {
      this.props.history.goBack()
      event.stopPropagation()
    }
  
    drillDown(event,errorId) {
      this.props.history.push(`${this.props.history.location.pathname}/${errorId}`)
    }
  
    enterComment() {
      this.setState({commentDialogueOpen:true})
    }
  
    deleteError = async () => {
  
      let options = {
        method : 'POST',
        url : `${Config.getConfig('adminAPi')}request`,
        data : {
          pkg : "Errors",
          fn : "deleteError",
          params : {
            uniqueErrorId : this.state.uniqueErrorId
          }
        },
        headers : {
          'Content-type': 'application/json',
          'x-api-key': Config.getAPIKey(),
        },
      }
  
      await Axios(options)
  
      this.props.history.goBack()
    }
  
    closeCommentDialogue = () => {
      this.setState({commentDialogueOpen:false})
    }
  
    closeFixDialogue = () => {
      this.setState({fixDialogueOpen:false})
    }
  
    saveComment = async () => {
      try
      {
        let comment = this.commentFieldRef.value
        this.setState({commentDialogueOpen:false})
  
        let options = {
          method : 'POST',
          url : `${Config.getConfig('adminApi')}request`,
          data : {
            pkg : "Errors",
            fn : "putComment",
            params : {
              uniqueErrorId : this.state.uniqueErrorId,
              comment:comment
            }
          },
          headers : {
            'Content-type': 'application/json',
            'x-api-key': Config.getAPIKey(),
          },
        }
  
        let result = await Axios(options)
  
        this.setState({comments:result.data.data})
      }
      catch(err)
      {
        console.error(err)
        throw(err)      
      }
    }
  
    async fixError() {
      this.setState({fixDialogueOpen:true})
    }
  
    saveFix = async () => {
      try
      {
        let comment = this.fixCommentFieldRef.value
        this.setState({fixDialogueOpen:false})
  
        let options = {
          method : 'POST',
          url : `${Config.getConfig('adminApi')}request`,
          data : {
            pkg : "Errors",
            fn : "fixError",
            params : {
              uniqueErrorId : this.state.uniqueErrorId,
              comment:comment
            }
          },
          headers : {
            'Content-type': 'application/json',
            'x-api-key': Config.getAPIKey(),
          },
        }
  
        let result = await Axios(options)
  
        this.setState({comments:result.data.data})
  
        let uniqueError = Object.assign({},this.state.uniqueError)
        uniqueError.fixed = new Date()
        this.setState({uniqueError})
      }
      catch(err)
      {
        console.error(err)
        throw(err)      
      }
    }
  
    render() {
  
      const { classes } = this.props
  
      return (
        <React.Fragment>
  
          <div className="stageMenu">
            <Button component={NavLink} to={`../${this.state.uniqueError.stage}`}>Back to {this.state.uniqueError.stage} stage error list</Button>
          </div>
  
          <Typography variant="subtitle1" gutterBottom style={{clear:'both'}}>Error Details</Typography>
  
          <Paper className={classes.paper}>
            <Table className={classes.table}>
  
              <TableBody>
                <TableRow>
                  <TableCell>Error Type</TableCell>
                  <TableCell>{this.state.uniqueError.name}</TableCell>               
                </TableRow>
                <TableRow>
                  <TableCell>Error Message</TableCell>
                  <TableCell>{this.state.uniqueError.message}</TableCell>               
                </TableRow>
                <TableRow>
                  <TableCell>Unique Error Id</TableCell>
                  <TableCell>{this.state.uniqueErrorId}</TableCell>              
                </TableRow>
                <TableRow>
                  <TableCell>Application</TableCell>
                  <TableCell>{this.state.uniqueError.application}</TableCell>              
                </TableRow>
                <TableRow>
                  <TableCell>Stage</TableCell>
                  <TableCell><Button component={Link} to={`../${this.state.uniqueError.stage}`}>{this.state.uniqueError.stage}</Button></TableCell>             
                </TableRow>
                <TableRow>
                  <TableCell>Version</TableCell>
                  <TableCell>{this.state.uniqueError.version}</TableCell>               
                </TableRow>
                <TableRow>
                  <TableCell>Lambda Function</TableCell>
                  <TableCell><Button href={`https://eu-west-1.console.aws.amazon.com/lambda/home?region=eu-west-1#/functions/${this.state.uniqueError.lambdaFunctionName}?tab=graph`} target="_aws"><img src={awsLogo} alt="link to AWS" className={classes.leftIcon}/> {this.state.uniqueError.lambdaFunctionName}</Button> <Button onClick={event => Utils.copyToClipboard(this.state.uniqueError.lambdaFunctionName)}><CopyIcon /></Button></TableCell>               
                </TableRow>
                <TableRow>
                  <TableCell>Occurences</TableCell>
                  <TableCell>{this.state.uniqueError.occurences}</TableCell>               
                </TableRow>
                <TableRow>
                  <TableCell>Log Group Name</TableCell>
                  <TableCell><Button href={`https://eu-west-1.console.aws.amazon.com/cloudwatch/home?region=eu-west-1#logStream:group=${this.state.uniqueError.logGroupName}`} target="_aws"><img src={awsLogo} alt="link to AWS" className={classes.leftIcon}/> {this.state.uniqueError.logGroupName}</Button> <Button onClick={event => Utils.copyToClipboard(this.state.uniqueError.logGroupName)}><CopyIcon /></Button></TableCell>               
                </TableRow>
                <TableRow>
                  <TableCell>First occurence</TableCell>
                  <TableCell>{Utils.formattedDateTime(this.state.uniqueError.earliestTS)}</TableCell>               
                </TableRow>
                <TableRow>
                  <TableCell>Latest occurence</TableCell>
                  <TableCell>{Utils.formattedDateTime(this.state.uniqueError.latestTS)}</TableCell>               
                </TableRow>
                {this.state.uniqueError.expected || 
                  <TableRow>
                    <TableCell>Fixed</TableCell>
                    <TableCell>{this.state.uniqueError.fixed ? Utils.formattedDateTime(this.state.uniqueError.fixed) : <Button color="secondary" onClick={event => this.fixError(event)}>fix</Button>}</TableCell>               
                  </TableRow>
                }
                <TableRow>
                  <TableCell>Delete Error From Logs</TableCell>
                  <TableCell>
                    <Button variant="fab" mini color="primary" aria-label="Delete" className={classes.button} onClick={event => this.deleteError(event)}>
                      <DeleteIcon />
                    </Button>                  
                  </TableCell>               
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
  
          <Typography variant="subtitle1" gutterBottom>Comments
            <Button variant="fab" mini color="primary" aria-label="Add" className={classes.button} onClick={event => this.enterComment(event)}>
              <AddIcon />
            </Button>
          </Typography>
  
          <Paper className={classes.paper}>
            <Table>
  
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableNoWrapCell}>Timestamp</TableCell>
                  <TableCell className={classes.tableCell}>Comments</TableCell>
                </TableRow>
              </TableHead>
  
              <TableBody>
                {this.state.comments.map(comment => {
  
                  return (
  
                    <TableRow key={comment.id}>
                      <TableCell className={classes.tableNoWrapCell}>{Utils.formattedDateTime(comment.ts)}</TableCell>
                      <TableCell className={classes.tableCell}>{comment.comment}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Paper>
  
          <Typography variant="subtitle1" gutterBottom>Error Occurences</Typography>
  
          <Paper className={classes.paper}>
            <Table>
  
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>ID</TableCell>
                  <TableCell className={classes.tableCell}>Timestamp</TableCell>
                  <TableCell className={classes.tableCell}>Licensee</TableCell>
                  <TableCell className={classes.tableCell}>Organisation</TableCell>
                  <TableCell className={classes.tableCell}>User</TableCell>
                  <TableCell className={classes.tableCell}>AWS Request Id</TableCell>
                  <TableCell className={classes.tableCell}>View</TableCell>
                </TableRow>
              </TableHead>
  
              <TableBody>
                {this.state.errors.data.map(error => {
  
                  return (
  
                    <TableRow key={error.id} className={classes.clickableTableRow + ' ' + classes.stripeRow} onDoubleClick={event => this.drillDown(event,error.id)}>
                      <TableCell className={classes.tableNoWrapCell}>{error.id}</TableCell>
                      <TableCell className={classes.tableNoWrapCell}>{Utils.formattedDateTimeWithSeconds(error.ts)}</TableCell>
                      <TableCell className={classes.tableNoWrapCell}>{error.licenseeName}</TableCell>
                      <TableCell className={classes.tableNoWrapCell}>{error.organisation}</TableCell>
                      <TableCell className={classes.tableNoWrapCell}>{error.username}</TableCell>
                      <TableCell className={classes.tableCell}><Button href={`https://eu-west-1.console.aws.amazon.com/cloudwatch/home?region=eu-west-1#logEventViewer:group=${this.state.uniqueError.logGroupName};stream=${error.logStreamName};filter="${error.awsRequestId}"`} target="_aws"><img src={awsLogo} alt="link to AWS" className={classes.leftIcon}/> {error.awsRequestId}</Button> <Button onClick={event => Utils.copyToClipboard(error.awsRequestId)}><CopyIcon /></Button></TableCell>
                      <TableCell className={classes.tableCell}><IconButton color="primary" onClick={event => this.drillDown(event,error.id)}><ViewIcon /></IconButton></TableCell>
                    </TableRow>
  
                  )
                })}
              </TableBody>
            </Table>
  
            {this.state.errors.more && 
              <Button className="autoMargin" onClick={event => this.getErrors()}>load more...</Button>
            }
  
          </Paper>
  
          <Dialog
            open={this.state.commentDialogueOpen}
            onClose={this.closeCommentDialogue}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Add Comment</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Enter your comment below. A timestamp will be added automatically.
              </DialogContentText>
              <TextField
                autoFocus
                id="comment-form"
                label="Comment"
                multiline
                fullWidth
                rowsMax="10"
                inputRef={ref => this.commentFieldRef = ref}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeCommentDialogue}>
                Cancel
              </Button>
              <Button onClick={this.saveComment} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
    
            <Dialog
            open={this.state.fixDialogueOpen}
            onClose={this.closeFixDialogue}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Mark issue as fixed</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please do not mark this issue as fixed until the fix has been deployed.
              </DialogContentText>
              <TextField
                autoFocus
                id="comment-form"
                label="Comment"
                multiline
                fullWidth
                rowsMax="10"
                inputRef={ref => this.fixCommentFieldRef = ref}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeFixDialogue}>
                Cancel
              </Button>
              <Button onClick={this.saveFix} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
  
        </React.Fragment>
      )
    }
  }

ErrorDetail.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(ErrorDetail))