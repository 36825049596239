import React, { Component } from 'react';

import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";
import "./datePicker.css";

export default class DatePickerComponent extends Component {

  constructor(props) {
    super(props)

    this.state = {
      logsDate: this.props.logsDate,
      getDateFn : this.props.getDateFn,
      setDateFn : this.props.setDateFn,
    }

    setInterval(() => {
      let logsDate = this.state.getDateFn()

      if (logsDate !== this.state.logsDate)
      {
        this.setState({logsDate})
      }
    },1000)
  }

  setLogsDate(logsDate) {
    this.state.setDateFn(logsDate)
    this.setState({logsDate})
  }

  render() {
    return (
      <div><DatePicker selected={this.state.logsDate} onChange={this.setLogsDate.bind(this)} maxDate={new Date()} dateFormat="dd MMM yyyy" className="datePicker"/></div>
    )
  }
}
