import React, { Component } from 'react'

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import ViewIcon from '@material-ui/icons/Visibility'
import { ImLink as LinkIcon } from "react-icons/im"

class ActionCellRenderer extends Component {
  constructor(props) {
    super(props)
    this.btnClickedHandler = this.btnClickedHandler.bind(this)
  }

  btnClickedHandler(action,value) {
   this.props.clicked(action,value)
  }

  render() {
    return this.props.value ? (
      
      <React.Fragment>
        {this.props.view &&
          <Tooltip title="View image">
            <IconButton
              onClick={event => this.btnClickedHandler('view',this.props.node.data)}
              color="primary"
              size="large">
              <ViewIcon/>
            </IconButton> 
          </Tooltip>
        }

        {(this.props.link) && 
          <Tooltip title="View Coach data">
            <IconButton
              onClick={event => this.btnClickedHandler('link',this.props.node)}
              color="default"
              size="large">
              <LinkIcon/>
            </IconButton>
          </Tooltip>
        }

      </React.Fragment>
    ) : (<div/>);
  }
}

export default ActionCellRenderer