import _ from 'lodash'
import Moment from 'moment'

const Utils = {

  sleep : function(ms) {
    return new Promise(resolve => setTimeout(resolve, ms)) 
  },

  yesNoFormat : function(value) {
    return value ? 'Y' : 'N'
  },

  yesFormat : function(value) {
    return value ? 'Y' : '\u00A0'
  },

  yesFullFormat : function(value) {
    return value ? 'Yes' : '\u00A0'
  },

  yesNoFullFormat : function(value) {
    return value ? 'Yes' : 'No'
  },

  roundedMiliSeconds : function(time) {
    return _.round(time)
  },

  roundedSeconds : function(time) {
    return time > 100000 ? _.round(time/1000) : _.round(time/100)/10
  },

  twoDp : function(value) {
    return value.toFixed(2)
  },

  money : function(value) {
    return '£' + value.toFixed(2)
  },

  parseDBDateTime :  function(datestimestring) {
    return datestimestring ? Moment(datestimestring) : null
  },

  parseDateTime :  function(datestimestring) {
    return datestimestring ? Moment(datestimestring,'DDMMYYYYHHmm') : null
  },

  month : function(datetime) {
    return datetime ? Moment(datetime).utc().format('MMMM YYYY') : null
  },

  year : function(datetime) {
    return datetime ? Moment(datetime).utc().format('YYYY') : null
  },

  formattedDate : function(datetime) {
    return datetime ? Moment(datetime).utc().format('DD-MM-YYYY') : null
  },

  formattedDateTime : function(datetime) {
    return datetime ? Moment(datetime).utc().format('DD-MM-YYYY HH:mm') : null
  },

  formattedTime : function(datetime) {
    return datetime ? Moment(datetime).utc().format('HH:mm:ss') : null
  },

  formattedDateTimeWithSeconds : function(datetime) {
    return datetime ? Moment(datetime).utc().format('DD-MM-YYYY HH:mm:ss') : null
  },

  formattedDateTimeWithDecimalSeconds : function(datetime) {
    return datetime ? Moment(datetime).utc().format('DD-MM-YYYY HH:mm:ss.SS') : null
  },

  dayOfWeek : function(datetime) {
    return datetime ? Moment(datetime).utc().format('dddd') : null
  },

  formattedMinDateAndTime : function(datetime) {
    return datetime ? Moment(datetime).utc().format('DD-MM HH:mm:ss') : null
  },

  numberToFixedLengthString : function(number,length) {
    return number.toString().padStart(length," ").replace(/ /g,"\u00a0")
  },

  firstListItemPlusCount : function(obj) {
    if (obj)
    {
      let arr = obj.split(",")
    let val = arr[0]

    if (arr.length > 1)
    {
      val += ", +" + (arr.length-1).toString()
    }

    return val
  }
  },

  formatLogMessage : function(message)
  {
    let messageSplit = _.split(message,String.fromCharCode(9))

    switch(messageSplit.length)
    {
      case 2:
      {
        return message
      }

      case 3:
      {
        try
        {
          return JSON.stringify(JSON.parse(messageSplit[2]),null,2).replace(/\\n/g,String.fromCharCode(10))
        }
        catch(err)
        {
          return messageSplit[2]
        }
      }

      default:
      {
        return message.replace(/\t/g,'\n')
      }
    }
  },


}

export default Utils