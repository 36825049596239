import React from 'react'
import Button from '@material-ui/core/Button'

import Utils from '../utils'

import { toggleUserEnabled , deleteUser, deleteAPIKey, toggleDevMode } from '../data-providers/users'

const deleteUserAndKeys = async node => {

  if (node.data.apikeys)
  {
    for (let key of node.data.apikeys)
    {
      deleteAPIKey(key.keyId)
    }
  }
  
  await deleteUser(node)

  node.node.refreshGrid(node.data.organisationId,node.data.userId)
}

const addKey = node => {
  node.node.addApiKey(node)
}

const deleteKey = async node => {
  await deleteAPIKey(node.value)

  node.node.refreshGrid(node.data.organisationId,node.data.userId)
}

const toggleDev = async node => {
  await toggleDevMode(node.data.keyId,!node.data.plans.includes("DEV"))
  node.node.refreshGrid(node.data.organisationId,node.data.userId)
}

const editUser = node => {
  node.node.editUser(node)
}

const enableUserButtonRenderer = node => {
  return <Button variant="contained" color={node.value === 1 ? "secondary" : "primary"} onClick={event => toggleUserEnabled(node)} disabled={[0,1,5].includes(node.data.userId)}>{node.value === 1 ? "Disable" : "Enable"}</Button>
}

// Neded to delete all keys in AWS and delete DB records
const deleteUserButtonRenderer = node => {
  return <Button variant="contained" color="secondary" onClick={event => deleteUserAndKeys(node)} disabled={[0,1,5].includes(node.data.userId)}>Delete User</Button>
}

const addKeyButtonRenderer = node => {
  return node.data.apikeys.length < 2 ? <Button variant="contained" color="primary" onClick={event => addKey(node)} disabled={[0,1,5].includes(node.data.userId)}>Add API key</Button> : ""
}

const deleteKeyButtonRenderer = node => {
  return <Button variant="contained" color="secondary" disabled={[0,1,5].includes(node.data.userId)} onClick={event => deleteKey(node)}>Delete Key</Button>
}

const toggleDevPlanButtonRenderer = node => {
  return node.data.mode === "test" ? <Button variant="contained" color="primary" onClick={event => toggleDev(node)} disabled={[0,1,5].includes(node.data.userId)}>Toggle DEV</Button> : ""
}

const editUserButtonRenderer = node => {
  return <Button variant="contained" color="primary" onClick={event => editUser(node)}>Edit User</Button>
}


const Config = {
   
  visibleRowBufferSize : 10,

  frameworkComponents : {
    enableUserButtonRenderer: enableUserButtonRenderer,
    deleteUserButtonRenderer: deleteUserButtonRenderer,
    addKeyButtonRenderer: addKeyButtonRenderer,
    editUserButtonRenderer: editUserButtonRenderer,
  },

  columnDefs : [
    {
      field: "organisation",
      headerName: "Org",
      menuTabs : [],
      headerClass : "dateHeader",
      rowGroup: true,
      hide : true,
    },
    {
      field: "username",
      menuTabs : [],
      headerClass : "dateHeader",
      width : 300,
      cellRenderer: 'agGroupCellRenderer',
      filter:'agTextColumnFilter',
      filterParams:{
        filterOptions : ['contains'],
      },
    },
    {
      field: "userId",
      headerName: "ID",
      menuTabs : [],
      headerClass : "dateHeader",
      width : 70,
    },
    {
      field: "enabled",
      headerName: "Enabled",
      menuTabs : [],
      headerClass : "dateHeader",
      filter : true,
      valueFormatter : obj => Utils.yesFormat(obj.value),
      width : 100,
    },
    {
      field: "licenseeAdmin",
      headerName: "Lic Admin",
      menuTabs : [],
      headerClass : "dateHeader",
      valueFormatter : obj => Utils.yesFormat(obj.value),
      width : 100,
      filter:true,
    },
    {
      field: "organisationAdmin",
      headerName: "Org Admin",
      menuTabs : [],
      headerClass : "dateHeader",
      valueFormatter : obj => Utils.yesFormat(obj.value),
      width : 110,
      filter:true,
    },
    {
      field: "refunds",
      headerName: "Refunds",
      menuTabs : [],
      headerClass : "dateHeader",
      valueFormatter : obj => Utils.yesFormat(obj.value),
      width : 100,
      filter:true,
    },
    {
      field: "authModes",
      headerName: "Auth",
      menuTabs : [],
      headerClass : "dateHeader",
      filter:true,
      valueFormatter : obj => obj.value.replace(/[^a-zA-Z0-9]/g,""),
      width : 150,
    },
    {
      headerName: 'Keys',
      field: 'apikeys',
      width : 110,
      valueFormatter : obj => obj.value.length,
    },
    {
      headerName: '',
      field: 'enabled',
      cellRenderer: 'enableUserButtonRenderer',
      width : 110,
    },
    {
      headerName: '',
      field: 'userId',
      cellRenderer: 'deleteUserButtonRenderer',
      width : 140,
    },
    {
      headerName: '',
      field: 'username',
      cellRenderer: 'addKeyButtonRenderer',
      width : 150,
    },
    {
      headerName: '',
      field: 'username',
      cellRenderer: 'editUserButtonRenderer',
      width : 150,
    },
  ],

  detailCellRendererParams: {

    // provide the Grid Options to use on the Detail Grid
    detailGridOptions: {
      frameworkComponents: {
        deleteKeyButtonRenderer: deleteKeyButtonRenderer,
        toggleDevPlanButtonRenderer: toggleDevPlanButtonRenderer,
      },

        columnDefs: [
          {
            field: "keyId",
            headerName: "Key Id",
            menuTabs : [],
            headerClass : "dateHeader",
          },
          {
            field: "key",
            headerName: "Key",
            menuTabs : [],
            headerClass : "dateHeader",
            width : 400,
          },
          {
            field: "mode",
            headerName: "Mode",
            menuTabs : [],
            headerClass : "dateHeader",
          },
          {
            field: "plans",
            headerName: "Usage Plans",
            menuTabs : [],
            headerClass : "dateHeader",
            width : 400,
          },
          {
            headerName: '',
            field: 'keyId',
            cellRenderer: 'deleteKeyButtonRenderer',
            width : 130,
          },
          {
            headerName: '',
            field: 'keyId',
            cellRenderer: 'toggleDevPlanButtonRenderer',
            width : 130,
          },
          {
            field: "organisationId",
            hide : true,
          },
          {
            field: 'userId',
            hide : true,
          },
        ],
    },

    // get the rows for each Detail Grid
    getDetailRowData: function(params) {
        params.successCallback(params.data.apikeys);
    }
  }
}

export default Config