import React, { PureComponent } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Table, TableBody, TableCell, TableRow, AppBar, Tabs, Tab } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Axios from 'axios'

import Config from '../config'
import Utils from '../utils'

import AWS from 'aws-sdk'
import AdmZip from 'adm-zip'
import prettifyXml from 'prettify-xml'

import Moment from 'moment-timezone'

const styles = theme => ({
  paper: {
    width: 'calc(100% - 20px)',
    marginTop: theme.spacing() * 3,
    overflowX: 'auto',
    padding : '10px'
  },
  table: {
    minWidth: 700,
    width : '100%',
    tableLayout : 'auto'
  },
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    padding : '4px 15px 4px 15px'
  },
  tableCellHeader: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    padding : '4px 15px 4px 15px'
  },
  tableCellData: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding : '4px 15px 4px 15px',
    fontWeight : 'bold'
  }, 
  tableCellDataWrap: {
    overflow: 'wrap',
    padding : '4px 15px 4px 15px',
    fontWeight : 'bold'
  }, 
  noBulletList: {
    listStyle: 'none'
  }
})


function TabPanel(props) {
  const { children, value, index, data, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {formatTabData(data.title,data.data)}
    </div>
  )
}

function formatTabData(title,data) {

  switch(title)
  {
    case "lsm":
    case "nrs":
    {
      try
      {
        return <ul>{data.map((l,i) =>
          <li key={i}>
            <h2>Request {i+1}</h2>
            <pre>{prettifyXml(l.request,{useSelfClosingElement:true})}</pre>
            <b>Response</b>
            <pre>{prettifyXml(l.response,{useSelfClosingElement:true})}</pre>
          </li>
        )}</ul>
      }
      catch(err)
      {
        console.log("ERROR",{err})

        return <ul>{data.map((l,i) =>
          <li key={i}>
            <h2>Request {i+1}</h2>
            <pre>{(l.request.replace(/<[^>]*>/,''))}</pre>
            <b>Response</b>
            <pre>{(l.response.replace(/<[^>]*>/,''))}</pre>
          </li>
        )}</ul>
      }
    }
    case "rars":
    {
      return <ul>{data.map((l,i) =>
        <li key={i}>
          <h2>Request {i+1}</h2>
          <pre>{JSON.stringify(l.request.data ? l.request.data : l.request,null,2)}</pre>
          <b>Response</b>
          <pre>{JSON.stringify(l.response.data ? l.response.data : l.response,null,2)}</pre>
        </li>
      )}</ul>
    }
    case "cloudwatch":
    { 
      return <ul>{data.map((l,i) => {

        if (!l.message)
        {
          return <li>{l}</li>
        }

        let log = l.message.split("\t")
        let isTs = /[0-9{4}]-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{3}Z/.test(log[0])
        let ts = Moment(log[0].substr(0,23).replace(/T/," "),"YYYY-MM-DD HH:mm:ss.SSS").format("HH:mm:ss.SSS")

        return (
          <li key={i}>
            <strong>{isTs ? ts : log[0]}</strong>
            <pre>{log[3]}</pre>
          </li>
        )
      })}</ul>
    }
    default: return <pre>{JSON.stringify(data,null,2)}</pre>
  }
}


class LogPopup extends PureComponent {

  constructor(props, context) {
    super(props, context)
    this.state = {extraLogData:{},activeTab:0,s3LogData:[],cloudwatch:{title:"cloudwatch",data:["Click above to load cloudwatch logs"]}}
  }

  async componentDidMount() {
    let {selectedLog} = this.props
    this.getExtraLogDetail(selectedLog.id)
    this.getS3Log(selectedLog.awsRequestId)
  }

  componentDidUpdate(prevProps) {
    let {selectedLog} = this.props

    if(selectedLog && prevProps.selectedLog !== selectedLog)
    {
      this.setState({activeTab:0,s3LogData:[],extraLogData:{},cloudwatch:{title:"cloudwatch",data:["Click above to load cloudwatch logs"]}})
      this.getExtraLogDetail(selectedLog.id)
      this.getS3Log(selectedLog.awsRequestId)
    }
  }

  getExtraLogDetail = async id => {
    try
    {
      let options = {
        method : 'POST',
        url : `${Config.getConfig('adminApi')}request`,
        data : {
          pkg: "Logs",
          fn : "getFullDataForLogRow",
          params : {
            mode : this.props.stage,
            id,
          }
        },
        headers : {
          'Content-type': 'application/json',
          'x-api-key': Config.getAPIKey(),
        },
      }

      let extraLogData = (await Axios(options)).data

      this.setState({extraLogData})
    }
    catch(err)
    {
      console.log("Axios error",err)
    }
  }

  getS3Log = async awsRequestId => {
    try
    {
      let awsConfig = Config.getConfig("awsConfig")
      if (!awsConfig) { return }
      const S3 = new AWS.S3(awsConfig)

      let logzip = await S3.getObject({ Bucket: `com.hazardousfrog.logs.${this.props.stage}`, Key: awsRequestId + '.zip' }).promise()

      let dataJSON = await this.getLogsZipContent(logzip.Body)

      let data = JSON.parse(dataJSON)
        
      let s3LogData = []

      for (let item in data)
      {
        if (item !== 'bookingRef')
        {
          try
          {
            s3LogData.push({title:item,data:JSON.parse(data[item])})
          }
          catch(err)
          {
            s3LogData.push({title:item,data:data[item]})
          }
        }
      }

      console.log({s3LogData})

      this.setState({s3LogData})

    }
    catch(err)
    {
      // ignore. No logs in S3
    }
  }
  
  getLogsZipContent = async zipdata => {

    try
    {
      let zip = new AdmZip(zipdata)
      var zipEntries = zip.getEntries()
      
      return zipEntries[0].getData().toString('utf8')
    }
    catch(err)
    {
      // ZIP does not throw proper errors..
  
      let error = {
        name : "zip",
        message : err
      }
      
      throw error
    }
  }

  formatTicketRefs = (refs) => {
    if (refs)
    {
      return refs.split(',').map(r => <li key={r}>{r}</li>)
    }
  }

  handleTabChange = (event, activeTab) => {
    this.setState({activeTab})
  }

  getCloudwatchLogs = async () => {
    const { selectedLog } = this.props

    let awsConfig = Config.getConfig("awsConfig")
    if (!awsConfig) { return }

    const CW = new AWS.CloudWatchLogs(awsConfig)

    // Get cloudwatch, format data and add to state.

    let data = {
      logGroupName: `/aws/lambda/${selectedLog.api}-${selectedLog.stage}-${selectedLog.functionName}`,
      startTime: Moment(selectedLog.tsStart.substr(0,19).replace(/T/," "),"YYYY-MM-DD HH:mm:ss").startOf('minute').valueOf(),
      endTime: Moment(selectedLog.tsEnd.substr(0,19).replace(/T/," "),"YYYY-MM-DD HH:mm:ss").endOf('minute').valueOf(),
      filterPattern: `"${selectedLog.awsRequestId}"`,
      interleaved: false,
      logStreamNames: [selectedLog.logStreamName],
    }

    this.setState({cloudwatch:{title:"cloudwatch",data:["Loading cloudwatch logs..."]}})

    try
    {
      let logs = await CW.filterLogEvents(data).promise()

      if (logs.events.length)
      {
        this.setState({cloudwatch:{title:"cloudwatch",data:logs.events}})
      }
      else
      {
        this.setState({cloudwatch:{title:"cloudwatch",data:["No cloudwatch logs found"]}})
      }
    }
    catch(err)
    {
      this.setState({cloudwatch:{title:"cloudwatch",data:["No cloudwatch logs found"]}})
    }
  }

  render()
  {
    const { classes, selectedLog } = this.props
    const extraLogData = this.state.extraLogData

    return (
      <Dialog
        open={this.props.modalOpen}
        onClose={this.props.handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        maxWidth="lg"
      >

        <DialogTitle id="scroll-dialog-title">{selectedLog.id}</DialogTitle>
        
        <DialogContent dividers={true}>
            
        <Table className={classes.table}>
            <TableBody>

              <TableRow>
                <TableCell className={classes.tableCellHeader}>Date</TableCell>
                <TableCell className={classes.tableCellData}>{Utils.formattedDate(selectedLog.tsStart)}</TableCell>
                <TableCell className={classes.tableCellHeader}>Started</TableCell>
                <TableCell className={classes.tableCellData}>{Utils.formattedTime(selectedLog.tsStart)}</TableCell>
                <TableCell className={classes.tableCellHeader}>Ended</TableCell>
                <TableCell className={classes.tableCellData}>{Utils.formattedTime(extraLogData.tsEnd)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHeader}>Duration</TableCell>
                <TableCell className={classes.tableCellData}>{selectedLog.responseTime} ms</TableCell>
                <TableCell className={classes.tableCellHeader}>Status</TableCell>
                <TableCell className={classes.tableCellData}>{selectedLog.response}</TableCell>
                <TableCell className={classes.tableCellHeader}>Cold Start</TableCell>
                <TableCell className={classes.tableCellData}>{Utils.yesNoFullFormat(selectedLog.coldstart)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHeader}>Licensee</TableCell>
                <TableCell className={classes.tableCellData}>{selectedLog.licensee}</TableCell>
                <TableCell className={classes.tableCellHeader}>Organisation</TableCell>
                <TableCell className={classes.tableCellData}>{selectedLog.organisation}</TableCell>
                <TableCell className={classes.tableCellHeader}>User</TableCell>
                <TableCell className={classes.tableCellData}>{selectedLog.user}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHeader}>API</TableCell>
                <TableCell className={classes.tableCellData}>{selectedLog.api}</TableCell>
                <TableCell className={classes.tableCellHeader}>Function</TableCell>
                <TableCell className={classes.tableCellData}>{selectedLog.functionName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHeader}>Stage</TableCell>
                <TableCell className={classes.tableCellData}>{selectedLog.stage}</TableCell>  
                <TableCell className={classes.tableCellHeader}>Version</TableCell>
                <TableCell className={classes.tableCellData}>{selectedLog.version}</TableCell>
                <TableCell className={classes.tableCellHeader}>Memory</TableCell>
                <TableCell className={classes.tableCellData}>{extraLogData.memoryAllocated} Mb</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHeader}>AWS Request ID</TableCell>
                <TableCell className={classes.tableCellData}>{selectedLog.awsRequestId}</TableCell>
                <TableCell className={classes.tableCellHeader}>Gateway ID</TableCell>
                <TableCell className={classes.tableCellData}>{extraLogData.gatewayRequestId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHeader}>Log Stream</TableCell>
                <TableCell className={classes.tableCellData} colSpan="5">{extraLogData.logStreamName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHeader}>Machine ID</TableCell>
                <TableCell className={classes.tableCellData}>{selectedLog.machineId}</TableCell>
                <TableCell className={classes.tableCellHeader}>Cart Slots</TableCell>
                <TableCell className={classes.tableCellData}>{selectedLog.cartSlots}</TableCell>
                <TableCell className={classes.tableCellHeader}>Ticket Type</TableCell>
                <TableCell className={classes.tableCellDataWrap}>{selectedLog.ticketType}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHeader}>Booking Reference</TableCell>
                <TableCell className={classes.tableCellData}>{selectedLog.bookingRef}</TableCell>
                <TableCell className={classes.tableCellHeader}>Tickets</TableCell>
                <TableCell className={classes.tableCellData}><ul className={classes.noBulletList}>{this.formatTicketRefs(selectedLog.ticketRefs)}</ul></TableCell>
                <TableCell className={classes.tableCellHeader}>Reservations</TableCell>
                <TableCell className={classes.tableCellData} colSpan="3"><ul className={classes.noBulletList}>{this.formatTicketRefs(selectedLog.reservationRefs)}</ul></TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <AppBar position="static">
            <Tabs value={this.state.activeTab} onChange={this.handleTabChange}>
              {this.state.s3LogData.map((d,i) => <Tab label={d.title} key={i}/>)}
              <Tab label="cloudwatch" onClick={this.getCloudwatchLogs}/>
            </Tabs>
          </AppBar>
          {this.state.s3LogData.map((d,i) => <TabPanel value={this.state.activeTab} index={i} key={i} data={d}/>)}
          <TabPanel value={this.state.activeTab} index={this.state.s3LogData ? this.state.s3LogData.length : 0} key={this.state.s3LogData ? this.state.s3LogData.length : 0} data={this.state.cloudwatch}/>
 
        </DialogContent>

        <DialogActions>
          <Button onClick={this.props.handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}


LogPopup.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LogPopup)
