import React, { Component } from 'react'

import Config from '../config'
import AWS from 'aws-sdk'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Chart from './chart'
import ChartAll from './chartAll'

import Utils from '../utils'

import Moment from 'moment'
import _ from 'lodash'

import './index.css'

var CW 

const deployBucketRegExp = RegExp('serverlessdeployment')

const day = 60*60*24
const week = day * 7
const month = day * 30
const periods = {day:day,week:week,month:month}
const styles = theme => ({
  Paper: {
    marginBottom: theme.spacing.unit * 5,
    overflowX: 'auto',
    padding : '10px',
    margin : 'auto',
    width : 1000,
  },
})

class S3Overview extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {buckets:[],totals:[{}]}
    CW = new AWS.CloudWatch(Config.getConfig('aws'))
  }

  componentDidMount() {
    this.init()
  }

  init() {
    this.getBucketList()
  }

  async getBucketList()
  {
    let params = {
      Namespace : "AWS/S3",
    }

    let data = (await CW.listMetrics(params).promise()).Metrics

    let metricsList = []
    let namedBuckets = []

    for (let d=0;d<data.length;d++)
    {
      let item = data[d]
      let isLambdaBucket = deployBucketRegExp.test(item.Dimensions[1].Value)
    
      if (!isLambdaBucket)
      {
        metricsList.push({MetricName:item.MetricName, BucketName:this.getBucketName(item.Dimensions), Dimensions:item.Dimensions})
        namedBuckets.push(item.Dimensions[1].Value)
      }     
    }

    this.getMetrics(metricsList,namedBuckets)
  }

  getBucketName(dimensions)
  {
    let BN

    _.forEach(dimensions,dim => {

      if (dim.Name === 'BucketName')
      {
        BN = dim.Value
      }
    })

    return BN
  }

  getMetricDataQuery (namespace,MetricName,index,Period,Stat, Dimensions) {

    return {
      Id : "a_" + index,
      MetricStat : {
        Metric : {
          MetricName : MetricName,
          Namespace : namespace,
          Dimensions : Dimensions,
        },
        Period : Period,
        Stat : Stat,
      },
      ReturnData : true
    }
  }

  async getMetrics(metricsList,namedBuckets)
  {
    let endTime = Moment().endOf("day")
    let startTime = Moment().endOf("day")
    startTime.subtract(periods.month,"s")

    let params = {
      StartTime : startTime.toISOString(),
      EndTime : endTime.toISOString(),
      MetricDataQueries : []
    }

    // Add query for every S3 bucket / metric combination
    _.forEach(metricsList,(metric,index) => {
      params.MetricDataQueries.push(this.getMetricDataQuery("AWS/S3",metric.MetricName,index,periods.day,"Maximum",metric.Dimensions))
    })

    let metricResults = []

    // We may get partical data of we ask for too many metrics, so, if we have NextToken, ask for next batch and combine results 
    do {
      let MR = await CW.getMetricData(params).promise()

      // Remove partial results.
      let MRComplete = _.remove(MR.MetricDataResults,function(item) {
        return item.StatusCode === "Complete" // Ones to keep! 
      })

      // Merge these results with previous ones
      metricResults = _.concat(metricResults,MRComplete)

      // Add NextToken to params (can be undefined)
      params.NextToken = MR.NextToken
    }
    while (params.NextToken) // Bomb out when we no longer have a NextToken


    // Make an array of dates from today back 30 days
    let today = Moment().endOf("day")
    let dates = {}

    for (let d=0;d<30;d++) // Excludes TODAY as we do not get metrics for today...
    {
      today.subtract(periods.day,"s")
      dates[today.format('YYYY-MM-DD')] = {key: today.format('YYYY-MM-DD'), date : today.format('Do MMM'),NumberOfObjects:0,BucketSizeBytes:0}
    }

    let bucketsObj = {} // Need to use cloneDeep rather than Object.assign or {...dates} as these both do shallow clone...

    _.forEach(namedBuckets,name => {
      bucketsObj[name] = _.cloneDeep(dates)
    })

    _.forEach(metricResults,metric => {
      
      let index = metric.Id.split('_')[1]

      let bn = metricsList[index].BucketName
      let metricName = metricsList[index].MetricName
      let bucketName = (_.indexOf(namedBuckets,bn) !== -1) ? bn : 'lambda'

      this.addMetricsToResults(bucketsObj,metric,bucketName,metricName)
    })

    // At this point, bucketsObj is an object with dates as objects.
    // We want dates to be an array... in reverse order

    let buckets = {}

    _.forEach(bucketsObj,(bucket,key) => {
      buckets[key] = {name:key,stats:_.reverse(_.values(bucket))}
    })
    

    let totalsObj = _.cloneDeep(dates)

    for (let b in buckets)
    {
      buckets[b].stats.forEach(stat => {
        totalsObj[stat.key][b] = Utils.twoDp(stat.BucketSizeBytes / 1024)
        delete totalsObj[stat.key].BucketSizeBytes
        delete totalsObj[stat.key].NumberOfObjects
      })
    }

    let totals = _.reverse(_.values(totalsObj))

    // Save to state
    this.setState({buckets})
    this.setState({totals})
  }

  addMetricsToResults = (bucketsObj,metric,bucketName,metricName) => {

    _.forEach(metric.Timestamps,(ts,index) => {

      let key = Moment(ts).format('YYYY-MM-DD')
      let rawValue = metric.Values[index] 

      let value = metricName === "NumberOfObjects" ? rawValue : rawValue / (1024*1024) // Bytes to MegaBytes

      bucketsObj[bucketName][key][metricName] += value
    })

    _.forEach(metric.Timestamps,(ts,index) => {
      let key = Moment(ts).format('YYYY-MM-DD')

      bucketsObj[bucketName][key][metricName] = Utils.twoDp(bucketsObj[bucketName][key][metricName])
    })
  
  }


  render() {

    const { classes } = this.props

    return (

      <React.Fragment>

        <Paper className={classes.Paper}>
          <ChartAll data={this.state.totals} />
        </Paper>

        {Object.keys(this.state.buckets).map(bucketName => {

          let bucket = this.state.buckets[bucketName]

          return (
            <div key={bucketName}>
              <Typography variant="subtitle1" gutterBottom>{bucketName}</Typography>
              <Paper className={classes.Paper}>
                <Chart data={bucket.stats} />
              </Paper>
            </div>
          )

        })}

      </React.Fragment>
    )
  }
}

S3Overview.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(S3Overview)
