import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import { Dialog, DialogActions, DialogContent, Button, FormGroup, FormControlLabel, Switch } from '@material-ui/core'

import { makeAPIKey , addKeyToUser } from '../data-providers/users'

const styles = theme => ({
  formControl: {
    margin: 0,
    minWidth: 120,
    maxWidth: 300,
  },
  formGroup: {
    paddingTop: '20px',
  },
  button: {
    margin: theme.spacing(),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select : {
    minWidth : 300
  },
})


class NewApiKeyForm extends Component {

  constructor(props, context) {
    super(props, context)  
    this.state = {testKey:false,liveKey:false,devMode:false}
  }

  componentDidMount() {
    this.setState({testKey:false,liveKey:false,devMode:false})
  }

  componentDidUpdate(prevProps) {
    setTimeout(this.init,100)
  }
  
  handleCancel = () => {
    this.props.close()
  }

  handleSubmit = async () => {
    try
    {
      if (this.state.testKey)
      {
        let testKey = await makeAPIKey(this.props.node.username,this.props.node.organisation,"test",this.state.devMode)

        let keyData = {
          key : testKey.value,
          type : 'test',
          keyId : testKey.id,
          userId : this.props.node.userId
        }

        await addKeyToUser(keyData)
      }

      if (this.state.liveKey)
      {
        let liveKey = await makeAPIKey(this.props.node.username,this.props.node.organisation,"live")

        let keyData = {
          key : liveKey.value,
          type : 'live',
          keyId : liveKey.id,
          userId : this.props.node.userId
        }

        await addKeyToUser(keyData)
      }

      this.props.refreshGrid(this.props.node.organisationId,this.props.node.userId)

      this.props.close()
    }
    catch(err)
    {
      console.log(err)
      throw err
    }
  }

  handleChange = event => {
    let value = event.target.value ? event.target.value : event.target.checked ? true : false 
    this.setState({[event.target.name]:value})
  }

  hasKey = (mode,node) => {
    return node.keys ? node.keys.filter(n => n.mode === mode).length > 0 : false
  }

  render() {

    const { classes, open  } = this.props

    return (

        <Dialog open={open} fullWidth={true} maxWidth="md">
          <DialogContent>

            {this.props.node && 
              <div>{this.props.node.username}</div>
            }

            <FormGroup row className={classes.formGroup}>
              <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.testKey}
                      onChange={this.handleChange}
                      name="testKey"
                      color="primary"
                      disabled={this.hasKey("test",this.props.node)}
                    />
                  }
                  label="Test key required"
                />


                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.devMode}
                      onChange={this.handleChange}
                      name="devMode"
                      color="primary"
                      disabled={this.state.testKey === 0 || !this.hasKey("test",this.props.node)}
                    />
                  }
                  label="Enable DEV mode"
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.liveKey}
                      onChange={this.handleChange}
                      name="liveKey"
                      color="primary"
                      disabled={this.hasKey("live",this.props.node)}
                    />
                  }
                  label="Live key required"
                />

            </FormGroup>

          </DialogContent>

          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">Cancel</Button>
            <Button onClick={this.handleSubmit} color="primary">Add Key</Button>
          </DialogActions>
        </Dialog>

    )
  }
}

export default withStyles(styles)(NewApiKeyForm)