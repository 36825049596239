import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import cookie from 'react-cookies'

import Config from './config'

class Logout extends Component {

  render() {
    cookie.remove('config',{expires:0, path: '/'})
    Config.deleteConfig("awsConfig")
    Config.deleteConfig('keys')
    Config.deleteConfig('userData')
    Config.deleteConfig('authModes')
    Config.deleteConfig('adminModes')
    Config.deleteConfig('routes')

    return (<Redirect to="/login"/>)
  }
}

export default Logout