import React, { PureComponent } from 'react'
import { withRouter, NavLink } from 'react-router-dom'
 
import Axios from 'axios'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import ViewIcon from '@material-ui/icons/OpenInNew'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Config from '../config'
import Utils from '../utils'

const DataLimit = 15 // How many rows of data to load from DB per request

const styles = theme => ({
  paper: {
  width: 'calc(100%)',
  marginTop: theme.spacing(3),
  overflowX: 'auto',
  },
  table: {
  minWidth: 700,
  width : '100%',
  tableLayout : 'auto'
  },
  tableCell: {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '200px',
  padding : '4px 15px 4px 15px'
  },
  clickableTableRow: {
  cursor : 'pointer'
  },
  button: {
  margin: theme.spacing(),
  },  
  stripeRow: {
  '&:nth-of-type(odd)': {
  backgroundColor: theme.palette.background.default,
  },
  }, 
})

class Errors extends PureComponent {

  constructor(props, context) {
    super(props, context)
    this.state = {stage:"",uniqueErrors:{data:[]},fixDialogueOpen:false,uniqueErrorId:0,fixIndex:0}
  }

  async componentDidMount() {
    let {stage} = this.props.match.params
    this.setState({stage},this.getUniqueErrors)
  }

  componentDidUpdate(prevProps) {
    let {stage} = this.props.match.params

    if(prevProps.match.params.stage !== stage)
    {
      this.setState({uniqueErrors:{data:[]}},() => {
        this.setState({stage},this.getUniqueErrors)
      })
    }
  }

  async getUniqueErrors() {
  
    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "Errors",
        fn : "getUniqueErrors",
        params : {
          stage : this.state.stage,
          limit : DataLimit,
          offset: this.state.uniqueErrors.data.length
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey(),
      },
    }

    Axios(options).then(result => {

      let uniqueErrors = Object.assign({},this.state.uniqueErrors)

      let newData = uniqueErrors.data.concat(result.data.data)
      uniqueErrors.data = newData.filter(function(item, pos) {return newData.indexOf(item) === pos})
      uniqueErrors.more = result.data.more

      this.setState({uniqueErrors})
    })
    .catch(err => {
     console.error(err)
    })    
  }

  drillDown(event,uniqueErrorId)
  {
    this.props.history.push(`${this.props.history.location.pathname}/${uniqueErrorId}`)
  }

  markFixed(event,uniqueErrorId,fixIndex)
  {
    this.setState({uniqueErrorId})
    this.setState({fixIndex})
    this.setState({fixDialogueOpen:true})
  }

  closeFixDialogue = () => {
    this.setState({fixDialogueOpen:false})
  }

  saveFix = async () => {
    try
    {
      let comment = this.fixCommentFieldRef.value
      this.setState({fixDialogueOpen:false})

      let options = {
        method : 'POST',
        url : `${Config.getConfig('adminApi')}request`,
        data : {
          pkg : "Errors",
          fn : "fixError",
          params : {
            uniqueErrorId:this.state.uniqueErrorId,
            comment:comment
          }
        },
        headers : {
          'Content-type': 'application/json',
          'x-api-key': Config.getAPIKey(),
        },
      }

      await Axios(options)

      // Now need to overwrite the fixed value in 

      let uniqueErrors = {...this.state.uniqueErrors}
      uniqueErrors.data[this.state.fixIndex].fixed = new Date()

      this.setState({uniqueErrors})
    }
    catch(err)
    {
      console.error(err)
      throw(err)      
    }
  }
  
render() {

  const { classes } = this.props

    return (

      <React.Fragment>

        <div className="stageMenu">
          <Button component={NavLink} to="/errors/v3" activeClassName="active">V3</Button>
          <Button component={NavLink} to="/errors/v4" activeClassName="active">V4</Button>
          <Button component={NavLink} to="/errors/prod" activeClassName="active">Services</Button>
        </div>

        <Typography variant="subtitle1" gutterBottom style={{clear:'both'}}>{this.state.stage.toUpperCase()} Errors</Typography>

        <Paper className={classes.paper}>
          <Table className={classes.table}>

            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>ID</TableCell>
                <TableCell className={classes.tableCell}>Stage</TableCell>
                <TableCell className={classes.tableCell}>Application</TableCell>
                <TableCell className={classes.tableCell}>Version</TableCell>
                <TableCell className={classes.tableCell}>Function</TableCell>
                <TableCell className={classes.tableCell}>Error</TableCell>
                <TableCell className={classes.tableCell}>Message</TableCell>
                <TableCell className={classes.tableCell}>Num</TableCell>
                <TableCell className={classes.tableCell}>Latest</TableCell>
                <TableCell className={classes.tableCell}>Fixed</TableCell>
                <TableCell className={classes.tableCell}>View</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.state.uniqueErrors.data.map((error,index) => {

                return (

                  <TableRow key={error.id} className={classes.clickableTableRow + ' ' + classes.stripeRow} onDoubleClick={event => this.drillDown(event,error.id)}>
                    <TableCell numeric="true" className={classes.tableCell}>{error.id}</TableCell>
                    <TableCell className={classes.tableCell}>{error.stage}</TableCell>
                    <TableCell className={classes.tableCell}>{error.application}</TableCell>
                    <TableCell className={classes.tableCell}>{error.version}</TableCell>
                    <TableCell className={classes.tableCell}>{error.lambdaFunctionName}</TableCell>
                    <TableCell className={classes.tableCell}>{error.name}</TableCell>
                    <TableCell className={classes.tableCell}>{error.message}</TableCell>
                    <TableCell numeric="true" className={classes.tableCell}>{error.occurences}</TableCell>
                    <TableCell className={classes.tableCell}>{Utils.formattedDateTime(error.latestTS)}</TableCell>
                    <TableCell className={classes.tableCell}>{error.expected ? "" : (error.fixed ? Utils.formattedDateTime(error.fixed) : <Button size="small" color="secondary" className={classes.button} onClick={event => this.markFixed(event,error.id,index)}>fix</Button>)}</TableCell>
                    <TableCell className={classes.tableCell}><IconButton color="primary" onClick={event => this.drillDown(event,error.id)}><ViewIcon /></IconButton></TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>

          {this.state.uniqueErrors.more && 
            <Button className="autoMargin" onClick={event => this.getUniqueErrors()}>load more...</Button>
          }
    
        </Paper>

          <Dialog
          open={this.state.fixDialogueOpen}
          onClose={this.closeFixDialogue}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Mark issue as fixed</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please do not mark this issue as fixed until the fix has been deployed.
            </DialogContentText>
            <TextField
              autoFocus
              id="comment-form"
              label="Comment"
              multiline
              fullWidth
              rowsMax="10"
              inputRef={ref => this.fixCommentFieldRef = ref}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeFixDialogue}>
              Cancel
            </Button>
            <Button onClick={this.saveFix} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

      </React.Fragment>
    )
  }
}

Errors.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(Errors))