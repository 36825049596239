import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  card: {
    textAlign : 'center',
    cursor : 'pointer',
    backgroundColor : '#20205f',
    borderRadius : '10px',
  },
  cardContent : {
    padding : '5px !important',
    backgroundColor : '#fdfede',
  },
  titleColumn  : {
    width : '100%',
    display : 'inline-block',
    fontSize: 16,
    color : 'white',
    textAlign:'center'
  },
  yesterdayCount : {
    textAlign : 'left',
    display : 'inline-block',
    color : 'white',
    float : 'left',
    margin : '3px',
  },
  lastWeekCount : {
    textAlign : 'right',
    display : 'inline-block',
    color : 'white',
    float : 'right',
    margin : '3px',
  },
  monthCount : {
    textAlign : 'center',
    display : 'inline-block',
    padding : '3px',
    color : 'white',
  },
})

class StatsCard extends Component {

  render() {

    const { classes } = this.props

    return (
      <Card className={classes.card} onClick={event => this.props.clickHandler(this.props)}>
      <Typography gutterBottom component="h2" className={classes.titleColumn}>{this.props.title}</Typography>

      <CardContent className={classes.cardContent}>
        <Typography variant="h3" color="textPrimary">
          {this.props.salesStats.reservations.today}
        </Typography>
      </CardContent>

      <Typography gutterBottom component="h2">
        <span className={classes.yesterdayCount}>{this.props.salesStats.reservations.yesterday}</span>
        <span className={classes.lastWeekCount}>{this.props.salesStats.reservations.lastweek}</span>
        <Typography variant="body1" className={classes.monthCount}>
          {this.props.salesStats.reservations.month} ({this.props.salesStats.reservations.lastmonth})
        </Typography>
      </Typography>
    </Card>
    )
  }
}

export default withStyles(styles)(StatsCard)
