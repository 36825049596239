const getGridConfig = () => {

  let config = {
   
    visibleRowBufferSize : 10,

    columnDefs : [
      {
        field: "accomId",
        headerName: "Seat",
        sortable: true,
        width :100,
      },
      {
        field: "inventoryClass",
        headerName: "IC",
        sortable: true, 
        filter: 'agSetColumnFilter',
        menuTabs : ['filterMenuTab'],
        width : 60
      },
      {
        field: "facing",
        headerName: "Face",
        sortable: true, 
        filter: 'agSetColumnFilter',
        menuTabs : ['filterMenuTab'],
        width : 60
      },
    ]
  }

  return config
}

export default getGridConfig