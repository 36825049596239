import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Badge from '@material-ui/core/Badge'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = theme => ({
  card: {
    textAlign : 'center',
    cursor : 'pointer',
  },
  cardContent : {
    padding : '0 !important',
  },
  title: {
    padding : '3px',
    fontSize: 14,
    color : '#999',
  },
  calls: {
    fontSize: 28,
  },
  coldstarts: {
    fontSize: 10,
  },
  duration: {
    fontSize: 12,
  },
  alert : {
    color : 'red',
  },
})

const tooltipStyle = {
  tooltip : {
    fontSize: "20px",
    whiteSpace: "pre-line"
  }
}

const CustomTooltip = withStyles(tooltipStyle)(Tooltip)

class StatsCard extends Component {

  constructor(props, context) {
    super(props, context)

    this.state = {data:{}}
  }

  componentDidMount() {
    if (this.props.dataSource)
    {
      this.updateData()

      this.intervalRef = setInterval(() => {
        if (!this.props.sleeping)
        {
          this.updateData()
        }
      }, this.props.updateInterval)
    }
  }
  
  componentWillUnmount() {
    clearInterval(this.intervalRef)
  }

  async updateData() {
    if (this.props.namespace)
    {
      let data = await this.props.dataSource(this.props.namespace,this.props.mode)

      data.coldstartPercent = data.coldstartsSum ? (data.coldstartsSum / data.invocationsSum * 100).toFixed(1) : 0
      data.errorPercent = data.errorsSum ? (data.errorsSum / data.invocationsSum * 100).toFixed(1) : 0
      data.timeoutPercent = data.timeoutsSum ? (data.timeoutsSum / data.invocationsSum * 100).toFixed(1) : 0
  
      this.setState({data})  
    }
  }

  render() {

    const { classes } = this.props
  
    let tooltipText = `Number of calls : ${this.state.data.invocationsSum}

    Errors : ${this.state.data.errorsSum} (${this.state.data.errorPercent}%)
    Coldstarts : ${this.state.data.coldstartsSum} (${this.state.data.coldstartPercent}%)
    Timeouts : ${this.state.data.timeoutsSum} (${this.state.data.timeoutPercent}%)
    
    Average Duration : ${parseInt(this.state.data.durationAverage,10)}ms
    Maximum Duration : ${this.state.data.durationMaximum}ms
    Minimum Duration : ${this.state.data.durationMinimum}ms
    `

    return (
      <Card className={classes.card} onClick={event => this.props.clickHandler(this.props)}>
        <CustomTooltip title={tooltipText}>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.title} gutterBottom component="h2">
              {this.props.title}
            </Typography>

            <Typography className={classes.calls} variant="h5" color="textPrimary">
              <Badge badgeContent={this.state.data.errorsSum} color="secondary">
                {this.state.data.invocationsSum}
              </Badge>
            </Typography>

            <Typography className={classes.duration} variant="h5" color="textPrimary">
              {parseInt(this.state.data.durationAverage,10)} ms
            </Typography>

            <Typography className={classes.coldstarts} color="textSecondary">
              <SvgIcon style={{ fontSize: 12 }}><path d="M15 13V5a3 3 0 0 0-6 0v8a5 5 0 1 0 6 0m-3-9a1 1 0 0 1 1 1v3h-2V5a1 1 0 0 1 1-1z" fill="#626262"/></SvgIcon><span className={this.state.data.coldstartPercent > 0 ? 'alert' : ''}>{this.state.data.coldstartPercent} %</span>
              &nbsp;&nbsp;&nbsp;
              <AccessAlarmIcon style={{ fontSize: 12 }}/> {this.state.data.timeoutPercent} %
            </Typography>
          </CardContent>
        </CustomTooltip>
      </Card>
    )
  }
}

export default withStyles(styles)(StatsCard)
