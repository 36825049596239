import _ from 'lodash'
import React, { Component } from 'react'
import {
  ShapeEditor,
  ImageLayer,
  DrawLayer,
  wrapShape,
  
} from '../react-shape-editor'

import DynamicComponent from '../components/dynamic-component'

import Tooltip from '@material-ui/core/Tooltip'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'

import { SiAirtable } from "react-icons/si"
import { FaLuggageCart } from "react-icons/fa" 
import { FaToiletPaper } from "react-icons/fa" 
import { MdExitToApp } from "react-icons/md" 
import { MdOutlineEmojiFoodBeverage } from "react-icons/md" 
import { MdOutlineDirectionsBike } from "react-icons/md" 
import { MdOutlineCleaningServices } from "react-icons/md" 


import Config from '../config'

const areaIcons = {
  table : SiAirtable,
  luggage : FaLuggageCart,
  toilet : FaToiletPaper,
  exit  : MdExitToApp,
  food  : MdOutlineEmojiFoodBeverage,
  bike  : MdOutlineDirectionsBike,
}

const RectShape = wrapShape(({ width, height, type }) => (
    <React.Fragment>
      <rect width={width} height={height} fill="rgba(0,0,0,0.5)" strokeWidth="2" stroke="#000000"/>
      <DynamicComponent component={areaIcons[type]} color="#ffffff" size="2em" x={(width/2) - 15} y={(height/2) - 20}/>
    </React.Fragment>
  )
)

class AreaEditor extends Component {

  constructor(props) {
    super(props)

    this.state = {
      coach : _.cloneDeep(this.props.coach),
      items : _.cloneDeep(this.props.coach.areaData),
      vectorHeight : 0,
      vectorWidth : 0,
    }

    if (this.props.isEditor)
    {
      this.props.setActionButtons(this.getActionButtons())
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.coach !== this.props.coach)
    {
      // Make a copy of seats so that we do not mutate props when we adjust seat properties
      let coach = _.cloneDeep(this.props.coach)
      let items = _.cloneDeep(this.props.coach.areaData)
      this.setState({coach,items})
    }
  }

  componentDidMount(){
    document.addEventListener("keydown", this.handleKeyPress, false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.handleKeyPress, false);
  }

  handleKeyPress = event => {

    let key = event.key

    if (event.metaKey) key += '-meta'
    if (event.ctrlKey) key += '-ctrl'
    if (event.shiftKey) key += '-shift'

    switch(key)
    {
      case "ArrowUp-ctrl": return this.moveAreas(0,-1)
      case "ArrowDown-ctrl": return this.moveAreas(0,1)
      case "ArrowRight-ctrl": return this.moveAreas(1,0)
      case "ArrowLeft-ctrl": return this.moveAreas(-1,0)

      case "ArrowUp-ctrl-shift": return this.moveAreas(0,-10)
      case "ArrowDown-ctrl-shift": return this.moveAreas(0,10)
      case "ArrowRight-ctrl-shift": return this.moveAreas(10,0)
      case "ArrowLeft-ctrl-shift": return this.moveAreas(-10,0)

      default: {} // Ignore
    }
  }

  tidyAreas = () => {
    console.log("tidying areas")

    let items = this.state.items

    items.forEach(i => {
      switch (i.type)
      {
        case "table":
        {
          i.height = i.height > 75 ? 100 : 50
          i.y = i.y < 150 ? 10 : 300 -10 -i.height
          i.width = 30
          break
        }
        case "luggage":
        {
          i.height = i.height > 75 ? 100 : 50
          i.y = i.y < 150 ? 5 : 300 -5 -i.height
          break
        }
        case "toilet":
        {
          i.height = 100
          i.y = i.y < 150 ? 5 : 300 -5 -i.height
          break
        }
        case "exit":
        {
          i.height = 60
          i.y = i.y < 150 ? 0 : 300 -i.height
          break
        }
        case "food":
        {
          break
        }
        case "bike":
        {
          i.height = 100
          i.y = i.y < 150 ? 5 : 300 -5 -i.height
          break
        }
        default: {
          console.log("Unexpected area type",i.type,"area-editor.js/tidyAreas")
        }
      }
    })

    this.areasChanged()
  }

  getActionButtons = () => (
    <RadioGroup value={this.props.selectedAreaType} onChange={async e => {
        await this.props.handleRadioChange("selectedAreaType",e.target.value)
        this.props.setActionButtons(this.getActionButtons())}
      } row>
      {Object.keys(areaIcons).map((k,i) => (
        <Tooltip title={k} key={i}>
          <FormControlLabel id={i} value={k} label={<DynamicComponent component={areaIcons[k]} color="#3953B1" size="2em"/>} control={<Radio/>} />
        </Tooltip>
      ))}

      <Tooltip title="Tidy up">
        <IconButton onClick={this.tidyAreas} color="primary" size="large">
          <MdOutlineCleaningServices/>
        </IconButton>
      </Tooltip>

      <div>Use arrow keys to move an area. Use CTRL-arrow to move all areas (Shift-CTRL-arrow to move faster)</div>

    </RadioGroup>
  )

  areasChanged = () => {

    if (this.props.isEditor)
    {
      let coach = this.state.coach
      let items = this.state.items
  
      coach.areaData = items
      this.props.updateCoach(coach)  
    }
  }

  adjustSizeAndPositionOfNewArea = (x,y,width,height,type) => {

    let imageHeight = Config.getConfig('coachHeight')
    let imageBottom = imageHeight -1 // -1 because our points go from 0 - 299
    let imageVerticalCentre = imageHeight / 2

    switch(type) {
      case "table" : {

        width = 30
        height = 100
        y = y < imageVerticalCentre ? 10 : imageBottom -height -10

        this.state.items.forEach(item => {
          if (item.type === "table")
          {
            width = item.width
            height = item.height

            if ((y < imageVerticalCentre && item.y < imageVerticalCentre) || (y > imageVerticalCentre && item.y > imageVerticalCentre))
            {
              y = item.y
            }

            // check if similar x - or find centre of nearest seats
          }
        })

        break
      }

      case "luggage" : {
        height = 110
        width = 75
        y = y < imageVerticalCentre ? 4 : imageBottom -height -4
        break
      }

      case "exit" : {
        height = 60
        width = 100
        y = y < imageVerticalCentre ? 0 : imageBottom - height


        this.state.items.forEach(item => {
          if (item.type === "exit")
          {
            width = item.width
          }
        })

        // Find exit with similar x and set the same

        break
      }

      case "food" : {
        break
      }

      case "toilet" : {
        break
      }

      case "bike" : {
        height = 110
        width = 75
        y = y < imageVerticalCentre ? 4 : imageBottom -height -4
        break
      }
      default: {
        console.log("Unexpected area type",type,"area-editor.js/adjustSizeAndPositionOfNewArea")
      }
    }

    return [x,y,width,height]
  }

  moveAreas = (x,y) => {
    let items = this.state.items

    items.forEach(i => {
      i.x += x
      i.y += y
    })

    this.setState({items},this.areasChanged())
  }

  render() {

    const { items, vectorWidth, vectorHeight } = this.state

    return (
      <React.Fragment>
        <br/>
        <ShapeEditor vectorWidth={vectorWidth} vectorHeight={vectorHeight}>
          <ImageLayer
            src={this.props.coach.ourImageUrl}
            onLoad={({ naturalWidth, naturalHeight }) => {
              this.setState({
                vectorWidth: naturalWidth,
                vectorHeight: naturalHeight,
              })
            }}
          />
          <DrawLayer
            onAddShape={({ x, y, width, height }) => {

              [x,y,width,height] = this.adjustSizeAndPositionOfNewArea(x,y,width,height,this.props.selectedAreaType)

              let items = this.state.items
              items.push({ x, y, width, height, type:this.props.selectedAreaType })
              this.setState({items},this.areasChanged())
            }}
          />
          {items.map((item, index) => {
            const { height, width, x, y, type } = item
            return (
              <RectShape
                key={index}
                shapeId={'s' + index}
                height={height}
                width={width}
                type={type}
                x={x}
                y={y}
                onChange={newRect => {
                  let items = this.state.items
                  items[index] = {...newRect,type}
                  this.setState({items},this.areasChanged())
                }}
                onDelete={() => {
                  let items = this.state.items
                  items.splice(index,1)
                  this.setState({items},this.areasChanged())
                }}
              />
            )
          })}
        </ShapeEditor>
        <div style={{position:'relative',top:-vectorHeight-2,width:vectorWidth,height:vectorHeight,pointerEvents:'none'}}>
          {this.props.seats.map(seat => (
            <div className="seat greySeat" style={{position:'absolute',width:this.props.coach.ourSeatSize.width,height:this.props.coach.ourSeatSize.height,top:seat.position.y,left:seat.position.x}}/>
          ))}
        </div>
      </React.Fragment>
    )
  }
}

export default AreaEditor