import _ from 'lodash'
import React, { Component } from 'react'

import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'

import Utils from '../utils'
import Config from '../config'

import DynamicComponent from '../components/dynamic-component'

import { SiAirtable } from "react-icons/si"
import { FaLuggageCart } from "react-icons/fa" 
import { FaToiletPaper } from "react-icons/fa" 
import { MdExitToApp } from "react-icons/md" 
import { MdOutlineEmojiFoodBeverage } from "react-icons/md" 
import { MdOutlineDirectionsBike } from "react-icons/md" 

const areaIcons = {
  table : SiAirtable,
  luggage : FaLuggageCart,
  toilet : FaToiletPaper,
  exit  : MdExitToApp,
  food  : MdOutlineEmojiFoodBeverage,
  bike  : MdOutlineDirectionsBike,
}

const getSeatSize = ourSeatSize => {

  let standardSeatSize = Config.getConfig('seatSize')

  if (ourSeatSize.width === standardSeatSize.width && ourSeatSize.height === standardSeatSize.height)
  {
    return {className:"size-standard",msg:`Standard ${standardSeatSize.width}x${standardSeatSize.height} seats`}
  }
  else
  {
    return {className:"size-non-standard",msg:`Non-Standard - W:${ourSeatSize.width} H:${ourSeatSize.height}`}
  }
}

const getImageSize = ourImageSize => {
  let standardImageHeight = Config.getConfig('coachHeight')

  if (ourImageSize.height === standardImageHeight)
  {
    return {className:"size-standard",msg:`Standard ${standardImageHeight} tall image (${ourImageSize.width} wide)`}
  }
  else
  {
    return {className:"size-non-standard",msg:`Non-Standard - W:${ourImageSize.width} H:${ourImageSize.height}`}
  }
}


class CoachOverview extends Component {

  constructor(props) {
    super(props)
    this.state = {
      coach : _.cloneDeep(this.props.coach),
      showMergePopup : false
    }

    if (this.props.isEditor)
    {
      this.props.setActionButtons(this.getActionButtons())
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.coach !== this.props.coach)
    {
      // Make a copy of seats so that we do not mutate props when we adjust properties
      let coach = _.cloneDeep(this.props.coach)
      this.setState({coach,showMergePopup:false})
    }
  }

  getActionButtons = () => (
    <React.Fragment>

    </React.Fragment>
  )

  handleChange = event => {
    let coach = this.state.coach
    coach[event.target.name] = event.target.checked ? 1 : 0
    this.props.updateCoach(coach)
  }

  handleTextChange = event => {
    let coach = this.state.coach
    coach[event.target.name] = event.target.value
    this.setState({coach})
  }
  
  saveTextFieldChanges = () => {
    this.props.updateCoach(this.state.coach)
  }

  closePopups = () => {
    this.setState({showMergePopup:false})
  }

  render() {

    let seatSize = getSeatSize(this.state.coach.ourSeatSize)
    let imageSize = getImageSize(this.state.coach.ourImageSize)

    return  (
      <React.Fragment>

        <h2>Name:</h2>
          <TextField value={this.state.coach.ourName} name="ourName" onChange={this.handleTextChange} onBlur={this.saveTextFieldChanges} variant="outlined" id="nameTextInput"></TextField>

          <FormControlLabel
            control={<Switch checked={this.state.coach.furtherWorkRequired === 1} onChange={this.handleChange} name="furtherWorkRequired" />}
            label="Further Manual Corrections Needed"
          />
          <FormControlLabel
            control={<Switch checked={this.state.coach.countedPlace === 1} onChange={this.handleChange} name="countedPlace" />}
            label="Counted Place coach (no reservable seats)"
          />

        <h2>Dates:</h2>
          <div>Discovered : {Utils.formattedDate(this.state.coach.discoveryDate)}</div>
          <div>Last Seen : {Utils.formattedDate(this.state.coach.lastSeenDate)}</div>


        <h2>Sizes:</h2>
          <div className={seatSize.className}>seat size : {seatSize.msg}</div>
          <div className={imageSize.className}>image size : {imageSize.msg}</div>

        <h2>Connections to adjoining carriages:</h2>
        <FormGroup row>
          <FormControlLabel
            control={<Switch checked={this.state.coach.walkthroughLeft === 1} onChange={this.handleChange} name="walkthroughLeft" />}
            label="Left"
          />
          <FormControlLabel
            control={<Switch checked={this.state.coach.walkthroughRight === 1} onChange={this.handleChange} name="walkthroughRight" />}
            label="Right"
          />
        </FormGroup>

        <h2>Notes:</h2>
          <TextField value={this.state.coach.notes} name="notes" multiline rows={10} onChange={this.handleTextChange} onBlur={this.saveTextFieldChanges} variant="outlined" id="commentsTextInput"></TextField>

        <h2>Our Image:</h2>
          <div style={{position:'relative'}}>
            <img src={this.state.coach.ourImageUrl} alt="" style={{position:'absolute',height:this.state.coach.ourImageSize.height,width:this.state.coach.ourImageSize.width,left:0,top:0}}/>

            {this.props.coach.areaData.map((area,idx) => (
              <div key={idx} className="area" style={{position:'absolute',width:area.width,height:area.height,top:area.y,left:area.x}}>
                <div style={{position:'absolute',left:(area.width/2) - 15, top:(area.height/2) - 20}}>
                  <DynamicComponent component={areaIcons[area.type]} size="1.5em"/>
                </div>
              </div>
            ))}

            {this.props.seats.map(seat => (
              <div key={seat.accomId} className="seat greySeat" style={{position:'absolute',width:this.state.coach.ourSeatSize.width,height:this.state.coach.ourSeatSize.height,top:seat.position.y,left:seat.position.x}}>
                {seat.accomId}
                <br/>
                {seat.facing === "L" ? "<":""}{seat.facing === "R" ? ">":""}
              </div>
            ))}

          </div>

      </React.Fragment>
    )
  }
}

export default CoachOverview