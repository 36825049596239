
import TimeFilter from './agGridTimeFilter'

import Utils from '../utils'

const Config = {
   
  visibleRowBufferSize : 10,

  columnDefs : [
    {
      field: "id",
      headerName: "ID", // \u00A0
      headerClass : "dateHeader",
      sortable: true, 
      filter: 'agNumberColumnFilter',
      width :120,
      menuTabs : ['filterMenuTab'],
      filterParams : {
        filterOptions : ['inRange','greaterThanOrEqual','lessThanOrEqual'],
        suppressAndOrCondition : true,
        inRangeInclusive : true,
      },
    },
    {
      field: "licensee",
      headerName: "L",
      sortable: false, 
      filter: true,
      width :80,
      menuTabs : ['filterMenuTab'],
    },
    {
      field: "tsStart",
      headerName: "Time",
      valueFormatter : obj => Utils.formattedTime(obj.value),
      sortable: true, 
      filter: TimeFilter,
      width :100,
      menuTabs : ['filterMenuTab'],
    },
    {
      field: "responseTime",
      headerName: "ms",
      sortable: true, 
      filter: 'agNumberColumnFilter',
      width :100,
      type : 'rightAligned',
      headerClass : "dateHeader",
      menuTabs : ['filterMenuTab'],
      filterParams : {
        filterOptions : ['greaterThanOrEqual','inRange','lessThanOrEqual'],
        suppressAndOrCondition : true,
        inRangeInclusive : true,
      },
    },
    {
      field: "coldstart",
      headerName: "Cold",
      valueFormatter : obj => obj.value ? 'cold start' : '',
      sortable: true, 
      filter: true,
      width : 100,
      menuTabs : ['filterMenuTab'],
    },
    {
      field: "response",
      headerName: "Status",
      sortable: true, 
      filter: 'agNumberColumnFilter',
      width :100,
      menuTabs : ['filterMenuTab'],
      filterParams : {
        filterOptions : ['greaterThanOrEqual','lessThanOrEqual'],
        suppressAndOrCondition : true,
      },
    },
    {
      field: "api",
      headerName: "API",
      sortable: true, 
      filter: 'agSetColumnFilter',
      width :180,
      menuTabs : ['filterMenuTab'],
    },
    {
      field: "functionName",
      headerName: "Function",
      sortable: true, 
      filter: 'agSetColumnFilter',
      width :250,
      menuTabs : ['filterMenuTab'],
    },
    {
      field: "bookingRef",
      headerName: "Booking",
      sortable: true, 
      filter: 'agTextColumnFilter',
      filterParams : {
        filterOptions : ['contains'],
        suppressAndOrCondition : true,
      },
      menuTabs : ['filterMenuTab'],
      width :150
    },
    {
      field: "ticketType",
      headerName: "TKT",
      sortable: true, 
      filter: true,
      width : 80,
      menuTabs : ['filterMenuTab'],
    },
    {
      field: "machineId",
      headerName: "Machine",
      sortable: false, 
      filter: true,
      width : 120,
      menuTabs : ['filterMenuTab'],
    },
    {
      field: "reservationRefs",
      headerName: "Reservations",
      sortable: true, 
      filter: 'agTextColumnFilter',
      filterParams : {
        filterOptions : ['contains'],
        suppressAndOrCondition : true,
      },
      menuTabs : ['filterMenuTab'],
      width :250,
      valueFormatter : obj => Utils.firstListItemPlusCount(obj.value),
    },
    {
      field: "ticketRefs",
      headerName: "Tickets",
      sortable: true, 
      filter: 'agTextColumnFilter',
      filterParams : {
        filterOptions : ['contains'],
        suppressAndOrCondition : true,
      },
      menuTabs : ['filterMenuTab'],
      width :160,
      valueFormatter : obj => Utils.firstListItemPlusCount(obj.value),
    },
  
    {
      field: "stage",
      headerName: "Stage",
      sortable: true, 
      filter: true,
      width :100,
      menuTabs : ['filterMenuTab'],
    },
    {
      field: "version",
      headerName: "Version",
      sortable: true, 
      filter: true,
      width :110,
      menuTabs : ['filterMenuTab'],
    },
    {
      field: "organisation",
      headerName: "Organisation",
      sortable: true, 
      filter: true,
      width :200,
      menuTabs : ['filterMenuTab'],
    },
    {
      field: "user",
      headerName: "User",
      sortable: true, 
      filter: true,
      width :200,
      menuTabs : ['filterMenuTab'],
    },
  
    {
      field: "awsRequestId",
      headerName: "AWS Req ID",
      sortable: false, 
      filter: 'agTextColumnFilter',
      filterParams : {
        filterOptions : ['contains'],
        suppressAndOrCondition : true,
      },
      menuTabs : ['filterMenuTab'],
      width : 350
    },
    {
      field: "gatewayRequestId",
      headerName: "Gateway ID",
      sortable: false, 
      filter: 'agTextColumnFilter',
      filterParams : {
        filterOptions : ['contains'],
        suppressAndOrCondition : true,
      },
      menuTabs : ['filterMenuTab'],
      width : 350
    },
  ]
}

export default Config