import Moment from 'moment-timezone'
import React from 'react'
import ReactDOMServer from 'react-dom/server'

function TimeFilter() {
}

TimeFilter.prototype.init = function(params) {
    this.valueGetter = params.valueGetter
    
    this.startHour = "00"
    this.startMin  = "00"
    this.endHour   = "23"
    this.endMin    = "59"
    
    this.setupGui(params)
}

// not called by ag-Grid, just for us to help setup
TimeFilter.prototype.setupGui = function(params) {
    this.gui = document.createElement('div')
    this.gui.innerHTML = this.renderForm()

    this.startHourSelect = this.gui.querySelector('#startHour')
    this.startMinSelect = this.gui.querySelector('#startMin')
    this.endHourSelect = this.gui.querySelector('#endHour')
    this.endMinSelect = this.gui.querySelector('#endMin')
    this.resetButton = this.gui.querySelector('#reset')

    this.startHourSelect.addEventListener("change", listener)
    this.endHourSelect.addEventListener("change", listener)
    this.startMinSelect.addEventListener("change", listener)
    this.endMinSelect.addEventListener("change", listener)
    this.resetButton.addEventListener("click", reset)

    var that = this

    function listener(event) {
   
      that.startHour = that.startHourSelect.value
      that.startMin = that.startMinSelect.value
      that.endHour = that.endHourSelect.value
      that.endMin = that.endMinSelect.value

      params.filterChangedCallback()
    }

    function reset() {

      that.startHour = "00"
      that.startMin = "00"
      that.endHour = "23"
      that.endMin = "59"

      that.startHourSelect.selectedIndex = 0
      that.startMinSelect.selectedIndex = 0
      that.endHourSelect.selectedIndex = 23
      that.endMinSelect.selectedIndex = 11

      params.filterChangedCallback()
    }
}

TimeFilter.prototype.getGui = function() {
    return this.gui
}

TimeFilter.prototype.doesFilterPass = function(params) {
    var value = this.valueGetter(params)

    let startTime = Moment.utc(value).set('hour',parseInt(this.startHour,10)).set('minute',parseInt(this.startMin,10)).set('second',0)
    let endTime = Moment.utc(value).set('hour',parseInt(this.endHour,10)).set('minute',parseInt(this.endMin,10)).set('second',59)

    let testTime = Moment.utc(value)

    return testTime.isBetween(startTime,endTime)
}

TimeFilter.prototype.isFilterActive = function() {
    
  if (this.startHour !== "00") return true
  if (this.startMin !== "00") return true
  if (this.endHour !== "23") return true
  if (this.endMin !== "59") return true

  return false
}

TimeFilter.prototype.getModel = function() {
    return { startHour:this.startHour, startMin:this.startMin, endHour:this.endHour, endMinute:this.endMinute }
}

TimeFilter.prototype.setModel = function(model) {
  this.startHour = model.startHour
  this.startMin = model.startMin
  this.endHour = model.endHour
  this.endMinute = model.endMinute
}

// Need to return HTML string, not JSX rendered HTML...
TimeFilter.prototype.renderForm = function () {
  let hourOptions = [...Array(24)].map((v,i) => i.toString().padStart(2,'0'))
  let startMinOptions = [...Array(12)].map((v,i) => (i*5).toString().padStart(2,'0'))
  let endMinOptions = [...Array(12)].map((v,i) => ((i*5) +4).toString().padStart(2,'0'))

  return ReactDOMServer.renderToStaticMarkup(
    <table>
      <tr>
        <th colSpan='3'>From</th>
        <th>&nbsp;</th>
        <th colSpan='3'>To</th>
      </tr>
      <tr>
        <td><select id='startHour' defaultValue={this.startHour}>{hourOptions.map((h,i) => <option value={parseInt(h,10)} key={i}>{h}</option>)}</select></td>
        <td>:</td>
        <td><select id='startMin' defaultValue={this.startMin}>{startMinOptions.map((h,i) => <option value={parseInt(h,10)} key={i}>{h}</option>)}</select></td>
        <td style={{textAlign:'center'}} width="100%"> - </td>
        <td><select id='endHour' defaultValue={this.endHour}>{hourOptions.map((h,i) => <option value={parseInt(h,10)} key={i}>{h}</option>)}</select></td>
        <td>:</td>
        <td><select id='endMin' defaultValue={this.endMin}>{endMinOptions.map((h,i) => <option value={parseInt(h,10)} key={i}>{h}</option>)}</select></td>
      </tr>
      <tr>
        <td colSpan="7" align="right"><input type="button" id="reset" value="Reset" style={{marginTop:'10px'}}/></td>
      </tr>
    </table>
    
  )
}

export default TimeFilter