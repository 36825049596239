import _ from 'lodash'
import React, { Component } from 'react'
import Jimp from 'jimp'

import {
  ShapeEditor,
  ImageLayer,
  wrapShape,
} from '../react-shape-editor'

import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

import { BiCrop } from "react-icons/bi"
import { GiResize } from "react-icons/gi" 
import { AiOutlineRotateRight } from "react-icons/ai"

import Config from '../config'

const RectShape = wrapShape(({ width, height }) => (
    <rect width={width} height={height} fill="rgba(0,0,0,0.5)" strokeWidth="2" stroke="#000000"/>
  )
)

class CropAndResizeEditor extends Component {

  constructor(props) {
    super(props)

    this.state = {
      coach : _.cloneDeep(this.props.coach),
      seats : _.cloneDeep(this.props.seats),
      crop : {
        height : 0,
        width : 0,
        x : 0,
        y : 0,
      },
    }

    if (this.props.isEditor)
    {
      this.props.setActionButtons(this.getActionButtons())
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.seats !== this.props.seats)
    {
      // Make a copy of seats so that we do not mutate props when we adjust properties
      let seats = _.cloneDeep(this.props.seats)
      this.setState({seats})
    }

    if (prevProps.coach !== this.props.coach)
    {
      // Make a copy of coach so that we do not mutate props when we adjust properties
      let coach = _.cloneDeep(this.props.coach)
      this.setState({coach})
    }
  }

  componentDidMount(){
    document.addEventListener("keydown", this.handleKeyPress, false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.handleKeyPress, false);
  }

  getActionButtons = () => (

    <React.Fragment>
      <Tooltip title="Crop (CTRL-C)">
        <IconButton onClick={this.crop} color="primary" size="large">
          <BiCrop/>
        </IconButton>
      </Tooltip>
      <Tooltip title="Resize (CTRL-R)">
        <IconButton onClick={this.resize} color="primary" size="large">
          <GiResize/>
        </IconButton>
      </Tooltip>
      <Tooltip title="Rotate - 180' (CTRL-F)">
        <IconButton onClick={this.rotate} color="primary" size="large">
          <AiOutlineRotateRight/>
        </IconButton>
      </Tooltip>
    </React.Fragment>
  )

  handleKeyPress = event => {

    let key = event.key

    if (event.metaKey) key += '-meta'
    if (event.ctrlKey) key += '-ctrl'
    if (event.shiftKey) key += '-shift'

    switch(key)
    {
      case "c-ctrl" : case "C-ctrl" : return this.crop()
      case "r-ctrl" : case "R-ctrl" : return this.resize()
      case "f-ctrl" : case "F-ctrl" : return this.rotate()
      default: {} // Ignore
    }
  }

  crop = async () => {

    let crop = this.state.crop
    let img = await Jimp.read(this.state.coach.ourImageUrl + (/https?:\/\//.test(this.state.coach.ourImageUrl) ? '?defeat-cors=1' : ''))

    if (crop.width === 0)
    {
      crop.x = 10
      crop.y = 10
      crop.width = img.bitmap.width - 20
      crop.height = img.bitmap.height - 20

      this.setState({crop})
    }
    else
    {
      crop.x = Math.max(crop.x,0)
      crop.y = Math.max(crop.y,0)

      if (crop.x + crop.width > img.bitmap.width - crop.x)
      {
        crop.width = img.bitmap.width - crop.x
      }
      if (crop.y + crop.height > img.bitmap.wiheightdth - crop.y)
      {
        crop.height = img.bitmap.height - crop.y
      }

      img.crop(crop.x,crop.y,crop.width,crop.height)
    
      let imgBase64 = await img.getBase64Async(Jimp.MIME_PNG)
  
      let coach = this.state.coach
      coach.ourImageUrl = imgBase64
      coach.ourImageSize = {
        width : img.bitmap.width ,
        height : img.bitmap.height ,
      }
  
      for (let area of coach.areaData)
      {
        area.x -= crop.x
        area.y -= crop.y
  
        area.x = Math.max(area.x,0)
        area.y = Math.max(area.y,0)
      }
  
      this.props.updateCoach(coach)
      
      let seats = this.state.seats
  
      for (let seat of seats)
      {
        seat.position.x -= crop.x
        seat.position.y -= crop.y
  
        seat.position.x = Math.max(seat.position.x,0)
        seat.position.y = Math.max(seat.position.y,0)
      }
  
      this.props.updateSeats(seats)
  
      crop = {
        x : 0,
        y : 0,
        width : 0,
        height : 0,
      }
  
      this.setState({crop})
    }
  }

  resize = async () => {
    let img = await Jimp.read(this.state.coach.ourImageUrl + (/https?:\/\//.test(this.state.coach.ourImageUrl) ? '?defeat-cors=1' : ''))
    img.resize(Jimp.AUTO,Config.getConfig("coachHeight"))

    let imgBase64 = await img.getBase64Async(Jimp.MIME_PNG)

    let coach = this.state.coach

    let resizeRatio = Config.getConfig("coachHeight") / coach.ourImageSize.height

    coach.ourImageUrl = imgBase64
    coach.ourImageSize = {
      width : img.bitmap.width ,
      height : img.bitmap.height ,
    }

    for (let area of coach.areaData)
    {
      area.x = Math.round(area.x * resizeRatio)
      area.y = Math.round(area.y * resizeRatio)
      area.width = Math.round(area.width * resizeRatio)
      area.height = Math.round(area.height * resizeRatio)
    }

    this.props.updateCoach(coach)

    let seats = this.state.seats

    for (let seat of seats)
    {
      seat.position.x = Math.round(seat.position.x * resizeRatio)
      seat.position.y = Math.round(seat.position.y * resizeRatio)
    }

    this.props.updateSeats(seats)
  }

  rotate = async () => {
    let img = await Jimp.read(this.state.coach.ourImageUrl + (/https?:\/\//.test(this.state.coach.ourImageUrl) ? '?defeat-cors=1' : ''))
    img.rotate(180)

    let imgBase64 = await img.getBase64Async(Jimp.MIME_PNG)

    let coach = this.state.coach
    coach.ourImageUrl = imgBase64
    this.props.updateCoach(coach)
  }

  resizeToDeclaredSize = async atts => {
    let img = await Jimp.read(this.state.coach.ourImageUrl + (/https?:\/\//.test(this.state.coach.ourImageUrl) ? '?defeat-cors=1' : ''))

    if (this.state.coach.ourImageSize.height !== atts.naturalWidth)
    {
      img.resize(this.state.coach.ourImageSize.width,this.state.coach.ourImageSize.height)

      let imgBase64 = await img.getBase64Async(Jimp.MIME_PNG)
  
      let coach = this.state.coach
      coach.ourImageUrl = imgBase64
      this.props.updateCoach(coach)  
    }
  }

  render() {

    const { coach, crop } = this.state

    if (!coach.ourImageUrl) return (<React.Fragment></React.Fragment>)
  
    return (
      <React.Fragment>
        <br/>
        <ShapeEditor vectorWidth={coach.ourImageSize.width} vectorHeight={coach.ourImageSize.height}>
          <ImageLayer
            src={coach.ourImageUrl}
            onLoad={this.resizeToDeclaredSize}
          />
          <RectShape
            key={"i0"}
            shapeId={'s0'}
            height={crop.height}
            width={crop.width}
            type="px"
            x={crop.x}
            y={crop.y}
            onChange={newRect => {
              this.setState({crop:newRect})
            }}
          />
        </ShapeEditor>
      </React.Fragment>
    )
  }
}

export default CropAndResizeEditor