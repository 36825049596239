import moment from "moment"
import Axios from 'axios'
import Config from '../config'

const recordTypes = {
  CG : [
    {
      name: "Number of transactions",
      start : 2,
      length : 2,
      format : "int"
    },
    {
      name: "Number of payments",
      start : 4,
      length : 2,
      format : "int"
    },
    {
      name: "Transaction header (seq)",
      start : 6,
      length : 5
    },
    {
      name: "Selling NLC",
      start : 11,
      length : 4
    },
    {
      name: "Date",
      start : 15,
      length : 8,
      format : "date"
    },
    {
      name: "Retail/fulfilment event",
      start : 23,
      length : 1,
      format : "fulfilmentEvent"
    },
    {
      name: "Method of fulfilment",
      start : 24,
      length : 3,
      format : "fulfilmentMethod"
    },
    {
      name: "Media provider",
      start : 27,
      length : 4
    },
    {
      name: "Fulfilment reference",
      start : 31,
      length : 32
    },
    {
      name: "Channel type code",
      start : 63,
      length : 3,
      format : "channelType"
    },
  ],
  BE : [
    {
      name: "Lennon type of ticket code (LTOC)",
      start: 2,
      length: 3,
    },
    {
      name: "Fare method indicator",
      start: 5,
      length: 1,
      format: "fareMethod"
    },
    {
      name: "Via London Indicator",
      start: 6,
      length: 1,
      format: 'boolean'
    },
    {
      name: "Fare Amount",
      start: 7,
      length: 10,
      format: "money"
    },
    {
      name: "Currency",
      start: 17,
      length: 1,
      format: "currencyCode"
    },
    {
      name: "MoP Code",
      start: 18,
      length: 3,
      format: "mopCode"
    },
    {
      name: "Transaction Number",
      start: 21,
      length: 5,
    },
    {
      name: "Ticket number",
      start: 26,
      length: 9,
    },
    {
      name: "Transaction header (seq)",
      start: 35,
      length: 5,
    },
    {
      name: "Status code",
      start: 40,
      length: 3,
    },
    {
      name: "Number of adults",
      start: 43,
      length: 2,
      format: "int"
    },
    {
      name: "Number of children",
      start: 45,
      length: 2,
      format: "int"
    },
    {
      name: "Fare class",
      start: 47,
      length: 1,
      format: "fareClass"
    },
    {
      name: "Origin",
      start: 48,
      length: 4,
      format: "nlc"
    },
    {
      name: "Destination",
      start: 52,
      length: 4,
      format: "nlc"
    },
    {
      name: "Route",
      start: 56,
      length: 5,
      format: "route"
    },
    {
      name: "Promotion code",
      start: 61,
      length: 2,
    },
    {
      name: "Discount code",
      start: 63,
      length: 4,
    },
    {
      name: "Discount percent",
      start: 67,
      length: 3,
      format:"percent"
    },
    {
      name: "Travel date",
      start: 70,
      length: 8,
      format:"date"
    },
    {
      name: "Issue time",
      start: 78,
      length: 4,
      format:"time"
    },
  ],
  CF : [
    {
      name: "Number of transactions",
      start: 2,
      length: 2
    },
    {
      name: "Number of payments",
      start: 4,
      length: 2
    },
    {
      name: "Selling NLC",
      start: 6,
      length: 4
    },
    {
      name: "CTR reference",
      start: 10,
      length: 8
    },
    {
      name: "RSP RFU",
      start: 18,
      length: 4
    },
    {
      name: "Selling machine number",
      start: 22,
      length: 4
    },
    {
      name: "Date of sale",
      start: 26,
      length: 8,
      format: "date"
    },
    {
      name: "ToD/Non-ToD indicator",
      start: 34,
      length: 1,
      format: "todIndicator"
    },
    {
      name: "Selling machine type code",
      start: 35,
      length: 2
    },
    {
      name: "Transaction header (seq)",
      start: 37,
      length: 5
    },
    {
      name: "RSP RFU",
      start: 42,
      length: 1
    },
  ],
  BP : [
    {
      name: "Fare method indicator",
      start: 2,
      length: 1,
      format:"fareMethod"
    },
    {
      name: "Fare amount",
      start: 3,
      length: 10,
      format: "money"
    },
    {
      name: "Currency indicator",
      start: 13,
      length: 1,
      format: "currencyCode"
    },
    {
      name: "MoP Code",
      start: 14,
      length: 3,
      format: "mopCode"
    },
    {
      name: "Transaction number (primary)",
      start: 17,
      length: 5,
      format: "int"
    },
    {
      name: "Transaction number (sundry)",
      start: 22,
      length: 5,
      format: "int"
    },
    {
      name: "Sundry code",
      start: 27,
      length: 5,
    },
    {
      name: "Owning transaction header (seq)",
      start: 32,
      length: 5,
    },
    {
      name: "Debit/Credit indicator",
      start: 37,
      length: 1,
      format: "debitCreditIndicator"
    },
    {
      name: "Number of sundries",
      start: 39,
      length: 4,
      format: "int"
    },
    {
      name: "Time of issue",
      start: 42,
      length: 4,
      format: "time"
    },
  ],
  BS: [
    {
      name: "Origin",
      start: 2,
      length: 4,
      format: "nlc"
    },
    {
      name: "Destination",
      start: 6,
      length: 4,
      format: "nlc"
    },
    {
      name: "route",
      start: 10,
      length: 5,
      format: "route"
    },
    {
      name: "Ticket code",
      start: 15,
      length: 3,
    },
    {
      name: "Status code",
      start: 18,
      length: 3,
    },
    {
      name: "Number of adults",
      start: 21,
      length: 2,
      format:"int"
    },
    {
      name: "Number of children",
      start: 23,
      length: 2,
      format:"int"
    },
    {
      name: "Fare class",
      start: 25,
      length: 1,
      format: "fareClass"
    },
    {
      name: "Season refund date",
      start: 26,
      length: 8,
      format:"date"
    },
    {
      name: "Season ticket expiry date",
      start: 34,
      length: 8,
      format:"date"
    },
    {
      name: "Season ticket expiry date",
      start: 34,
      length: 8,
      format:"date"
    },
    {
      name: "Currency",
      start: 50,
      length: 1,
      format:"currencyCode"
    },
    {
      name: "RSP RFU",
      start: 51,
      length: 11,
    },
    {
      name: "Fare amount",
      start: 62,
      length: 10,
      format:"money"
    },
    {
      name: "Refund amount",
      start: 72,
      length: 10,
      format:"money"
    },
    {
      name: "MoP Code",
      start: 82,
      length: 1,
      format:"mopCode"
    },
    {
      name: "Reason for refund",
      start: 83,
      length: 2,
      format:"refundReason"
    },
    {
      name: "Authorisation reference",
      start: 85,
      length: 8,
    },
    {
      name: "Refund reference",
      start: 93,
      length: 8,
    },
    {
      name: "Sale Transaction number (seq)",
      start: 101,
      length: 5,
    },
    {
      name: "Machine number",
      start: 106,
      length: 4,
    },
    {
      name: "Issuing NLC",
      start: 110,
      length: 4,
    },
    {
      name: "Issuing window",
      start: 114,
      length: 2,
    },
    {
      name: "Issue date",
      start: 116,
      length: 8,
      format:"date"
    },
    {
      name: "Primary transaction number (seq)",
      start: 124,
      length: 5,
    },
    {
      name: "Owning transaction header number (seq)",
      start: 129,
      length: 5,
    },
    {
      name: "Refund time",
      start: 134,
      length: 4,
      format:"time"
    },
    {
      name: "Refund indicator",
      start: 138,
      length: 1,
      format:"refundIndicator"
    },
  ],
  CW: [
    {
      name: "Non-RSP Payment code",
      start: 2,
      length: 6,
      format:"rspPaymentCode"
    },
    {
      name: "Non-RSP Payment type",
      start: 8,
      length: 7,
      format:"rspPaymentType"
    },
    {
      name: "Payment amount",
      start: 15,
      length: 10,
      format:"money"
    },
    {
      name: "Currency",
      start: 25,
      length: 1,
      format:"currencyCode"
    },
    {
      name: "Debit / credit indicator",
      start: 26,
      length: 1,
      format:"debitCreditIndicator"
    },
    {
      name: "Owning transaction header number (seq)",
      start: 27,
      length: 5,
    },
  ],
  DB : [
    {
      name: "Shift start date",
      start: 2,
      length: 8,
      format:"date"
    },
    {
      name: "Shft start time",
      start: 10,
      length: 4,
      format:"time"
    },
    {
      name: "Operating mode",
      start: 14,
      length: 1,
      format:"operatingMode"
    },
    {
      name: "Machine type ID",
      start: 15,
      length: 2,
    },
    {
      name: "Machine number",
      start: 17,
      length: 4,
    },
    {
      name: "Selling NLC",
      start: 21,
      length: 4,
    },
    {
      name: "Shift number",
      start: 25,
      length: 4,
    },
    {
      name: "Window number",
      start: 29,
      length: 2,
    },
    {
      name: "Business group code",
      start: 31,
      length: 3,
    },
    {
      name: "Outlet code",
      start: 34,
      length: 2,
      format:"outletCode"
    },
    {
      name: "User ID",
      start: 36,
      length: 8,
    },
    {
      name: "Total debit amount",
      start: 44,
      length: 12,
      format: "money"
    },
    {
      name: "Total credit amount",
      start: 56,
      length: 12,
      format: "money"
    },
    {
      name: "Primary transaction number (seq)",
      start: 68,
      length: 5,
    },
    {
      name: "Last sundry transaction number",
      start: 73,
      length: 5,
    },
    {
      name: "Next payment transaction number",
      start: 78,
      length: 5,
    },
    {
      name: "Last cross London transaction number",
      start: 83,
      length: 5,
    },
    {
      name: "Last sale transaction number",
      start: 88,
      length: 5,
    },
    {
      name: "Shift identity indicator",
      start: 93,
      length: 1,
      format:"shiftIdentityIndicator"
    },
  ],
  "2C" : [
    {
      name: "Software version",
      start: 2,
      length: 6,
      format: "softwareVersion"
    },
    {
      name: "Software installation date",
      start: 8,
      length: 8,
      format:"2cdate"
    },
    {
      name: "Software installation time",
      start: 16,
      length: 4,
      format:"time"
    },
  ],
  DD : [
    {
      name: "Shift end date",
      start: 2,
      length: 8,
      format:"date"
    },
    {
      name: "Shift end time",
      start: 10,
      length: 4,
      format:"time"
    },
    {
      name: "Total debit amount",
      start: 14,
      length: 12,
      format:"money"
    },
    {
      name: "Total credit amount",
      start: 26,
      length: 12,
      format:"money"
    },
    {
      name: "Currency",
      start: 38,
      length: 1,
      format:"currencyCode"
    },
    {
      name: "Machine type ID",
      start: 39,
      length: 2,
    },
    {
      name: "Machine number",
      start: 41,
      length: 4,
    },
    {
      name: "Selling NLC",
      start: 45,
      length: 4,
    },
    {
      name: "Shift NnumberC",
      start: 49,
      length: 4,
    },
    {
      name: "Window number",
      start: 53,
      length: 2,
    },
    {
      name: "Business Group",
      start: 55,
      length: 3,
    },
    {
      name: "Outlet code",
      start: 58,
      length: 2,
      format: "outletCode"
    },
    {
      name: "Operating mode",
      start: 60,
      length: 1,
      forma: "operatingMode"
    },
    {
      name: "Primary transaction number (seq)",
      start: 61,
      length: 5,
    },
    {
      name: "Last cross London transaction number",
      start: 66,
      length: 5,
    },
    {
      name: "Next transaction number (payment)",
      start: 71,
      length: 5,
    },
    {
      name: "Last sundry transaction number",
      start: 76,
      length: 5,
    },
    {
      name: "Last transaction header number (seq)",
      start: 81,
      length: 5,
    },
    {
      name: "Total ticket debit amount",
      start: 86,
      length: 12,
      format:"money"
    },
    {
      name: "Total ticket credit amount",
      start: 98,
      length: 12,
      format:"money"
    },
    {
      name: "Total sundry debit amount",
      start: 110,
      length: 12,
      format:"money"
    },
    {
      name: "Total sundry credit amount",
      start: 122,
      length: 12,
      format:"money"
    },
    {
      name: "Total payment debit amount",
      start: 134,
      length: 12,
      format:"money"
    },
    {
      name: "Total payment credit amount",
      start: 146,
      length: 12,
      format:"money"
    },
    {
      name: "Refund reversal debit amount",
      start: 158,
      length: 12,
      format:"money"
    },
    {
      name: "Total refunds credit amount",
      start: 170,
      length: 12,
      format:"money"
    },
    {
      name: "Duplicate season ticket count",
      start: 182,
      length: 3,
      format:"int"
    },
    {
      name: "Replacement season ticket count",
      start: 185,
      length: 3,
      format:"int"
    },
    {
      name: "Changeover season ticket count",
      start: 188,
      length: 3,
      format:"int"
    },
    {
      name: "Shft non-issue count",
      start: 191,
      length: 3,
      format:"int"
    },
    {
      name: "Shft zero fare count",
      start: 194,
      length: 3,
      format:"int"
    },
    {
      name: "Fare override count",
      start: 197,
      length: 3,
      format:"int"
    },
    {
      name: "Shift Identity Indicator",
      start: 200,
      length: 1,
      format:"shiftIdentityIndicator"
    },
  ],
  BR : [
    {
      name: "Fare Method",
      start: 2,
      length: 1,
      format:"fareMethod"
    },
    {
      name: "Fare Amount",
      start: 3,
      length: 10,
      format:"money"
    },
    {
      name: "Currency",
      start: 1,
      length: 1,
      format:"currencyCode"
    },
    {
      name: "MOP Code",
      start: 14,
      length: 3,
      format:"mopCode"
    },
    {
      name: "Primary Transaction Number",
      start: 17,
      length: 5,
    },
    {
      name: "Sundry Transaction Number",
      start: 22,
      length: 5,
    },
    {
      name: "Sundry Code",
      start: 27,
      length: 5,
      format : "int"
    },
    {
      name: "Sundry Transaction Number",
      start: 32,
      length: 5,
    },
    {
      name: "Credit / Debit Indicator",
      start: 37,
      length: 1,
      format : "debitCreditIndicator"
    },
    {
      name: "Number of sundry items",
      start: 38,
      length: 4,
      format : "int"
    },
    {
      name: "Non-Issue Time",
      start: 42,
      length: 4,
      format: "time"
    },
    {
      name: "Non-Issue Indicator",
      start: 46,
      length: 1,
      format : "nonIssueIndicator"
    },
  ]
}

export function decodeSDCIRecord(record) 
{
  if (recordTypes[record.recordType])
  {
    return decode(recordTypes[record.recordType],record.recordData)
  }

  else
  {
    return [{name:"Error",data:"Record type not supported"}]
  }
}

const decode = async (template,data) => {
  
  let decoded = [{name:"Record Type",data:data.substr(0,2), rawdata:data.substr(0,2)}]

  for (let t=0;t<template.length;t++)
  {
    let extractedData = data.substr(template[t].start,template[t].length)
    let formattedData = template[t].format ? await formatData(template[t].format,extractedData) : extractedData.trim()

    decoded.push({
      name : template[t].name,
      data : formattedData,
      rawdata : extractedData,
    })
  }

  return decoded
}

const formatData = async (format,data) => {
  
  switch (format)
  {
    case "int": { return parseInt(data,10)}
    case "money" : {return `£${(parseInt(data,10)/100).toFixed(2)}`}
    case "percent" : {return parseInt(data,10) ? `${parseInt(data,10)}%` : ''}
    case "boolean" : { return data === "1" ? 'Yes' : 'No' }
    case "date" : {return moment(data,"DDMMYYYY").format("DD-MM-YYYY")}
    case "2cdate" : {return moment(data,"YYYYMMDD").format("DD-MM-YYYY")}
    case "time" : {return moment(data,"HHmm").format("HH:mm")}
    case "fulfilmentEvent": { return fulfilmentEvents[data]}
    case "fulfilmentMethod": { return fulfilmentMethods[data]}
    case "channelType": { return channelTypes[data]}
    case "currencyCode": { return currencyCodes[data]}
    case "fareMethod" : { return fareMethods[data]}
    case "mopCode" : { return extractMopCodes(data)}
    case "nlc" : { return await getStationNameFromNlc(data)}
    case "route" : { return await getRouteName(data)}
    case "todIndicator": {return todIndicator[data]}
    case "debitCreditIndicator": {return debitCreditIndicator[data]}
    case "refundReason": {return refundReason[data]}
    case "refundIndicator": {return refundIndicator[data]}
    case "rspPaymentCode": {return rspPaymentCode[data]}
    case "rspPaymentType": {return rspPaymentType[data]}
    case "operatingMode" : {return operatingMode[data]}
    case "outletCode" : {return outletCode[data]}
    case "fareClass" : {return fareClass[data]}
    case "shiftIdentityIndicator" : {return shiftIdentityIndicator[data]}
    case "softwareVersion" : {return `${data.substr(0,2)}.${data.substr(2,2)}.${data.substr(4,2)}`}
    case "nonIssueIndicator" : {return nonIssueIndicator[data]}
    
    default: { return `[!! ${format} !!]`}
  }
}

const nonIssueIndicator = {
  A : "Automatic",
  M : "Manual"
}

const shiftIdentityIndicator = {
  "0" : "Current Period (normal shift)",
  "1" : "Last Period (corrections)",
  "3" : "Bulk Shift"
}

const outletCode = {
  "01":"Station outlet (non-EPS)",
  "02":"Travel Agent outlet",
  "03":"Telesales Outlet / Web-TIS",
}

const operatingMode = {
  "0" : "Revenue mode",
  "1" : "Non-Revenue mode"
}

const fareClass = {
  "1" : "First Class",
  "2" : "Standard Class",
  "9" : "Other"
 }

var stationCache = {}
var routeCache = {}

const getStationNameFromNlc = async nlc => {

  if (!stationCache[nlc])
  {
    let station = await getStationData(nlc)
    if (station)
    {
      stationCache[nlc] = station
    }
  }

  return stationCache[nlc].screenName
}

const getRouteName = async routeCode => {
  if (!routeCache[routeCode])
  {
    let route = await getRouteData(routeCode)
    if (route)
    {
      routeCache[routeCode] = route
    }
  }

  return routeCache[routeCode].screenName
}

const getStationData = async nlc => {

  let options = {
    method : 'GET',
    url : `${Config.getConfig('apiUrlDm')}getStation?nlc=${nlc}`, // can be booking ref, ticket ref, reservation ref or aws request ID
    headers : {
      'Content-type': 'application/json',
      'x-api-key': Config.getAPIKey('apiUrlDm'),
    },
  }

  let result = await Axios(options)
  return result.data
}

const getRouteData = async routeCode => {
  let options = {
    method : 'GET',
    url : `${Config.getConfig('apiUrlDm')}getRoute?route=${routeCode}`, // can be booking ref, ticket ref, reservation ref or aws request ID
    headers : {
      'Content-type': 'application/json',
      'x-api-key': Config.getAPIKey('apiUrlDm'),
    },
  }

  let result = await Axios(options)
  return result.data
}

const rspPaymentCode = {
  "100000" : "Non-RSP Payment"
}

const rspPaymentType = {
  "0000001":"Non-RSP payment",
  "0000002":"Business Account settlement ",
  "0000003":"Balancing Payment ",
}


const refundReason = {
  "01":"Season ticket left at home",
  "02":"Waiting for duplicate",
  "03":"Service disruptions & cancellations",
  "04":"No First Class accommodation",
  "05":"No Seat Reservations ",
  "06":"Full Ticket not Required",
  "07":"Part Ticket not Required",
  "08":"Notional Refund",
  "09":"Other",
  "10":"Fulfilment Failure",
  "11":"Same Day Void",
  "12":"TOC guaranteed refunds",
  "13":"Re-book and Refund change process",
//  The values "14" through to "99" are reserved for RSP future use.
}

const refundIndicator = {
  T:"Automatic",
  K:"keyed"
}

const todIndicator = {
  " " : "Non-ToD",
  R: "ToD Sale",
  I: "ToD Issue"
}

const debitCreditIndicator = {
  C:"Credit",
  D:"Debit"
}

const currencyCodes = {
  S:"Sterling",
  E:"Euro",
}

const fulfilmentEvents ={
  F: "Fulfilment Only",
  S: "Sale",
}

const fulfilmentMethods = {
  "001" : "Smartcard Direct",
  "002" : "Smartcard Indirect",
  "003" : "EMV",
  "004" : "Self Print",
  "005" : "Oyster card transactions",
  "006" : "M-ticket",
  "007" : "e-Ticket",
  "008" : "Paper Roll Ticket",
  "009" : "ISRN Unknown",
  "010" : "Plastic Railcard ",
  "011" : "Digital Railcard "
}

const channelTypes = {
  "000" : "Unknown",
  "001" : "Station Booking Office",
  "002" : "TVM",
  "003" : "WebTIS",
  "004" : "WebTIS – Smartphone",
  "005" : "Mobile App",
  "006" : "Kiosk",
  "007" : "Portable TIS",
  "008" : "Telesales ",
}

const fareMethods = {
  A : "Automatic",
  K : "Keyed"
}

const mopCodes = {
  M:"Cash",
  Q:"Cheque",
  W:"Warrant",
  X:"Payment card",
  S:"Smart card",
}


const extractMopCodes = mop => {
    let methods = []

  for (let c in mop)
  {
    if (mopCodes[mop[c]])
    {
      methods.push(mopCodes[mop[c]])
    }
  }

  return methods.join(", ")
}