import React, { Component } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import Checkbox from '@material-ui/core/Checkbox'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import CloseIcon from '@material-ui/icons/Close'

class AttributeSelector extends Component {

  constructor(props) {
    super(props)
    this.state = {seat:this.props.seat}
  }

  componentDidUpdate(prevProps) {
    if (prevProps.seat !== this.props.seat)
    {
      let seat = this.props.seat
      this.setState({seat})  
    }
  }

  getOptions = () => {

    let options = []
    let selectedItem = null

    for (let o in this.props.attributeGroup.items)
    {
      let item = this.props.attributeGroup.items[o]
      let selected = this.props.seat.attributes[this.props.attributeGroup.code].includes(item.code)
      
      options.push({item,selected})

      if (selected)
      {
        selectedItem = item.code
      }
    }

    if (this.props.attributeGroup.noSelectionPermitted)
    {
      options.push({item:{attributeName:"No Selection",code:null},selected:selectedItem ? true : false})
    }

    if (this.props.attributeGroup.multiple)
    {
      return options.map(o => this.getCheckBox(o))
    }
    else
    {
      return this.getRadioButtons(options,selectedItem,this.props.attributeGroup.code)
    }
  }

  getCheckBox = option => (
    <FormControlLabel key={option.item.code} value={option.item.code} label={option.item.attributeName} control={
      <Checkbox
        checked={option.selected}
        value={option.code}
        onChange={this.handleCheckboxChange}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />}/>
  )

  getRadioButtons = (options,selectedItem,groupName) => (
    <RadioGroup value={selectedItem} onChange={this.handleRadioChange}>
      {options.map(o => this.getRadioButton(o))}
    </RadioGroup>
  )

  getRadioButton = option => (
    <FormControlLabel key={option.item.code} value={option.item.code} label={option.item.attributeName} control={<Radio />}  />
  )

  handleRadioChange = event => {
    let groupName = this.props.attributeGroup.code
    let selectedValue = event.target.value

    this.props.saveSeatAttributes(groupName,selectedValue,false,true) // MULTIPLE=false, Always checked!
  }

  handleCheckboxChange = event => {
    let checked = event.target.checked
    let selectedValue = event.target.value
    let groupName = this.props.attributeGroup.code

    this.props.saveSeatAttributes(groupName,selectedValue,true,checked) // MULTIPLE = TRUE
  }

  render() {
    const { open, close, seat, attributeGroup } = this.props

    if (!seat) return ''

    return (
      <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={close}>
        <DialogTitle id="form-dialog-title">Seat: {seat.accomId} : {attributeGroup.name}</DialogTitle>
        <DialogContent>
          {this.getOptions()}
        </DialogContent>

        <DialogActions>
          <IconButton onClick={close} color="primary" size="large">
            <CloseIcon/>
          </IconButton>
        </DialogActions>
      </Dialog>
    )
  }
}

export default AttributeSelector