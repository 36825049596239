export function getStyleDefs(theme) 
{
  return {
    paper: {
      width: 'calc(100%)',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    searchInput: {
      width: 'calc(100% - 100px)',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      backgroundColor:'white',
    },
    center: {
      textAlign : 'center'
    },
    error: {
      color : 'red'
    },
    ok: {
      color : 'green'
    },  
    clickableTableRow: {
      cursor : 'pointer'
    },
    button: {
      margin: theme.spacing(),
    },  
    searchBar : {
      flex: 1,    
    },
    input: {
      marginLeft: theme.spacing(),
    },
    iconButton: {
      padding: 10,
    }, 
    indentedText : {
      marginLeft: '45px' 
    },
    table: {
      minWidth: 700,
      width : '100%',
      tableLayout : 'auto'
    },
    tableCell: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '200px',
      padding : '4px 15px 4px 15px'
    },
    tableCellScroll: {
      whiteSpace: 'nowrap',
      overflow: 'auto',
      textOverflow: 'ellipsis',
      maxWidth: '200px',
      padding : '4px 15px 4px 15px'
    },
    tableCellHeader: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '200px',
      padding : '4px 15px 4px 15px'
    },
    tableCellData: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '200px',
      padding : '4px 15px 4px 15px',
      fontWeight : 'bold'
    },
    tableCellDataCompact: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding : '4px 15px 4px 15px',
      fontWeight : 'bold'
    },
    tableCellDataWide: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '450px',
      padding : '4px 15px 4px 15px',
      fontWeight : 'bold'
    },
    leftIcon: {
      marginRight: theme.spacing(),
    },
    stripeRow: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    }, 
    eTicketPayloadDiv : {
      width : '400px',
      wordWrap : 'break-word',
    }, 
  }
}
