import UIfx from 'uifx'
import cookie from 'react-cookies'

import { w3cwebsocket as W3CWebSocket } from "websocket"

import React, { Component }  from 'react'
import { BrowserRouter,Route, Redirect } from 'react-router-dom'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import getRoutes from './config/routes'
import MainNav from './components/main-nav'
import DynamicComponent from './components/dynamic-component'
import AlertClear from './data-interactions/clear-alert'

import Login from './login'
import Config from './config'

import newInfoSound from './sounds/alert-info.wav'
import newWarningSound from './sounds/alert-warning.wav'
import newErrorSound from './sounds/alert-error.wav'
import newIncomeSound from './sounds/alert-income.wav'

import './App.css'

var playAlertSounds = true
var playSalesSounds = true

const alertSounds = {
  info : new UIfx(
    newInfoSound,
    {
      volume: 1, // number between 0.0 ~ 1.0
    }
  ),
  warning : new UIfx(
    newWarningSound,
    {
      volume: 1, // number between 0.0 ~ 1.0
    }
  ),
  error : new UIfx(
    newErrorSound,
    {
      volume: 1, // number between 0.0 ~ 1.0
    }
  ),
  income : new UIfx(
    newIncomeSound,
    {
      volume: 1, // number between 0.0 ~ 1.0
    }
  ),
}

const theme = createMuiTheme({
  overrides : {
    MuiAppBar : {
      root : {
        background: 'black',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        color: 'white'
      }
    },
    MuiMenu : {
      paper : {
        background: '#333',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        color: 'white',
      },
    },
    MuiMenuItem : {
      root : {
        '&:hover' : {
          background: '#000',
          color: 'yellow',
        }
      },
    },
  }
})

function isLoggedIn() {

  let cg = Config.getConfig("awsConfig")
  return cg ? true : false
}

function checkLoginCookie() {
  try
  {
    let config = cookie.load('config')

    if (config !== undefined)
    {
      let authModes = JSON.parse(config.userData.authModes)

      let adminModes = {
        owner : config.userData.systemOwner,
        system : config.userData.systemAdmin,
        licensee : config.userData.licenseeAdmin,
        organisation : config.userData.organisationAdmin,
      }

      Config.setConfig('awsConfig', config.awsConfig)
      Config.setConfig('keys', config.keys)
      Config.setConfig('userData', config.userData)
      Config.setConfig('authModes',authModes)
      Config.setConfig('adminModes',adminModes)
      Config.setConfig('routes',getRoutes(authModes,adminModes))
    }
  }
  catch(err)
  {
    cookie.remove('config',{expires:0, path: '/'})
    Config.deleteConfig('awsConfig')
    Config.deleteConfig('keys')
    Config.deleteConfig('userData')
    Config.deleteConfig('authModes')
    Config.deleteConfig('adminModes')
    Config.deleteConfig('routes')
  }
}

const salesStats = {
  sales:{today:0,yesterday:0,lastweek:0,month:0,lastmonth:0},
  refunds:{today:0,yesterday:0,lastweek:0,month:0,lastmonth:0},
  reservations:{today:0,yesterday:0,lastweek:0,month:0,lastmonth:0},
  ausi:{today:0,yesterday:0,lastweek:0,month:0,lastmonth:0},
  income:{today:0,yesterday:0,lastweek:0,month:0,lastmonth:0}}

class App extends Component {

  constructor(props) {
    super(props)
    checkLoginCookie()
    this.state = {alerts:[],salesStats:salesStats,isLoggedIn:isLoggedIn(),sleeping:false,sleepTimer:null,searchTerm:""}
  }

  async componentDidMount() {

    if (this.state.isLoggedIn)
    {
      this.createWebSocketConnection()
    // this.setSleepMode(false)
    }
  }

  setLoginState(state) {
    this.setState({isLoggedIn:state,alerts:[]})
    
    if (state)
    {
      this.createWebSocketConnection()
     // this.setSleepMode(false)
    }
  }
  
  createWebSocketConnection = () =>
  {
    playAlertSounds = false // Disable so we do not get sounds on connection
    playSalesSounds = false

    let wsClient = new W3CWebSocket(Config.getConfig("socketUrl"))
    
    this.setState({wsClient})

//    wsClient.onopen = () => {
//      console.log('WebSocket Client Connected')
//    }

    wsClient.onclose = () => {
      this.createWebSocketConnection()
    }

    wsClient.onmessage = (message) => {
      let messageData = JSON.parse(message.data)

      switch(messageData.type)
      {
        case 'alert':
        {
          let alerts = messageData.alerts

          if (alerts.length && playAlertSounds && (!this.state.sleeping || alerts[0].type === "error") && alerts.length)
          {
            if (JSON.stringify(alerts) !== JSON.stringify(this.state.alerts))
            {
              alertSounds[alerts[0].type].play()
            }
          }

          playAlertSounds = true // We always have a refresh after reconnecting to socket. No need to play sound for this.

          this.setState({alerts})  

          break  
        }

        case 'sales':
        {
          let salesStats = messageData.salesStats

          if (playSalesSounds && !this.state.sleeping)
          {
            // Don't alert at midnight when figures go back to zero
            if (salesStats.sales.today || salesStats.refunds.today || salesStats.reservations.today || salesStats.ausi.today)
            {
              // alertSounds.income.play() 
            }
          }

          playSalesSounds = true // We always have a refresh after reconnecting to socket. No need to play sound for this.

          this.setState({salesStats})  

          break
        }
        default: break
      }
    }
  }

  /*
  setSleepMode = (sleeping=false) => {

    this.setState({sleeping})

    if (!sleeping)
    {
      if (this.state.sleepTimer)
      {
        clearTimeout(this.state.sleepTimer)      
      }
      let sleepTimer = setTimeout(this.setSleepMode.bind(this,true),Config.getConfig("wakingPeriod"))
      this.setState({sleepTimer})
    }
  }

  resetSleepTimer = event => {
    this.setSleepMode(false)
  }
*/

  deleteAlert = async alert => {
    let alerts = this.state.alerts
    alerts.splice( alerts.indexOf(alert), 1 )
    this.setState({alerts})

    await AlertClear(alert.ids)
  }

  searchTermChanged = searchTerm => {
    this.setState({searchTerm})
  }

  render() {
    let routes = Config.getConfig('routes')

    // Check that user is allowed to access the route associated with the current URL
    // If not, redirect them to their default route.
    let routeFound = false  
    routes.forEach(r => {
      let urlRoot = r.url === '/' ? '/' : r.url.split("/")[0]
      let pathRoot = window.location.pathname === '/' ? '/' : window.location.pathname.split("/")[0]
      
      if (urlRoot === pathRoot)
      {
        routeFound = true
      }
    })

    if (routes.length && !routeFound)
    {
      window.location.href = routes[0].url.replace(/:stage/,Config.getConfig('mode'))
    } 

    // Force login
    if (!this.state.isLoggedIn && window.location.pathname !== '/login')
    {
      return (
      <BrowserRouter>
        <Redirect to="/login"/>
        <Route exact path="/login">
          <MainNav routes={[]}  activeRoute={{name:"Login"}} alertCount={0}/>
          <Login setLoginState={event => this.setLoginState(event)}/>
        </Route>
      </BrowserRouter>)
    }

    return (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <div  onClick={this.resetSleepTimer}>
            <Route exact path="/login">
              <MainNav routes={[]}  activeRoute={{name:"Login"}} alertCount={0} />
              <Login setLoginState={event => this.setLoginState(event)}/>
            </Route>

            {routes.map(route => (
              <Route exact={route.exact} key={route.url} path={route.url} component={route.src}>
                <MainNav routes={routes} activeRoute={route} alertCount={this.state.alerts.length} salesStats={this.state.salesStats} sleeping={this.state.sleeping} searchTermChanged={this.searchTermChanged.bind(this)} setSleepMode={this.setSleepMode}/>
                <div className="App">
                  <DynamicComponent component={route.component} route={route} alerts={this.state.alerts} salesStats={this.state.salesStats} deleteAlert={this.deleteAlert} sleeping={this.state.sleeping} searchTerm={this.state.searchTerm}/>
                </div>
              </Route>
            ))}
          </div>
        </ThemeProvider>
      </BrowserRouter>
    )
  }
}

export default App