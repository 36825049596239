import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { NavLink,withRouter } from 'react-router-dom'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise'

import GridConfig from './grid-config'
import Utils from '../utils'

import { getLicensees, getUsersAndKeys, getUsagePlansAndKeys } from '../data-providers/users'

import NewUserForm from './new-user-form'
import NewApiKeyForm from './new-api-key-form'
import EditUserForm from './edit-user-form'

const styles = theme => ({
  paper: {
    width: 'calc(100% - 60)',
    marginTop: theme.spacing() * 3,
    overflowX: 'auto',
    position : 'fixed',
    top : 120,
    bottom : 20,
    left : 20,
    right : 20,
  },
  button: {
    margin: theme.spacing(),
  },  
})


class UserAdmin extends Component {

  constructor(props, context) {
    super(props, context)  
    this.state = {licenseeCode:null,licensees:[],activeLicensee:{},users:[],filteredUsers:[],showAddUserForm:false,showAddApiKeyForm:false,selectedNode:{},showEditUserForm:false}
  }

  componentDidMount() {
    let {licenseeCode} = this.props.match.params
    this.setState({licenseeCode},this.initData)
  }

  componentDidUpdate(prevProps) {
    let {licenseeCode} = this.props.match.params

    if(prevProps.match.params.licenseeCode !== licenseeCode)
    {
      this.setState({licenseeCode,licensees:[]},this.initData)
    }

    if(prevProps.searchTerm !== this.props.searchTerm)
    {
      this.filterUsers(this.props.searchTerm)
    }
  }

  initData = async () => {
    let promises = []

    promises.push(getLicensees())
    promises.push(getUsagePlansAndKeys())

    let [licensees,usagePlansAndKeys] = await Promise.all(promises)
    
    this.setState({licensees,usagePlansAndKeys})

    for (let l of licensees)
    {
      if (l.id === this.state.licenseeCode)
      {
        this.setState({activeLicensee:l},this.getUsersAndkeys)
        break
      }
    }
  }

  addApiKey = node => {

    let selectedNode = {
      userId : node.data.userId,
      organisationId : node.data.organisationId,
      organisation : node.data.organisation,
      username : node.data.username,
      keys : node.data.apikeys,
    }

    this.setState({selectedNode,showAddApiKeyForm:true})
  }

  editUser = node => {
    this.setState({selectedNode:node.data,showEditUserForm:true})
  }
  
  getUsersAndkeys = async (orgId,userId) => {

    if(this.gridApi)
    {
      let usagePlansAndKeys = await getUsagePlansAndKeys()

      this.setState({usagePlansAndKeys})

      let users = await getUsersAndKeys(this.state.activeLicensee.id)

      users.forEach(u => {
        if (u.apikeys)
        {
          u.apikeys.forEach(a => {
            a.plans = this.getUsagePlansForKey(a.keyId)
          })
        }
        else
        {
          u.apikeys = []
        }
      })

      let selectedNode = {orgId,userId}

      this.setState({users,filteredUsers:users,selectedNode})
    }
    else
    {
      setTimeout(this.getUsersAndkeys,500)
    }
  }

  onRowDataChanged = async () => {

    await Utils.sleep(100)

    if (this.state.selectedNode.orgId)
    {      
      this.gridApi.forEachNode(n => {

        if (n.data)
        {
          if (n.data.userId === this.state.selectedNode.userId)
          {
            n.expanded = true
          }
        }
        else
        {
          if (n.allLeafChildren)
          {
            n.allLeafChildren.forEach(c => {

              if (c.data.organisationId === this.state.selectedNode.orgId && (!this.state.selectedNode.userId || c.data.userId === this.state.selectedNode.userId))
              {
                n.expanded = true
                c.expanded = true
              }
            })
          }
        }
      })
    }

    this.gridApi.onGroupExpandedOrCollapsed() 
    this.onRowGroupOpened()
  }

  getUsagePlansForKey = key => {
    let plans = []

    for (let p of this.state.usagePlansAndKeys)
    {
      if (p.keys.includes(key))
      {
        plans.push(p.name)
      }
    }
    return plans
  }

  onGridReady = params => {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi
  }
   
  onRowGroupOpened = () => {
    this.gridApi.forEachNode(n => {

      n.refreshGrid = this.refreshGrid
      n.addApiKey = this.addApiKey 
      n.editUser = this.editUser 

      for (let d in n.gridApi.detailGridInfoMap)
      {
        if (n.gridApi.detailGridInfoMap[d] && n.gridApi.detailGridInfoMap[d].api)
        {
          n.gridApi.detailGridInfoMap[d].api.forEachNode(l => {
            l.refreshGrid = this.refreshGrid
          })
        }
      }
    })
  }

  refreshGrid = (orgId,userId) => {
    this.getUsersAndkeys(orgId,userId)
  }

  addOrganisation = async () => {
    console.log("addOrganisation")
  }
   
  openNewUserForm = async () => {
    this.setState({showAddUserForm:true})
  } 

  closeNewUserForm = () => {
    this.setState({showAddUserForm:false})
  }

  closeNewApiKeyForm = () => {
    this.setState({showAddApiKeyForm:false})
  }

  closeEditUserForm = () => {
    this.setState({showEditUserForm:false})
  }

  filterUsers = searchTerm => {

    if (searchTerm)
    {
      let regExpUser = new RegExp(searchTerm)
      let regExpKey = new RegExp("$" + searchTerm + "^")
  
      let filteredUsers = this.state.users.filter(u => {
        return regExpUser.test(u.username) || u.apikeys.filter(k => regExpKey.test(k.key)).length > 0
      })
  
      this.setState({filteredUsers},() => {
        this.gridApi.forEachNode(node => {
          node.expanded = true
        })
    
        this.gridApi.onGroupExpandedOrCollapsed()  
      })
    }
    else
    {
      this.setState({filteredUsers:this.state.users},() => {
        this.gridApi.forEachNode(node => {
          node.expanded = false
        })
    
        this.gridApi.onGroupExpandedOrCollapsed()  
      })
    }
  }

  render() {

    const { classes } = this.props

    return (
      <React.Fragment>

        <div className="stageMenu">
          <Button onClick={this.openNewUserForm}>Add User</Button>
        </div>

        <div>{this.state.licensees.map(l => <Button component={NavLink} key={l.id} to={'/user-admin/' + l.id} activeClassName="active">{l.name}</Button>)}</div>

        <Paper className={classes.paper}>
          <div className="ag-theme-alpine" style={ {height: '100%', width: '100%'} }>
            <AgGridReact
              onGridReady={this.onGridReady}
              onRowGroupOpened={this.onRowGroupOpened}
              rowBuffer={GridConfig.visibleRowBufferSize*2}
              rowData={this.state.filteredUsers}
              onRowDataChanged={this.onRowDataChanged}
              columnDefs={GridConfig.columnDefs}
              animateRows={true}
              defaultColDef={{filterParams:{ newRowsAction:'keep'}}}
              sideBar={{
                toolPanels : ['filters'],
                closedByDefault : true,
              }}
              masterDetail={true}
              autoGroupColumnDef = {{
                headerName: "Organisation",
                headerClass : "dateHeader",
                width : 300,
                cellRendererParams: {
                  suppressCount: false
                },
              }}
              groupUseEntireRow={true}
              detailRowHeight={195}
              detailCellRendererParams={GridConfig.detailCellRendererParams}
              frameworkComponents= {GridConfig.frameworkComponents}
              >
            </AgGridReact>
          </div>
        </Paper>
        
        <NewUserForm open={this.state.showAddUserForm} close={this.closeNewUserForm} gridApi={this.gridApi} refreshGrid={this.refreshGrid}/>
        <NewApiKeyForm open={this.state.showAddApiKeyForm} close={this.closeNewApiKeyForm} gridApi={this.gridApi} node={this.state.selectedNode} refreshGrid={this.refreshGrid}/>
        <EditUserForm open={this.state.showEditUserForm} close={this.closeEditUserForm} gridApi={this.gridApi} node={this.state.selectedNode} refreshGrid={this.refreshGrid}/>
        
      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(UserAdmin))