import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter, NavLink } from 'react-router-dom'
import Moment from 'moment'

import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'

import SalesPopup from './user-breakdown'

import classNames from 'classnames'

import { getIncome } from '../data-providers/income'

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin : 'auto',
    width : 'calc(100% + 48px)'
  },
  h1 : {
    textAlign : 'center',
    color : '#A7CADE',
  },
  chart : {
    backgroundColor : 'white',
    borderRadius : '5px',
  },
  paper: {
    width: 'calc(100%)',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    backgroundColor : '#fff',
  },
  table: {
    minWidth: 700,
    width : '100%',
    tableLayout : 'auto'
  },
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    padding : '4px 5px 4px 5px'
  },
  errored : {
    color : 'red'
  },
  clickableTableRow: {
    cursor : 'pointer'
  },
  button: {
    margin: theme.spacing(),
  },  
  stripeRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  }, 
  bold : {
    fontWeight : "bold"
  },
  better : {
    color : "green"
  },
  worse : {
    color : "red"
  },
  right : {
    textAlign : "right"
  }
})
class Income extends Component {

  constructor(props, context) {
    super(props, context)  
    this.state = {thisMonthIncome:[],thisMonthIncomeOriginal:[],prevMonthsIncome:[], prevYearsIncome:[],selectedMonth:null,modalOpen:false}
  }

  componentDidMount() {
    setTimeout(this.initData,1)
  }

  componentDidUpdate(prevProps) {

    let salesStats = this.props.salesStats

    if(prevProps.salesStats.income.today !== salesStats.income.today)
    {
      // Today sales data has updated so we need to update our display
      this.addTodaySalesStatsToThisMonthData()
    }
  }

  addTodaySalesStatsToThisMonthData() {

    console.log("addTodaySalesStatsToThisMonthData")

    let salesStats = this.props.salesStats

    if (this.state.thisMonthIncomeOriginal.length && salesStats.sales.today)
    {
      let thisMonthIncome = [{
        ts:new Date(),
        
        numSales : salesStats.sales.today,
        numRefunds : salesStats.refunds.today,
        numAusi : salesStats.ausi.today,
        numReservations : salesStats.reservations.today,

        salesIncome : salesStats.income.today_sales,
        refundIncome : salesStats.income.today_refunds,
        ausiIncome : salesStats.income.today_ausi,
        reservationIncome : salesStats.income.today_reservations,

        totalIncome : salesStats.income.today
      }].concat(this.state.thisMonthIncomeOriginal)

      this.setState({thisMonthIncome})
    }
    else
    {
      console.log("Not got any sales data yet so wait...")
    }
  }
  
  initData = async () => {
    console.log("get this month data start")
    let thisMonthIncome = await getIncome("getSalesRefundsAndIncomeForRecentMonths",{from:Moment().startOf("month").format("YYYY-MM-DD"),to:Moment().endOf("month").format("YYYY-MM-DD")})
    this.setState({thisMonthIncome,thisMonthIncomeOriginal:thisMonthIncome},this.addTodaySalesStatsToThisMonthData)
    console.log("get this month data end")

    console.log("get previous months data start")
    let prevMonthsIncome = await getIncome("getSalesRefundsAndIncomeForPeriodGroupedByMonth",{from:Moment().subtract(12,'months').startOf("year").format("YYYY-MM-DD"),to:Moment().startOf("month").subtract(1,'day').format("YYYY-MM-DD")})
    console.log("get previous months data end")
    this.setState({prevMonthsIncome})

    console.log("get yearly data start")
    let prevYearsIncome = await getIncome("getSalesRefundsAndIncomeForPeriodGroupedByYear",{})
    console.log("get yearly data end")
    this.setState({prevYearsIncome})
  }

  openMonthBreakdown = date => {
    console.log("openMonthBreakdown")
    this.setState({modalOpen:true,selectedMonth:date})
  }

  handleCloseModal = () => {
    this.setState({modalOpen:false,selectedMonth:null})
  }

  render() {
    const { classes } = this.props
    const { thisMonthIncome , prevMonthsIncome, prevYearsIncome} = this.state

    let thisMonthTotals = {numSales:0,numRefunds:0,numAusi:0,numReservations:0,salesIncome:0,refundIncome:0,ausiIncome:0,reservationIncome:0,totalIncome:0}
    let thisYearTotals = {numSales:0,numRefunds:0,numAusi:0,numReservations:0,salesIncome:0,refundIncome:0,ausiIncome:0,reservationIncome:0,totalIncome:0}
    let prevYearTotals = {numSales:0,numRefunds:0,numAusi:0,numReservations:0,salesIncome:0,refundIncome:0,ausiIncome:0,reservationIncome:0,totalIncome:0}

    return (
      <React.Fragment>

        <div className="stageMenu">
          <Button component={NavLink} to="/sales" activeClassName="active">Sales</Button>
          <Button component={NavLink} to="/refunds" activeClassName="active">Refunds</Button>
          <Button component={NavLink} to="/reservations" activeClassName="active">Reservations</Button>
          <Button component={NavLink} to="/ausi" activeClassName="active">AUSI</Button>
          <Button component={NavLink} to="/income" activeClassName="active">Income</Button>
        </div>

        <Table className={classes.paper}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>Date</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>Sales</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>Refunds</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>Reservations</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>AUSI</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>Sales</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>Refunds</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>Reservations</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>AUSI</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>Total Income</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {thisMonthIncome.map(t => {

              thisMonthTotals.numSales += t.numSales
              thisMonthTotals.numRefunds += t.numRefunds
              thisMonthTotals.numReservations += t.numReservations
              thisMonthTotals.numAusi += t.numAusi
              thisMonthTotals.salesIncome += t.salesIncome
              thisMonthTotals.refundIncome += t.refundIncome
              thisMonthTotals.reservationIncome += t.reservationIncome
              thisMonthTotals.ausiIncome += t.ausiIncome
              thisMonthTotals.totalIncome += t.totalIncome

              return (
              <TableRow className={classNames(classes.stripeRow)} key={t.ts}>
                <TableCell className={classNames(classes.tableCell,classes.bold)}>{Moment(t.ts).format("ddd DD MMM YYYY")}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>{t.numSales}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>{t.numRefunds}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>{t.numReservations}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>{t.numAusi}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>£{(t.salesIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>£{(t.refundIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>£{(t.reservationIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>£{(t.ausiIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>£{(t.totalIncome/100).toFixed(2)}</TableCell>
              </TableRow>
            )})}
            </TableBody>
            
            <TableFooter>
              <TableRow className={classNames(classes.stripeRow)}>
                <TableCell className={classNames(classes.tableCell,classes.bold)}>Total</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>{thisMonthTotals.numSales}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>{thisMonthTotals.numRefunds}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>{thisMonthTotals.numReservations}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>{thisMonthTotals.numAusi}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>£{(thisMonthTotals.salesIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>£{(thisMonthTotals.refundIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>£{(thisMonthTotals.reservationIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>£{(thisMonthTotals.ausiIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>£{(thisMonthTotals.totalIncome/100).toFixed(2)}</TableCell>
              </TableRow>
            </TableFooter>
        </Table>

        <Table className={classes.paper}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>Month</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>Sales</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>Refunds</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>Reservations</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>AUSI</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>Sales</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>Refund</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>Reservation</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>AUSI</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>Total Income</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {prevMonthsIncome.map(t => {

              thisYearTotals.numSales += t.numSales
              thisYearTotals.numRefunds += t.numRefunds
              thisYearTotals.numAusi += t.numAusi
              thisYearTotals.numReservations += t.numReservations
              thisYearTotals.salesIncome += t.salesIncome
              thisYearTotals.refundIncome += t.refundIncome
              thisYearTotals.ausiIncome += t.ausiIncome
              thisYearTotals.reservationIncome += t.reservationIncome
              thisYearTotals.totalIncome += t.totalIncome

              return (
              <TableRow className={classNames(classes.clickableTableRow,classes.stripeRow)} key={t.ts} onDoubleClick={event => this.openMonthBreakdown(t.ts)}>
                <TableCell className={classNames(classes.tableCell,classes.bold)}>{Moment(t.ts).format("MMM YYYY")}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>{t.numSales}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>{t.numRefunds}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>{t.numReservations}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>{t.numAusi}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>£{(t.salesIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>£{(t.refundIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>£{(t.reservationIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>£{(t.ausiIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>£{(t.totalIncome/100).toFixed(2)}</TableCell>
              </TableRow>
            )})}
            </TableBody>
            
            <TableFooter>
              <TableRow className={classNames(classes.stripeRow)}>
                <TableCell className={classNames(classes.tableCell,classes.bold)}>Total</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>{thisYearTotals.numSales}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>{thisYearTotals.numRefunds}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>{thisYearTotals.numReservations}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>{thisYearTotals.numAusi}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>£{(thisYearTotals.salesIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>£{(thisYearTotals.refundIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>£{(thisYearTotals.reservationIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>£{(thisYearTotals.ausiIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>£{(thisYearTotals.totalIncome/100).toFixed(2)}</TableCell>
              </TableRow>
            </TableFooter>
        </Table>

        <Table className={classes.paper}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>Year</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>Sales</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>Refunds</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>Reservations</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>AUSI</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>Sales</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>Refunds</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>Reservations</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>AUSI</TableCell>
              <TableCell className={classNames(classes.tableCell,classes.right)}>Total Income</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {prevYearsIncome.map(t => {

              prevYearTotals.numSales += t.numSales
              prevYearTotals.numRefunds += t.numRefunds
              prevYearTotals.numReservations += t.numReservations
              prevYearTotals.numAusi += t.numAusi
              prevYearTotals.salesIncome += t.salesIncome
              prevYearTotals.reservationIncome += t.reservationIncome
              prevYearTotals.ausiIncome += t.ausiIncome
              prevYearTotals.refundIncome += t.refundIncome
              prevYearTotals.totalIncome += t.totalIncome

              return (
              <TableRow className={classNames(classes.stripeRow)} key={t.ts}>
                <TableCell className={classNames(classes.tableCell,classes.bold)}>{Moment(t.ts).format("YYYY")}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>{t.numSales}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>{t.numRefunds}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>{t.numReservations}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>{t.numAusi}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>£{(t.salesIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>£{(t.refundIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>£{(t.reservationIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>£{(t.ausiIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right)}>£{(t.totalIncome/100).toFixed(2)}</TableCell>
              </TableRow>
            )})}
            </TableBody>
            
            <TableFooter>
              <TableRow className={classNames(classes.stripeRow)}>
                <TableCell className={classNames(classes.tableCell,classes.bold)}>Total</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>{prevYearTotals.numSales}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>{prevYearTotals.numRefunds}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>{prevYearTotals.numReservations}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>{prevYearTotals.numAusi}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>£{(prevYearTotals.salesIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>£{(prevYearTotals.refundIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>£{(prevYearTotals.reservationIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>£{(prevYearTotals.ausiIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>£{(prevYearTotals.totalIncome/100).toFixed(2)}</TableCell>
              </TableRow>
            </TableFooter>
        </Table>

        {this.state.selectedMonth && 
          <SalesPopup modalOpen={this.state.modalOpen} selectedMonth={this.state.selectedMonth} handleCloseModal={this.handleCloseModal} classes={classes}/>
        }

      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(Income))