import Dashboard from '../dashboard/index'

import Alerts from '../alerts/index'

import APIUsage from '../api-usage/overview'
import APIUsageLicensees from '../api-usage/licensees'
import APIUsageOrganisations from '../api-usage/organisations'
import APIUsageUsers from '../api-usage/users'

import Bookings from '../bookings/index'

import Coaches from '../coaches/list'
import CoachDetail from '../coaches/coach'

import RarsCoaches from '../rars-coaches/list'

import DataManagerPublished from '../data-manager/published'
import DataManagerProcessed from '../data-manager/processed'
import DataManagerIncoming from '../data-manager/incoming'
import DataManagerPublishedDetail from '../data-manager/published-detail'
import DataManagerProcessedDetail from '../data-manager/processed-detail'
import DataManagerIncomingDetail from '../data-manager/incoming-detail'

import Errors from '../errors/index'
import ErrorDetail from '../errors/detail'
import ErrorInstance from '../errors/instance'

import ExternalService from '../nrs-lsm-tvd/overview'

import Income from '../income/index'
import Logout from '../logout'
import Refunds from '../sales-stats-cards/index'

import S3Stats from '../s3/index'
import Sales from '../sales-stats-cards/index'
import Reservations from '../sales-stats-cards/index'
import Ausi from '../sales-stats-cards/index'

import Settlement from '../settlement/list'
import SettlementDetail from '../settlement/detail'

import UserAdmin from '../user-admin/index'
import IPAdmin from '../ip-admin/index'
import Database from '../database/index'

import Logs from '../logs/index'

import Config from '../config'

let latestVersion = Config.getConfig("latestVersion")

const authModeRoutes = {
  dashboard : [
    {
      name : "Dashboard",
      component : Dashboard,
      url : "/",
      search : false,
      menu : true,
      exact : true,
    }
  ],
  db : [
    {
      name : "Manage Database",
      component : Database,
      url : "/database",
      search : false,
      menu : true,
      exact : true,
    }
  ],
  income : [
    {
      name : "Income Stats",
      component : Income,
      url : "/income",
      menu : true,
      exact : true,
      search : false,
      ownerOnly : true,
    }
  ],
  sales : [
    {
      name : "Sales Stats",
      component : Sales,
      url : "/sales",
      menu : true,
      exact : true,
      search : false,
    },
    {
      name : "Refunds Stats",
      component : Refunds,
      url : "/refunds",
      menu : true,
      exact : true,
      search : false,
    },
    {
      name : "Reservations Stats",
      component : Reservations,
      url : "/reservations",
      menu : true,
      exact : true,
      search : false,
    },
    {
      name : "AUSI Stats",
      component : Ausi,
      url : "/ausi",
      menu : true,
      exact : true,
      search : false,
    }
  ],
  logs : [
    {
      name : "Booking Finder",
      component : Bookings,
      url : "/bookings/:stage",
      link : "/bookings/live",
      search : true,
      menu : true,
      exact : true,
      searchTip : "Booking reference, AWS Request ID or Reservation reference"
    },
    {
      name : "API Call Logs",
      component : Logs,
      url : "/logs/:stage",
      link : "/logs/live",
      search : true,
      menu : true,
      exact : true,
      searchTip : "Booking Ref / Ticket Ref / Reservation Ref or AWS Request/Gateway ID"
    },
  ],
  errors : [
    {
      name : "Error Log",
      component : Errors,
      url : "/errors/:stage",
      link : "/errors/"+latestVersion,
      search : true,
      menu : true,
      exact : true,
      searchTip : "Error Text"
    },
    {
      name : "Error Log - Detail",
      component : ErrorDetail,
      url : "/errors/:stage/:uniqueErrorId",
      search : true,
      menu : false,
      exact : true,
      searchTip : "Error Text"
    },
    {
      name : "Error Log - Instance",
      component : ErrorInstance,
      url : "/errors/:stage/:uniqueErrorId/:errorId",
      search : true,
      menu : false,
      exact : true,
      searchTip : "Error Text"
    },
  ],
  alerts:[
    {
      name : "Alerts",
      component : Alerts,
      url : "/alerts/",
      search : true,
      menu : true,
      exact : true,
      searchTip : "Alert text"
    },
  ],
  services : [
    {
      name : "External Service Logs",
      component : ExternalService,
      url : "/nrs-lsm-tvd/:mode",
      link : "/nrs-lsm-tvd/live",
      menu : true,
      exact : true,
      search : false,
    },
  ],
  stats: [
    {
      name : "API Usage Stats",
      component : APIUsage,
      url : "/api-usage/:stage",
      link : "/api-usage/live",
      menu : true,
      exact : true,
      search : false,
    },
      {
        name : "API Usage Stats - Licensees",
        component : APIUsageLicensees,
        url : "/api-usage/:stage/:licenseeId/:licensee",
        menu : false,
        exact : true,
        search : false,
      },
      {
        name : "API Usage Stats - Organisation",
        component : APIUsageOrganisations,
        url : "/api-usage/:stage/:licenseeId/:licensee/:organisationId/:organisation",
        menu : false,
        exact : true,
        search : false,
      },
      {
        name : "API Usage Stats - Users",
        component : APIUsageUsers,
        url : "/api-usage/:stage/:licenseeId/:licensee/:organisationId/:organisation/:userId/:user",
        menu : false,
        exact : true,
        search : false,
      },
  ],
  dm : [
    {
      name : "Data Manager",
      component : DataManagerPublished,
      url : "/data-manager/published",
      menu : true,
      exact : true,
      search : false,
    },
    {
      name : "Data Manager - Published Detail",
      component : DataManagerPublishedDetail,
      url : "/data-manager/published/:key",
      menu : false,
      exact : true,
      search : false,
    }, 
    {
      name : "Data Manager - Processed",
      component : DataManagerProcessed,
      url : "/data-manager/processed",
      menu : false,
      exact : true,
      search : false,
    }, 
    {
      name : "Data Manager - Processed Detail",
      component : DataManagerProcessedDetail,
      url : "/data-manager/processed/:tbl",
      menu : false,
      exact : true,
      search : false,
    }, 
    {
      name : "Data Manager - Incoming",
      component : DataManagerIncoming,
      url : "/data-manager/incoming",
      menu : false,
      exact : true,
      search : false,
    }, 
    {
      name : "Data Manager - Incoming Detail",
      component : DataManagerIncomingDetail,
      url : "/data-manager/incoming/:source/:type",
      menu : false,
      exact : true,
      search : false,
    },  
    {
      name : "S3 Stats",
      component : S3Stats,
      url : "/s3-stats",
      menu : true,
      exact : true,
      search : false,
    },
  ],
  carriages : [
    {
      name : "RARS Coaches",
      component : RarsCoaches,
      url : "/rars-coaches",
      search : false,
      menu : true,
      exact : true,
    },
    {
      name : "Train Carriages",
      component : Coaches,
      url : "/train-carriages",
      search : true,
      menu : true,
      exact : true,
      searchTip : "Coach ID"
    },
    {
      name : "Train Carriages - Detail",
      component : CoachDetail,
      url : "/train-carriages/:toc/:typeId/:direction",
      search : true,
      menu : false,
      exact : true,
      searchTip : "Coach ID"
    }
  ],
  settlement : [
    {
      name : "Settlement",
      component : Settlement,
      url : "/settlement/:stage",
      link : "/settlement/lennon",
      search : false,
      menu : true,
      exact : true,
    },
      {
        name : "Settlement",
        component : SettlementDetail,
        url : "/settlement/:stage/:licensee/:machineId",
        search : true,
        false : true,
        exact : true,
        searchTip : "Booking Reference"
      },
  ],
  users : [
    {
      name : "User Admin",
      component : UserAdmin,
      url : "/user-admin/:licenseeCode",
      link : "/user-admin/RE1",
      search : true,
      menu : true,
      exact : true,
      searchTip : "API key or username"
    },
  ],
  ip : [
    {
      name : "IP Admin",
      component : IPAdmin,
      url : "/ip-admin",
      search : false,
      menu : true,
      exact : true,
    },
  ],
  carriageEditor : [],
}

const getRoutes = (authModes,adminModes) => {

  let routes = []
   
  authModes.forEach(auth => {
    routes = routes.concat(authModeRoutes[auth])
  })

  // Everyone can logout!
  routes.push(
    {
      name : "Logout",
      component : Logout,
      url : "/logout",
      search : false,
      exact : true,
      menu : true,
    }
  )
  return routes
}

export default getRoutes