import AWS from 'aws-sdk'
import _ from 'lodash'

import React, { PureComponent } from 'react'
import { withRouter, NavLink, Link } from 'react-router-dom'
 
import Axios from 'axios'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import AddIcon from '@material-ui/icons/Add'
import CopyIcon from '@material-ui/icons/Assignment'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Config from '../config'
import Utils from '../utils'
import awsLogo from '../images/aws.svg'

var CWL 

const styles = theme => ({
  paper: {
  width: 'calc(100%)',
  marginTop: theme.spacing(3),
  overflowX: 'auto',
  },
  table: {
  minWidth: 700,
  width : '100%',
  tableLayout : 'auto'
  },
  tableCell: {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '200px',
  padding : '4px 15px 4px 15px'
  },
  clickableTableRow: {
  cursor : 'pointer'
  },
  button: {
  margin: theme.spacing(),
  },  
  stripeRow: {
  '&:nth-of-type(odd)': {
  backgroundColor: theme.palette.background.default,
  },
  }, 
})

class ErrorInstance extends PureComponent {

    constructor(props, context) {
      super(props, context)
      this.state = {uniqueErrorId:"",errorId:"",error:{logGroupName:"",uniqueErrorId:"",stage:""},logs:[],comments:[],commentDialogueOpen:false}
      CWL = new AWS.CloudWatchLogs(Config.getConfig("awsConfig"))
    }
  
    componentDidMount() {
      let {stage,uniqueErrorId,errorId} = this.props.match.params
      this.setState({stage,uniqueErrorId,errorId},this.init)
    }
  
    componentDidUpdate(prevProps) {
      let {stage} = this.props.match.params
  
      if(prevProps.stage !== this.props.stage)
      {
        this.setState({uniqueErrors:{data:[]}},() => {
          this.setState({stage},this.init)
        })
      }
    }

    async init() {
      await this.getError()
  
  
      await this.getLogs()
      await this.getComments()
    }
  
    async getError() {
  
      try
      {
        let options = {
          method : 'POST',
          url : `${Config.getConfig('adminApi')}request`,
          data : {
            pkg : "Errors",
            fn : "getError",
            params : {
              errorId : this.state.errorId
            }
          },
          headers : {
            'Content-type': 'application/json',
            'x-api-key': Config.getAPIKey(),
          },
        }
  
        let result = await Axios(options)
  
        let error = result.data.data[0]
        this.setState({error})
      }
      catch(err)
      {
          console.error(err)
          throw(err)
      }
    }
  
    async getComments() {
  
      try
      {
        let options = {
          method : 'POST',
          url : `${Config.getConfig('adminApi')}request`,
          data : {
            pkg : "Errors",
            fn : "getComments",
            params : {
              uniqueErrorId : this.state.uniqueErrorId
            }
          },
          headers : {
            'Content-type': 'application/json',
            'x-api-key': Config.getAPIKey(),
          },
        }
  
        let result = await Axios(options)
  
        this.setState({comments:result.data.data})
      }
      catch(err)
      {
        console.error(err)
        throw(err)
      }  
    }
  
    async getLogs() {
  
      try
      {
        let params = {
          logGroupName : this.state.error.logGroupName,
          logStreamNames : [this.state.error.logStreamName],
          interleaved : true,
          filterPattern : `"${this.state.error.awsRequestId}"`
        }
  
        let logs = (await CWL.filterLogEvents(params).promise()).events
  
        this.setState({logs})
      }
      catch(err)
      {
        console.error(err)
      }
    }
  
  
    enterComment() {
      this.setState({commentDialogueOpen:true})
    }
  
    closeCommentDialogue = () => {
      this.setState({commentDialogueOpen:false})
    }
  
    saveComment = async () => {
      try
      {
        let comment = this.commentFieldRef.value
        this.setState({commentDialogueOpen:false})
  
        let options = {
          method : 'POST',
          url : `${Config.getConfig('adminApi')}request`,
          data : {
            pkg : "Errors",
            fn : "putComment",
            params : {
              uniqueErrorId : this.state.uniqueErrorId,
              comment:comment
            }
          },
          headers : {
            'Content-type': 'application/json',
            'x-api-key': Config.getAPIKey(),
          },
        }
  
        let result = await Axios(options)
  
        this.setState({comments:result.data.data})
      }
      catch(err)
      {
        throw(err)
      }
    }
  
    backToList(event) {
      this.props.history.goBack()
      event.stopPropagation()
    }

  render() {

    const { classes } = this.props

    return (
      <React.Fragment>

        <div className="stageMenu">
          <Button component={NavLink} to={`../../${this.state.error.stage}`}>Back to {this.state.error.stage} stage error list</Button>
          <Button component={NavLink} to={`../${this.state.error.uniqueErrorId}`}>Back to error detail</Button>
        </div>

        <Typography variant="subtitle1" gutterBottom style={{clear:'both'}}>Error instance detail</Typography>
         
        <Paper className={classes.paper}>
          <Table className={classes.table}>

            <TableBody>
              <TableRow>
                <TableCell>Error Message</TableCell>
                <TableCell>{this.state.error.message}</TableCell>               
              </TableRow>
              <TableRow>
                <TableCell>Unique Error Id</TableCell>
                <TableCell><Button component={Link} to={`../${this.state.error.uniqueErrorId}`}>{this.state.error.uniqueErrorId}</Button></TableCell>              
              </TableRow>
              <TableRow>
                <TableCell>Application</TableCell>
                <TableCell>{this.state.error.application}</TableCell>              
              </TableRow>
              <TableRow>
                <TableCell>Stage</TableCell>
                <TableCell><Button component={Link} to={`../../${this.state.error.stage}`}>{this.state.error.stage}</Button></TableCell>             
              </TableRow>
              <TableRow>
                <TableCell>Version</TableCell>
                <TableCell>{this.state.error.version}</TableCell>               
              </TableRow>
              <TableRow>
                <TableCell>Lambda Function</TableCell>
                <TableCell><Button href={`https://eu-west-1.console.aws.amazon.com/lambda/home?region=eu-west-1#/functions/${_.split(this.state.error.logGroupName,'/')[3]}?tab=graph`} target="_aws"><img src={awsLogo} alt="link to AWS" className={classes.leftIcon}/> {_.split(this.state.error.logGroupName,'/')[3]}</Button> <Button onClick={event => Utils.copyToClipboard(_.split(this.state.error.logGroupName,'/')[3])}><CopyIcon /></Button></TableCell>               
              </TableRow>
              <TableRow>
                <TableCell>Function</TableCell>
                <TableCell>{this.state.error.fnName}</TableCell>               
              </TableRow>
              <TableRow>
                <TableCell>Log Group Name</TableCell>
                <TableCell><Button href={`https://eu-west-1.console.aws.amazon.com/cloudwatch/home?region=eu-west-1#logStream:group=${this.state.error.logGroupName}`} target="_aws"><img src={awsLogo} alt="link to AWS" className={classes.leftIcon}/> {this.state.error.logGroupName}</Button> <Button onClick={event => Utils.copyToClipboard(this.state.error.logGroupName)}><CopyIcon /></Button></TableCell>               
              </TableRow>
              <TableRow>
                <TableCell>Log Stream Name</TableCell>
                <TableCell><Button href={`https://eu-west-1.console.aws.amazon.com/cloudwatch/home?region=eu-west-1#logEventViewer:group=${this.state.error.logGroupName};stream=${this.state.error.logStreamName}`} target="_aws"><img src={awsLogo} alt="link to AWS" className={classes.leftIcon}/> {this.state.error.logStreamName}</Button> <Button onClick={event => Utils.copyToClipboard(this.state.error.logStreamName)}><CopyIcon /></Button></TableCell>               
              </TableRow>
              <TableRow>
                <TableCell>AWS Request ID</TableCell>
                <TableCell><Button href={`https://eu-west-1.console.aws.amazon.com/cloudwatch/home?region=eu-west-1#logEventViewer:group=${this.state.error.logGroupName};stream=${this.state.error.logStreamName};filter="${this.state.error.awsRequestId}"`} target="_aws"><img src={awsLogo} alt="link to AWS" className={classes.leftIcon}/> {this.state.error.awsRequestId}</Button> <Button onClick={event => Utils.copyToClipboard(this.state.error.awsRequestId)}><CopyIcon /></Button></TableCell>               
              </TableRow>
            </TableBody>

          </Table>
        </Paper>

        <Typography variant="subtitle1" gutterBottom>Comments
          <Button variant="fab" mini color="primary" aria-label="Add" className={classes.button} onClick={event => this.enterComment(event)}>
            <AddIcon />
          </Button>
        </Typography>

        <Paper className={classes.paper}>
          <Table>

            <TableHead>
              <TableRow>
                <TableCell className={classes.tableNoWrapCell}>Timestamp</TableCell>
                <TableCell className={classes.tableCell}>Comments</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.state.comments.map(comment => {

                return (

                  <TableRow key={comment.id}>
                    <TableCell className={classes.tableNoWrapCell}>{Utils.formattedDateTime(comment.ts)}</TableCell>
                    <TableCell className={classes.tableCell}>{comment.comment}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Paper>

        <Typography variant="subtitle1" gutterBottom>Logs</Typography>

        <Paper className={classes.paper}>
          <Table className={classes.table}>
            <TableBody>

              {this.state.logs.map((log,index) => {

                return (
                  <TableRow key={log.eventId} className={classes.stripeRow}>
                    <TableCell className={classes.tableNoWrapCell}>{Utils.formattedDateTimeWithDecimalSeconds(log.timestamp)}</TableCell>
                    <TableCell className={classes.tableCell}><pre>{Utils.formatLogMessage(log.message)}</pre></TableCell>
                  </TableRow>
                )
              })}

            </TableBody>
          </Table>
        </Paper>


        <Dialog
          open={this.state.commentDialogueOpen}
          onClose={this.closeCommentDialogue}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add Comment</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter your comment below. A timestamp will be added automatically.
            </DialogContentText>
            <TextField
              autoFocus
              id="comment-form"
              label="Comment"
              multiline
              fullWidth
              rowsMax="10"
              inputRef={ref => this.commentFieldRef = ref}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeCommentDialogue}>
              Cancel
            </Button>
            <Button onClick={this.saveComment} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

      </React.Fragment>
    )
  }
}

ErrorInstance.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(ErrorInstance))