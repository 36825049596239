import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { ResponsiveContainer, ComposedChart, Bar, Line, XAxis,YAxis,CartesianGrid,Legend,Tooltip } from 'recharts'

const chartOptions = {
  metrics : ['Timer','Sum','Timer','Timer','Timer'],
  stats : ['Requests','Errors','Avg','Min','Max'],
  colours : ['#ddd','#000','#ff9900','#65AD63','#ff0000'],
}

const useStyles = makeStyles(theme => ({
  chart : {
    backgroundColor : 'white',
    borderRadius : '5px',
  },
}))

export default function DashboardChart(props) {
  
  const classes = useStyles()

  return (
    <ResponsiveContainer height={200} width='100%'>
      <ComposedChart data={props.data} margin={{top: 35, right: 5, left: 10, bottom: 0}} className={classes.chart}>
        <XAxis dataKey="date" height={55} interval={3} key={Math.random()}/>
        <CartesianGrid strokeDasharray="1 1"/>
        <YAxis yAxisId="right"  orientation="right" stroke="#8884d8" key={Math.random()}/>
        <YAxis yAxisId="left" orientation="left" key={Math.random()}/>
        <Legend verticalAlign="top" align="right"/>
        <Tooltip/>

        {chartOptions.stats.map((stat,index) => {

          let metric = props.source.toLowerCase() + chartOptions.metrics[index].toLowerCase()
          let statname = chartOptions.stats[index]
          let datakey = metric + stat
          let title = chartOptions.stats[index]

          switch (statname)
          {
            case "Requests": return (<Bar yAxisId="left" name={`${props.source} ${title}`} key={datakey} type="monotone" dataKey={stat} fill={chartOptions.colours[index]} />)
            case "Errors": return (<Bar yAxisId="left" name={title} key={datakey} type="monotone" dataKey={stat} stroke={chartOptions.colours[index]} />)
            default : return (<Line yAxisId="right" name={title} key={datakey} type="monotone" dataKey={stat} stroke={chartOptions.colours[index]} />)
          }
        })}
      </ComposedChart>
    </ResponsiveContainer>
  )
}
