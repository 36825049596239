import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputMask from "react-input-mask"

import { awsSdk, getMyIp } from '../data-providers/aws-admin'

const styles = theme => ({
  paper: {
    width: 'calc(80%)',
    marginTop: theme.spacing(3),
    margin : 'auto',
    overflowX: 'auto',
    backgroundColor : '#fff',
    padding : '10px'
  },
})

class IPAdmin extends Component {

  constructor(props, context) {
    super(props, context)  
    this.state = {ipRanges:[],myIp:""}
  }

  componentDidMount() {
    setTimeout(this.initData,1)
  }

  initData = async () => {
    let ipRanges = await awsSdk("listPublicSgIPs",{})
    this.setState({ipRanges})
    this.refreshMyIp()
  }

  refreshMyIp = async () => {
    let myIp = await getMyIp()
    this.setState({myIp})
  }

  myIpNotUsed = () => {
    for (let r of this.state.ipRanges)
    {
      if (r.CidrIp === this.state.myIp + "/32")
      {
        return false
      }
    }
    return true
  }

  addRuleForMyIp = async () => {
    let Description = this.myDesc.value
    await awsSdk("addPublicIpRange",{CidrIp:this.state.myIp+ "/32",Description})
    this.myDesc.value = ""
    this.initData()
  }

  addRuleForOtherIp = async () => {
    let CidrIp = this.otherIp.value + "/32"
    let Description = this.otherDesc.value 

    await awsSdk("addPublicIpRange",{CidrIp,Description})

    this.otherDesc.value = ""

    this.initData()
  }

  updateRule = async (CidrIp,Description) => {
    await this.deleteRule(CidrIp)
    await awsSdk("addPublicIpRange",{CidrIp:this.state.myIp+ "/32",Description})
    this.initData()
  }

  deleteRule = async CidrIp => {
    await awsSdk("deletePublicIpRange",{CidrIp})
    this.initData()
  }

  render() {
    const { classes } = this.props

    let myIpNotInUse = this.myIpNotUsed()

    return (
      <Paper className={classes.paper}>
        <div style={{textAlign:'right'}}>My current IP: <strong>{this.state.myIp}</strong> <Button variant="contained" onClick={event => this.refreshMyIp()}>Refresh</Button></div>

        <Table className={classes.table}>

          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>Purpose</TableCell>
              <TableCell className={classes.tableCell}>IP</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {this.state.ipRanges.map(r => {

              return (
                <TableRow key={r.CidrIp} className={classes.stripeRow}>
                  <TableCell>{r.Description}</TableCell>
                  <TableCell>{r.CidrIp}</TableCell>
                  <TableCell><Button variant="contained" color="secondary" onClick={event => this.deleteRule(r.CidrIp)}>Delete</Button></TableCell>
                  <TableCell>{myIpNotInUse && <Button variant="contained" color="primary" onClick={event => this.updateRule(r.CidrIp,r.Description)}>Update with current IP</Button>}</TableCell>
                </TableRow>
              )
            })}

            {myIpNotInUse && 
              <TableRow className={classes.stripeRow}>
                <TableCell><TextField id="new-my-ip-description" inputRef={(c) => {this.myDesc = c}} label="Description" /></TableCell>
                <TableCell>{this.state.myIp}/32</TableCell>
                <TableCell></TableCell>
                <TableCell><Button variant="contained" onClick={event => this.addRuleForMyIp()}>Add new rule for current IP</Button></TableCell>
              </TableRow>
            }

            <TableRow className={classes.stripeRow}>
              <TableCell><TextField id="new-other-ip-description" inputRef={(c) => {this.otherDesc = c}} label="Description" /></TableCell>
              <TableCell>
              <InputMask
                mask="299.299.299.299"
                disabled={false}
                maskChar="_"
                formatChars= {{
                  '2': '[0-2]',
                  '9': '[0-9]',
                }}
              >
                {() => <TextField id="new-other-ip-ip" inputRef={(c) => {this.otherIp = c}} label="IP Address" InputProps={{endAdornment: <InputAdornment position="end">/32</InputAdornment>}}/>}
              </InputMask>

                </TableCell>
              <TableCell></TableCell>
              <TableCell><Button variant="contained" onClick={event => this.addRuleForOtherIp()}>Add new rule for another IP</Button></TableCell>
            </TableRow>

          </TableBody>
        </Table>

      </Paper>
    )
  }
}

export default withRouter(withStyles(styles)(IPAdmin))