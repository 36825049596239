import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import WarningIcon from '@material-ui/icons/Warning'
import NewIcon from '@material-ui/icons/NewReleases'

const styles = theme => ({
  card: {
    textAlign : 'center',
    cursor : 'pointer',
  },
  cardContent : {
    padding : '0 !important',
  },
  title: {
    padding : '3px',
    fontSize: 14,
    color : '#999',
  },
  calls: {
    fontSize: 28,
  },
  coldstarts: {
    fontSize: 14,
  },
  duration: {
    fontSize: 12,
  },
  alert : {
    color : 'red',
  },
})

const tooltipStyle = {
  tooltip : {
    fontSize: "20px",
    whiteSpace: "pre-line"
  }
}

const CustomTooltip = withStyles(tooltipStyle)(Tooltip)

class StatsCard extends Component {

  constructor(props, context) {
    super(props, context)

    this.state = {data:{}}
  }

  componentDidMount() {
    if (this.props.dataSource)
    {
      this.updateData()

      this.intervalRef = setInterval(() => {
        if (!this.props.sleeping)
        {
          this.updateData()
        }
      }, this.props.updateInterval)
    }
  }
  
  componentWillUnmount() {
    clearInterval(this.intervalRef)
  }

  async updateData() {
    if (this.props.dataSource)
    {
      let data = await this.props.dataSource()

      this.setState({data})  
    }
  }

  render() {

    const { classes } = this.props
  
    let tooltipText = `Number of coach types : ${this.state.data.coaches}
    
    New coaches : ${this.state.data.newCoaches}
    Requirung attention : ${this.state.data.attention}`

    return (
      <Card className={classes.card} onClick={event => this.props.clickHandler(this.props)}>
        <CustomTooltip title={tooltipText}>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.title} gutterBottom component="h2">
              {this.props.title}
            </Typography>

              <Typography className={classes.calls} variant="h5" color="textPrimary">
                {this.state.data.coaches}
              </Typography>

              <Typography className={classes.duration} variant="h5" color="textPrimary">
                  &nbsp;
              </Typography>

              <Typography className={classes.coldstarts} color="textSecondary">
                <NewIcon style={{ fontSize: 12 }}/> {this.state.data.newCoaches}
                &nbsp;&nbsp;&nbsp;
                <WarningIcon style={{ fontSize: 12 }}/> {this.state.data.attention}
              </Typography>
          </CardContent>
        </CustomTooltip>
      </Card>
    )
  }
}

export default withStyles(styles)(StatsCard)
