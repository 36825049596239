import React from 'react'
import Utils from '../utils'

const ImageFormatter = props => <img src={props.value} height={60} alt=""/>

const getGridConfig = actionClickHandler => {

  return {
   
  visibleRowBufferSize : 10,

  columnDefs : [
    {
      field: "ourTypeId",
      headerName: "Our Type ID",
      sortable: true, 
      filter: 'agNumberColumnFilter',
      width :100,
      menuTabs : ['filterMenuTab'],
      filterParams : {
        filterOptions : ['inRange','greaterThanOrEqual','lessThanOrEqual'],
        suppressAndOrCondition : true,
        inRangeInclusive : true,
      },
    },
    {
      field: "carrierCode",
      headerName: "TOC",
      sortable: true, 
      filter: 'agSetColumnFilter',
      menuTabs : ['filterMenuTab'],

      width : 60
    },
    {
      field: "ourName",
      headerName: "Name",
      sortable: true, 
      filter: 'agSetColumnFilter',
      menuTabs : ['filterMenuTab'],

      width : 300
    },
    {
      field: "ourImageSize",
      headerName: "Width",
      menuTabs : ['filterMenuTab'],
      valueFormatter : obj => obj.value.width,
      width : 100
    },
    {
      field: "walkthroughLeft",
      headerName: "Walk",
      sortable: true, 
      valueFormatter : obj => (obj.data.walkthroughLeft ? "L" : "") + (obj.data.walkthroughRight ? "R" : ""),
      filter: 'agSetColumnFilter',
      menuTabs : ['filterMenuTab'],

      width : 60
    },
    {
      field: "countedPlace",
      headerName: "NoPl",
      sortable: true, 
      valueFormatter : obj => obj.value === 1 ? 'yes' : '',
      filter: 'agSetColumnFilter',
      menuTabs : ['filterMenuTab'],

      width : 60
    },
    {
      field: "numSeats",
      headerName: "Seats",
      sortable: true, 

      width : 60
    },
    {
      field: "numDuplicates",
      headerName: "Dups",
      sortable: true, 
      valueFormatter : obj => obj.value === 0 ? '' : obj.value,
      filter: 'agSetColumnFilter',
      menuTabs : ['filterMenuTab'],

      width : 60
    },
    {
      field: "manuallyCorrected",
      headerName: "Checked",
      sortable: true, 
      valueFormatter : obj => obj.value === 1 ? 'yes' : '',
      filter: 'agSetColumnFilter',
      menuTabs : ['filterMenuTab'],

      width : 60
    },
    {
      field: "furtherWorkRequired",
      headerName: "More Work",
      sortable: true, 
      valueFormatter : obj => obj.value === 1 ? 'yes' : '',
      filter: 'agSetColumnFilter',
      menuTabs : ['filterMenuTab'],

      width : 60
    },
    {
      field: "discoveryDate",
      headerName: "Found",
      valueFormatter : obj => Utils.formattedDate(obj.value),
      sortable: true, 
      width :120,
      menuTabs : ['filterMenuTab'],
    },
    {
      field: "lastSeenDate",
      headerName: "Last Seen",
      valueFormatter : obj => Utils.formattedDate(obj.value),
      sortable: true, 
      width :120,
      menuTabs : ['filterMenuTab'],
    },
    {
      field: "ourImageUrl",
      headerName: "Our Image",
      sortable: false,
      autoHeight : true,
      width : 500,
      cellRendererFramework: ImageFormatter
    },
    {
      field: "ourTypeId",
      headerName: "View",
      width : 80,
      cellRenderer: 'actionCellRenderer',
      cellRendererParams: item => {
        return {
          clicked: actionClickHandler,
          link : item,
        }
      },
    },
  ]
}
}

export default getGridConfig