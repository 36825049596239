import React, { PureComponent } from 'react'
import { NavLink,withRouter } from 'react-router-dom'

import Axios from 'axios'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import ViewIcon from '@material-ui/icons/OpenInNew'
import NewShiftIcon from '@material-ui/icons/CreateNewFolder'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Config from '../config'
import Utils from '../utils'

var visible = false

const styles = theme => ({
  paper: {
    width: 'calc(100%)',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  paperBorder : {
    width: 'calc(100%)',
    marginTop: theme.spacing.unit * 3,    
    padding: 40,
    textAlign: 'center'
  },
  table: {
    minWidth: 700,
    width : '100%',
    tableLayout : 'auto'
  },
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    padding : '4px 15px 4px 15px'
  },
  center: {
    textAlign : 'center'
  },
  error: {
    color : 'red'
  },
  ok: {
    color : 'green'
  },  
  clickableTableRow: {
    cursor : 'pointer'
  },
  button: {
    margin: theme.spacing.unit,
    backgroundColor : '#ffffff'
  },  
  stripeRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  }, 
})

class SettlementOverview extends PureComponent {

  constructor(props, context) {
    super(props, context)
    this.state = {stage:"",licensees:{}}
  }

  async componentDidMount() {
    let {stage} = this.props.match.params
    let mode = (stage === "alts") ? "test" : "live"
    Config.setConfig("mode",mode)
    this.setState({stage},this.init)
  }

  componentDidUpdate(prevProps) {
    let {stage} = this.props.match.params

    if(prevProps.match.params.stage !== stage)
    {
      let mode = (stage === "alts") ? "test" : "live"
      Config.setConfig("mode",mode)
      this.setState({stage:stage,licensees:{}},this.init)
    }
  }

  componentWillUnmount() {
    visible = false
  }

  async init() {
    visible = true    
    this.getMachines()
  }

  getMachines = async => {

    if (!visible)
    {
      return
    }

    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "SDCI",
        fn : "getLicenseesAndMachines",
        params : {
          stage : this.state.stage
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey(),
      },
    }

    Axios(options).then(result => {

      let licensees = result.data.data
      this.setState({licensees:licensees})

    })
    .catch(err => {
     console.error(err)
    })    
  }

  drillDown = (event,licensee,machineId) => {
    this.props.history.push(`${this.props.history.location.pathname}/${licensee}/${machineId}`)
  }

  endShift = async (machineId,licensee) => {
    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "SDCI",
        fn : "endShift",
        params : {
          stage : this.state.stage,
          licensee : licensee,
          machineId : machineId
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey(),
      },
    }

    console.log("end shift",options)

    return await Axios(options)
  }


  endAllShifts = async shifts => {

    let promises = []

    for (let l=0;l<shifts.length;l++)
    {
      let shift = shifts[l]

      console.log("shift",shift)
      if (shift.enabled)
      {
        promises.push(this.endShift(shift.machineId,shift.id))
      }
    }

    await Promise.all(promises)
  }

  render() {

    const { classes } = this.props
  
    return (

      <React.Fragment>

        <div className="stageMenu">
          <Button component={NavLink} to="/settlement/lennon" activeClassName="active">Lennon</Button>
          <Button component={NavLink} to="/settlement/alts" activeClassName="active">ALTS</Button>
        </div>

        {!Object.keys(this.state.licensees).length &&
          <Paper className={classes.paperBorder}>
            No data found
          </Paper>
        }

        {Object.keys(this.state.licensees).map(licensee => {

          return (
            <React.Fragment key={licensee}>

              <Typography variant="subtitle1" gutterBottom style={{clear:'both'}}>{licensee} <Button className={classes.button} color="secondary" onClick={event => this.endAllShifts(this.state.licensees[licensee])} variant="outlined"><NewShiftIcon />End all shifts</Button></Typography>
     
              <Paper className={classes.paper}>
                <Table className={classes.table}>

                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell}>{this.state.stage === "lennon" ? "Lennon" : "ALTS"} Machine Name</TableCell>
                      <TableCell className={classes.tableCell}>Machine ID</TableCell>
                      <TableCell className={classes.tableCell}>Window</TableCell>
                      <TableCell className={classes.tableCell}>Business Group</TableCell>
                      <TableCell className={classes.tableCell}>Notes</TableCell>
                      <TableCell className={classes.tableCell}>Send</TableCell>
                      <TableCell className={classes.tableCell}>Enabled</TableCell>
                      <TableCell className={classes.tableCell}>Reports</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {this.state.licensees[licensee].map((item,index) => {

                      let rowclass = classes.tableCell
                      if (item.reportError)
                      {
                        rowclass += ' ' + classes.error
                      }

                      return (
                        <TableRow key={item.machineId} className={classes.clickableTableRow + ' ' + classes.stripeRow} onDoubleClick={event => this.drillDown(event,item.id,item.machineId)}>                      
                          <TableCell className={rowclass}>{item.MachineName}</TableCell>
                          <TableCell className={rowclass}>{item.machineId}</TableCell>
                          <TableCell className={rowclass}>{item.window}</TableCell>
                          <TableCell className={rowclass}>{item.businessGroup}</TableCell>
                          <TableCell className={rowclass}>{item.notes}</TableCell>
                          <TableCell className={rowclass}>{Utils.yesNoFormat(item.send)}</TableCell>
                          <TableCell className={rowclass}>{Utils.yesNoFormat(item.enabled)}</TableCell>
                          <TableCell className={rowclass}><IconButton color="primary" onClick={event => this.drillDown(event,item.id,item.machineId)}><ViewIcon /></IconButton></TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
          
              </Paper>

              <br/><br/>
            </React.Fragment>
          )
        })}

      </React.Fragment>
    )
  }
}

SettlementOverview.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(SettlementOverview))