import React from 'react'
import clsx from 'clsx'
import Moment from 'moment-timezone'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'
import Badge from '@material-ui/core/Badge'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

import { makeStyles } from '@material-ui/core/styles'
import { amber, green } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  snackbar : {
    width : '100%'
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  modelive : {
    border : '1px solid white'
  },
  modetest : {
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
    closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}


function MySnackbarContentWrapper(props) {
  const classes = useStyles()
  const { className, message, source, counter, tsFirst, tsRecent, onClose, onClick, variant, mode, awsRequestId, ...other } = props
  const Icon = variantIcon[variant]

  return (
    <Badge badgeContent={counter} color="secondary">
    <SnackbarContent onClick={() => onClick(props)}
      className={clsx(classes[variant], classes['mode' + mode], className)}
      classes={{
          root:classes.snackbar   //in this css class set the maxWidth 
      }}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {Moment(tsFirst).format('D MMM HH:mm')} : {source}
          <br/>
          {message} ({awsRequestId})
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  </Badge>
  )
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

const useStyles2 = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  snackbar: {
    marginBottom : '3px',
  }
}))

export default function Alerts(props) {
  const classes = useStyles2()

  const [open, setOpen] = React.useState(false)
  const [viewedAlert, setViewedAlert] = React.useState({})

  var deletedAlert

  const handleClickOpen = (alert) => {
    let thisAlert = {...alert}
    thisAlert.formattedData = JSON.parse(thisAlert.data)

    setViewedAlert(thisAlert)

    if (alert !== deletedAlert)
    {
      setOpen(true)
    }
  }

  const handleClose = value => {
    setOpen(false)
  }

  return (
    <div>
      {props.alerts.map(alert => {
        return (
          <MySnackbarContentWrapper
            onClose={e => {deletedAlert = alert; props.deleteAlert(alert)}}
            onClick={e => handleClickOpen(alert)}
            variant={alert.counter > 9 ? 'error' : alert.type}
            source={alert.source}
            message={alert.content}
            awsRequestId={alert.awsRequestId}
            mode={alert.mode}
            data={alert.data}
            counter={alert.counter}
            className={classes.snackbar}
            tsFirst={alert.tsFirst}
            tsRecent={alert.tsRecent}
            key={alert.ids}
          />
        )
      })}
  
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={'md'} scroll={'paper'}>
        <DialogTitle id="form-dialog-title">{viewedAlert.source}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {viewedAlert.content}
          </DialogContentText>
          
          {viewedAlert.formattedData && viewedAlert.formattedData.map((d,index) => (
            <DialogContentText key={index} style={{whiteSpace: 'pre'}}>{JSON.stringify(d,null,2)}</DialogContentText>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Close
          </Button>
          <Button onClick={handleClose} color="primary">
            View
          </Button>
          <Button onClick={handleClose} color="primary">
            Clear
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}