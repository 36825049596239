import _ from 'lodash'
import React, { Component } from 'react'

import getGridConfig from './duplicates-grid-config'
import ActionCellRenderer from './action-cell-renderer'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise/dist/styles/ag-grid.css'
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'

const defaultState = {
  instances : [],
  selectedNode:null,
  showSeats : true
}
class DuplicateCopachInstances extends Component {

  constructor(props) {
    super(props)
    this.state = defaultState
    this.state.instances = _.cloneDeep(this.props.instances)
  }

  componentDidUpdate(prevProps) {
    if (this.props.instances !== prevProps.instances)
    {
      let instances = _.cloneDeep(this.props.instances)
      this.setState({instances})
    }
  }

  onGridReady = params => {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi
    this.gridApi.setSortModel([{colId:"id",sort:"desc"}])
  }

  selectionChanged = () => {
    let selectedNode = this.gridApi.getSelectedNodes()[0]
    this.setState({selectedNode})
  }


  // Used to de-select a previously selected row
  rowClicked = event => {
    if (event.node === this.state.selectedNode) // Don't do anything unless selected row has been clicked
    {
      setTimeout(() => { // Need to clear selection, unless we have double clicked to open the modal
          this.setState({selectedNode:null},() => {
            event.node.setSelected(false)
          })
      },100)
    }
  }

  actionClickHandler = (action,node) => {
    switch(action)
    {
      case "view" : return console.log("view clicked")
      default: {}
    }
  }

  setShowSeats = event => {
    let showSeats = event.target.checked
    this.setState({showSeats})
  }

  render() {

    if (!this.state.instances.length) return ''

    const selectedInstance = this.state.selectedNode ? this.state.selectedNode.data : this.state.instances[0]

    let GridConfig = getGridConfig(this.actionClickHandler.bind(this))

    return (
      <React.Fragment>
        <div className="ag-theme-alpine" style={{height: 300, width: '100%', marginBottom:'50px'}}>
          <h2>Duplicate instances</h2>

          <AgGridReact
            onGridReady={this.onGridReady}
            rowBuffer={GridConfig.visibleRowBufferSize*2}
            columnDefs={GridConfig.columnDefs}
            rowData={this.state.instances}
            rowSelection="single"
            rowDeselection={true}
            suppressCellSelection={true}
            enableCellTextSelection={true}
            defaultColDef={{filterParams:{ newRowsAction:'keep'}}}
            frameworkComponents = {{
              actionCellRenderer : ActionCellRenderer,
            }}
            onRowClicked={this.rowClicked.bind(this)}
            onSelectionChanged={this.selectionChanged.bind(this)}
            >
          </AgGridReact>
        </div>

        <h2>TOC instance image</h2>
        <div style={{display: 'flex',flexDirection: 'row'}}>
          <Typography>No</Typography>
          <Switch checked={this.state.showSeats} onChange={this.setShowSeats} />
          <Typography>Yes</Typography>
        </div>


        <div style={{position:"relative"}}>
          <img src={selectedInstance.tocImageUrl} alt="" style={{position:'absolute',height:selectedInstance.tocImageSize.height,left:0,top:0}} height={selectedInstance.tocImageSize.height}/>
            
              {this.state.showSeats &&selectedInstance.tocSeats.map(seat => (
                <div key={seat.id} className="seat greySeat" style={{position:'absolute',width:selectedInstance.tocSeatSize.width,height:selectedInstance.tocSeatSize.height,top:seat.yPos,left:seat.xPos}}>
                  {seat.id}
                </div>
              ))}
        </div>

      </React.Fragment>
    )
  }
}

export default DuplicateCopachInstances




