import React from 'react'
import { withRouter } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { fade, makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import InputBase from '@material-ui/core/InputBase'
import Badge from '@material-ui/core/Badge'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import MenuIcon from '@material-ui/icons/Menu'
import SleepIcon from '@material-ui/icons/VolumeOff'
import AwakeIcon from '@material-ui/icons/VolumeUp'

import SearchIcon from '@material-ui/icons/Search'
import NotificationsIcon from '@material-ui/icons/Notifications'
import Tooltip from '@material-ui/core/Tooltip'

import Config from '../config'

import logo from '../images/logo.png'

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  link : {
    textDecoration :  "none",
    display : "block",
  },
  logoHolder : {
    position : 'relative',
    width : '100px',
    cursor : 'pointer',
  },
  logo : {
    position : 'absolute',
    top : '-30px',
    '-webkit-filter': 'drop-shadow(3px 5px 2px rgba(0,0,0,0.3))'
  },
}))

export default withRouter(function MainNav(props) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const isMenuOpen = Boolean(anchorEl)

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const goToDashboard = () => {
    let routes = Config.getConfig('routes')
    let mode = Config.getConfig('mode')
    let url = routes[0].url.replace(":stage",mode)

    props.history.push(url)
  }

  const goToAlerts = () => {
    props.history.push('/alerts')
  }

  const sleepNow = () => {
    setTimeout(() => {props.setSleepMode(true)},1000)
  }

  let mode = Config.getConfig('mode')

  if (props.sleeping && props.history.location.pathname !== Config.getConfig('routes')[0].url.replace(":stage",mode))
  {
//    setTimeout(goToDashboard,1000)
  }

  const menuId = 'primary-search-account-menu'

  const userData = Config.getConfig("userData")

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {props.routes.filter(r => r.menu).filter(r => {return r.ownerOnly ? userData.systemOwner : true}).map(route => {
          return (<MenuItem key={route.url} component={NavLink} to={route.link ? route.link : route.url}>{route.name}</MenuItem>)
        }
      )}
    </Menu>
  )

  const search = event => {
    let searchTerm = event.target.value
    
    if(event.keyCode === 13)
    {
      props.searchTermChanged(searchTerm)
    }
  }

  const routes = Config.getConfig('routes')
  const hasDashboard = routes && routes.length && routes[0].url === '/'

  return (

    <div className={classes.grow}>

      <AppBar position="static" color={props.sleeping ? "secondary" : "inherit"}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>

          <div className={classes.logoHolder} onClick={e => goToDashboard(props)}>
            <img src={logo} alt="Smartix logo" className={classes.logo}/>
          </div>

          <Typography className={classes.title} variant="h6" noWrap>
            Smartix {props.activeRoute.name}
          </Typography>
          
          <div className={classes.grow} />

          {props.salesStats &&
            <Typography className={classes.title} variant="h6" noWrap>
              {props.salesStats.sales.today} : {props.salesStats.refunds.today} : {props.salesStats.reservations.today} : {props.salesStats.ausi.today} : £{(props.salesStats.income.today/100).toFixed(2)}
            </Typography>
          }

          <div className={classes.grow} />

          {props.activeRoute.search &&
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <Tooltip title={props.activeRoute.searchTip}>
              <InputBase
                placeholder="Search..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                onKeyUp={search}
                inputProps={{ 'aria-label': 'search' }}
              /></Tooltip>
            </div>
          }

          {hasDashboard &&
            <div className={classes.sectionDesktop} onClick={e => goToAlerts(props)}>
              <IconButton color="inherit">
                <Badge badgeContent={props.alertCount} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </div>
          }

          {!props.sleeping &&
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              onClick={sleepNow}
            >
              <AwakeIcon />
            </IconButton>
          }

          {props.sleeping &&
            <SleepIcon />
          }
        </Toolbar>
      </AppBar>
      
      {renderMenu}
    </div>
  )
})