import Axios from 'axios'
import Config from '../config'

export default async function getAlerts(type,incCleared=false) {

  try
  {
    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "Alerts",
        fn : "getAlerts",
        params : {
          type: type,
          incCleared : incCleared
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey('adminApi'),
      },
    }
    
    let result = await Axios(options)

    return result.data.data
  }
  catch(err)
  {
    console.log(JSON.stringify(err,null,2))
    throw err
  }
}