import _ from 'lodash'
import React, { Component , createRef } from 'react'
import { SelectableGroup } from 'react-selectable-fast'

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import { MdAutoFixHigh } from "react-icons/md"
import { CgArrowAlignH } from "react-icons/cg"
import { CgArrowAlignV } from "react-icons/cg"
import { AiOutlineExpand } from "react-icons/ai"
import { BiSelectMultiple } from "react-icons/bi"
import { BsBoxArrowDown } from "react-icons/bs"
import { GiResize } from "react-icons/gi"
import { CgUserAdd } from "react-icons/cg"

import SelectionTools from './selection-tools'
import AddSeatHandler from './add-seat-handler'
import seatAdjust from './seat-adjust'
import Seat from './seat'

import Config from '../config'

import DynamicComponent from '../components/dynamic-component'

import { SiAirtable } from "react-icons/si"
import { FaLuggageCart } from "react-icons/fa" 
import { FaToiletPaper } from "react-icons/fa" 
import { MdExitToApp } from "react-icons/md" 
import { MdOutlineEmojiFoodBeverage } from "react-icons/md" 
import { MdOutlineDirectionsBike } from "react-icons/md" 

import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise/dist/styles/ag-grid.css'
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import getGridConfig from './position-editor-grid-config'

const areaIcons = {
  table : SiAirtable,
  luggage : FaLuggageCart,
  toilet : FaToiletPaper,
  exit  : MdExitToApp,
  food  : MdOutlineEmojiFoodBeverage,
  bike  : MdOutlineDirectionsBike,
}


const defaultState = {
  selectedSeatIds:[],
  seats : [],
  showAddSeatPopup : false
}
 
let SelectionToolsBound

class SeatPositionEditor extends Component {

  constructor(props) {
    super(props)
    this.state = defaultState
    this.state.seats = _.cloneDeep(this.props.seats)
    this.state.coach = _.cloneDeep(this.props.coach)

    SelectionToolsBound = {
      selectAllSeats : SelectionTools.selectAllSeats.bind(this),
      clearSeatSelection : SelectionTools.clearSeatSelection.bind(this),
      invertSeatSelection : SelectionTools.invertSeatSelection.bind(this),
      handleSelectionFinish : SelectionTools.handleSelectionFinish.bind(this),
      gridSelectionChanged : SelectionTools.gridSelectionChanged.bind(this),
      setSelectedSeatsInSelectableGroup :  SelectionTools.setSelectedSeatsInSelectableGroup.bind(this), 
    }

    if (this.props.isEditor)
    {
      this.props.setActionButtons(this.getActionButtons())
    }
  }
  
  componentDidMount(){
    document.addEventListener("keydown", this.handleKeyPress, false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.handleKeyPress, false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.seats !== this.props.seats)
    {
      // Make a copy of seats so that we do not mutate props when we adjust seat properties
      let seats = _.cloneDeep(this.props.seats)
      this.setState({seats})

      this.gridApi.setRowData(seats)
    }

    if (prevProps.coach !== this.props.coach)
    {
      // Make a copy of seats so that we do not mutate props when we adjust seat properties
      let coach = _.cloneDeep(this.props.coach)
      this.setState({coach})
    }
  }

  getActionButtons = () => (
    <React.Fragment>
      <Tooltip title="Auto adjust seat positions (CTRL-A)">
        <IconButton onClick={this.autoAdjustSeats} color="primary" size="large">
          <MdAutoFixHigh/>
        </IconButton>
      </Tooltip>

      <Tooltip title="Align selected seats TOP (CTRL-Y)">
        <IconButton onClick={e => this.alignSelectedSeats("y")} color="primary" size="large">
          <CgArrowAlignV/>
        </IconButton>
      </Tooltip>

      <Tooltip title="Align selected seats LEFT (CTRL-X)">
        <IconButton onClick={e => this.alignSelectedSeats("x")} color="primary" size="large">
          <CgArrowAlignH/>
        </IconButton>
      </Tooltip>

      <Tooltip title="Select all seats (CTRL-A)">
        <IconButton onClick={SelectionToolsBound.selectAllSeats} color="primary" size="large">
          <BiSelectMultiple/>
        </IconButton>
      </Tooltip>

      <Tooltip title="Invert seat selection (CTRL-I)">
        <IconButton onClick={SelectionToolsBound.invertSeatSelection} color="primary" size="large">
          <BsBoxArrowDown/>
        </IconButton>
      </Tooltip>

      <Tooltip title="Clear seat selection (CTRL-S)">
        <IconButton onClick={SelectionToolsBound.clearSeatSelection} color="primary" size="large">
          <AiOutlineExpand/>
        </IconButton>
      </Tooltip>

      <Tooltip title="Resize seats (CTRL-R)">
        <IconButton onClick={this.resizeSeats} color="primary" size="large">
          <GiResize/>
        </IconButton>
      </Tooltip>

      <Tooltip title="Add a seat">
        <IconButton onClick={this.addSeat} color="primary" size="large">
          <CgUserAdd/>
        </IconButton>
      </Tooltip>

      <div>Use arrow keys to move selected seats (Shift-arrow to move faster)</div>

    </React.Fragment>
  )

  handleKeyPress = event => {

    let key = event.key

    if (event.metaKey) key += '-meta'
    if (event.ctrlKey) key += '-ctrl'
    if (event.shiftKey) key += '-shift'

    switch(key)
    {
      case "ArrowUp": return this.moveSelectedSeats(0,-1)
      case "ArrowDown": return this.moveSelectedSeats(0,1)
      case "ArrowRight": return this.moveSelectedSeats(1,0)
      case "ArrowLeft": return this.moveSelectedSeats(-1,0)

      case "ArrowUp-shift": return this.moveSelectedSeats(0,-10)
      case "ArrowDown-shift": return this.moveSelectedSeats(0,10)
      case "ArrowRight-shift": return this.moveSelectedSeats(10,0)
      case "ArrowLeft-shift": return this.moveSelectedSeats(-10,0)
      
      case "a-ctrl" : case "A-ctrl" : return SelectionToolsBound.selectAllSeats()
      case "s-ctrl" : case "S-ctrl" : return SelectionToolsBound.clearSeatSelection()
      case "i-ctrl" : case "I-ctrl" : return SelectionToolsBound.invertSeatSelection()
      case "r-ctrl" : case "R-ctrl" : return this.resizeSeats()

      case "x-ctrl" : case "X-ctrl" : return this.alignSelectedSeats("x")
      case "y-ctrl" : case "Y-ctrl" : return this.alignSelectedSeats("y")
 
      case "z-ctrl" : case "Z-ctrl" :  return this.props.undoHistory()

      default: {} // Ignore
    }
  }
  

  moveSelectedSeats = (x,y) => {

    let seats = this.state.seats

    seats.forEach(s => {
      if (this.state.selectedSeatIds.includes(s.accomId))
      {
        s.position.x += x
        s.position.y += y
      }
    })

    this.props.updateSeats(seats)
  }

  autoAdjustSeats = () => {
    let seats = this.state.seats
    let coach = this.state.coach

    seats = seatAdjust.autoAdjustSeatsY(seats,coach.ourSeatSize)
    seats = seatAdjust.autoAdjustSeatsX(seats,coach.ourSeatSize)

    this.props.updateSeats(seats)
  }

  alignSelectedSeats = dir => {

    console.log("alignSelectedSeats",dir)
    let seats = this.state.seats

    let maxPos = 0

    seats.forEach(s => {
      if (this.state.selectedSeatIds.includes(s.accomId))
      {
        if (s.position[dir] > maxPos) maxPos = s.position[dir]
      }
    })

    seats.forEach(s => {
      if (this.state.selectedSeatIds.includes(s.accomId))
      {
        s.position[dir] = maxPos
      }
    })

    this.props.updateSeats(seats)
  }

  resizeSeats = () => {
    let coach = this.state.coach
    let size = Config.getConfig("seatSize")

    coach.ourSeatSize = {
      width : size.width,
      height : size.height,
    }

    this.props.updateCoach(coach)
  }

  addSeat = () => {
    this.setState({showAddSeatPopup:true})
  }

  closePopups = () => {
    this.setState({showAddSeatPopup:false})
  }

  onGridReady = params => {
    this.gridApi = params.api
    this.gridApi.setRowData(this.state.seats) 
  }

  gridDataUpdated = () => {
    this.gridApi && this.gridApi.forEachNode(node => {
      let selected = this.state.selectedSeatIds.includes(node.data.accomId)
      node.setSelected(selected)
    })
  }

  addNewSeat = newSeat => {

    let seats = this.state.seats
    seats.push(newSeat)

    this.props.updateSeats(seats)
    this.gridApi.setRowData(seats)

    let selectedSeatIds = [newSeat.accomId]
    this.setState({selectedSeatIds},() => {
      this.gridDataUpdated()
      SelectionToolsBound.setSelectedSeatsInSelectableGroup(selectedSeatIds)
    })
  } 

  render() {

    this.selectableGroupRef = createRef()
    // selectableGroupRef.clearSelection() clears all selected items...
    // ignoreList={this.props.isEditor ? [] : ['.seat']} below disables the select if user is not an editor

    const GridConfig = getGridConfig()

    return (
      <React.Fragment>
        <br/>
        <SelectableGroup
          ref={this.selectableGroupRef}
          className="selectable-group"
          clickClassName="tick"
          enableDeselect={true}
          mixedDeselect={false}
          tolerance={0}
          onSelectionFinish={SelectionToolsBound.handleSelectionFinish}
          ignoreList={this.props.isEditor ? [] : ['.seat']}>
          
          <div>
            <img src={this.state.coach.ourImageUrl} style={{width:this.state.coach.ourImageSize.width,height:this.state.coach.ourImageSize.height}} alt=""/>

            {this.props.coach.areaData.map(area => (
              <div className="area" style={{position:'absolute',width:area.width,height:area.height,top:area.y,left:area.x}}>
                <div style={{position:'absolute',left:(area.width/2) - 15, top:(area.height/2) - 20}}>
                  <DynamicComponent component={areaIcons[area.type]} size="2em"/>
                </div>
              </div>
            ))}

            <div style={{position:"absolute",top:0}}>
              <div style={{position:"relative"}}>
                {this.state.seats.map(seat => (
                  <Seat seat={seat} coach={this.state.coach} key={seat.accomId} tooltip={false}/>
                ))}
              </div>
            </div>
          </div>

        </SelectableGroup>

        <AddSeatHandler open={this.state.showAddSeatPopup} close={this.closePopups} seats={this.state.seats} coach={this.state.coach} updateSeats={this.props.updateSeats.bind(this)} updateCoach={this.props.updateCoach.bind(this)} addNewSeat={this.addNewSeat}/>

        <div className="ag-theme-alpine" style={{width:'100%',height:`calc(100vh - ${this.props.coach.ourImageSize.height + 210}px)`}}>
          <AgGridReact
            onGridReady={this.onGridReady}
            rowBuffer={GridConfig.visibleRowBufferSize*2}
            columnDefs={GridConfig.columnDefs}
            animateRows={true}
            rowSelection="multiple"
            rowDeselection={true}
            suppressCellSelection={true}
            suppressRowClickSelection={!this.props.isEditor}
            onSelectionChanged={SelectionToolsBound.gridSelectionChanged}
            onModelUpdated={this.gridDataUpdated.bind(this)}
            statusBar = {{
              statusPanels : [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'right' },
              ]
            }}
            sideBar={{
              toolPanels : ['filters'],
              closedByDefault : true,
            }}
            >
          </AgGridReact>
        </div>

      </React.Fragment>
    )
  }
}


export default SeatPositionEditor