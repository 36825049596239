import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'

const styles = theme => ({
  card: {
    textAlign : 'center',
    cursor : 'pointer',
    backgroundColor : '#ff5f20',
    borderRadius : '10px',
  },
  cardContent : {
    padding : '5px !important',
    backgroundColor : '#fdfede',
  },
  titleColumn  : {
    width : '100%',
    display : 'inline-block',
    fontSize: 16,
    color : 'white',
    textAlign:'center'
  },
  yesterdayCount : {
    textAlign : 'left',
    display : 'inline-block',
    color : 'white',
    float : 'left',
    margin : '3px',
  },
  lastWeekCount : {
    textAlign : 'right',
    display : 'inline-block',
    color : 'white',
    float : 'right',
    margin : '3px',
  },
  monthCount : {
    textAlign : 'center',
    display : 'inline-block',
    padding : '3px',
    color : 'white',
  },
})

const tooltipStyle = {
  tooltip : {
    fontSize: "20px",
    whiteSpace: "pre-line"
  }
}

const CustomTooltip = withStyles(tooltipStyle)(Tooltip)


class StatsCard extends Component {

  render() {

    const { classes } = this.props
  
    return (
      <Card className={classes.card} onClick={event => this.props.clickHandler(this.props)}>
        <Typography gutterBottom component="h2" className={classes.titleColumn}>{this.props.title}</Typography>

        <CardContent className={classes.cardContent}>
          <Typography variant="h3" color="textPrimary">
            {this.props.salesStats.sales.today}
          </Typography>
        </CardContent>

        <Typography gutterBottom component="h2">
          <CustomTooltip title="Yesterday"><span className={classes.yesterdayCount}>{this.props.salesStats.sales.yesterday}</span></CustomTooltip>
          <CustomTooltip title="Last Week"><span className={classes.lastWeekCount}>{this.props.salesStats.sales.lastweek}</span></CustomTooltip>
          <CustomTooltip title="This Month (Last Month)">
            <Typography variant="body1" className={classes.monthCount}>
              {this.props.salesStats.sales.month} ({this.props.salesStats.sales.lastmonth})
            </Typography>
          </CustomTooltip>
        </Typography>
      </Card>
    )
  }
}

export default withStyles(styles)(StatsCard)
