import Axios from 'axios'
import Config from '../config'

export default async function clearAlert(ids) {

  try
  {
    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "Alerts",
        fn : "clearAlert",
        params : {
          ids:ids
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey('apiUrlErrors'),
      },
    }

    let result = await Axios(options)

    return result.data.data
  }
  catch(err)
  {
    console.log(JSON.stringify(err,null,2))
    throw err
  }
}