import Utils from '../utils'

const getGridConfig = actionClickHandler => {

  return {
   
  visibleRowBufferSize : 10,

  columnDefs : [
    {
      field: "tocTypeId",
      headerName: "TOC Type ID",
      sortable: true, 
      filter: 'agNumberColumnFilter',
      width :100,
      menuTabs : ['filterMenuTab'],
      filterParams : {
        filterOptions : ['inRange','greaterThanOrEqual','lessThanOrEqual'],
        suppressAndOrCondition : true,
        inRangeInclusive : true,
      },
    },
    {
      field: "carrierCode",
      headerName: "TOC",
      sortable: true, 
      filter: 'agSetColumnFilter',
      menuTabs : ['filterMenuTab'],

      width : 60
    },
    {
      field: "name",
      headerName: "Name",
      sortable: true, 
      filter: 'agSetColumnFilter',
      menuTabs : ['filterMenuTab'],

      width : 300
    },
    {
      field: "discoveryDate",
      headerName: "Discovered",
      valueFormatter : obj => Utils.formattedDate(obj.value),
      sortable: true, 
      width :120,
      menuTabs : ['filterMenuTab'],
    },
    {
      field: "updateDate",
      headerName: "Updated",
      valueFormatter : obj => Utils.formattedDate(obj.value),
      sortable: true, 
      width :120,
      menuTabs : ['filterMenuTab'],
    },
    {
      field: "manuallyCorrected",
      headerName: "Checked",
      sortable: true, 
      valueFormatter : obj => obj.value === 1 ? 'yes' : 'no',
      filter: 'agSetColumnFilter',
      menuTabs : ['filterMenuTab'],

      width : 100
    },
    {
      field: "rarsDataChanged",
      headerName: "Changed",
      sortable: true, 
      valueFormatter : obj => obj.value === 1 ? 'yes' : 'no',
      filter: 'agSetColumnFilter',
      menuTabs : ['filterMenuTab'],

      width : 100
    },
    {
      field: "digest",
      headerName: "MD5",
      sortable: true, 
      filter: 'agSetColumnFilter',
      menuTabs : ['filterMenuTab'],

      width : 300
    },
  ]
}
}

export default getGridConfig