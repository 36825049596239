import React, { Component } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

import CloseIcon from '@material-ui/icons/Close'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'

class ConfirmClose extends Component {

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.checkForUnsavedChanges}>
        <DialogTitle>You have unsaved changes. Are you sure you wish to exit?</DialogTitle>
        <DialogActions>
          <Tooltip title="Save">
            <IconButton onClick={this.props.save} color="primary" size="large">
              <SaveIcon/>
            </IconButton>
          </Tooltip>

          <Tooltip title="Do not close">
            <IconButton onClick={this.props.cancel} color="secondary" size="large">
              <CancelIcon/>
            </IconButton>
          </Tooltip>

          <Tooltip title="Close and lose edits">
            <IconButton onClick={this.props.close} color="secondary" size="large">
              <CloseIcon/>
            </IconButton>
          </Tooltip>
        </DialogActions>
      </Dialog>
    )
  }
}

export default ConfirmClose