import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Axios from 'axios'
import cookie from 'react-cookies'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import TextField from '@material-ui/core/TextField'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import getRoutes from './config/routes'

import Config from './config'

const styles = theme => ({
  DialogContent : {
    padding : '20px 24px 24px',
    width : 400,
  }
})

class Login extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {loginError:null}
  }

  componentDidMount() {
    this.props.setLoginState(false)
    cookie.remove('config',{expires:0, path: '/'})
    Config.deleteConfig('awsConfig')
    Config.deleteConfig('keys')
    Config.deleteConfig('userData')
    Config.deleteConfig('authModes')
    Config.deleteConfig('adminModes')
    Config.setConfig('routes',[])
  }

 onKeyDown = event => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      this.login()
    }
  }

  async login () {

    let data = {
      "username" : this.usernameFieldRef.value,
      "password" : this.passwordFieldRef.value
    }

    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}login`,
      data : data,
      headers : {
        'Content-type': 'application/json',
      },
    }

    try
    {
      let result = await Axios(options)

      console.log("login result",result)

      if (result.status === 200)
      {
        this.setState({loginError:null})
        this.userIsLoggedIn(result.data)
      }
    }
    catch(err)
    {
      console.log("Login error",err)
      this.setState({loginError:"Incorrect login credentials"})
    }
  }

  userIsLoggedIn(config) {

    console.log("userIsLoggedIn",config)

    cookie.save('config', JSON.stringify(config), { path: '/' })

    let authModes = JSON.parse(config.userData.authModes)
    let adminModes = {
      owner : config.userData.systemOwner,
      system : config.userData.systemAdmin,
      licensee : config.userData.licenseeAdmin,
      organisation : config.userData.organisationAdmin,
    }
    let routes = getRoutes(authModes,adminModes)

    Config.setConfig('awsConfig',config.awsConfig)
    Config.setConfig('keys',config.keys)
    Config.setConfig('userData',config.userData)
    Config.setConfig('authModes',authModes)
    Config.setConfig('adminModes',adminModes)
    Config.setConfig('routes',routes)

    this.props.setLoginState(true)
    this.props.history.push(routes[0].url)
  }
  
  render() {

    const { classes } = this.props

    return (
      <Dialog
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Login</DialogTitle>

        <DialogContent className={classes.DialogContent}>

          <form>

          <TextField
            autoFocus
            id="username"
            label="Username"
            fullWidth
            inputRef={ref => this.usernameFieldRef = ref}
          />

          <TextField
            id="password"
            label="Password"
            fullWidth
            type="password"
            inputRef={ref => this.passwordFieldRef = ref}
            onKeyDown={this.onKeyDown}
          />            

          </form>

          <div style={{color:'red',paddingTop:'20px',fontStyle:'italic'}}>{this.state.loginError}</div>

        </DialogContent>

        <DialogActions>
          <Button onClick={event => this.login()} color="primary">
            Login
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(Login))