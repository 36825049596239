import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import { LineChart, Line, XAxis,YAxis,CartesianGrid,Tooltip,Legend } from 'recharts'


const styles = theme => ({
  DialogContent : {
    padding : '20px 24px 24px',
  }
})

class CustomizedAxisTick extends React.Component {

  render () {
    const {x, y, payload} = this.props;
    
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">{payload.value}</text>
      </g>
    )
  }
}


class Chart extends Component {

  closeChart = () => {
    this.props.close()
  }

  render() {

    const { classes } = this.props

    return (

      <LineChart width={1000} height={300} data={this.props.data} margin={{top: 30, right: 30, left: 30, bottom: 30}} className="S3Chart">
       <XAxis dataKey="date" height={55} tick={<CustomizedAxisTick/>} interval={1}/>
       <YAxis yAxisId="size" label={{value:"Bucket Size (MB)", angle:90, position: 'insideTopLeft', offset:-10}}/>
       <YAxis yAxisId="items" label={{value:"Number of items", angle:-90, position: 'insideTopRight', offset:-10}} orientation="right" />
       <CartesianGrid strokeDasharray="3 3"/>
       <Tooltip/>
       <Legend verticalAlign="top" align="right" className={classes.Legend}/>

        <Line type="monotone" dataKey="BucketSizeBytes" stroke="#65AD63" yAxisId="size" name="Bucket Size (MB)"/>
        <Line type="monotone" dataKey="NumberOfObjects" stroke="#0000FF" yAxisId="items" name="Number of Objects"/>

      </LineChart>

    )
  }
}

Chart.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Chart)

