import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import AlertsCard from '../dashboard//dashboard-alerts'

import Alerts from '../data-providers/alerts'

const spacing = 2

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin : 'auto',
    width : 'calc(100% + 48px)'
  },
  h1 : {
    textAlign : 'center',
    color : '#A7CADE',
  },
  chart : {
    backgroundColor : 'white',
    borderRadius : '5px',
  },
  paper: {
    width: 'calc(100%)',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    backgroundColor : '#fff',
  },
  table: {
    minWidth: 700,
    width : '100%',
    tableLayout : 'auto'
  },
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    padding : '4px 5px 4px 5px'
  },
  errored : {
    color : 'red'
  },
  clickableTableRow: {
    cursor : 'pointer'
  },
  button: {
    margin: theme.spacing(),
  },  
  stripeRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  bold : {
    fontWeight : "bold"
  },
  better : {
    color : "green"
  },
  worse : {
    color : "red"
  }
})

class Bookings extends Component {

  constructor(props, context) {
    super(props, context)  
    this.state = {info:[],warning:[],error:[]}
  }

  componentDidMount() {
   // setTimeout(this.refreshAlerts,1)
  }

  componentDidUpdate(prevProps) {
    if(prevProps.alerts !== this.props.alerts)
    {
      this.refreshAlerts()
    }
  }

  async refreshAlerts() {
    let promises  = []

    promises.push(this.refreshAlertType("info"))
    promises.push(this.refreshAlertType("warning"))
    promises.push(this.refreshAlertType("error"))
      
    await Promise.all(promises)
  }

  async refreshAlertType(type) {
    
    let alerts = await Alerts(type,true)

    this.setState({[type] : alerts})
  }


  render() {

    const { classes } = this.props
        
    return (
      <React.Fragment>
        <Grid container className={classes.root} spacing={spacing}>

          <Grid item xs={4} container direction="column" spacing={spacing}>
            <AlertsCard alerts={this.state.info} sleeping={this.props.sleeping} deleteAlert={this.props.deleteAlert} history={this.props.history}/>
          </Grid>

          <Grid item xs={4} container direction="column" spacing={spacing}>
            <AlertsCard alerts={this.state.warning} sleeping={this.props.sleeping} deleteAlert={this.props.deleteAlert} history={this.props.history}/>
          </Grid>

          <Grid item xs={4} container direction="column" spacing={spacing}>
            <AlertsCard alerts={this.state.error} sleeping={this.props.sleeping} deleteAlert={this.props.deleteAlert} history={this.props.history}/>
          </Grid>

        </Grid>
      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(Bookings))