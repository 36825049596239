import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'

import Axios from 'axios'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

import ViewIcon from '@material-ui/icons/OpenInNew'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Config from '../config'

import ScrollableAnchor from 'react-scrollable-anchor'
import { configureAnchors } from 'react-scrollable-anchor'
configureAnchors({offset: -60, scrollDuration: 200, scrollUrlHashUpdate:false})

const DataLimit = 100 // How many rows of data to load from DB per request

const styles = theme => ({
  paper: {
    width: 'calc(100%)',
    marginTop: theme.spacing() * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
    width : '100%',
    tableLayout : 'auto'
  },
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    padding : '4px 15px 4px 15px'
  },
  errored : {
    color : 'red'
  },
  clickableTableRow: {
    cursor : 'pointer'
  },
  button: {
    margin: theme.spacing(),
  },  
  stripeRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  }, 
})

var visible = false

class CoachList extends PureComponent {

  constructor(props, context) {
    super(props, context)
    this.state = {coaches:{data:[]},filterMode:'all',filteredCoaches:[]}
  }

  async init() {
    visible = true
    this.getCoaches()
  }

  async componentDidMount() {
    this.init()
  }

  componentWillUnmount() {
    visible = false
  }

  async getCoaches() {

    if (!visible)
    {
      return
    }

    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg: "Coach",
        fn : "getCoaches",
        params : {
          limit: DataLimit,
          offset : this.state.coaches.data.length
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey(),
      },
    }

    Axios(options).then(result => {

      let coaches = Object.assign({},this.state.coaches)

      coaches.data = coaches.data.concat(result.data.data)
      coaches.more = result.data.more

      // Abort loading if we have changed view
      if (!visible)
      {
        return
      }

      this.setState({coaches},() => {
        this.filterCoaches()
      })

      if (coaches.more)
      {
        this.getCoaches()
      }
    })
    .catch(err => {
     console.error(err)
    })    
  }

  filterCoaches()
  {
    switch(this.state.filterMode)
    {
      case 'error':
      {
        let coaches = this.state.coaches

        let filteredCoaches = []

        for (let c=0;c<coaches.data.length;c++)
        {
          if (this.attentionNeededClass(coaches.data[c]))
          {
            filteredCoaches.push(coaches.data[c])
          }
        }

        this.setState({filteredCoaches})
        break
      }

      case 'nodirection':
      {
        let coaches = this.state.coaches
        
        let filteredCoaches = []

        for (let c=0;c<coaches.data.length;c++)
        {
          if (coaches.data[c].travelDirection === 'unknown')
          {
            filteredCoaches.push(coaches.data[c])
          }
        }

        this.setState({filteredCoaches})
        break
      }
      case 'bikes':
      {
        let coaches = this.state.coaches
        
        let filteredCoaches = []

        for (let c=0;c<coaches.data.length;c++)
        {
          if (coaches.data[c].bike)
          {
            filteredCoaches.push(coaches.data[c])
          }
        }

        this.setState({filteredCoaches})
        break
      }

      case 'noclass':
      {
        let coaches = this.state.coaches
        
        let filteredCoaches = []

        for (let c=0;c<coaches.data.length;c++)
        {
          if (!coaches.data[c].coachClass || coaches.data[c].coachClass > 3)
          {
            filteredCoaches.push(coaches.data[c])
          }
        }

        this.setState({filteredCoaches})
        break
      }

      case 'notchecked':
      {
        let coaches = this.state.coaches
        
        let filteredCoaches = []

        for (let c=0;c<coaches.data.length;c++)
        {
          if (!coaches.data[c].manuallyChecked)
          {
            filteredCoaches.push(coaches.data[c])
          }
        }

        this.setState({filteredCoaches})
        break
      }

      default: 
      {  
        this.setState({filteredCoaches:[...this.state.coaches.data]})
        break
      }     
    }
  }

  setCoachFilter(mode)
  {
    this.setState({filterMode:mode},() => {
      this.filterCoaches()
    })
  }

  drillDown(event,toc,coachTypeId,direction)
  {
    this.props.history.push(`${this.props.history.location.pathname}/${toc}/${coachTypeId}/${direction}`)
  }

  renderSeats(coach)
  {
    let data = {...coach}

    if (data.seatsSeen === null) { data.seatsSeen = 0 }

    let numPlaces = data.numPlaces ? parseInt(data.numPlaces,10) : 0

    let string = data.seats === numPlaces ? numPlaces : `${data.seats} / ${numPlaces}`

    if (data.seatsSeen !== data.seats)
    {
      string += ` (${data.seatsSeen})`
    }

    return string
  }

  seatsClass(coach)
  {
    const { classes } = this.props
    
    if (coach.seats !== parseInt(coach.numPlaces,10)) { return classes.errored }
  }

  coachClassClass(coach)
  {
    const { classes } = this.props
    
    if (coach.coachClass === 0) { return classes.errored }
  }

  facingClass(coach)
  {
    const { classes } = this.props

    if (coach.noFacing && coach.travelDirection !== 'unknown')
    {
      return classes.errored
    }
  }

  aislePosClass(coach)
  {
    const { classes } = this.props

    if (coach.noAislePos && coach.travelDirection !== 'unknown') { return classes.errored }
  }

  nearSummary(coach)
  {
    let near = ""
    
    if (coach.nearLuggage) { near += "L"}
    if (coach.nearToilets) { near += "T"}
    if (coach.nearFood) { near += "F"}

    return near
  }

  attentionNeededClass(coach)
  {
    const { classes } = this.props

    if (coach.needsAttention) { return classes.errored }
    if (coach.seats !== parseInt(coach.numPlaces,10)) { return classes.errored }
    if (coach.coachClass === 0) { return classes.errored }
    if (coach.noFacing && coach.travelDirection !== 'unknown') { return classes.errored }
    if (coach.noClass || coach.noSeatType || coach.noSeatPos) { return classes.errored }
    if (coach.noAislePos) { return classes.errored }
  }

  render() {

  const { classes } = this.props

    return (

      <React.Fragment>

        <Paper className={classes.paper}>
          <Button onClick={event => this.setCoachFilter('all')}>Show all coaches</Button>
          <Button onClick={event => this.setCoachFilter('error')}>Coaches with errors</Button>
          <Button onClick={event => this.setCoachFilter('nodirection')}>No travel direction</Button>
          <Button onClick={event => this.setCoachFilter('bikes')}>Bike places</Button>
          <Button onClick={event => this.setCoachFilter('noclass')}>Undefined class</Button>
          <Button onClick={event => this.setCoachFilter('notchecked')}>Not Checked</Button>

          <Table className={classes.table}>

            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>Our ID</TableCell>
                <TableCell className={classes.tableCell}>NRS</TableCell>
                <TableCell className={classes.tableCell}>Direction</TableCell>
                <TableCell className={classes.tableCell}>Description</TableCell>
                <TableCell className={classes.tableCell}>Class</TableCell>
                <TableCell className={classes.tableCell}>Accom</TableCell>
                <TableCell className={classes.tableCell}>Type</TableCell>
                <TableCell className={classes.tableCell}>Seats (seen)</TableCell>
                <TableCell className={classes.tableCell}>Class</TableCell>
                <TableCell className={classes.tableCell}>Facing</TableCell>
                <TableCell className={classes.tableCell}>Seat Type</TableCell>
                <TableCell className={classes.tableCell}>Near</TableCell>
                <TableCell className={classes.tableCell}>Checked</TableCell>
                <TableCell className={classes.tableCell}>Attention</TableCell>
              </TableRow>
            </TableHead>

              <TableBody>
                {this.state.filteredCoaches.map((coach,index) => {
                  return (
                    <ScrollableAnchor id={coach.toc + coach.typeId} key={coach.toc + coach.typeId} >
                      <React.Fragment>
                        <TableRow className={classes.clickableTableRow + ' ' + classes.stripeRow} onDoubleClick={event => this.drillDown(event,coach.toc,coach.typeId,coach.direction)}>
                          <TableCell numeric="true" className={classes.tableCell}>{coach.groupTypeId}</TableCell>
                          <TableCell numeric="true" className={classes.tableCell}><span className={this.attentionNeededClass(coach)}>{coach.toc}{coach.typeId}</span></TableCell>
                          <TableCell className={classes.tableCell}>{coach.direction}</TableCell>
                          <TableCell className={classes.tableCell}><span className={this.attentionNeededClass(coach)}>{coach.description}</span></TableCell>
                          <TableCell numeric="true" className={classes.tableCell}><span className={this.coachClassClass(coach)}>{coach.coachClass}</span></TableCell>
                          <TableCell className={classes.tableCell}>{coach.accomType}</TableCell>
                          <TableCell className={classes.tableCell}>{coach.type}</TableCell>
                          <TableCell numeric="true" className={classes.tableCell}><span className={this.seatsClass(coach)}>{this.renderSeats(coach)}</span></TableCell>
                          <TableCell numeric="true" className={classes.tableCell}><span className={classes.errored}>{coach.noClass}</span></TableCell>
                          <TableCell numeric="true" className={classes.tableCell}><span className={this.facingClass(coach)}>{coach.noFacing}</span></TableCell>
                          <TableCell numeric="true" className={classes.tableCell}><span className={classes.errored}>{coach.noSeatType}</span></TableCell>
                          <TableCell numeric="true" className={classes.tableCell}><span className={this.attentionNeededClass(coach)}>{this.nearSummary(coach)}</span></TableCell>
                          <TableCell className={classes.tableCell}>{coach.manuallyChecked}</TableCell>
                          <TableCell className={classes.tableCell}>{coach.needsAttention}</TableCell>
                          <TableCell className={classes.tableCell}><IconButton color="primary" onClick={event => this.drillDown(event,coach.groupTypeId)}><ViewIcon /></IconButton></TableCell>
                        </TableRow>
                        {(coach.needsAttention && coach.comment && coach.comment.length) ?
                          <TableRow className={classes.clickableTableRow + ' ' + classes.stripeRow} onDoubleClick={event => this.drillDown(event,coach.toc,coach.typeId,coach.direction)}>
                            <TableCell colSpan={15} className={classes.tableCell}>{coach.comment}</TableCell>
                          </TableRow> : null}
                      </React.Fragment>
                    </ScrollableAnchor>
                  )
              })}
            </TableBody>
          </Table>
          {this.state.coaches.more && 
            <Button className="autoMargin" onClick={event => this.getCoaches()}>load more...</Button>
          }
    
        </Paper>

      </React.Fragment>
    )
  }
}

CoachList.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(CoachList))
