import React, { PureComponent } from 'react'
import { withRouter, NavLink } from 'react-router-dom'

import Axios from 'axios'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Config from '../config'
import Utils from '../utils'

const styles = theme => ({
  paper: {
    width: 'calc(100%)',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
    width : '100%',
    tableLayout : 'auto'
  },
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    padding : '4px 15px 4px 15px'
  },
  clickableTableRow: {
    cursor : 'pointer'
  },
  button: {
    margin: theme.spacing(),
  },  
  error : {
    color : 'red'
  },
  stripeRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  }, 
})

class PublishedDetail extends PureComponent {

  constructor(props, context) {
    super(props, context)
    this.state = {stats:[],key:""}
  }

  async componentDidMount() {
    let {key} = this.props.match.params

    this.setState({key},this.getStats)
  }

  async getStats() {

    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "DM",
        fn: "getPublishedDataStatsForKey",
        params : {
          key : this.state.key
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey(),
      },
    }

    Axios(options).then(result => {

      let stats = result.data.data

      this.setState({stats})
    })
    .catch(err => {
     console.error(err)
    })    
  }

  render() {

  const { classes } = this.props

    return (

      <React.Fragment>

        <div className="stageMenu">
          <Button component={NavLink} to="/data-manager/published" activeClassName="active">published</Button>
          <Button component={NavLink} to="/data-manager/processed" activeClassName="active">processed</Button>
          <Button component={NavLink} to="/data-manager/incoming" activeClassName="active">incoming</Button>
        </div>

        <Typography variant="subtitle1" gutterBottom style={{clear:'both'}}>
          published Data : {this.state.key} 
        </Typography>

        <Paper className={classes.paper}>
          <Table className={classes.table}>

            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>Version ID</TableCell>
                <TableCell className={classes.tableCell}>Uploaded</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.state.stats.map((stat,index) => {
                
                return (

                  <TableRow key={index} className={classes.clickableTableRow + ' ' + classes.stripeRow}>
                    <TableCell className={classes.tableCell}>{stat.version}</TableCell>
                    <TableCell className={classes.tableCell}>{Utils.formattedDateTime(stat.uploaded)}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
    
        </Paper>

      </React.Fragment>
    )
  }
}

PublishedDetail.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(PublishedDetail))
