import AWS from 'aws-sdk'
import Moment from 'moment-timezone'
import Config from '../config'

const hour = 60*60
const day = hour*24

export default async function getStats(namespace,mode) {

  let awsConfig = Config.getConfig("awsConfig")

  if (!awsConfig) { return }
  const CW = new AWS.CloudWatch(awsConfig)

  let startTime = Moment().tz('Europe/London').startOf("day")
  let endTime = Moment().tz('Europe/London').endOf("day")

  let params = {
    StartTime : startTime.toISOString(),
    EndTime : endTime.toISOString(),
    MetricDataQueries : []
  }

  params.MetricDataQueries.push(getMetricDataQuery(namespace,mode,'Invocations',"Sum",day))
  params.MetricDataQueries.push(getMetricDataQuery(namespace,mode,'Duration',"Average",day))
  params.MetricDataQueries.push(getMetricDataQuery(namespace,mode,'Duration',"Maximum",day))
  params.MetricDataQueries.push(getMetricDataQuery(namespace,mode,'Duration',"Minimum",day))
  params.MetricDataQueries.push(getMetricDataQuery(namespace,mode,'Errors',"Sum",day))
  
  let data = (await CW.getMetricData(params).promise()).MetricDataResults

  let result = {}

  for (let d=0;d<data.length;d++)
  {
    let dataItem = data[d]

    result[dataItem.Id] = dataItem.Values.length ? dataItem.Values[0] : 0
  }
  
  return result
}

function getMetricDataQuery (namespace,mode,MetricName,Stat,period) {

  return {
    Id : MetricName.toLowerCase() + Stat.toLowerCase(),
    MetricStat : {
      Metric : {
        MetricName : MetricName,
        Namespace : `SMARTIX/${namespace.toUpperCase()}`,
        Dimensions: [
          {
            Name: "mode",
            Value: mode
          }
        ]
      },
      Period : period,
      Stat : Stat,
    },
    ReturnData : true
  }
}