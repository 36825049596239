import AWS from 'aws-sdk'
import Config from '../config'


export async function getRdsInstances() {
  try
  {
    const RDS = new AWS.RDS(Config.getConfig("awsConfig"))
    let data = await RDS.describeDBInstances().promise()
    return data.DBInstances
  }
  catch(err)
  {
    console.log("getRdsInstances error",err)
    throw err
  }
}


export async function restartRdsInstance(rds) {
  try
  {
    const RDS = new AWS.RDS(Config.getConfig("awsConfig"))
    await RDS.rebootDBInstance({DBInstanceIdentifier:rds.DBInstanceIdentifier,ForceFailover:rds.MultiAZ}).promise()
  }
  catch(err)
  {
    console.log("restartRdsInstance error",err)
    throw err
  }
}