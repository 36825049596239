import Moment from 'moment-timezone'
import Utils from '../utils'

const Config = {
   
  visibleRowBufferSize : 10,

  columnDefs : [
    {
      field: "weekday",
      headerName: "DAY",
      headerClass : "dateHeader",
      menuTabs : [],
      filter: true,
      width : 70,
      valueFormatter : obj => obj.value.substr(0,3),
      pinnedRowCellRenderer: 'customPinnedRowRenderer',
      filterParams: {
        // provide all days, even if days are missing in data!
        values: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        suppressSorting : true,
      }
    },
    {
      field: "date",
      headerName: "DATE",
      menuTabs : [],
      sortable: true, 
      width : 120,
      headerClass : "dateHeader",
      filter: 'agDateColumnFilter',
      filterParams : {
        filterOptions : ['inRange','equals','empty'],
        suppressAndOrCondition : true,
        inRangeInclusive : true,
        comparator : function (filter, value) {
          let filterMoment = Moment(filter)
          let valueMoment = Moment(value).tz('Europe/London')

          if (valueMoment.isSame(filterMoment,'day')) return 0
          if (valueMoment.isBefore(filterMoment,'day')) return -1
          if (valueMoment.isAfter(filterMoment,'day')) return 1
        },
      },
      valueFormatter : obj => obj.value === "AVERAGE" ? "AVERAGE" : Utils.formattedDate(obj.value),
    },
  ]
}

let item = {
  sortable: true,
  filter: false,
  width : 50,
  menuTabs : [],
  cellClass : calculateCellClasses,
  cellStyle : {padding:0,margin:0,paddingRight:'10px'},
}

for (let f=0;f<24;f++)
{
  let field = f.toString()
  let headerName = `${field.padStart(2,'0')}-${(f+1).toString().padStart(2,'0')}`
  Config.columnDefs.push({...item,field,headerName})
}

Config.columnDefs.push({...item,field:'total',headerName:'TOTAL',sortable:true})
Config.columnDefs.push({...item,field:'totalnow',headerName:'NOW',sortable:true})

function calculateCellClasses(cellData) {

  if (cellData.data.date === "AVERAGE") { return "numberCellBold" }
  if (isNaN(cellData.colDef.field) && !["total","totalnow"].includes(cellData.colDef.field)) { return "numberCell"}

  let dayTotals = {}
  let allTotals = {total:0,totalnow:0,count:0}

  for (let h=0;h<24;h++)
  {
    allTotals[h] = 0
  }

  cellData.api.forEachNode(node => {

    if (!dayTotals[node.data.weekday])
    {
      dayTotals[node.data.weekday] = {...node.data,count:1}
    }
    else
    {
      dayTotals[node.data.weekday].count ++
      dayTotals[node.data.weekday].total += node.data.total
      dayTotals[node.data.weekday].totalnow += node.data.totalnow

      for (let h=0;h<24;h++)
      {
        dayTotals[node.data.weekday][h] += node.data[h]
      }
    }

    allTotals.count ++
    allTotals.total += node.data.total
    allTotals.totalnow += node.data.totalnow

    for (let h=0;h<24;h++)
    {
      allTotals[h] += node.data[h]
    }
  })

  let dayAverages = {}

  for (let day in dayTotals)
  {
    dayAverages[day] = {
      total : Math.round(dayTotals[day].total / dayTotals[day].count),
      totalnow : Math.round(dayTotals[day].totalnow / dayTotals[day].count),
    }

    for (let h=0;h<24;h++)
    {
      dayAverages[day][h] = Math.round(dayTotals[day][h] / dayTotals[day].count)
    }
  }

  let allAverages = {
    total : Math.round(allTotals.total / allTotals.count),
    totalnow : Math.round(allTotals.totalnow / allTotals.count),
  }

  for (let h=0;h<24;h++)
  {
    allAverages[h] = Math.round(allTotals[h] / allTotals.count)
  }

  let myClasses = ["numberCell"]

  let val = cellData.value
  let idx = cellData.colDef.field

  let rowDate = Moment(cellData.data.date,"YYYY-MM-DD")
  let cellDate = rowDate.add(parseInt(idx),'hours')
  let now = Moment().tz('Europe/London')
  let cellDay = cellData.data.weekday

  if (cellDate < now)
  {
    if (val > dayAverages[cellDay][idx]) { myClasses.push("betterThanAverageDay") }
    if (val < dayAverages[cellDay][idx]) { myClasses.push("worseThanAverageDay") }

    if (val > allAverages[idx]) { myClasses.push("betterThanAverageAll") }
    if (val < allAverages[idx]) { myClasses.push("worseThanAverageAll") }
  }

  return myClasses.join(" ")
}


export default Config