var config = {

  apptitle : "Smartix Admin.....",
  adminApi : "https://api.hazardousfrog.com/admin-api/",
  //adminApi : "http://localhost:4001/prod/",

  socketUrl : "wss://m9tnbq961a.execute-api.eu-west-1.amazonaws.com/prod/",
  deliveryReportsS3Url : "https://s3.console.aws.amazon.com/s3/buckets/com.hazardousfrog.rdg.reports/?region=eu-west-1&tab=overview&prefixSearch=",
  mode : 'live',
  latestVersion : "v3",
  wakingPeriod : (15 * 60 * 1000), // 15 minutes

  aws : {},

  coachHeight : 300,
  seatSize : {
    height : 50,
    width : 40
  },

  seatYCoords : [
    {
      min : 0,
      max : 49,
      pos : 10
    },
    {
      min : 51,
      max : 149,
      pos : 70
    },
    {
      min : 150,
      max : 210,
      pos : 180
    },
    {
      min : 200,
      max : 300,
      pos : 240
    },
  ],

  routes : []
}

module.exports = {

  setConfig : function(key,value)
  {
    config[key] = value
  },

  getConfig : function(key)
  {
    return config[key]
  },

  deleteConfig : function(key)
  {
    delete config[key]
  },

  getAPIKey : function()
  {
    let url = this.getConfig("adminApi")

    if (url.substr(7,9) === "localhost")
    {
      return config.keys.local
    }
    else
    {
      return config.keys[config.mode]
    }
  },
}