import AWS  from 'aws-sdk'
import Axios from 'axios'

import Config from '../config'

export async function getLicensees() {

  try
  {
    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "Users",
        fn : "getLicensees",
        params : {}
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey('adminApi'),
      },
    }

    let result = await Axios(options)

    return result.data.data
  }
  catch(err)
  {
    console.log(JSON.stringify(err,null,2))
    throw err
  }
}


export async function toggleDevMode(keyId,enable) {
  try
  {
    const APIGateway = new AWS.APIGateway(Config.getConfig("awsConfig"))

    if (enable)
    {
      let reqData = {
        keyId : keyId,
        keyType : "API_KEY",
        usagePlanId : usagePlans.dev
      }

      await APIGateway.createUsagePlanKey(reqData).promise()
    }
    else
    {
      let reqData = {
        keyId : keyId,
        usagePlanId : usagePlans.dev
      }

      await APIGateway.deleteUsagePlanKey(reqData).promise()
    }
  }
  catch(err)
  {
    console.log(JSON.stringify(err,null,2))
    throw err
  }
}


export async function updateUser(userId,user,newPassword) {
  try
  {
    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "Users",
        fn : "updateUser",
        params : {
          user : user,
          userId : userId,
          newPassword : newPassword,
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey('adminApi'),
      },
    }

    let result = await Axios(options)

    return result.data.data
  }
  catch(err)
  {
    console.log(JSON.stringify(err,null,2))
    throw err
  }
}

export async function getUsersAndKeys(licenseeCode) {

  try
  {
    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "Users",
        fn : "getUsersAndKeys",
        params : {
          licenseeCode : licenseeCode
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey('adminApi'),
      },
    }

    let result = await Axios(options)

    return result.data.data
  }
  catch(err)
  {
    console.log(JSON.stringify(err,null,2))
    throw err
  }
}

export async function getUsagePlansAndKeys() {

  try
  { 
    const APIGateway = new AWS.APIGateway(Config.getConfig("awsConfig"))

    let usagePlans = await APIGateway.getUsagePlans().promise()
    let userPlans = usagePlans.items.filter(p => !["data-manager","admin-tools"].includes(p.name))

    for (let p of userPlans)
    {
      p.keys = (await APIGateway.getUsagePlanKeys({usagePlanId:p.id}).promise()).items.map(k => k.id)
    }

    return userPlans
  }
  catch(err)
  {
    console.log(JSON.stringify(err,null,2))
    throw err
  }
}

export async function toggleUserEnabled(grid) {

  try
  { 
    let enabled = 1-grid.data.enabled

    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "Users",
        fn : "setUserEnabledState",
        params : {
          username : grid.data.username,
          enabled
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey('adminApi'),
      },
    }

    await Axios(options)

    grid.data.enabled = enabled
    grid.api.refreshCells({rowNodes:[grid.node]})
  }
  catch(err)
  {
    console.log(JSON.stringify(err,null,2))
    throw err
  }
}

// These are IDs defined in our AWS account
const usagePlans = {
  test : "864sa6",
  live : "9v4h78",
  dev : "tl9m6f",
}
export async function makeAPIKey(username,organisation,mode,devPlan) {
  try
  {
    const APIGateway = new AWS.APIGateway(Config.getConfig("awsConfig"))

    let orgshortname = organisation.split(" ")[0]

    let reqData1 = {
      name : `${orgshortname} - ${username} (${mode})`,
      description : `${organisation}
        ${username}
        ${mode}`,
      enabled : true
    }

    let keyResult = await APIGateway.createApiKey(reqData1).promise()

    let reqData2 = {
      keyId : keyResult.id,
      keyType : "API_KEY",
      usagePlanId : usagePlans[mode]
    }

    await APIGateway.createUsagePlanKey(reqData2).promise()


    if (devPlan)
    {
      await APIGateway.createUsagePlanKey({...reqData2,usagePlanId:usagePlans.dev}).promise()
    }

    return keyResult
  }
  catch(err)
  {
    console.log(JSON.stringify(err,null,2))
    throw err
  }
}

export async function addKeyToUser(data) {
  try
  {
    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "Users",
        fn : "addKeyToUser",
        params : data
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey('adminApi'),
      },
    }

    let result = await Axios(options)

    return result.data.data
  }
  catch(err)
  {
    console.log(JSON.stringify(err,null,2))
    throw err
  }
}

export async function addUser(user,apiKeys) {

  try
  { 
    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "Users",
        fn : "addUser",
        params : {
          user,
          apiKeys
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey('adminApi'),
      },
    }

    let result = await Axios(options)

    return result.data.data
  }
  catch(err)
  {
    console.log(JSON.stringify(err,null,2))
    throw err
  }
}

export async function deleteUser(grid) {

  try
  { 
    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "Users",
        fn : "deleteUser",
        params : {
          id : grid.data.userId,
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey('adminApi'),
      },
    }

    await Axios(options)
  }
  catch(err)
  {
    console.log(JSON.stringify(err,null,2))
    throw err
  }
}

export async function deleteAPIKey(keyId) {
  try
  {
    const APIGateway = new AWS.APIGateway(Config.getConfig("awsConfig"))
    
    // Delete key from AWS
    await APIGateway.deleteApiKey({apiKey:keyId}).promise()

    // Delete from our DB
    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "Users",
        fn : "deleteAPIKey",
        params : {
          id : keyId,
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey('adminApi'),
      },
    }

    await Axios(options)
  }
  catch(err)
  {
    console.log(JSON.stringify(err,null,2))
    throw err
  }
}

export async function addAPIKey(keyId) {
  try
  {
    console.log("addAPIKey")
  }
  catch(err)
  {
    console.log(JSON.stringify(err,null,2))
    throw err
  }
}

