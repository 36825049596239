import _ from 'lodash'
import Axios from 'axios'
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Badge from '@material-ui/core/Badge'

import CloseIcon from '@material-ui/icons/Close'
import SaveIcon from '@material-ui/icons/Save'
import { AiOutlineUndo } from "react-icons/ai"
import { AiOutlineRedo } from "react-icons/ai"


import Config from '../config'

import SeatPositionEditor from './position-editor'
import SeatAttributeEditor from './attribute-editor'
import AreaEditor from './area-editor'
import CoachOverview from './coach-overview'
import DuplicateCoachInstances from './duplicate-coach-instances'
import ImageEditor from './image-editor'
import CropAndResizeEditor from './crop-and-resize-editor'
import ConfirmCloseDialog from './confirm-close'

import './styles.css'

const styles = theme => ({
  table: {
    width: 'calc(100% - 20)',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  ownerText: {
    fontSize : '12px',
    float : 'right',
  },
})

const defaultState = {
  instances : [],
  seats:[],
  imageDimensions:{x:0,y:0},
  showDuplicates:false,
  showTocImage:false,
  visibleTab : 0,
  actionButtons : null,
  selectedAreaType : "table",
  imageActionType : "crop",
  promptToSaveChanges : false,
  history : [],
  future : [],
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <React.Fragment>
      {value === index && (
        <React.Fragment>{children}</React.Fragment>
      )}
    </React.Fragment>
  )
}

class ImagePopup extends Component {

  constructor(props) {
    super(props)
    this.state = _.cloneDeep(defaultState)

    let adminModes = Config.getConfig("adminModes")
    let authModes = Config.getConfig("authModes")

    // eslint-disable-next-line
    TabPanel = TabPanel.bind(this)

    this.state.isEditor = adminModes.owner || adminModes.system || authModes.includes("carriageEditor")
  }

  componentDidUpdate(prevProps) {
    let coach = this.props.coach

    if (coach && (!prevProps.coach || (this.props.coach.ourTypeId !== prevProps.coach.ourTypeId)))
    {
      let state = _.cloneDeep(defaultState)

      this.setState({...state,coach:_.cloneDeep(this.props.coach)},() => {
        this.getCoachInstances()
        this.getCoachSeats()
      })
    }
    else
    {
      if (coach && this.props.allCoaches !== prevProps.allCoaches)
      {
        this.getCoachInstances()
      }
    }
  }

  async getCoachInstances() {

    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg: "RarsCoaches",
        fn : "getCoachInstances",
        params : {
          ourTypeId : this.state.coach.ourTypeId
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey(),
      },
    }

    let instances = (await Axios(options)).data.instances
    this.setState({instances})
  }

  async getCoachSeats() {

    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg: "RarsCoaches",
        fn : "getCoachSeats",
        params : {
          ourTypeId : this.state.coach.ourTypeId
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey(),
      },
    }

    let seats = (await Axios(options)).data.seats

    this.setState({seats})
  }

  toggleViewOption = option => {
    this.setState({[option]:! this.state[option] })
  }
   
  handleChangeTab = (event, visibleTab) => {
    this.setState({visibleTab})
  }

  updateCoach = coach => {
    let history = this.state.history

    let historyItem = {
      coach :  _.cloneDeep(this.state.coach),
      seats :  _.cloneDeep(this.state.seats),
      tab : this.state.visibleTab,
    }

    history.push(historyItem)
    let future = []
    
    this.setState({coach,history,future})
  }

  updateSeats = seats => {

    console.log("updateSeats",seats)
    let history = this.state.history

    let historyItem = {
      coach :  _.cloneDeep(this.state.coach),
      seats :  _.cloneDeep(this.state.seats),
      tab : this.state.visibleTab,
    }

    history.push(historyItem)
    let future = []

    this.setState({seats,history,future})
  }

  setActionButtons = actionButtons => {
    this.setState({actionButtons})
  }

  handleRadioChange = (attribute,value) => {
    return new Promise(resolve => {
      let state = this.state
      state[attribute] = value
      this.setState(state, () => {
        resolve()
      })
    })
  }


  save = async () => {
    let promises = []
    promises.push(this.saveCoach())
    promises.push(this.saveSeats())
    await Promise.all(promises)

    let history = []
    let future = []
    this.setState({history,future})
    this.closePopup()
    this.props.close()
  }

  async saveCoach() {

    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg: "RarsCoaches",
        fn : "saveCoach",
        params : {
          coach : this.state.coach
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey(),
      },
    }

    await Axios(options)

    // Also update the data in our list

    this.props.saveCoach(this.state.coach)
  }

  async saveSeats() {

    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg: "RarsCoaches",
        fn : "saveSeats",
        params : {
          ourTypeId : this.state.coach.ourTypeId,
          seats : this.state.seats,
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey(),
      },
    }

    await Axios(options)

    this.state.seats.forEach(s => {delete s.isNew})
  }

  checkForUnsavedChanges = () => {
    if (this.state.history.length)
    {
      this.setState({promptToSaveChanges:true})
    }
    else
    {
      this.closePopup()
    }
  }

  closePopup = () => {
    this.setState({promptToSaveChanges:false})
    this.props.close()
  }

  cancelExit = () => {
    this.setState({promptToSaveChanges:false})
  }

  undoHistory = () => {
    let history = this.state.history
    let historyItem = history.pop()

    if (historyItem)
    {
      let seats = historyItem.seats
      let coach = historyItem.coach
      let visibleTab = historyItem.tab

      let future = this.state.future
      let futureItem = {
        seats : _.cloneDeep(this.state.seats),
        coach : _.cloneDeep(this.state.coach),
        tab : this.state.visibleTab,
      }

      future.push(futureItem)

      this.setState({history,future,coach,seats,visibleTab})
    }
  }

  redoHistory = () => {
    let future = this.state.future
    let futureItem = future.pop()

    if (futureItem)
    {
      let seats = futureItem.seats
      let coach = futureItem.coach
      let visibleTab = futureItem.tab

      let history = this.state.history

      let historyItem = {
        seats : _.cloneDeep(this.state.seats),
        coach : _.cloneDeep(this.state.coach),
        tab : this.state.visibleTab,
      }
      history.push(historyItem)

      this.setState({history,future,coach,seats,visibleTab})
    }
  }

  render() {
    const { open } = this.props

    if (!this.state.coach || !this.state.instances.length > 0) return ''

    return (
      <React.Fragment>
        <Dialog fullScreen open={open} onClose={this.checkForUnsavedChanges}>
          <DialogTitle id="form-dialog-title">{this.state.coach.ourTypeId} : {this.state.coach.tocName} : {this.state.coach.ourName}</DialogTitle>
          <DialogContent className="background">
            <AppBar position="static">
              <Tabs value={this.state.visibleTab} onChange={this.handleChangeTab}>
                <Tab label="Overview" />
                <Tab label="Crop and Resize Image" />
                <Tab label="Seat Positions" />
                <Tab label="Area Editor" />
                <Tab label="Seat Attributes" />
                <Tab label="Image Management" />
                <Tab label="Duplicates" />
              </Tabs>
            </AppBar>

            <TabPanel value={this.state.visibleTab} index={0}>
              <CoachOverview isEditor={this.state.isEditor} setActionButtons={this.setActionButtons} coach={this.state.coach} seats={this.state.seats} updateCoach={this.updateCoach} allCoaches={this.props.allCoaches} refreshList={this.props.refreshList} getCoachInstances={this.getCoachInstances} undoHistory={this.undoHistory}/>
            </TabPanel>

            <TabPanel value={this.state.visibleTab} index={1}>
              <CropAndResizeEditor isEditor={this.state.isEditor} setActionButtons={this.setActionButtons} coach={this.state.coach} seats={this.state.seats} imageActionType={this.state.imageActionType} handleRadioChange={this.handleRadioChange} updateCoach={this.updateCoach} updateSeats={this.updateSeats} undoHistory={this.undoHistory}/>
            </TabPanel>

            <TabPanel value={this.state.visibleTab} index={2}>
              <SeatPositionEditor isEditor={this.state.isEditor} setActionButtons={this.setActionButtons} seats={this.state.seats} coach={this.state.coach} updateSeats={this.updateSeats} updateCoach={this.updateCoach} undoHistory={this.undoHistory}/>
            </TabPanel>

            <TabPanel value={this.state.visibleTab} index={3}>
              <AreaEditor isEditor={this.state.isEditor} setActionButtons={this.setActionButtons} coach={this.state.coach} seats={this.state.seats} selectedAreaType={this.state.selectedAreaType} handleRadioChange={this.handleRadioChange} updateCoach={this.updateCoach} undoHistory={this.undoHistory}/>
            </TabPanel>

            <TabPanel value={this.state.visibleTab} index={4}>
              <SeatAttributeEditor isEditor={this.state.isEditor} setActionButtons={this.setActionButtons} seats={this.state.seats} coach={this.state.coach} updateSeats={this.updateSeats} undoHistory={this.undoHistory}/>
            </TabPanel>

            <TabPanel value={this.state.visibleTab} index={5}>
              <ImageEditor isEditor={this.state.isEditor} setActionButtons={this.setActionButtons} coach={this.state.coach} seats={this.state.seats} updateCoach={this.updateCoach} undoHistory={this.undoHistory}/>
            </TabPanel>

            <TabPanel value={this.state.visibleTab} index={6}>
              <DuplicateCoachInstances isEditor={this.state.isEditor} setActionButtons={this.setActionButtons} instances={this.state.instances} undoHistory={this.undoHistory}/>
            </TabPanel>
          </DialogContent>

          <DialogActions>
            {this.state.actionButtons}

            <div style={{flex: '1 0 0'}} />

            <Tooltip title={"Undo changes (CTRL-Z) " + this.state.history.length}>
              <Badge badgeContent={this.state.history.length} color="primary">
                <IconButton onClick={this.undoHistory} color="primary" size="large">
                  <AiOutlineUndo/>
                </IconButton>
              </Badge>
            </Tooltip>

            <Tooltip title={"Redo changes (CTRL-Z) " + this.state.future.length} >
              <Badge badgeContent={this.state.future.length} color="primary">
                <IconButton onClick={this.redoHistory} color="primary" size="large">
                  <AiOutlineRedo/>
                </IconButton>
              </Badge>
            </Tooltip>

            {this.state.isEditor &&
              <Tooltip title="Save changes">
                <IconButton onClick={this.save} color="primary" size="large">
                  <SaveIcon/>
                </IconButton>
              </Tooltip>
            }

            <Tooltip title="Close (cancel)">
              <IconButton onClick={this.checkForUnsavedChanges} color="primary" size="large">
                <CloseIcon/>
              </IconButton>
            </Tooltip>
            
          </DialogActions>
        </Dialog>

        <ConfirmCloseDialog open={this.state.promptToSaveChanges} save={this.save} close={this.closePopup} cancel={this.cancelExit}/>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(ImagePopup)