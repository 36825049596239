import StatsCard from '../dashboard/dashboard-stats-api-card'
import ExternalCard from '../dashboard/dashboard-stats-external-card'
import SalesCard from '../dashboard/dashboard-sales-card'
import RefundCard from '../dashboard/dashboard-refund-card'
import ReservationsCard from '../dashboard/dashboard-reservations-card'
import AusiCard from '../dashboard/dashboard-ausi-card'
import IncomeCard from '../dashboard/dashboard-income-card'
import CoachesCard from '../dashboard/dashboard-coaches-card'
import AuthCard from '../dashboard/dashboard-stats-auth-card'
import ErrorsCard from '../dashboard/dashboard-stats-errors-card'

import getApiStatsData from '../data-providers/api-stats'
import getAuthStatsData from '../data-providers/auth-stats'
import getExternalStatsData from '../data-providers/external-stats'
import getErrorStatsData from '../data-providers/error-stats'
import getCoachStatsData from '../data-providers/coach-stats'

import Config from '../config'

const updateIntervalStandard = 60*1000 // 1 minute
const updateIntervalFast = 30*1000 // 30 seconds
const updateIntervalSlow = 5*60*1000 // 5 minutes

function statsCardClickHandler(props) {
  console.log("stats clicked!",props)
}

function salesCardClickHandler(props) {
  props.history.push("/sales")
}

function refundsCardClickHandler(props) {
  props.history.push("/refunds")
}

function reservationsCardClickHandler(props) {
  props.history.push("/reservations")
}

function ausiCardClickHandler(props) {
  props.history.push("/ausi")
}

function incomeCardClickHandler(props) {
  props.history.push("/income")
}

function coachesCardClickHandler(props) {
  props.history.push("/train-carriages")
}

function externalCardClickHandler(props) {
  props.history.push("/nrs-lsm-tvd/live")
}

function errorsClickHandler(props) {
  let v = Config.getConfig("latestVersion")
  props.history.push("/errors/"+v)
}

const mainStatsLive = 
  [
    [
      {
        title : "Reservations",
        type : StatsCard,
        mode : "live",
        dataSource : getApiStatsData,
        updateInterval : updateIntervalStandard,
        namespace : "reservations",
        clickHandler : statsCardClickHandler,
      },
      {
        title : "Fulfilment",
        type : StatsCard,
        mode : "live",
        dataSource : getApiStatsData,
        updateInterval : updateIntervalStandard,
        namespace : "fulfilment",
        clickHandler : statsCardClickHandler,
      },
      {
        title : "Sales Ledger",
        type : StatsCard,
        mode : "live",
        dataSource : getApiStatsData,
        updateInterval : updateIntervalStandard,
        namespace : "sales-ledger",
        clickHandler : statsCardClickHandler,
      },
      {
        title : "SDCI+",
        type : StatsCard,
        mode : "live",
        dataSource : getApiStatsData,
        updateInterval : updateIntervalStandard,
        namespace : "sdci-transmission",
        clickHandler : statsCardClickHandler,
      },
    ],
    [
      {
        title : "LSM",
        type : ExternalCard,
        dataSource : getExternalStatsData,
        updateInterval : updateIntervalFast,
        mode : "live",
        namespace : "lsm",
        clickHandler : externalCardClickHandler,
      },
      {
        title : "NRS",
        type : ExternalCard,
        dataSource : getExternalStatsData,
        updateInterval : updateIntervalFast,
        namespace : "nrs",
        mode : "live",
        clickHandler : externalCardClickHandler,
      },
      {
        title : "eTVD",
        type : ExternalCard,
        dataSource : getExternalStatsData,
        updateInterval : updateIntervalFast,
        namespace : "tvd",
        mode : "live",
        clickHandler : externalCardClickHandler,
      },
      {
        title : "Errors",
        type : ErrorsCard,
        dataSource : getErrorStatsData,
        mode : "live",
        updateInterval : updateIntervalSlow,
        clickHandler : errorsClickHandler
      },
    ]
  ]

const mainStatsTest = Array.from(mainStatsLive).map(row => Array.from(row).map(s => {return {...s,mode:"test"}}))

const middleStats = [
  [
    {
      title : "Income",
      type : IncomeCard,
      mode : "live",
      width : 12,
      clickHandler : incomeCardClickHandler,
      ownerOnly : true
    },
  ],
  [
    {
      title : "Sales",
      type : SalesCard,
      mode : "live",
      width : 6,
      clickHandler : salesCardClickHandler,
    },
    {
      title : "Refunds",
      type : RefundCard,
      mode : "live",
      width : 6,
      clickHandler : refundsCardClickHandler,
    },
    {
      title : "Reservations",
      type : ReservationsCard,
      mode : "live",
      width : 6,
      clickHandler : reservationsCardClickHandler,
    },
    {
      title : "AUSI",
      type : AusiCard,
      mode : "live",
      width : 6,
      clickHandler : ausiCardClickHandler,
    },
  ],
  [
    {
      title : "Admin API",
      type : StatsCard,
      mode : "live",
      dataSource : getApiStatsData,
      updateInterval : updateIntervalSlow,
      namespace : "admin-api",
      width : 6,
    },
    {
      title : "Authentication",
      type : AuthCard,
      width : 6,
      dataSource : getAuthStatsData,
      updateInterval : updateIntervalSlow,
      namespace : "authentication"
    },
  ],
  [
    {
      title : "Data Manager",
      type : StatsCard,
      mode : "live",
      dataSource : getApiStatsData,
      updateInterval : updateIntervalSlow,
      width : 6,
      namespace : "data-manager",
    },
    {
      title : "Coaches",
      type : CoachesCard,
      width : 6,
      clickHandler : coachesCardClickHandler,
      updateInterval : updateIntervalSlow,
      dataSource : getCoachStatsData,
    },
  ]
]

const smallStats = [
  [
    {
      title : "Income",
      type : IncomeCard,
      mode : "live",
      width : 12,
      clickHandler : incomeCardClickHandler,
      ownerOnly : true
    },
  ],
  [
    {
      title : "Sales",
      type : SalesCard,
      mode : "live",
      width : 6,
      clickHandler : salesCardClickHandler,
    },
    {
      title : "Refunds",
      type : RefundCard,
      mode : "live",
      width : 6,
      clickHandler : refundsCardClickHandler,
    },
  ],
]

export const statsCardDefs = {
  live : mainStatsLive,
  test : mainStatsTest,
  middle : middleStats,
  small : smallStats,
}
