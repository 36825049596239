import React, { PureComponent } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Table, TableHead, TableRow, TableBody, TableCell, TableFooter } from '@material-ui/core'
import Moment from 'moment'
import classNames from 'classnames'
import { getIncome } from '../data-providers/income'


class SalesPopup extends PureComponent {

  constructor(props) {
    super(props)  
    this.state = {thisMonthIncome:[]}
  }

  componentDidMount() {
    setTimeout(this.refreshMonthData,1)
  }

  componentDidUpdate(prevProps) {
    if(prevProps.selectedMonth !== this.props.selectedMonth)
    {
      this.refreshMonthData()
    }
  }

  refreshMonthData = async () => {
    let thisMonthIncome = await getIncome("getSalesRefundsAndIncomeForMonthByOrganisation",{from:Moment(this.props.selectedMonth).startOf("month").format("YYYY-MM-DD"),to:Moment(this.props.selectedMonth).endOf("month").format("YYYY-MM-DD")})
    
    console.log("thisMonthIncome",thisMonthIncome)
    
    this.setState({thisMonthIncome})
  }

  render()
  {
    let totals = {
      numSales : 0,
      numRefunds : 0,
      numAusi : 0,
      numReservations : 0,
      salesIncome : 0,
      refundIncome : 0,
      ausiIncome : 0,
      reservationIncome : 0,
      totalIncome : 0
    }

    let classes = this.props.classes


    return (
      <Dialog
        open={this.props.modalOpen}
        onClose={this.props.handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        maxWidth="lg"
      >

        <DialogTitle id="scroll-dialog-title">Income by organisation</DialogTitle>

        <DialogContent>
          {this.state.thisMonthIncome.length === 0 && 
            <React.Fragment>Loading...</React.Fragment>
          }

          {this.state.thisMonthIncome.length > 0 && 
            <Table className={classes.paper}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Licensee</TableCell>
                  <TableCell className={classes.tableCell}>Organisation</TableCell>
                  <TableCell className={classes.tableCell}>Fees</TableCell>
                  <TableCell className={classNames(classes.tableCell,classes.right)}>Sales</TableCell>
                  <TableCell className={classNames(classes.tableCell,classes.right)}>Refunds</TableCell>
                  <TableCell className={classNames(classes.tableCell,classes.right)}>Reservations</TableCell>
                  <TableCell className={classNames(classes.tableCell,classes.right)}>AUSI</TableCell>
                  <TableCell className={classNames(classes.tableCell,classes.right)}>Sales</TableCell>
                  <TableCell className={classNames(classes.tableCell,classes.right)}>Refunds</TableCell>
                  <TableCell className={classNames(classes.tableCell,classes.right)}>Reservations</TableCell>
                  <TableCell className={classNames(classes.tableCell,classes.right)}>AUSI</TableCell>
                  <TableCell className={classNames(classes.tableCell,classes.right)}>Total Income</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {this.state.thisMonthIncome.map(t => {

                  totals.numSales += t.numSales
                  totals.numRefunds += t.numRefunds
                  totals.numReservations += t.numReservations
                  totals.numAusi += t.numAusi
                  totals.salesIncome += t.salesIncome
                  totals.reservationIncome += t.reservationIncome
                  totals.ausiIncome += t.ausiIncome
                  totals.refundIncome += t.refundIncome
                  totals.totalIncome += t.totalIncome

                  return (
                  <TableRow className={classNames(classes.stripeRow)} key={t.ts}>
                    <TableCell className={classNames(classes.tableCell,classes.bold)}>{t.licenseeName}</TableCell>
                    <TableCell className={classNames(classes.tableCell,classes.bold)}>{t.organisationName}</TableCell>
                    <TableCell className={classNames(classes.tableCell,classes.bold)}>{t.saleFee}|{t.refundFee}|{t.reservationFee}|{t.ausiFee}</TableCell>
                    <TableCell className={classNames(classes.tableCell,classes.right)}>{t.numSales}</TableCell>
                    <TableCell className={classNames(classes.tableCell,classes.right)}>{t.numRefunds}</TableCell>
                    <TableCell className={classNames(classes.tableCell,classes.right)}>{t.numReservations}</TableCell>
                    <TableCell className={classNames(classes.tableCell,classes.right)}>{t.numAusi}</TableCell>
                    <TableCell className={classNames(classes.tableCell,classes.right)}>£{(t.salesIncome/100).toFixed(2)}</TableCell>
                    <TableCell className={classNames(classes.tableCell,classes.right)}>£{(t.refundIncome/100).toFixed(2)}</TableCell>
                    <TableCell className={classNames(classes.tableCell,classes.right)}>£{(t.reservationIncome/100).toFixed(2)}</TableCell>
                    <TableCell className={classNames(classes.tableCell,classes.right)}>£{(t.ausiIncome/100).toFixed(2)}</TableCell>
                    <TableCell className={classNames(classes.tableCell,classes.right)}>£{(t.totalIncome/100).toFixed(2)}</TableCell>
                  </TableRow>
                )})}
              </TableBody>

              <TableFooter>
              <TableRow className={classNames(classes.stripeRow)}>
                <TableCell className={classNames(classes.tableCell,classes.bold)}></TableCell>
                <TableCell className={classNames(classes.tableCell,classes.bold)}></TableCell>
                <TableCell className={classNames(classes.tableCell,classes.bold)}>Total</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>{totals.numSales}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>{totals.numRefunds}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>{totals.numReservations}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>{totals.numAusi}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>£{(totals.salesIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>£{(totals.refundIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>£{(totals.reservationIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>£{(totals.ausiIncome/100).toFixed(2)}</TableCell>
                <TableCell className={classNames(classes.tableCell,classes.right,classes.bold)}>£{(totals.totalIncome/100).toFixed(2)}</TableCell>
              </TableRow>
            </TableFooter>
            </Table>
          }
        </DialogContent>

        <DialogActions>
          <Button onClick={this.props.handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default SalesPopup
