import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Badge from '@material-ui/core/Badge'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = theme => ({
  card: {
    textAlign : 'center',
    cursor : 'pointer',
  },
  cardContent : {
    padding : '0 !important',
  },
  title: {
    padding : '3px',
    fontSize: 14,
    color : '#999',
  },
  calls: {
    fontSize: 28,
  },
  coldstarts: {
    fontSize: 14,
  },
  duration: {
    fontSize: 12,
  },
  alert : {
    color : 'red',
  },
  label : {
    color : '#333',
    fontSize: 14,
  }
})

const tooltipStyle = {
  tooltip : {
    fontSize: "20px",
    whiteSpace: "pre-line"
  }
}

const CustomTooltip = withStyles(tooltipStyle)(Tooltip)

class StatsCard extends Component {

  constructor(props, context) {
    super(props, context)

    this.state = {data:{}}
  }

  componentDidMount() {
    if (this.props.dataSource)
    {
      this.updateData()

      this.intervalRef = setInterval(() => {
        if (!this.props.sleeping)
        {
          this.updateData()
        }
      }, this.props.updateInterval)
    }
  }
  
  componentWillUnmount() {
    clearInterval(this.intervalRef)
  }

  async updateData() {
    if (this.props.namespace)
    {
      let data = await this.props.dataSource(this.props.namespace,this.props.mode)

      data.numCalls = (data.invocationstest ? data.invocationstest : 0) + (data.invocationslive ? data.invocationslive : 0)
      data.numErrors = (data.responsestestFailure ? data.responsestestFailure : 0) + (data.responsesliveFailure ? data.responsesliveFailure : 0)

      data.errorPercent = (data.numErrors/data.numCalls).toFixed(1)
      data.duration = (data.durationlive ? data.durationlive : 0) + (data.durationtest ? data.durationtest : 0) 

      data.coldstartsSum = (data.coldstartstest ? data.coldstartstest : 0) + (data.coldstartslive ? data.coldstartslive : 0)

      data.coldstartPercent = data.coldstartsSum ? (data.coldstartsSum/data.numCalls * 100).toFixed(1) : 0

      this.setState({data})
    }
  }

  render() {

    const { classes } = this.props
  
    let tooltipText = `Number of calls : ${this.state.data.numCalls}
    Live calls : ${this.state.data.invocationslive}
    Test calls : ${this.state.data.invocationstest}
    
    Coldstarts : ${this.state.data.coldstartsSum} (${this.state.data.coldstartPercent}%)
    Errors : ${this.state.data.numErrors} (${this.state.data.errorPercent}%)

    Live errors : ${this.state.data.responsesliveFailure} 
    Test errors : ${this.state.data.responsestestFailure}
    `

    return (
      <Card className={classes.card} onClick={event => this.props.clickHandler(this.props)}>
        <CustomTooltip title={tooltipText}>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.title} gutterBottom component="h2">
              {this.props.title}
            </Typography>

            <Typography className={classes.calls} variant="h5" color="textPrimary">
              <Badge badgeContent={this.state.data.numErrors} color="secondary">
              {this.state.data.numCalls}
              </Badge>
            </Typography>

            <Typography className={classes.duration} variant="h5" color="textPrimary">
              <Badge badgeContent={this.state.data.errors} color="secondary">
                {parseInt(this.state.data.duration,10)} ms
              </Badge>
            </Typography>

            <Typography className={classes.coldstarts} color="textSecondary"> 
              <SvgIcon style={{ fontSize: 12 }}><path d="M15 13V5a3 3 0 0 0-6 0v8a5 5 0 1 0 6 0m-3-9a1 1 0 0 1 1 1v3h-2V5a1 1 0 0 1 1-1z" fill="#626262"/></SvgIcon> <span className={this.state.data.coldstartPercent > 0 ? 'alert' : ''}>{this.state.data.coldstartPercent} %</span>
            </Typography>
    
          </CardContent>
        </CustomTooltip>
      </Card>
    )
  }
}

export default withStyles(styles)(StatsCard)
