import AWS from 'aws-sdk'
import Moment from 'moment-timezone'
import Config from '../config'

const hour = 60*60
const day = hour*24

export default async function getStats(namespace,mode) {

  const AWSConfig = Config.getConfig("awsConfig")
  if (!AWSConfig) { return } // Not initialised yet
  const CW = new AWS.CloudWatch(AWSConfig)

  let endTime = Moment().tz('Europe/London').endOf("day")
  let startTime = Moment().tz('Europe/London').startOf("day")

  let params = {
    StartTime : startTime.toISOString(),
    EndTime : endTime.toISOString(),
    MetricDataQueries : []
  }

  params.MetricDataQueries.push(getMetricDataQuery(namespace,"test",'Invocations',"SampleCount",day))
  params.MetricDataQueries.push(getMetricDataQuery2(namespace,"test",'Responses',"SampleCount",day))
  params.MetricDataQueries.push(getMetricDataQuery(namespace,"live",'Invocations',"SampleCount",day))
  params.MetricDataQueries.push(getMetricDataQuery2(namespace,"live",'Responses',"SampleCount",day))
  params.MetricDataQueries.push(getMetricDataQuery(namespace,"test",'Duration',"Average",day))
  params.MetricDataQueries.push(getMetricDataQuery(namespace,"live",'Duration',"Average",day))
  params.MetricDataQueries.push(getMetricDataQuery(namespace,"test",'Coldstarts',"SampleCount",day))
  params.MetricDataQueries.push(getMetricDataQuery(namespace,"live",'Coldstarts',"SampleCount",day))
  
  let data = (await CW.getMetricData(params).promise()).MetricDataResults

  let result = {}

  for (let d=0;d<data.length;d++)
  {
    let dataItem = data[d]

    result[dataItem.Id] = dataItem.Values.length ? dataItem.Values[0] : 0
  }

  return result
}

function getMetricDataQuery (namespace,mode,MetricName,Stat,period) {

  return {
    Id : MetricName.toLowerCase() + mode,
    MetricStat : {
      Metric : {
        MetricName : MetricName,
        Namespace : `SMARTIX/${namespace.toUpperCase()}`,
        Dimensions: [
          {
            Name: "mode",
            Value: mode
          }
        ]
      },
      Period : period,
      Stat : Stat,
    },
    ReturnData : true
  }
}

function getMetricDataQuery2 (namespace,mode,MetricName,Stat,period) {

  return {
    Id : MetricName.toLowerCase() + mode + "Failure",
    MetricStat : {
      Metric : {
        MetricName : MetricName,
        Namespace : `SMARTIX/${namespace.toUpperCase()}`,
        Dimensions: [
          {
            Name: "mode",
            Value: mode
          },
          {
            Name: "authorised",
            Value: "false"
          },
        ]
      },
      Period : period,
      Stat : Stat,
    },
    ReturnData : true
  }
}