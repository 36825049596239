import _ from 'lodash'
import React, { Component } from 'react'

import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import DynamicComponent from '../components/dynamic-component'

import { triggerBase64Download } from 'react-base64-downloader';
import Jimp from 'jimp'
import Dropzone from 'react-simple-dropzone/dist'

import { BsFillCloudDownloadFill } from "react-icons/bs"

import Axios from 'axios'
import Config from '../config'

import { SiAirtable } from "react-icons/si"
import { FaLuggageCart } from "react-icons/fa" 
import { FaToiletPaper } from "react-icons/fa" 
import { MdExitToApp } from "react-icons/md" 
import { MdOutlineEmojiFoodBeverage } from "react-icons/md" 
import { MdOutlineDirectionsBike } from "react-icons/md" 
import { AiFillCaretLeft } from "react-icons/ai"
import { AiFillCaretRight } from "react-icons/ai"
import { AiFillCamera } from "react-icons/ai"

const areaIcons = {
  table : SiAirtable,
  luggage : FaLuggageCart,
  toilet : FaToiletPaper,
  exit  : MdExitToApp,
  food  : MdOutlineEmojiFoodBeverage,
  bike  : MdOutlineDirectionsBike,
}


const templatesOtherSides = {
  selectedTemplateLeft : "selectedTemplateRight",
  selectedTemplateRight : "selectedTemplateLeft"
}

class ImageEditor extends Component {

  constructor(props) {
    super(props)

    this.state = {
      coach : _.cloneDeep(this.props.coach),
      templates : [],
      selectedTemplateLeft : 0,
      selectedTemplateRight : 0,
      carriageTemplate : 0,
      coachWidth : this.props.coach.ourImageSize.width
    }

    if (this.props.isEditor)
    {
      this.props.setActionButtons(this.getActionButtons())
      this.getImageTemplates()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.coach !== this.props.coach)
    {
      // Make a copy of seats so that we do not mutate props when we adjust seat properties
      let coach = _.cloneDeep(this.props.coach)
   
      let coachWidth = this.props.coach.ourImageSize.width

      this.setState({coach,coachWidth})
    }
  }

  componentDidMount(){
    document.addEventListener("keydown", this.handleKeyPress, false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.handleKeyPress, false);
  }

  getImageTemplates = async () => {
    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg: "RarsCoaches",
        fn : "getCoachTemplates"
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey(),
      },
    }

    let templates = (await Axios(options)).data.templates

    let selectedTemplateLeft = templates.filter(t => t.cab === 0)[0]
    let selectedTemplateRight = templates.filter(t => t.cab === 0)[0]
    let carriageTemplate = templates.filter(t => t.cab === 0)[0]

    this.setState({templates,selectedTemplateLeft,selectedTemplateRight,carriageTemplate})
  }

  getBase64 = async () => {

    let ourImageUrl = this.state.coach.ourImageUrl

    if (/data:image/.test(ourImageUrl))
    {
      return ourImageUrl
    }
    else
    {
      let img = await Jimp.read(ourImageUrl+'?defeat-cors')
      return await img.getBase64Async(Jimp.MIME_PNG)
    }
  }

  downloadImage = async () => {
    let base64Image = await this.getBase64()
    triggerBase64Download(base64Image,this.props.coach.ourTypeId)
  }

  uploadImage = async img => {
    let coach = this.state.coach
    coach.ourImageUrl = img

    let imgObj = await Jimp.read(img)

    coach.ourImageSize = {
      width : imgObj.bitmap.width,
      height : imgObj.bitmap.height
    }

    this.props.updateCoach(coach)
  }

  getActionButtons = () => (
    <React.Fragment>
      <Tooltip title="Download Image">
        <IconButton onClick={this.downloadImage} color="primary" size="large">
          <BsFillCloudDownloadFill/>
        </IconButton>
      </Tooltip>

      <Tooltip title="Reduce coach length (Left Arrow / Shift-Left Arrow)">
        <IconButton onClick={e => this.changeCoachWidth(e.shiftKey ? -20 : -5)} color="primary" size="large">
          <AiFillCaretLeft/>
        </IconButton>
      </Tooltip>

      <Tooltip title="Increase coach length (Right Arrow / Shift-Right Arrow)">
        <IconButton onClick={e => this.changeCoachWidth(e.shiftKey ? 20 : 5)} color="primary" size="large">
          <AiFillCaretRight/>
        </IconButton>
      </Tooltip>

      <Tooltip title="Snapshot coach image (CTRL-S)">
        <IconButton onClick={this.snaphotCoach} color="primary" size="large">
          <AiFillCamera/>
        </IconButton>
      </Tooltip>
      
      
    </React.Fragment>
  )


  setSelectedTemplate = event => {
    let state = this.state
    state[event.target.name] = event.target.value

    if (event.target.value.cab)
    {
      state[templatesOtherSides[event.target.name]] = state.carriageTemplate
    }

    this.setState(state)
  }

  handleKeyPress = event => {

    let key = event.key

    if (event.metaKey) key += '-meta'
    if (event.ctrlKey) key += '-ctrl'
    if (event.shiftKey) key += '-shift'

    switch(key)
    {
      case "ArrowRight": return this.changeCoachWidth(5)
      case "ArrowLeft": return this.changeCoachWidth(-5)

      case "ArrowRight-shift": return this.changeCoachWidth(20)
      case "ArrowLeft-shift": return this.changeCoachWidth(-20)

      case "s-ctrl": case "S-ctrl":  return this.snaphotCoach()

      default: {} // Ignore
    }
  }

  changeCoachWidth = amount => {
    let coachWidth = this.state.coachWidth
    coachWidth += amount
    this.setState({coachWidth})
  }

  snaphotCoach = async () => {

    let coachHeight = Config.getConfig("coachHeight")
    let sizeOfPieces = parseInt(this.state.coachWidth/2,10)

    let leftImgRaw = await Jimp.read(this.state.selectedTemplateLeft.leftImageUrl + '?defeat-cors=1')
    leftImgRaw.crop(0,0,sizeOfPieces,coachHeight)
    let leftImgCropped = await leftImgRaw.getBase64Async(Jimp.MIME_PNG)

    let rightImgRaw = await Jimp.read(this.state.selectedTemplateRight.rightImageUrl + '?defeat-cors=1')
    rightImgRaw.crop(rightImgRaw.bitmap.width - sizeOfPieces,0,sizeOfPieces,coachHeight)
    let rightImgCropped = await rightImgRaw.getBase64Async(Jimp.MIME_PNG)

    let img = await new Jimp(this.state.coachWidth,coachHeight)
    let leftImg = await Jimp.read(leftImgCropped)
    let rightImg = await Jimp.read(rightImgCropped)

    img.composite(leftImg,0,0)
    img.composite(rightImg,sizeOfPieces,0)
    
    let imgBase64 = await img.getBase64Async(Jimp.MIME_PNG)

    let coach = this.state.coach
    coach.ourImageUrl = imgBase64

    coach.ourImageSize = {
      width :this.state.coachWidth,
      height : coachHeight,
    }

    this.props.updateCoach(coach)
  }

  render() {

    return (
      <React.Fragment>
        <p>It is important that any new image upoaded had the same dimensions as the existing image and that seats etc.</p>
        
        <p>Images should be free of any text / icons and seat visuals.</p>

        <p>If the new image dimensions differ from the existing one then manual adjustment of seat / area positions may be necessary.</p>

        <p><Dropzone onSuccessB64={img => this.uploadImage(img)} preview={false} validTypes={['image/png']}/></p>

        <div style={{position:'relative',height:this.state.coach.ourImageSize.height,width:this.state.coach.ourImageSize.width}}>

          <img src={this.state.coach.ourImageUrl} alt="" />

          {this.props.seats.map(seat => (
            <div key={seat.accomId} className="seat greySeat" style={{position:'absolute',width:this.state.coach.ourSeatSize.width,height:this.state.coach.ourSeatSize.height,top:seat.position.y,left:seat.position.x}}>
              {seat.accomId}
            </div>
          ))}
            
          {this.props.coach.areaData.map((area,idx) => (
            <div key={idx} className="area" style={{position:'absolute',width:area.width,height:area.height,top:area.y,left:area.x}}>
              <div style={{position:'absolute',left:(area.width/2) - 15, top:(area.height/2) - 20}}>
                <DynamicComponent component={areaIcons[area.type]} size="1.5em"/>
              </div>
            </div>
          ))}

        </div>

        <div style={{ display: 'flex' }}>
          <Select name="selectedTemplateLeft" value={this.state.selectedTemplateLeft} onChange={this.setSelectedTemplate}>
            {this.state.templates.map(t => <MenuItem value={t}>{t.name}</MenuItem>)}
          </Select>

          <div style={{flex: '1 0 0'}} />

          <Select name="selectedTemplateRight" value={this.state.selectedTemplateRight} onChange={this.setSelectedTemplate}>
            {this.state.templates.map(t => <MenuItem value={t}>{t.name}</MenuItem>)}
          </Select>
        </div>

        <br/>
        <p>Image width : {this.state.coachWidth}</p>
        <br/>

        <div style={{position:'relative',height:this.state.coach.ourImageSize.height,width:this.state.coachWidth}}>
          <div style={{position:"absolute",top:0,left:0,width:this.state.coachWidth/2,overflow:"hidden"}}>
            <img src={this.state.selectedTemplateLeft.leftImageUrl} alt=""/>
          </div>
          <div style={{position:"absolute",top:0,right:0,width:this.state.coachWidth/2,overflow:"hidden"}}>
            <div style={{position:'relative',height:this.state.coach.ourImageSize.height,width:this.state.coachWidth/2}}>
              <img src={this.state.selectedTemplateRight.rightImageUrl} style={{position:"absolute",top:0,right:0}} alt=""/>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ImageEditor