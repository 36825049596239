import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import { getRdsInstances, restartRdsInstance } from '../data-providers/database'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const styles = theme => ({
  paper: {
  width: 'calc(100%)',
  marginTop: theme.spacing(3),
  overflowX: 'auto',
  },
  table: {
  minWidth: 700,
  width : '100%',
  tableLayout : 'auto'
  },
  tableCell: {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '200px',
  padding : '4px 15px 4px 15px'
  },
  clickableTableRow: {
  cursor : 'pointer'
  },
  button: {
  margin: theme.spacing(),
  },  
  stripeRow: {
  '&:nth-of-type(odd)': {
  backgroundColor: theme.palette.background.default,
  },
  }, 
})


class Database extends PureComponent {

  constructor(props, context) {
    super(props, context)
    this.state = {rdsInstances : []}
  }
   
  componentDidUpdate(prevProps) {
    if (!this.state.rdsInstances.length)
    {
      this.getRdsInstanceData()
    }
  }

  getRdsInstanceData = async () => {
    let rdsInstances = await getRdsInstances()
    this.setState({rdsInstances})
  }

  restartRDS = async rds => {
    await restartRdsInstance(rds)
    this.getRdsInstanceData()
  }

  render() {

    const { classes } = this.props

    return (
      <>
        <Typography variant="subtitle1" gutterBottom style={{clear:'both'}}>Database Managerment</Typography>

        <Paper className={classes.paper}>
          <div style={{padding: "20px"}}>
            <table border={0} cellPadding={15}>
              <thead>
                <tr>
                <th>ID</th>
                <th>Class</th>
                <th>Status</th>
                <th>Version</th>
                <th>MultiAZ</th>
                <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
            {this.state.rdsInstances.map(rds => (
              <tr key={rds.DBInstanceIdentifier}>
               <td>{rds.DBInstanceIdentifier}</td>
               <td>{rds.DBInstanceClass}</td>
               <td>{rds.DBInstanceStatus}</td>
               <td>{rds.EngineVersion}</td>
               <td>{rds.MultiAZ ? "yes" : "no"}</td>
               <td><Button variant="contained" color="primary" onClick={e => this.restartRDS(rds)} disabled={rds.DBInstanceStatus !== "available"}>Restart</Button></td>
              </tr>
            ))}
            </tbody>
            </table>

            <Button variant="contained" color="primary" onClick={this.getRdsInstanceData}>Refresh</Button>
          </div>
        </Paper>
      </>
    )
  }
}
Database.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(Database))
