import React, { PureComponent } from 'react'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import { AreaChart, Area, XAxis,YAxis,CartesianGrid,Tooltip,Legend, ReferenceLine } from 'recharts'


const styles = theme => ({
  DialogContent : {
    padding : '20px 24px 24px',
  }
})

const colours = ['#000000','#000000', '#f39a9b', '#ec6e8d', '#d74a88', '#a93790', '#7b2397', '#5c1886', '#300c46', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000']

class CustomizedAxisTick extends React.PureComponent {

  render () {
    const {x, y, payload} = this.props;
    
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">{payload.value}</text>
      </g>
    )
  }
}


class Chart extends PureComponent {

  closeChart = () => {
    this.props.close()
  }

  render() {

    const { classes } = this.props

    return (

      <AreaChart width={1000} height={600} data={this.props.data} margin={{top: 30, right: 30, left: 30, bottom: 30}}>
       <XAxis dataKey="date" height={55} tick={<CustomizedAxisTick/>} interval={1} />
       <YAxis label={{value:"Bucket Size (GB)", angle:90, position: 'insideTopLeft', offset:-10}} interval={0}/>
       <CartesianGrid strokeDasharray="3 3"/>
       <Tooltip/>
       <Legend verticalAlign="top" align="right" className={classes.Legend}/>


        {Object.keys(this.props.data[0]).map((key,index) => {
          
          if (key !== "date" && key !== "key")
          {
            return (
              <Area key={key} type="monotone" dataKey={key} stackId="a" stroke={colours[index]} fill={colours[index]} />
            )
          }
          else
          {
            return null
          }
        })}

        <ReferenceLine y={5} stroke="red"/>

      </AreaChart>

    )
  }
}

Chart.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Chart)
