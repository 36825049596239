import _ from 'lodash'
import Config from '../config'

const seatAdjust = {
  
  autoAdjustSeatsY : (seats,seatSize) => {

    let yCoords = Config.getConfig("seatYCoords")

    seats.forEach(s => {
      yCoords.every(y => {
        if (s.position.y >= y.min && s.position.y <= y.max)
        {
          s.position.y = y.pos
          return false
        }
        else
        {
          return true
        }
      })
    })
    
    return seats
  },

  //TODO : get size from coach
  autoAdjustSeatsX : (seats,seatSize) => {
    let xPosList = {}

    seats.forEach(s => {
      xPosList[s.position.x] = s.position.x
    })

    let seatPosTollerance = seatSize.width/4

    let sizeGroups = []

    for (let s in xPosList)
    {
      let seatPos = xPosList[s]

      if (!sizeGroups.length)
      {
        sizeGroups.push([seatPos])
      }
      else
      {
        let foundAGroup = false

        for (let g=0;g<sizeGroups.length;g++)
        {
          let groupAveragePos = _.mean(sizeGroups[g])
          let groupAverageMaxPos = groupAveragePos + seatPosTollerance
          let groupAverageMinPos = groupAveragePos - seatPosTollerance

          if (seatPos <= groupAverageMaxPos && seatPos >= groupAverageMinPos)
          {
            sizeGroups[g].push(seatPos)
            foundAGroup = true
            break
          }
        }

        if (!foundAGroup)
        {
          sizeGroups.push([seatPos])          
        }
      }
    }

    for (let g=0;g<sizeGroups.length;g++)
    {
      let groupMembers = sizeGroups[g]
      let avgPos = Math.round(_.mean(groupMembers))

      groupMembers.forEach(m => {
        xPosList[m] = avgPos
      })
    }

    seats.forEach(s => s.position.x = xPosList[s.position.x])

    return seats
  }
}

export default (seatAdjust)
