const setSelectedRowsInGrid = function(selectedSeatIds) {

  if (!this.gridApi) return

  this.gridApi.forEachNode(node => {
    let selected = selectedSeatIds.includes(node.data.accomId)
    node.setSelected(selected)
  })
}


const selectAllSeatInGroup = function() {
  if (!this.selectableGroupRef) return
  this.selectableGroupRef.current.selectAll()
}

const clearSelectedSeatInGroup = function() {
  if (!this.selectableGroupRef) return
  this.selectableGroupRef.current.clearSelection()
}


const SelectionTools = {

  selectAllSeats : function() {
    let seats = this.state.seats
    let selectedSeatIds = seats.map(s => s.accomId)

    this.setState({selectedSeatIds})

    selectAllSeatInGroup.bind(this)()
    setSelectedRowsInGrid.bind(this)(selectedSeatIds)
  },

  clearSeatSelection : function() {
    let selectedSeatIds = []
    this.setState({selectedSeatIds})

    clearSelectedSeatInGroup.bind(this)()
    setSelectedRowsInGrid.bind(this)(selectedSeatIds)
  },

  invertSeatSelection : function() {
    let seats = this.state.seats
    let oldSelection = this.state.selectedSeatIds

    let selectedSeatIds = seats.filter(s => !oldSelection.includes(s.accomId)).map(s => s.accomId)

    this.setState({selectedSeatIds})

    setSelectedRowsInGrid.bind(this)(selectedSeatIds)
    SelectionTools.setSelectedSeatsInSelectableGroup.bind(this)(selectedSeatIds)
  },


  // We have finished a visual (drag) select
  // Need to ensure grid displays the same selected seats
  handleSelectionFinish : function(selectedNodes) {
    let selectedSeatIds = selectedNodes.map(n => n.props.seat.accomId)
  
    if (selectedSeatIds.join(".") !== this.state.selectedSeatIds.join("."))
    {
      this.setState({selectedSeatIds})
      setSelectedRowsInGrid.bind(this)(selectedSeatIds)
    }
  },
  
  // We have finished a selection from the grid
  // Need to ensure the visual (drag) selection displays the same selected seats
  gridSelectionChanged : function() {

    console.log("gridSelectionChanged")
    let selectedSeatIds = this.gridApi.getSelectedNodes().map(node => node.data.accomId)
  
    console.log("selectedSeatIds",selectedSeatIds)

    if (selectedSeatIds.join(".") !== this.state.selectedSeatIds.join("."))
    {
      this.setState({selectedSeatIds})
      SelectionTools.setSelectedSeatsInSelectableGroup.bind(this)(selectedSeatIds)
    }
  },
   
  setSelectedSeatsInSelectableGroup : function(selectedSeatIds) {

    if (!this.selectableGroupRef) return
  
    for (const item of this.selectableGroupRef.current.registry.values()) {
      let isSelected = selectedSeatIds.includes(item.props.seat.accomId)
  
      item.setState({isSelected})
  
      if (isSelected)
      {
        this.selectableGroupRef.current.selectedItems.add(item)
      }
      else
      {
        this.selectableGroupRef.current.selectedItems.delete(item)
      }
    }
  
    this.selectableGroupRef.current.setState({ selectionMode: true })
  }
}

export default SelectionTools