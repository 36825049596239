import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import passwordGenerator from 'generate-password'

import { Dialog, DialogActions, DialogContent, Button, FormGroup, FormControl, FormControlLabel, InputLabel, TextField, Select, MenuItem, Switch } from '@material-ui/core'

import { makeAPIKey, addUser } from '../data-providers/users'

const styles = theme => ({
  formControl: {
    margin: 0,
    minWidth: 120,
    maxWidth: 300,
  },
  formGroup: {
    paddingTop: '20px',
  },
  button: {
    margin: theme.spacing(),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select : {
    minWidth : 300
  },
})

const checkUsernameIsUnique = (gridApi,username) => {
  // Don't forget to check that username is unique!
  let usernameUnique = true

  gridApi.forEachNode(node => {
    if (node.data && node.data.username === username)
    {
      usernameUnique = false
    }
  })

  return usernameUnique
}

const getOrganisations = gridApi => {
  // Don't forget to check that username is unique!
  let orgs = {}

  gridApi.forEachNode(node => {
    if (node.data)
    {
      orgs[node.data.organisationId] = node.data.organisation
    }
  })

  let organisations = []
  for (let o in orgs)
  {
    organisations.push({id:o,name:orgs[o]})
  }
  return organisations
}

const blankUserData = {
  enabled : 1,
  username : '',
  organisationId : 0,
  systemAdmin : 0,
  licenseeAdmin : 0,
  organisationAdmin : 0,
  refunds : 0,
  password : ''
}

class NewUserForm extends Component {

  constructor(props, context) {
    super(props, context)  
    this.state = {user:{},apiKeys:[],organisations:[],passwordRequired:false,autoPassword:true,testKey:true,liveKey:false,devMode:false,usernameError:false}
  }

  componentDidMount() {
    this.setState({user:blankUserData,apiKeys:[],passwordRequired:false,autoPassword:true,testKey:true,liveKey:false,devMode:false,usernameError:false})
  }

  componentDidUpdate(prevProps) {
    setTimeout(this.init,100)
  }

  init = () => {
    if (this.props.gridApi)
    {
      let organisations = getOrganisations(this.props.gridApi)
      this.setState({organisations})  
    }
    else
    {
      setTimeout(this.init,100)
    }
  }
  
  handleCancel = () => {
    this.props.close()
  }

  handleSubmit = async () => {
    try
    {
      if (!this.state.usernameError && this.state.user.username && this.state.user.organisationId)
      {
        let organisation = this.state.organisations.filter(o => o.id === this.state.user.organisationId)[0].name

        let user = this.state.user
        let apiKeys = []

        if (this.state.testKey)
        {
          let testKey = await makeAPIKey(this.state.user.username,organisation,"test",this.state.devMode)

          let keyData = {
            key : testKey.value,
            type : 'test',
            keyId : testKey.id,
          }

          apiKeys.push(keyData)
        }

        if (this.state.liveKey)
        {
          let liveKey = await makeAPIKey(this.state.user.username,organisation,"live")

          let keyData = {
            key : liveKey.value,
            type : 'live',
            keyId : liveKey.id,
          }

          apiKeys.push(keyData)
        }

        if (this.state.passwordRequired && this.state.autoPassword)
        {
          user.password = passwordGenerator.generate({length:12,numbers:true,excludeSimilarCharacters:true,lowercase:true,uppercase:true})
        }

        let userId = await addUser(user,apiKeys)

        this.props.refreshGrid(user.organisationId,userId)

        this.setState({user:{...blankUserData}})

        this.props.close()
      }
    }
    catch(err)
    {
      console.log(err)
      throw err
    }
  }

  intToTrueFalse = value => value === 1

  handleUserChange = event => {
    let user = this.state.user
    user[event.target.name] = event.target.value ? event.target.value : event.target.checked ? 1 : 0

    let usernameError = !checkUsernameIsUnique(this.props.gridApi,this.state.user.username)

    this.setState({user,usernameError})
  }

  handleChange = event => {
    let value = event.target.value ? event.target.value : event.target.checked ? true : false 
    this.setState({[event.target.name]:value})
  }

  render() {

    const { classes, open  } = this.props

    return (

        <Dialog open={open} fullWidth={true} maxWidth="md">
          <DialogContent>
            
            <FormGroup row >
              <TextField autoFocus margin="dense" name="username" 
              value={this.state.user.username} onChange={this.handleUserChange} 
              inputProps={{"data-lpignore":"true"}}
              error={!this.state.user.username || !this.state.user.username.length || this.state.usernameError}
              label={this.state.usernameError ? "Username not unique" : "Username"}
              variant="outlined"
              />
              
            </FormGroup>

            <FormGroup row className={classes.formGroup}>

              <FormControl className={classes.formControl} error={!this.state.user.organisationId}>
                <InputLabel id="organisationId-label">{this.state.user.organisationId ? "Organisation" : "Choose an organisation"}</InputLabel>
                <Select name="organisationId" value={this.state.user.organisationId} onChange={this.handleUserChange} variant="outlined" className={classes.select}>
                  <MenuItem value="0" >Choose...</MenuItem>
                  {this.state.organisations.map(org => (
                    <MenuItem value={org.id} key={org.id}>{org.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

            </FormGroup>

            <FormGroup row className={classes.formGroup}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.intToTrueFalse(this.state.user.systemAdmin)}
                    onChange={this.handleUserChange}
                    name="systemAdmin"
                    color="primary"
                  />
                }
                label="System Admin"
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={this.intToTrueFalse(this.state.user.licenseeAdmin)}
                    onChange={this.handleUserChange}
                    name="licenseeAdmin"
                    color="primary"
                  />
                }
                label="Licensee Admin"
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={this.intToTrueFalse(this.state.user.organisationAdmin)}
                    onChange={this.handleUserChange}
                    name="organisationAdmin"
                    color="primary"
                  />
                }
                label="Organisation Admin"
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={this.intToTrueFalse(this.state.user.refunds)}
                    onChange={this.handleUserChange}
                    name="refunds"
                    color="primary"
                  />
                }
                label="Can do refunds"
              />

            </FormGroup>

            <FormGroup row className={classes.formGroup}>
              <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.passwordRequired}
                      onChange={this.handleChange}
                      name="passwordRequired"
                      color="primary"
                    />
                  }
                  label="Password required"
                />

                {this.state.passwordRequired && 
                  <React.Fragment>
                    <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.autoPassword}
                            onChange={this.handleChange}
                            name="autoPassword"
                            color="primary"
                          />
                        }
                        label="Auto Generate Password"
                      />

                    {!this.state.autoPassword && 
                      <TextField autoFocus margin="dense" name="password" label="Password" value={this.state.user.password} onChange={this.handleUserChange} inputProps={{"data-lpignore":"true"}}/>
                    }
                  </React.Fragment>
                }
            </FormGroup>
  
            <FormGroup row className={classes.formGroup}>
              <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.testKey}
                      onChange={this.handleChange}
                      name="testKey"
                      color="primary"
                    />
                  }
                  label="Test key required"
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.devMode}
                      onChange={this.handleChange}
                      name="devMode"
                      color="primary"
                      disabled={!this.state.testKey}
                    />
                  }
                  label="Enable DEV mode"
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.liveKey}
                      onChange={this.handleChange}
                      name="liveKey"
                      color="primary"
                    />
                  }
                  label="Live key required"
                />

            </FormGroup>

          </DialogContent>

          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">Cancel</Button>
            <Button onClick={this.handleSubmit} color="primary">Add User</Button>
          </DialogActions>
        </Dialog>

    )
  }
}

export default withStyles(styles)(NewUserForm)