import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'

import Chart from './dashboard-chart'
import AlertsCard from './dashboard-alerts'

import chartProvider from '../data-providers/charts'

import { statsCardDefs } from '../config/dashboard-stats-cards'

import DynamicComponent from '../components/dynamic-component'

import Media from 'react-media'

import Config from '../config'

const dataUpdateInterval = 60 * 1000 // 60 seconds
const dataPeriod =  20 // How many minutes of data we will show
const dataResolution = 1

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin : 'auto',
    width : 'calc(100% + 48px)'
  },
  h1 : {
    textAlign : 'center',
    color : '#A7CADE',
  },
  chart : {
    backgroundColor : 'white',
    borderRadius : '5px',
  }
})

const spacing = 2

function StatsRow(props) {

  return (
    <Grid item container spacing={spacing} direction="row">
      {props.rowData.map((item,index) => {
        return (
          <Grid item xs={3} key={index}>
            {item.type &&
              <DynamicComponent component={item.type} sleeping={props.sleeping} {...item}  history={props.history}/>
            }
          </Grid>
        )
      })}
    </Grid>
  )
}

function goToExternalServiceCharts(props) {
  props.history.push(`/nrs-lsm-tvd/${props.mode}`)
}

function StatsBlock(props) {
  return (
    <React.Fragment>
      {props.cards.map((row,index) => {
        return <StatsRow key={index} rowData={row} sleeping={props.sleeping} data={{}} history={props.history}/>
      })}

      <Grid item container spacing={spacing} direction="row">
        <Grid item xs={12} onClick={event => goToExternalServiceCharts(props)}>
          <Chart source="NRS" classes={props.classes} data={props.chartData.nrs}/>
        </Grid>
        
        <Grid item xs={12} onClick={event => goToExternalServiceCharts(props)}>
          <Chart source="LSM" classes={props.classes} data={props.chartData.lsm}/>
        </Grid>

        <Grid item xs={12} onClick={event => goToExternalServiceCharts(props)}>
          <Chart source="TVD" classes={props.classes} data={props.chartData.tvd}/>
        </Grid>
      </Grid>

    </React.Fragment>
  )
}


class Dashboard extends Component {

  constructor(props, context) {
    super(props, context)

    this.state = {stats:{},charts:{live:{},test:{}}}
  }

  componentDidMount() {
    setTimeout(this.refreshDashboard,1)
    this.timer = setInterval(this.refreshDashboard,dataUpdateInterval) 
  }
  
  refreshDashboard = () => {
    if (!this.props.sleeping)
    {
      this.refreshCharts()
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.timer)
  }

  refreshCharts = async () => {
    let promises = []

    promises.push(chartProvider('live','Nrs',dataPeriod,dataResolution))
    promises.push(chartProvider('live','Lsm',dataPeriod,dataResolution))
    promises.push(chartProvider('live','Tvd',dataPeriod,dataResolution))
    promises.push(chartProvider('test','Nrs',dataPeriod,dataResolution))
    promises.push(chartProvider('test','Lsm',dataPeriod,dataResolution))
    promises.push(chartProvider('test','Tvd',dataPeriod,dataResolution))

    let [statsLiveNrs,statsLiveLsm,statsLiveTvd,statsTestNrs,statsTestLsm,statsTestTvd] = await Promise.all(promises)
    
    let charts = {
      live : {
        nrs : statsLiveNrs,
        lsm : statsLiveLsm,
        tvd : statsLiveTvd,
      },
      test : {
        nrs : statsTestNrs,
        lsm : statsTestLsm,
        tvd : statsTestTvd,
      },
    }

    // Only update state if we have not navigates away
    if (this.timer)
    {
      this.setState({charts})
    }
  }

  render() {

    const { classes } = this.props
    const userData = Config.getConfig("userData")

    return (

      <Media queries={{ small: "(max-width: 599px)" }}>
      {matches =>
        matches.small ? (
          <div>
          {statsCardDefs.small.map((row,index) => (
            <div>
              {row.filter(s => s.ownerOnly ? userData.systemOwner : true).map((item,index) => (
                <DynamicComponent component={item.type} sleeping={this.props.sleeping} salesStats={this.props.salesStats} {...item} history={this.props.history}/>
              ))}
              </div>
          ))}
          <AlertsCard alerts={this.props.alerts} sleeping={this.props.sleeping} deleteAlert={this.props.deleteAlert} history={this.props.history}/>
          </div>
        ) : (
          <Grid container className={classes.root} spacing={spacing}>

          <Grid item xs={4} container direction="column" spacing={spacing}>
            <StatsBlock mode="live" cards={statsCardDefs.live} chartData={this.state.charts.live} classes={classes} sleeping={this.props.sleeping} history={this.props.history}/>
          </Grid>
  
          <Grid item xs={4} container direction="row" spacing={spacing}>
              
            {statsCardDefs.middle.map((row,index) => (
              <Grid item container spacing={spacing} direction="row" key={index}>
                {row.filter(s => s.ownerOnly ? userData.systemOwner : true).map((item,index) => (
                  <Grid item xs={item.width} key={index}>
                    <DynamicComponent component={item.type} sleeping={this.props.sleeping} salesStats={this.props.salesStats} {...item} history={this.props.history}/>
                  </Grid>
                ))}
              </Grid>
            ))}
  
            <Grid item container spacing={spacing} direction="row">
              <Grid item xs={12}>
                <AlertsCard alerts={this.props.alerts} sleeping={this.props.sleeping} deleteAlert={this.props.deleteAlert} history={this.props.history}/>
              </Grid>
            </Grid>
  
          </Grid>
  
          <Grid item xs={4} container direction="column" spacing={spacing}>
            <StatsBlock mode="test" cards={statsCardDefs.test} chartData={this.state.charts.test} classes={classes} sleeping={this.props.sleeping} history={this.props.history}/>
          </Grid>
  
        </Grid>
        )
      }
    </Media>



    )
  }
}

export default withRouter(withStyles(styles)(Dashboard))
