import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter, NavLink } from 'react-router-dom'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise'

import GridConfig from './grid-config'

import { getSalesData } from '../data-providers/sales'

import './styles.css'

import APIConfig from './api-config'

const styles = theme => ({
  paper: {
    width: 'calc(100% - 60)',
    marginTop: theme.spacing() * 3,
    overflowX: 'auto',
    position : 'fixed',
    top : 120,
    bottom : 20,
    left : 20,
    right : 20,
  },
  button: {
    margin: theme.spacing(),
  },  
})

function CustomPinnedRowRenderer () {}

CustomPinnedRowRenderer.prototype.init = function(params) {
    this.eGui = document.createElement('div')
    this.eGui.style = params.style
    this.eGui.innerHTML = params.value
}

CustomPinnedRowRenderer.prototype.getGui = function() {
    return this.eGui
}

function createPinDataAllDays(rowData) {

  if (!rowData.length) { return }

  var result = {
    date : "AVERAGE",
    weekday: "ALL"
  }

  let sums = new Array(24).fill(0)

  rowData.forEach(row => {
    for (let h=0;h<24;h++)
    {
      sums[h] += row[h]
    }
  })

  for (let h=0;h<24;h++)
  {
    result[h] = Math.round(sums[h] / rowData.length)
  }

  let hour = (new Date()).getHours()

  result.total = Math.round(sums.reduce((total,num) => total + num) / rowData.length)
  result.totalnow = Math.round(sums.slice(0,hour+1).reduce((total,num) => total + num) / rowData.length)

  return [result]
}

function createPinDataSameDays(rowData) {
   
  if (!rowData.length) { return }

  var result = {
    date : "AVERAGE",
    weekday: rowData[0].weekday.substr(0,3)
  }

  let sums = new Array(24).fill(0)
  let rowCount = 0

  rowData.forEach(row => {
    if (row.weekday === rowData[0].weekday)
    {
      rowCount++
      for (let h=0;h<24;h++)
      {
        sums[h] += row[h]
      }
    }
  })

  for (let h=0;h<24;h++)
  {
    result[h] = Math.round(sums[h] / rowCount)
  }

  let hour = (new Date()).getHours()
  
  result.total = Math.round(sums.reduce((total,num) => total + num) / rowCount)
  result.totalnow = Math.round(sums.slice(0,hour+1).reduce((total,num) => total + num) / rowCount)

  return [result]
}


class SalesGrid extends Component {

  constructor(props, context) {
    super(props, context)  
    this.state = {todaySales:[],fourWeekSales:[],fourWeekAverage:[],yearAverage:[],lastWeekSales:[],weekAverage:[],yearAllDaysAverage:[],todayFilter:false}
  }

  componentDidMount() {
    setTimeout(this.initData,1)
  }

  componentDidUpdate(prevProps) {
    if(prevProps.salesStats.sales.today !== this.props.salesStats.sales.today)
    {
      this.refreshTodayData()
    }
  }

  initData = async () => {

    let threeMonthSales = await getSalesData(APIConfig[this.props.history.location.pathname.replace(/\//,'')].pastDataFn)

    this.gridApi.applyTransactionAsync({add:threeMonthSales})

    this.refreshTodayData()

    this.gridApi.setPinnedTopRowData(createPinDataSameDays(threeMonthSales))
    this.gridApi.setPinnedBottomRowData(createPinDataAllDays(threeMonthSales))
  }

  refreshTodayData = async () => {
    let todaySales = await getSalesData(APIConfig[this.props.history.location.pathname.replace(/\//,'')].todayDataFn)

    let hour = (new Date()).getHours()

    let updateList = []
    this.gridApi.forEachNode(node => {
      if (node.data.date === todaySales.date)
      {
        for (let a in todaySales)
        {
          node.data[a] = todaySales[a]
        }
      }
      
      // populate total upto end of current hour
      node.data.totalnow = 0

      for (let h=0;h<hour+1;h++)
      {
        node.data.totalnow += node.data[h]
      }

      updateList.push(node.data)
    })

    if (updateList.length)
    {
      this.gridApi.applyTransactionAsync({update:updateList})
    }
    else
    {
      this.gridApi.applyTransactionAsync({add:[todaySales]})
    }
  }

  onGridReady = params => {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi
    this.gridApi.setSortModel([{colId:"date",sort:"desc"}])
    this.toggleDayFilter("today")
  }

  filterChanged = params => {
    let postFilterData = []

    params.api.forEachNodeAfterFilter(n => {
      postFilterData.push(n.data)
    })
    
    params.api.setPinnedTopRowData(createPinDataSameDays(postFilterData))
    params.api.setPinnedBottomRowData(createPinDataAllDays(postFilterData))
  }

  toggleDayFilter = dayFilter => {
    this.setState({dayFilter})

    if (dayFilter)
    {
      let idx = (new Date()).getDay()

      if (dayFilter === "yesterday")
      {
        idx = (idx === 0) ? 6 : (idx-1)
      }

      let dayOfWeek = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"][idx]

      var gridFilter = this.gridApi.getFilterInstance('weekday')
      gridFilter.setModel({values:[dayOfWeek]})
      gridFilter.onFilterChanged()
    }
    else
    {
      this.clearFilters()
    }
  }

  clearFilters = () => {
    this.gridApi.setFilterModel(null)
    this.gridApi.onFilterChanged()
    this.setState({dayFilter:null})
  }

  render() {
    const { classes } = this.props

    return (
      <React.Fragment>

        <div className="stageMenu">
          <Button component={NavLink} to="/sales" activeClassName="active">Sales</Button>
          <Button component={NavLink} to="/refunds" activeClassName="active">Refunds</Button>
          <Button component={NavLink} to="/reservations" activeClassName="active">Reservations</Button>
          <Button component={NavLink} to="/ausi" activeClassName="active">AUSI</Button>
          <Button component={NavLink} to="/income" activeClassName="active">Income</Button>
        </div>
        
        <div>
          <Button onClick={this.clearFilters}>Clear Filters</Button>
          <Button onClick={event => this.toggleDayFilter(this.state.dayFilter === "today" ?  null : "today")} className={this.state.dayFilter === "today" ? "active" : ""}>Today Only</Button>
          <Button onClick={event => this.toggleDayFilter(this.state.dayFilter === "yesterday" ? null : "yesterday")} className={this.state.dayFilter === "yesterday" ? "active" : ""}>Yesterday Only</Button>
        </div>
        
        <Paper className={classes.paper}>

        <div className="ag-theme-alpine" style={ {height: '100%', width: '100%'} }>
          <AgGridReact
            onGridReady={this.onGridReady}
            rowBuffer={GridConfig.visibleRowBufferSize*2}
            columnDefs={GridConfig.columnDefs}
            animateRows={true}
            rowSelection="multiple"
            defaultColDef={{filterParams:{ newRowsAction:'keep'}}}
            enableRangeSelection={true}
            components = {{
              customPinnedRowRenderer: CustomPinnedRowRenderer
            }}
            statusBar = {{
              statusPanels : [
                {
                  statusPanel: 'agTotalAndFilteredRowCountComponent',
                  align: 'left',
                },
                  {
                      statusPanel: 'agAggregationComponent',
                      statusPanelParams : {
                          // only show count and sum ('min', 'max', 'avg' won't be shown)
                          aggFuncs : ['min', 'max', 'avg', 'sum']
                      }
                  }
              ]
            }}

            sideBar={{
              toolPanels : ['filters'],
              closedByDefault : true,
            }}

            onFilterChanged = {this.filterChanged}
            >
          </AgGridReact>
        </div>

      </Paper>

      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(SalesGrid))