import Axios from 'axios'
import Config from '../config'

export default async function getStats() {

  let options = {
    method : 'POST',
    url : `${Config.getConfig('adminApi')}request`,
    data : {
      pkg : "Coach",
      fn : "getStats",
      params : {}
    },
    headers : {
      'Content-type': 'application/json',
      'x-api-key': Config.getAPIKey('adminApi'),
    },
  }

  let result = await Axios(options)

  return result.data.data
}