const Config = {

  sales : {
    todayDataFn : "getSalesByHourToday",
    pastDataFn : "getSalesByHourLast3Months",  
  },
  refunds : {
    todayDataFn : "getRefundsByHourToday",
    pastDataFn : "getRefundsByHourLast3Months"
  },
  reservations : {
    todayDataFn : "getReservationsByHourToday",
    pastDataFn : "getReservationsByHourLast3Months"
  },
  ausi : {
    todayDataFn : "getAusiByHourToday",
    pastDataFn : "getAusiByHourLast3Months"
  },
}

export default Config