import React, { Component } from 'react'
import Tooltip from '@material-ui/core/Tooltip'

const getAttributeName = (code,items) => {
  for (let i of items)
  {
    if (i.code === code) return i.shortAttributeName
  }
}

class ActionCellRenderer extends Component {
  constructor(props) {
    super(props)
    this.btnClickedHandler = this.btnClickedHandler.bind(this)
  }

  btnClickedHandler(attributeGroup,seat) {
    this.props.clickHandler(attributeGroup,seat)
  }

  render() {
    return (
      <Tooltip title={this.props.group.name + ' : ' + this.props.data.attributes[this.props.group.code].join(".")}>
        <div style={{cursor:"pointer"}} onDoubleClick={event => this.btnClickedHandler(this.props.group,this.props.node.data)}>{this.props.data.attributes[this.props.group.code].length ? this.props.data.attributes[this.props.group.code].map(code => getAttributeName(code,this.props.group.items)).join(".") : '-'}</div>
      </Tooltip>
    )
  }
}

export default ActionCellRenderer