const getGridConfig = (actionClickHandler,attributeGroups) => {

  let config = {
   
    visibleRowBufferSize : 10,

    columnDefs : [
      {
        field: "accomId",
        headerName: "Seat",
        sortable: true,
        width :100,
      },
      {
        field: "inventoryClass",
        headerName: "IC",
        sortable: true, 
        filter: 'agSetColumnFilter',
        menuTabs : ['filterMenuTab'],
        width : 60
      },
      {
        field: "facing",
        headerName: "Face",
        sortable: true, 
        filter: 'agSetColumnFilter',
        menuTabs : ['filterMenuTab'],
        width : 60
      },
    ]
  }

  for (let g in attributeGroups)
  {
    const group = attributeGroups[g]

    config.columnDefs.push({
      field: "attributes",
      headerName:group.name,
      filter: 'agSetColumnFilter',
      width : group.multiple ? 300 : 100,
      cellRenderer: 'actionCellRenderer',
      cellRendererParams: obj => {
        return {
          clickHandler: actionClickHandler,
          data : obj.data,
          group : group
        }
      },
    })
  }

  return config
}

export default getGridConfig