import React, { Component } from 'react';

export default class logsInDbCountComponent extends Component {

  constructor(props) {
    super(props)

    this.state = {
      numLogsToLoad: 0,
      updateFn : this.props.logsToLoadFn,
      refreshInternal : setInterval(this.updateLogsLeftToLoad.bind(this),500)
    }    
  }

  componentWillUnmount() {
    clearInterval(this.state.refreshInternal)
  }

  updateLogsLeftToLoad() {
    let numLogsToLoad = this.state.updateFn()
    this.setState({numLogsToLoad})
  }

  render() {
    return this.state.numLogsToLoad ? (
      <div className="ag-status-name-value">(
        <span className="component">Logs left to load&nbsp;</span>
        <span className="ag-status-name-value-value">{this.state.numLogsToLoad}</span>
      )
      </div>
    ) : (null)
  }
}
