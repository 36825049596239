import React, { PureComponent } from 'react'
import { withRouter, NavLink } from 'react-router-dom'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import ViewIcon from '@material-ui/icons/OpenInNew'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Axios from 'axios'

import Config from '../config'


const coachTypes = {
  QUIE : "Quiet coach",
  BUSI : "Business coach",
  RADI : "Audio coach",
  VIDO : "Video coach",
  FAMY : "Family coach",
  null : "Standard coach",
}

const accomTypes = {
  SEAT : "Seats",
  BRTH : "Sleeper berths"
}

const facing = {
  F : "Forward",
  B : "Back",
  null : "Unknown",
}

const leftRight = {
  L : "Left",
  R : "Right",
  null : "Unknown",
}

const leftRightLong = {
  left : "Left",
  right : "Right",
  unknown : "Unknown",
  null : "Unknown",
}

const seatType = {
  A : "Airline",
  T : "Table",
  B : "Bunk",
  W : "Wheelchair Space",
  null : "Unknown",
}

const seatPos = {
  W : "Window",
  M : "Middle",
  A : "Aisle",
  I : "Individual",
  U : "Upper Bunk",
  L : "Lower Bunk",
  null : "Unknown",
}

const yesNo = {
  1 : "yes",
  0 : "no",
  null : "Unknown"
}

const styles = theme => ({
  paper: {
    width: 'calc(100% - 20px)',
    marginTop: theme.spacing() * 3,
    overflowX: 'auto',
    padding : '10px'
  },
  table: {
    minWidth: 700,
    width : '100%',
    tableLayout : 'auto'
  },
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    padding : '4px 15px 4px 15px'
  },
  tableCellHeader: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    padding : '4px 15px 4px 15px'
  },
  tableCellData: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    padding : '4px 15px 4px 15px',
    fontWeight : 'bold'
  },  
  errored : {
    color : 'red'
  },
  clickableTableRow: {
    cursor : 'pointer'
  },
  button: {
    margin: theme.spacing(),
    marginRight : '5px'
  },  
  stripeRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  }, 
})


const ITEM_HEIGHT = 48;

const selectOptions = [
  {label:"All seats"},
  {label:"-------------",disabled:true},
  {label:"Seats with errors"},
  {label:"Seats without errors"},
  {label:"-------------",disabled:true},
  {label:"No Facing"},
  {label:"No Seat Position"},
  {label:"No Seat Type"},
  {label:"No Aisle Position"},
  {label:"No Seat Class"},
  {label:"-------------",disabled:true},
  {label:"Table Seats"},
  {label:"Airline Seats"},
  {label:"Bikes"},
  {label:"-------------",disabled:true},
  {label:"Near Luggage"},
  {label:"Near Toilets"},
  {label:"Near Food"},
]

const setOptionsFacing = [
  {attribute:"facing",value:"F",label:"Forward Facing"},
  {attribute:"facing",value:"B",label:"Backwards Facing"},
]

const setOptionsSeatType = [
  {attribute:"seatType",value:"A",label:"Airline Seat"},
  {attribute:"seatType",value:"T",label:"Table Seat"},
  {attribute:"seatType",value:"B",label:"Bunk"},
  {attribute:"dummy",value:"D",label:"------------------", key:"D1", disabled:true},
  {attribute:"seatType",value:"W",label:"Wheelchair Space"},
  {attribute:"bike",value:"1",label:"Bike Space"},
  {attribute:"bike",value:"0",label:"Not Bike Space"},
  {attribute:"seatPriority",value:"C",label:"Wheelchair Companion Seat"},
  {attribute:"seatPriority",value:"P",label:"Priority Seat"},
  {attribute:"seatPriority",value:"N",label:"Normal Seat (not priority)"},  
]

const setOptionsSeatPos = [
  {attribute:"seatPos",value:"W",label:"Window Seat", key:"PW"},
  {attribute:"seatPos",value:"A",label:"Aisle Seat", key:"PA"},
  {attribute:"seatPos",value:"I",label:"Individual Seat", key:"PI"},
  {attribute:"seatPos",value:"M",label:"Middle Seat", key:"PM"},
  {attribute:"dummy",value:"D",label:"------------------", key:"D1", disabled:true},
  {attribute:"aislePos",value:"L",label:"Aisle to Left", key:"AL"},
  {attribute:"aislePos",value:"R",label:"Aisle to Right", key:"AR"},
  {attribute:"dummy",value:"D",label:"------------------", key:"D2", disabled:true},
  {attribute:"seatPos",value:"U",label:"Upper Bunk", key:"PU"},
  {attribute:"seatPos",value:"L",label:"Lower Bunk", key:"PL"},
]

const setOptionsSeatClass = [
  {attribute:"class",value:"1",label:"1st Class"},
  {attribute:"class",value:"2",label:"Standard Class"},
  {attribute:"class",value:"9",label:"Other Class (bikes etc.)"},
]

const setOptionsSeatAtts = [
  {attribute:"nearLuggage",value:"1",label:"Near Luggage Racks"},
  {attribute:"nearLuggage",value:"0",label:"NOT Near Luggage Racks"},
  {attribute:"dummy",value:"D",label:"------------------", key:"D1", disabled:true},
  {attribute:"nearToilets",value:"1",label:"Near Toilets"},
  {attribute:"nearToilets",value:"0",label:"NOT Near Toilets"},
  {attribute:"dummy",value:"D",label:"------------------", key:"D1", disabled:true},
  {attribute:"nearFood",value:"1",label:"Near Food"},
  {attribute:"nearFood",value:"0",label:"NOT Near Food"},
]

class Coach extends PureComponent {

  constructor(props, context) {
    super(props, context)
    this.state = {typeId:null,direction:null,layoutChanged:false,coach:{layout:'',nrsLayout:''},seats:[],selectAnchor:null,deselectAnchor:null,setFacingAnchor:null,setPosAnchor:null,setTypeAnchor:null,setAttsAnchor:null,setClassAnchor:null,loadedSVG:false}
  }

  async init() {
    this.getCoach()
  }

  componentDidMount() {
    let {toc,typeId,direction} = this.props.match.params
    this.setState({toc:toc,typeId:typeId,direction:direction},this.init)
  }

  async getCoach() {

    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "Coach",
        fn : "getCoach",
        params : {
          toc : this.state.toc,
          typeId : this.state.typeId,
          direction : this.state.direction
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey(),
      },
    }

    this.setState({loadedSVG:false})

    Axios(options).then(result => {

      this.setState({coach:result.data.data[0],loadedSVG:true,direction:result.data.data[0].direction},() => {
        this.getSeats()
      })

    })
    .catch(err => {
     console.error(err)
    })    
  }

  async getSeats() {

    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}request`,
      data : {
        pkg : "Coach",
        fn : "getSeats",
        params : {
          typeId : this.state.coach.ourTypeId,
          direction : this.state.direction
        }
      },
      headers : {
        'Content-type': 'application/json',
        'x-api-key': Config.getAPIKey('apiUrlCoach'),
      },
    }

    Axios(options).then(result => {

      this.setState({seats:result.data.data})

      this.initSvg()   
    })
    .catch(err => {
     console.error(err)
    })    
  }


  initSvg()
  {
    var selector = "[id^='PL-']";  

    var ids = document.querySelectorAll(selector);      

    for (var x=0;x<ids.length;x++)      
    {
      ids[x].classList.remove('selected') 

      ids[x].addEventListener('click',function(event){
        event.target.classList.toggle('selected')    
      },true)
    }
  }

  drillDown(event,accomId)
  {
    this.props.history.push(`${this.props.history.location.pathname}/${accomId}`)
  }


  facingClass(seat)
  {
    const { classes } = this.props

    if (!seat.bike && this.state.coach.travelDirection && !seat.facing) { return classes.errored }    
  }

  seatTypeClass(seat)
  {
    const { classes } = this.props

    if (!seat.seatType && !seat.bike) { return classes.errored }    
  }

  classClass(seat)
  {
    const { classes } = this.props

    if (!seat.bike && seat.class !== 1 && seat.class !== 2) { return classes.errored }   
  }

  seatPosClass(seat)
  {
    const { classes } = this.props

    if (!seat.bike && !seat.seatPos) { return classes.errored }    
  }

  aislePos(seat)
  {
    if (!seat.bike && (seat.seatPos === 'A' || seat.seatPos === 'I')) { return leftRight[seat.aislePos] }    
  }

  aislePosClass(seat)
  {
    const { classes } = this.props

    if (!seat.bike && (seat.seatPos === 'A'  || seat.seatPos === 'I' ) && !seat.aislePos && this.state.coach.travelDirection) { return classes.errored }    
  }

  attentionNeededClass(seat)
  {
    const { classes } = this.props

    if (!seat.bike && (this.state.coach.travelDirection && !seat.facing)) { this.markAsErrored(seat); return classes.errored }
    if (!seat.bike && (!seat.seatType || !seat.seatPos)) { this.markAsErrored(seat); return classes.errored }   
    if (seat.seatPos === 'A' && !seat.aislePos) { this.markAsErrored(seat); return classes.errored }  
    if (!seat.bike && (seat.class !== 1 && seat.class !== 2)) { this.markAsErrored(seat); return classes.errored }   

    this.markAsOk(seat)
  }

  markAsErrored(seat)
  {
    var elem = document.querySelector('#PL-' + seat.accomId)

    if(elem)
    {
      elem.classList.add('errored')
    }
  }

  markAsOk(seat)
  {
    var elem = document.querySelector('#PL-' + seat.accomId)
    
    if(elem)
    {
      elem.classList.remove('errored')
    }
  }

  placesClass(places,seats)
  {
    const { classes } = this.props

    let numSeats = 0

    seats.forEach(s => {if(!s.bike) { numSeats++ }})

    if (parseInt(places,10) !== numSeats) { return classes.errored } 
  }

  yesNoFormat(value)
  {
    return yesNo[value ? 'Y' : 'N']
  }

  travelDirectionClass(direction,seats)
  {
    const { classes } = this.props

    if (!direction)
    {
      for (let s=0;s<seats.length;s++)
      {
        if (seats[s].facingFacing || /FACE/.test(seats[s].attributes) || /BACK/.test(seats[s].attributes))
        {
          return classes.errored
        }
      }
    }
  }



  handleOpenSelectMenu = event => {
    this.setState({ selectAnchor: event.currentTarget })
  }

  handleCloseSelectMenu = () => {
    this.setState({ selectAnchor: null })
  }

  handleOpenSetFacingMenu = event => {
    this.setState({ setFacingAnchor: event.currentTarget })
  }

  handleOpenSetPosMenu = event => {
    this.setState({ setPosAnchor: event.currentTarget })
  }

  handleOpenSetTypeMenu = event => {
    this.setState({ setTypeAnchor: event.currentTarget })
  }

  handleOpenSetClassMenu = event => {
    this.setState({ setClassAnchor: event.currentTarget })
  }

  handleOpenSetAttsMenu = event => {
    this.setState({ setAttsAnchor: event.currentTarget })
  }

    handleOpenDeselectMenu = event => {
    this.setState({ deselectAnchor: event.currentTarget })
  }

  handleCloseDeselectMenu = () => {
    this.setState({ deselectAnchor: null })
  }

  handleCloseSetFacingMenu = () => {
    this.setState({ setFacingAnchor: null })
  }

  handleCloseSetPosMenu = () => {
    this.setState({ setPosAnchor: null })
  }

  handleCloseSetTypeMenu = () => {
    this.setState({ setTypeAnchor: null })
  }

  handleCloseSetClassMenu = () => {
    this.setState({ setClassAnchor: null })
  }

  handleCloseSetAttsMenu = () => {
    this.setState({ setAttsAnchor: null })
  }

    selectSeatsWithoutAttribute = (mode,attribute) =>
  {
    let seatData = [...this.state.seats]
    let seats = []

    for (let s=0;s<seatData.length;s++)
    {
      if (!seatData[s][attribute] && (attribute !== 'aislePos' || seatData[s].seatPos === 'A'))
      {
        seats.push(document.querySelector('use#PL-' + seatData[s].accomId)) 
      }
    } 

    return seats
  }

  selectSeatsWithoutClass = mode =>
  {
    let seatData = [...this.state.seats]
    let seats = []

    for (let s=0;s<seatData.length;s++)
    {
      if (seatData[s].class !== 1 && seatData[s].class !== 2)
      {
        seats.push(document.querySelector('use#PL-' + seatData[s].accomId)) 
      }
    } 

    return seats
  }

  selectSeatsWithType = type => {

    let seatData = [...this.state.seats]
    let seats = []

    for (let s=0;s<seatData.length;s++)
    {
      if (seatData[s].seatType === type)
      {
        seats.push(document.querySelector('use#PL-' + seatData[s].accomId)) 
      }
    } 

    return seats 
  }


  selectBikePlaces = () => {

    let seatData = [...this.state.seats]
    let seats = []

    for (let s=0;s<seatData.length;s++)
    {
      if (seatData[s].bike)
      {
        seats.push(document.querySelector('use#PL-' + seatData[s].accomId)) 
      }
    } 

    return seats 
  }

  selectSeatsNearTo = type => {

    let seatData = [...this.state.seats]
    let seats = []

    for (let s=0;s<seatData.length;s++)
    {
      if (seatData[s][type])
      {
        seats.push(document.querySelector('use#PL-' + seatData[s].accomId)) 
      }
    } 

    return seats 
  }

  handleClick = (option,action) => {

    let seats = []

    switch(option)
    {
      case "All seats":
      {
        seats = document.querySelectorAll("[id^='PL-']")
        break
      }

      case "Seats with errors":
      {
        seats = document.querySelectorAll('use.errored')
        
        break
      }

      case "Seats without errors":
      {
        seats = document.querySelectorAll('use:not(.errored)')
        
        break
      }

      case "No Seat Position":
      {
        seats = this.selectSeatsWithoutAttribute(action,'seatPos')
        break
      }

      case "No Seat Type":
      {
        seats = this.selectSeatsWithoutAttribute(action,'seatType')
        break
      }

      case "No Aisle Position":
      {
        seats = this.selectSeatsWithoutAttribute(action,'aislePos')
        break
      }

      case "No Facing":
      {
        seats = this.selectSeatsWithoutAttribute(action,'facing')
        break
      }

      case "No Class":
      {
        seats = this.selectSeatsWithoutClass(action)
        break
      }

      case "Table Seats":
      {
        seats = this.selectSeatsWithType('T')
        break
      }

      case "Airline Seats":
      {
        seats = this.selectSeatsWithType('A')
        break
      }

      case "Bikes":
      {
        seats = this.selectBikePlaces()
        break
      }

      case "Near Luggage":
      {
        seats = this.selectSeatsNearTo('nearLuggage')
        break
      }

      case "Near Toilets":
      {
        seats = this.selectSeatsNearTo('nearToilets')
        break
      }

      case "Near Food":
      {
        seats = this.selectSeatsNearTo('nearFood')
        break
      }

      default:
    }

    let seatData = [...this.state.seats]

    seatData.forEach(s => {
      document.querySelector('use#PL-' + s.accomId).classList.remove('selected')
    })

    for (var x=0;x<seats.length;x++)      
    {
      seats[x].classList[action]('selected')
    } 

    action === 'add' ? this.setState({ selectAnchor: null }) : this.setState({ deselectAnchor: null })
  }

  toggleSelectedSeats = () =>
  {
    var svgDoc = document.querySelector('.svgCoachDiagram');

    var selector = "[id^='PL-']";

    var ids = svgDoc.querySelectorAll(selector);      

    for (var x=0;x<ids.length;x++)      
    {
      ids[x].classList.toggle('selected');     
    }
  }


  setAttribute = (attribute,value) =>
  {
    if (attribute === 'Dummy') return

    var selectedSeats = Array.from(document.querySelectorAll('use.selected')).map(node => node.id.replace(/PL-/,''))

    let seats = [...this.state.seats]

    for (let s=0;s<seats.length;s++)
    {
      if (selectedSeats.includes(seats[s].accomId))
      {
        switch (attribute)
        {
          case "seatPriority":
          {
            switch (value)
            {
              case "C":
              {
                seats[s].wheelchair = 0
                seats[s].companion = 1
                seats[s].priority = 1

                if (seats[s].seatType === "W")
                {
                  seats[s].seatType = 'A'
                }

                break
              }

              case "P":
              {
                seats[s].wheelchair = 0
                seats[s].companion = 0
                seats[s].priority = 1

                if (seats[s].seatType === "W")
                {
                  seats[s].seatType = 'A'
                }

                break
              }

              default:
              {
                seats[s].wheelchair = 0
                seats[s].companion = 0
                seats[s].priority = 0

                if (seats[s].seatType === "W")
                {
                  seats[s].seatType = 'A'
                }

                break
              }
            }

            break
          }

          case "seatType":
          {
            if (value === "W")
            {
              seats[s].wheelchair = 1
              seats[s].companion = 0
              seats[s].priority = 0
            }
            else
            {
              seats[s].wheelchair = 0            
            }
            
            seats[s][attribute] = value

            break
          }

          default:
          {
            seats[s][attribute] = value
          }
        }
      }
    }

    this.setState({seats})
    this.setState({ setFacingAnchor: null })
    this.setState({ setPosAnchor: null })
    this.setState({ setTypeAnchor: null })
    this.setState({ setClassAnchor: null })
    this.setState({ setAttsAnchor: null })
  }

  saveSeatsAndExit = async () => {
    await this.saveSeats(0)
    this.props.history.push(this.props.history.location.pathname.replace(/train-carriages.*/,'train-carriages#' + this.state.coach.toc + this.state.coach.nrsTypeId))
  }


  saveSeats = async (refresh = 1) => {

    try
    {
      let options = {
        method : 'POST',
        url : `${Config.getConfig('adminApi')}request`,
        data : {
          pkg : "Coach",
          fn : "saveSeatsData",
          params: {
            ourTypeId: this.state.coach.ourTypeId,
            nrsTypeId: this.state.coach.nrsTypeId,
            toc: this.state.coach.toc,
            direction: this.state.coach.direction,
            seats: this.state.seats,
            needsAttention : this.state.coach.needsAttention,
            comment : this.state.coach.comment,
            layout : this.state.layoutChanged ? this.state.coach.layout : null,
          }
        },
        headers : {
          'Content-type': 'application/json',
          'x-api-key': Config.getAPIKey(),
        },
      }

      await Axios(options)

      if (refresh)
      {
        await this.getCoach()
      }
    }
    catch (err)
    {
      throw err
    }
  }


  handleChange = name => event => {
    switch (name)
    {
      case "needsAttention":
      { this.setState({coach:{ ...this.state.coach, [name]: 1 - this.state.coach[name] }})
        break
      }      

      default:
      {
        this.setState({coach:{ ...this.state.coach, [name]: event.target.value }})
      }
    }
  }

  svgToClipboard = async () => {
    navigator.clipboard.writeText(this.state.coach.layout)
      .then(() => {
        // Success!
      })
      .catch(err => {
        console.log('Something went wrong', err);
      })
  }

  svgFromClipboard = async () => {
    navigator.clipboard.readText()
      .then(text => {
        // `text` contains the text read from the clipboard

        text = text.replace(/<\?xml[^>]*>/,"")
        
        if (text.substr(0,4) === "<svg")
        {
          let coach = {...this.state.coach}
          coach.layout = text
          this.setState({coach})
          let layoutChanged = true
          this.setState({layoutChanged})
        }
        else
        {
          alert("Pasted text is not SVG!",text)
        }
      })
      .catch(err => {
        // maybe user didn't grant access to read from clipboard
        console.log('Something went wrong', err);
      })
  }

  render() {
    const { classes } = this.props
    const { selectAnchor, deselectAnchor, setFacingAnchor, setTypeAnchor, setPosAnchor, setClassAnchor, setAttsAnchor } = this.state
    const selectOpen = Boolean(selectAnchor)
    const deselectOpen = Boolean(deselectAnchor)
    const setFacingOpen = Boolean(setFacingAnchor)
    const setPosOpen = Boolean(setPosAnchor)
    const setTypeOpen = Boolean(setTypeAnchor)
    const setClassOpen = Boolean(setClassAnchor)
    const setAttsOpen = Boolean(setAttsAnchor)

    return (
      <React.Fragment>

        <div className="stageMenu">
          <Button component={NavLink} to={`/train-carriages#`+this.state.coach.toc+this.state.coach.nrsTypeId}>Back to coach list</Button>
        </div>

        <Paper className={classes.paper}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableCellHeader}>Our Coach Type</TableCell>
                <TableCell className={classes.tableCellData}>{this.state.coach.ourTypeId}</TableCell>               
                <TableCell className={classes.tableCellHeader}>NRS Coach Type</TableCell>
                <TableCell className={classes.tableCellData}>{this.state.coach.toc}{this.state.coach.nrsTypeId}</TableCell>               
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHeader}>Description</TableCell>
                <TableCell className={classes.tableCellData}>{this.state.coach.description}</TableCell>               
                <TableCell className={classes.tableCellHeader}>Accommodation Type</TableCell>
                <TableCell className={classes.tableCellData}>{accomTypes[this.state.coach.accomType]}</TableCell>               
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHeader}>Coach Type</TableCell>
                <TableCell className={classes.tableCellData}>{coachTypes[this.state.coach.type]}</TableCell>               
                <TableCell className={classes.tableCellHeader}>Coach Class</TableCell>
                <TableCell className={classes.tableCellData}>{this.state.coach.class}</TableCell>               
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHeader}>Reservations permitted</TableCell>
                <TableCell className={classes.tableCellData}>{yesNo[this.state.coach.reservable]}</TableCell>               
                <TableCell className={classes.tableCellHeader}>Number of seats/berths</TableCell>
                <TableCell className={classes.tableCellData}><span className={this.placesClass(this.state.coach.numPlaces,this.state.seats)}>{this.state.coach.numPlaces}</span></TableCell>               
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHeader}>Direction of Travel</TableCell>
                <TableCell className={classes.tableCellData}><span className={this.travelDirectionClass(this.state.coach.direction,this.state.seats)}>{leftRightLong[this.state.coach.direction]}</span></TableCell>               
                <TableCell className={classes.tableCellHeader}>Wheelchair places</TableCell>
                <TableCell className={classes.tableCellData}>{yesNo[this.state.coach.wheelchair]}</TableCell>               
              </TableRow>              
              <TableRow>
                <TableCell className={classes.tableCellHeader}>Wheelchair companion's seats</TableCell>
                <TableCell className={classes.tableCellData}>{yesNo[this.state.coach.companion]}</TableCell>               
                <TableCell className={classes.tableCellHeader}>Priority seats</TableCell>
                <TableCell className={classes.tableCellData}>{yesNo[this.state.coach.priority]}</TableCell>               
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHeader}>Wifi</TableCell>
                <TableCell className={classes.tableCellData}>{yesNo[this.state.coach.wifi]}</TableCell>               
                <TableCell className={classes.tableCellHeader}>Bike spaces</TableCell>
                <TableCell className={classes.tableCellData}>{yesNo[this.state.coach.bike]}</TableCell>
              </TableRow>              
              <TableRow>
                <TableCell className={classes.tableCellHeader}>Manually Checked</TableCell>
                <TableCell className={classes.tableCellData}>{yesNo[this.state.coach.manuallyChecked]}</TableCell>               
                <TableCell className={classes.tableCellHeader}>Needs Attention</TableCell>
                <TableCell className={classes.tableCellData}>no
                  <Switch
                    checked={this.state.coach.needsAttention === 1}
                    onChange={this.handleChange('needsAttention')}
                    value="1"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  /> yes
                </TableCell>               
              </TableRow>     
              <TableRow>
                <TableCell colSpan={4} className={classes.tableCellHeader}>
                  <TextField
                    id="comment"
                    label="Comment"
                    value={this.state.coach.comment ? this.state.coach.comment : ""}
                    onChange={this.handleChange('comment')}
                    className={classes.textField}
                    fullWidth
                    margin="normal"
                  />
                </TableCell>
              </TableRow> 
            </TableBody>
          </Table>
         
        </Paper>

        {this.state.loadedSVG ? <Paper className={classes.paper}>
            <div dangerouslySetInnerHTML={{__html: this.state.coach.layout}} />

            <Button
              aria-label="Select"
              aria-owns={selectOpen ? 'select-menu' : null}
              aria-haspopup="true"
              variant="outlined"
              className={classes.button}
              onClick={this.handleOpenSelectMenu}>Select</Button>

            <Menu
              id="select-menu"
              anchorEl={selectAnchor}
              open={selectOpen}
              onClose={this.handleCloseSelectMenu}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 12,
                  width: 300,
                },
              }}
            >
              {selectOptions.map((option,index) => (
                <MenuItem key={index} onClick={event => this.handleClick(option.label,'add')} disabled={option.disabled}>
                  {option.label}
                </MenuItem>
              ))}
            </Menu>

            <Button
              aria-label="Deselect"
              aria-owns={deselectOpen ? 'deselect-menu' : null}
              aria-haspopup="true"
              variant="outlined"
              className={classes.button}
              onClick={this.handleOpenDeselectMenu}>Deselect</Button>

            <Menu
              id="deselect-menu"
              anchorEl={deselectAnchor}
              open={deselectOpen}
              onClose={this.handleCloseDeselectMenu}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 12,
                  width: 300,
                },
              }}
            >
              {selectOptions.map((option,index) => (
                <MenuItem key={index} onClick={event => this.handleClick(option.label,'remove')} disabled={option.disabled}>
                  {option.label}
                </MenuItem>
              ))}
            </Menu>

            <Button variant="outlined" className={classes.button} onClick={event => this.toggleSelectedSeats()}>Toggle Seats</Button>

            <Button
              aria-label="Set Facing"
              aria-owns={deselectOpen ? 'set-menu-facing' : null}
              aria-haspopup="true"
              variant="outlined"
              color="secondary"   
              className={classes.button}             
              onClick={this.handleOpenSetFacingMenu}>Set Facing</Button>


            <Menu
              id="set-menu-facing"
              anchorEl={setFacingAnchor}
              open={setFacingOpen}
              onClose={this.handleCloseSetFacingMenu}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 12,
                  width: 300,
                },
              }}
            >
              {setOptionsFacing.map((option,index) => (
                <MenuItem key={index} onClick={event => this.setAttribute(option.attribute,option.value)}>
                  {option.label}
                </MenuItem>
              ))}
            </Menu>

            <Button
              aria-label="Set Seat Position"
              aria-owns={deselectOpen ? 'set-menu-pos' : null}
              aria-haspopup="true"
              variant="outlined"
              color="secondary"
              className={classes.button}
              onClick={this.handleOpenSetPosMenu}>Set Seat Position</Button>

            <Menu
              id="set-menu-pos"
              anchorEl={setPosAnchor}
              open={setPosOpen}
              onClose={this.handleCloseSetPosMenu}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 12,
                  width: 300,
                },
              }}
            >
              {setOptionsSeatPos.map((option,index) => (
                <MenuItem key={index} onClick={event => this.setAttribute(option.attribute,option.value)} disabled={option.disabled}>
                  {option.label}
                </MenuItem>
              ))}
            </Menu>

            <Button
              aria-label="Set Seat Type"
              aria-owns={deselectOpen ? 'set-menu-type' : null}
              aria-haspopup="true"
              variant="outlined"
              color="secondary"
              className={classes.button}          
              onClick={this.handleOpenSetTypeMenu}>Set Seat Type</Button>

            <Menu
              id="set-menu-type"
              anchorEl={setTypeAnchor}
              open={setTypeOpen}
              onClose={this.handleCloseSetTypeMenu}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 12,
                  width: 300,
                },
              }}
            >
              {setOptionsSeatType.map((option,index) => (
                <MenuItem key={index} onClick={event => this.setAttribute(option.attribute,option.value)}>
                  {option.label}
                </MenuItem>
              ))}
            </Menu>

            <Button
              aria-label="Set Seat Class"
              aria-owns={deselectOpen ? 'set-menu-class' : null}
              aria-haspopup="true"
              variant="outlined"
              color="secondary"   
              className={classes.button}           
              onClick={this.handleOpenSetClassMenu}>Set Seat Class</Button>

            <Menu
              id="set-menu-class"
              anchorEl={setClassAnchor}
              open={setClassOpen}
              onClose={this.handleCloseSetClassMenu}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 12,
                  width: 300,
                },
              }}
            >
              {setOptionsSeatClass.map((option,index) => (
                <MenuItem key={index} onClick={event => this.setAttribute(option.attribute,option.value)}>
                  {option.label}
                </MenuItem>
              ))}
            </Menu>

            <Button
              aria-label="Set Seat Attributes"
              aria-owns={deselectOpen ? 'set-atts-class' : null}
              aria-haspopup="true"
              variant="outlined"
              color="secondary"   
              className={classes.button}           
              onClick={this.handleOpenSetAttsMenu}>Set Seat Attributes</Button>

            <Menu
              id="set-atts-class"
              anchorEl={setAttsAnchor}
              open={setAttsOpen}
              onClose={this.handleCloseSetAttsMenu}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 12,
                  width: 300,
                },
              }}
            >
              {setOptionsSeatAtts.map((option,index) => (
                <MenuItem key={index} onClick={event => this.setAttribute(option.attribute,option.value)}>
                  {option.label}
                </MenuItem>
              ))}
            </Menu>

            <Button variant="outlined" color="secondary" onClick={event => this.svgToClipboard()}>Copy SVG to clipboard</Button>
            <Button variant="outlined" color="secondary" onClick={event => this.svgFromClipboard()}>Read SVG from clipboard</Button>

            <Button variant="contained" color="secondary" onClick={event => this.saveSeats()}>Save</Button>
            <Button variant="contained" color="primary" onClick={event => this.saveSeatsAndExit()}>Save & Exit</Button>
          </Paper>

          : ''}


        <Paper className={classes.paper}>

          <Table className={classes.table}>

            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>IDX</TableCell>
                <TableCell className={classes.tableCell}>ID</TableCell>
                <TableCell className={classes.tableCell}>Class</TableCell>
                <TableCell className={classes.tableCell}>Facing</TableCell>
                <TableCell className={classes.tableCell}>Type</TableCell>
                <TableCell className={classes.tableCell}>Position</TableCell>
                <TableCell className={classes.tableCell}>Aisle</TableCell>
                <Tooltip title="Priority seat"><TableCell className={classes.tableCell}>Pri</TableCell></Tooltip>
                <Tooltip title="Wheelchair space"><TableCell className={classes.tableCell}>WCS</TableCell></Tooltip>
                <Tooltip title="Wheelchair companions seat"><TableCell className={classes.tableCell}>WCP</TableCell></Tooltip>
                <TableCell className={classes.tableCell}>Wifi</TableCell>
                <Tooltip title="Power sockets"><TableCell className={classes.tableCell}>Pwr</TableCell></Tooltip>
                <Tooltip title="Near luggage racks"><TableCell className={classes.tableCell}>NL</TableCell></Tooltip>
                <Tooltip title="Near toilets"><TableCell className={classes.tableCell}>NT</TableCell></Tooltip>
                <Tooltip title="Near food"><TableCell className={classes.tableCell}>NF</TableCell></Tooltip>
                <TableCell className={classes.tableCell}>Seen</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.state.seats.map((seat,index) => {

                return (
                  <Tooltip title={seat.attributes ? seat.attributes : '-'} key={seat.accomId} >
                    <TableRow className={classes.clickableTableRow + ' ' + classes.stripeRow} onDoubleClick={event => this.drillDown(event,seat.accomId)}>
                      <TableCell className={classes.tableCell}>{index+1}</TableCell>
                      <TableCell className={classes.tableCell}><span className={this.attentionNeededClass(seat)}>{seat.accomId}</span></TableCell>
                      <TableCell className={classes.tableCell}><span className={this.classClass(seat)}>{seat.bike ? '' : seat.class}</span></TableCell>
                      <TableCell className={classes.tableCell}><span className={this.facingClass(seat)}>{seat.bike ? '' : facing[seat.facing]}</span></TableCell>
                      <TableCell className={classes.tableCell}><span className={this.seatTypeClass(seat)}>{seat.bike ? 'BIKE SPACE' : seatType[seat.seatType]}</span></TableCell>
                      <TableCell className={classes.tableCell}><span className={this.seatPosClass(seat)}>{seat.bike ? '' : seatPos[seat.seatPos]}</span></TableCell>
                      <TableCell className={classes.tableCell}><span className={this.aislePosClass(seat)}>{seat.bike ? '' : this.aislePos(seat)}</span></TableCell>
                      <TableCell className={classes.tableCell}>{seat.bike ? '' : yesNo[seat.priority]}</TableCell>
                      <TableCell className={classes.tableCell}>{seat.bike ? '' : yesNo[seat.wheelchair]}</TableCell>
                      <TableCell className={classes.tableCell}>{seat.bike ? '' : yesNo[seat.companion]}</TableCell>
                      <TableCell className={classes.tableCell}>{seat.bike ? '' : yesNo[seat.wifi]}</TableCell>
                      <TableCell className={classes.tableCell}>{seat.bike ? '' : yesNo[seat.power]}</TableCell>
                      <TableCell className={classes.tableCell}>{seat.bike ? '' : yesNo[seat.nearLuggage]}</TableCell>
                      <TableCell className={classes.tableCell}>{seat.bike ? '' : yesNo[seat.nearToilets]}</TableCell>
                      <TableCell className={classes.tableCell}>{seat.bike ? '' : yesNo[seat.nearFood]}</TableCell>
                      <TableCell className={classes.tableCell}>{seat.bike ? '' : yesNo[seat.seenAvailable]}</TableCell>

                      <TableCell className={classes.tableCell}><IconButton color="primary" onClick={event => this.drillDown(event,seat.accomId)}><ViewIcon /></IconButton></TableCell>
                    </TableRow>
                  </Tooltip>

                )
              })}
            </TableBody>
          </Table>

        </Paper>

      </React.Fragment>
    )
  }
}

Coach.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(Coach))
